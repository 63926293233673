const OS = getOS();

function getOS() {
    if(window && window.navigator) {
        if(['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(window.navigator.platform) > -1) {
            return 'Mac';
        }
        else if (['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(window.navigator.platform) > -1) {
            return 'Win';
        }
        else if (['iPhone', 'iPad', 'iPod'].indexOf(window.navigator.platform) > -1) {
            return 'iOS';
        }
        else if(/Android/.test(window.navigator.userAgent)) {
            return 'Android';
        }
        else if(/Linux/.test(window.navigator.userAgent)) {
            return 'Linux';
        }
    }
    return 'Unknown';
}

export default OS;