import React,{useState,useEffect} from 'react';
import {Modal,Button,Dropdown,Input} from 'semantic-ui-react';
import {parseDate} from '../../../util/calendar';

const centuryYear2Text = (century,year) => {
    return (century * 100) + year;
}

const text2CenturyYear = (text) => {
    return {
        century: Math.floor(text / 100),
        year: text % 100
    }
}

const numberOnly = (text) => {
    return Math.floor(text);
}

const DateModal = ({open,dateString,onClose,onSave,calendar}) => {
    const [month,setMonth] = useState();
    const [day,setDay] = useState();
    //const [century,setCentury] = useState();
    //const [year,setYear] = useState();
    const [hour,setHour] = useState();
    const [minute,setMinute] = useState();
    const [fullyear,setFullyear] = useState();
    const date = parseDate(dateString);
    const days = calendar.getDayOptions(month).map( d => 
        <button 
            style={{ 
                width: '3em', 
                textAlign: 'center', 
                backgroundColor: (d.value === day)?'#008080':null}}
            onClick={() => setDay(d.value)}
            key={d.key}>{d.text}</button>);
    
    useEffect( () => {
        setMonth(date.month);
        setDay(date.day);
        //setCentury(date.century);
        //setYear(date.year);
        setHour(date.hour);
        setMinute(date.minute);
        setFullyear(centuryYear2Text(date.century,date.year));
    },[]);

    return (
        <Modal open={open} size='tiny' className='ceta-modal'>
            <Modal.Content>
                <div style={{ width: '100%'}}>
                <div style={{ width: '300px',marginLeft:'auto',marginRight:'auto'}}>
                    <div style={{ display: 'inline-block',marginBottom:'1em'}}>
                        Year&nbsp;&nbsp;
                        <Input type='number' step={1} value={fullyear} onChange={(ev,dat) => setFullyear(numberOnly(dat.value))}/>
                    </div>
                    <div style={{ marginBottom: '0.5em'}}>
                        <Dropdown selection options={calendar.getMonthOptions()} value={month} onChange={(ev,dat) => setMonth(dat.value)}/>
                        <p/>
                        <div style={{ display:'flex',flexDirection: 'row',flexWrap:'wrap'}}>
                            {days}
                        </div>
                    </div>
                    <div style={{ display: 'inline-block',marginBottom:'1em'}}>
                        Hour&nbsp;:&nbsp;Minute&nbsp;&nbsp;
                        <Dropdown compact={true} selection options={calendar.getHourOptions()} value={hour} onChange={(ev,dat) => setHour(dat.value)}/>
                        &nbsp;:&nbsp;
                        <Dropdown compact={true} selection options={calendar.getMinuteOptions()} value={minute} onChange={(ev,dat) => setMinute(dat.value)}/>
                    </div>
                </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button color='teal' onClick={() => {
                    const cy = text2CenturyYear(fullyear);
                    onSave(cy.century+','+cy.year+','+month+','+day+','+hour+','+minute);
                    onClose();
                }}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
}
export default DateModal;