import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Segment,Grid,Header,Message,Button,Icon, Form} from 'semantic-ui-react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import Toolbar from '../header/Toolbar';
import Showable from '../../components/Showable';
import {subscribePremium} from '../../payment/actions'

const PaymentPage = (props) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const email = useSelector(state => state.user.email);
    const isSubscribing = useSelector(state => state.payment.isSubscribing);
    const hasSubscribed = useSelector(state => state.payment.hasSubscribed);
    const subErrorMessage = useSelector(state => state.payment.errorMessage);
    const subShowError = useSelector(state => state.payment.showError);
    const [hasAgreed,setHasAgreed] = useState(false);
    const [creatingStripePayment,setCreatingStripePayment] = useState(false);
    const [showError,setShowError] = useState(false);
    const [errorMessage,setErrorMessage] = useState('');
    const isRejected = useSelector(state => state.payment.isRejected);

    const cardElementOptions = {
        style: {
            base: {
              color: "#fff",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          }        
    };

    const agreementText = (
        <label style={{textAlign:'left'}}>
            I understand that I am agreeing to an ongoing monthly fee of $4.99 (USD) that will
            automatically billed at the start of each monthly cycle. Cancellation of this subscription
            or an invalid payment method will result in the suspension of premium services.
        </label>);

    return (
        <div>
            <Toolbar>
                <div>
                <Grid inverted textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                    <Header inverted as='h1'>C e t a c e a n</Header>                    
                        <Showable showIf={!hasSubscribed}>
                        <Segment inverted style={{color:'white'}}>
                            <p style={{marginBottom:'3em'}}>
                            <label style={{fontWeight:'bold',float:'left'}}>Cetacean Premium</label>
                            <label style={{fontWeight:'bold',float:'right'}}>$4.99 (USD) per month</label>
                            </p>
                            <Form inverted size='large' error={showError || isRejected || subShowError}>
                            <Form.Field>
                                <CardElement options={cardElementOptions} />                                
                            </Form.Field>
                            <Form.Field>
                                <Form.Checkbox 
                                    label={agreementText}
                                    checked={hasAgreed}
                                    onChange={() => setHasAgreed(!hasAgreed)}
                                />
                            </Form.Field>
                            <Message
                                error
                                header='Payment Failed'
                                content={errorMessage || subErrorMessage}
                            />

                            <Button 
                                loading={(creatingStripePayment || isSubscribing)}
                                disabled={!hasAgreed} color='teal' 
                                fluid size='large' 
                                onClick={ async () => {
                                    setCreatingStripePayment(true);
                                    const result = await stripe.createPaymentMethod({
                                        type: 'card',
                                        card: elements.getElement(CardElement),
                                        billing_details: {
                                          email: email,
                                        },
                                      });
                                      
                                      if(result.error) {
                                        setErrorMessage(result.error);
                                        setShowError(true);
                                      }
                                      else {
                                        setCreatingStripePayment(false);
                                        dispatch(subscribePremium( stripe,{paymentMethod: result.paymentMethod.id}));
                                      }
                                }}>
                                <Icon name='credit card outline'/> Pay Now
                            </Button>
                            </Form>
                        </Segment>
                        </Showable>

                        <Showable showIf={hasSubscribed}>
                            <Message>
                                <Message.Header>You Have Subscribed</Message.Header>
                                Your monthly subscription to Cetacean Premium has been activated.
                            </Message>

                        </Showable>
                    </Grid.Column>
                </Grid>
                </div>
            </Toolbar>
        </div>
    );
}

export default PaymentPage;