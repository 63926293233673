import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Step,Icon,Container,Label} from 'semantic-ui-react';
import {hideIntroModal,updateLocalProjectView} from '../../project/actions';

const IntroModal = (props) => {
    const dispatch = useDispatch();
    const showIntroModal = useSelector(state => state.projects.showIntroModal);
    const [selected,setSelected] = useState('');
    return (
        <Modal open={showIntroModal}>
            <Modal.Header className='ceta-modal-header'>
                Where would you like to start?
            </Modal.Header>
            <Modal.Content className='ceta-modal-content'>
                <div style={{ display:'table',width:'100%'}}>
                    <div style={{ display:'table-row', width:'40%'}}>
                        <div style={{ display:'table-cell',textAlign:'right',paddingBottom:'1em'}}>
                            <Button color='teal' size='huge' fluid 
                                onClick={() => dispatch(updateLocalProjectView('Brainstorm'))} >
                                <Icon name='idea'/>Brainstorm
                            </Button>
                        </div>
                        <div style={{ display:'table-cell',fontSize:'larger',fontWeight:'bold',paddingLeft:'1em'}}>
                            Research and dream up ideas for your story
                        </div>
                    </div>

                    <div style={{ display:'table-row', width:'40%'}}>
                        <div style={{ display:'table-cell',textAlign:'right',paddingBottom:'1em'}}>
                            <Button color='teal' size='huge' fluid
                                onClick={() => dispatch(updateLocalProjectView('Outline'))} >
                                <Icon name='list'/>Outline
                            </Button>
                        </div>
                        <div style={{ display:'table-cell',fontSize:'larger',fontWeight:'bold',paddingLeft:'1em'}}>
                            Organize your ideas into a story structure
                        </div>
                    </div>

                    <div style={{ display:'table-row', width:'40%'}}>
                        <div style={{ display:'table-cell',textAlign:'right',paddingBottom:'1em'}}>
                            <Button color='teal' size='huge' fluid
                                onClick={() => dispatch(updateLocalProjectView('Writing'))} >
                                <Icon name='pencil'/>Writing
                            </Button>
                        </div>
                        <div style={{ display:'table-cell',fontSize:'larger',fontWeight:'bold',paddingLeft:'1em'}}>
                            Get your first draft done
                        </div>
                    </div>

                </div>
            </Modal.Content>
        </Modal>
    );
};

export default IntroModal;