import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Dimmer,Loader,Button} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import NewThoughtModal from './NewThoughtModal';
import Thought from './Thought';
import ProfileModal from './ProfileModal';
import {fetchThought} from '../../feed/actions';

const CurrentUserFeed = ({match}) => {
    const dispatch = useDispatch();
    const isFetchingThoughtComments = useSelector(state => state.feed.isFetchingThoughtComments);
    const openThought = useSelector(state => state.feed.openThought);

    useEffect( () => {
        console.log(match.params.thoughtId)
        dispatch(fetchThought(match.params.thoughtId));
    },[dispatch]);

    const commentList = openThought?.comments?.map( comment => <Thought thought={comment} readOnly={true} />)

    return (
        <Toolbar>
            <Dimmer active={isFetchingThoughtComments}>
                <Loader>Loading</Loader>
            </Dimmer>
            <NewThoughtModal />
            <ProfileModal />
            <div style={{ width: '40em',marginLeft:'auto',marginRight:'auto'}}>
                <Thought thought={openThought} readOnly={true}/>
                <div><h3 style={{ color:'white'}}>Comments</h3></div>
                <div>{commentList}</div>
            </div>
        </Toolbar>
    );
};

export default CurrentUserFeed;