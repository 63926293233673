import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import ChapterEditPanel from './writing/ChapterEditPanel';
import IdeaBoardPanel from './brainstorm/IdeaBoardPanel';
import CharacterEditPanel from './brainstorm/CharacterEditPanel';
import LocationEditPanel from './brainstorm/LocationEditPanel';
import OutlinePanel from './outline/OutlinePanel';
import RevisionPanel from './revision/RevisionPanel';
import TimelinesPanel from './outline/TimelinesPanel';
import {openChapter,openChaptersPanel,openIdeaBoard,openOutline,openRevision} from '../../project/actions';

const ProjectPanel = ({useMobile}) => {
    const dispatch = useDispatch();
    const projects = useSelector( state => state.projects);
    const projectView = useSelector( state => state.projects.openProject.view);

    useEffect( () => {
        switch(projectView) {
            case 'Brainstorm' : {
                dispatch(openIdeaBoard());
                break;
            }
            case 'Outline' : {
                dispatch(openOutline());
                break;
            }
            case 'Revision' : {
                dispatch(openRevision());
                break;
            }
            default: {
                if('Book' === projects.openProject.projectType) {
                    if(projects.openProject.chapters[0]) {
                        dispatch(openChapter(projects.openProject.chapters[0]._id));
                    }
                    else {                        
                        dispatch(openChaptersPanel(projects.openProject._id));
                    }
                }        
            }
        }
    },[dispatch,projectView]);

    switch(projects.openProjectPanel) {
        
        case 'Chapter':
            return (
                <ChapterEditPanel key={projects.openProject._id} useMobile={useMobile}/>
            );
        case 'IdeaBoard':
            return ( <IdeaBoardPanel key={projects.openProject._id}/>)
        case 'Outline':
            return ( <OutlinePanel key={projects.openProject._id}/>)
    
        case 'Character':
            return (
                <CharacterEditPanel key={projects.openProject._id}/>
            );    
        case 'Location':
            return (<LocationEditPanel key={projects.openProject._id}/>);    
        case 'Revision':
            return (<RevisionPanel key={projects.openProject._id} />);
        case 'Timeline':
            return (<TimelinesPanel key={projects.openProject._id} />);
    
        default:
            return (
                <div>
                    Project Panel Here
                </div>
            );            
    }
}

export default ProjectPanel;