import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Form} from 'semantic-ui-react';
import {addResearch,hideNewResearchLinkModal} from '../../../project/actions';

const NewLinkModal = (props) => {
    const dispatch = useDispatch();
    const showNewLinkModal = useSelector(state => state.projects.showNewLinkModal);
    const [text,setText] = useState('');
    return (
        <Modal open={showNewLinkModal}>
            <Modal.Header style={{backgroundColor:'#363636',color:'white'}}>
                Enter Website URL
            </Modal.Header>
            <Modal.Content style={{backgroundColor:'#363636',color:'white'}}>
                <Form>
                    <Form.Input placeholder='Website URL...' value={text} onChange={(ev) => setText(ev.target.value)}/>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636',color:'white'}}>
                <Button onClick={() => dispatch(hideNewResearchLinkModal())}>Cancel</Button>
                <Button disabled={(text==='')} color='teal' onClick={() => {
                    dispatch(addResearch('Website',text));
                    dispatch(hideNewResearchLinkModal());
                    setText('');
                }}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default NewLinkModal;