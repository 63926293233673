import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import ResponsiveContainer from './ResponsiveContainer';

const FAQ = () => (
  <ResponsiveContainer slug='Frequently Asked Questions'>
    <Segment style={{ padding: '8em 0em',backgroundColor:'white' }} vertical id='Features'>
    <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Accounts
        </Header>

        <Header as='h3' style={{ fontSize: '1.5em' }}>
          I signed up with a different email than the one I want to use
        </Header>
        <p style={{ fontSize: '1.25em' }}>
            Your email is the only way we have to verify who you are. You will need to email support@cetacean.app from the
            email account you signed up with and request a change.
        </p>

        <Header as='h3' style={{ fontSize: '1.5em' }}>
          I no longer have access to the email account I used to sign up for Cetacean.
        </Header>
        <p style={{ fontSize: '1.25em' }}>
            Unfortunately, there is nothing we can do about this. We take the security of our users very seriously and your 
            email is the only way we have to verify who you are. In order to prevent malicious actors from hijacking
            the accounts of others, it is policy that all support communication go through the registered email on the
            user account.
        </p>

    </Container>
    <Divider />
    <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Projects
        </Header>

        <Header as='h3' style={{ fontSize: '1.5em' }}>
          Why can I only create one project?
        </Header>
        <p style={{ fontSize: '1.25em' }}>
            Users of the free tier of Cetacean are limited to 1 project. To remove this restriction, you will need to
            pay for a subscription to Cetacean Premium.
        </p>

        <Header as='h3' style={{ fontSize: '1.5em' }}>
          I canceled my Cetacean Premium subscription and now I can only access one of my projects.
        </Header>
        <p style={{ fontSize: '1.25em' }}>
            Moving down to the free tier means you only have access to a single project. The other projects are not deleted,
            but locked. To unlock them, simply renew your subscription to Cetacean Premium and you will have full access again.
        </p>

    </Container>        

    <Divider />
    <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Collaboration
        </Header>

        <Header as='h3' style={{ fontSize: '1.5em' }}>
          Will free tier users be able to receive my messages and shared projects if I'm a Cetacean Premium user?
        </Header>
        <p style={{ fontSize: '1.25em' }}>
            Yes! Free tier users can read but not send messages, and can read but not edit shared project checkpoints. We do
            not expect your beta readers to have to pay in order to read your work.
        </p>

    </Container>        

    </Segment>


    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Help' />
              <List link inverted>
                <List.Item as='a'>FAQ</List.Item>
                <List.Item as='a' href="mailto:support@cetacean.app">Contact Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Company' />
              <List link inverted>
                <List.Item as='a' href="https://www.bluewizardlabs.com">About Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                Follow us on social media
              </Header>
              <p>
                <Icon color='teal' size='big' name='twitter square' link onClick={() => window.open('http://www.twitter.com/CetaceanApp')}/>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default FAQ