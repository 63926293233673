import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,TextArea,Icon,Label,Dropdown,Input,Statistic} from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import {Link} from 'react-router-dom';
import Avatar from '../../components/Avatar';
import Showable from '../../components/Showable';
import UploadedImage from '../../components/UploadedImage';
import WordCountThought from './WordCountThought';
import {closeNewThoughtModal,createThought} from '../../feed/actions';
import {fetchProjects} from '../../project/actions';
import {hasPremium} from '../../services/roles';

const getChapterOptions = (projects,project) => {
    if(projects && project) {
        for(let i=0;i<projects.length;i++) {
            if(projects[i]._id === project) {
                return projects[i].chapters.map( (chapter,index) => ({ key:chapter, text:'Chapter '+(index+1), value:chapter}));
            }
        }
    }
    return [];
}

const NewThoughtModal = ({wordCountProject}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const showNewThoughtModal = useSelector(state => state.feed.showNewThoughtModal);
    const isCreatingThought = useSelector(state => state.feed.isCreatingThought);
    const projects = useSelector(state => state.projects.all);
    const [text,setText] = useState('');
    const [tags,setTags] = useState([]);
    const [tag,setTag] = useState('');
    const [thoughtType,setThoughtType] = useState('Public');
    const [media,setMedia] = useState('');
    const [mediaType,setMediaType] = useState('');
    const [showImage,setShowImage] = useState(false);
    const [showVideo,setShowVideo] = useState(false);
    const [showProject,setShowProject] = useState(false);
    const [project,setProject] = useState('');
    const [chapter,setChapter] = useState('');

    useEffect( () => {
        dispatch(fetchProjects());
    },[dispatch]);

    const thoughtTypeOptions = [
        { key:'Public',text:'Public (Everyone can read)',value:'Public'},
        { key:'Private',text:'Private (Only your contacts can read)',value:'Private',icon:'lock'},
    ];

    const tagList = tags ? tags.map( tag => <Label key={tag} content={tag} color='teal' removeIcon='delete' onRemove={() => {
        let newTags = tags.filter(t => t !== tag);
        setTags(newTags);
    }}/>) : [];

    const projectOptions = projects.map( project => ({ key:project._id, text: project.name, value:project._id}));
    const chapterOptions = getChapterOptions(projects,project);

    const wordCountSection = wordCountProject ? (
        <WordCountThought 
            name={wordCountProject.name} 
            wordCount={wordCountProject.wordCount} 
            wordGoal={wordCountProject.wordGoal}
            />
        ) : null;

    return (
        <Modal open={showNewThoughtModal} className='ceta-modal'>
            <Modal.Header>
                <Avatar /> <span>Compose A Thought</span>
            </Modal.Header>
            <Modal.Content>
                    {wordCountSection}
                    <TextArea style={{ 
                        width: '100%',marginBottom:'1em',resize:'none',
                        border: 'none',outline: 'none',boxShadow:'none',
                        padding:'0.5em'
                        }} 
                        rows={7} value={text} autoFocus={true}
                        placeholder='What is on your mind...'
                        onChange={ (ev) => setText(ev.target.value)}/>
                    <Input style={{ marginBottom: '1em'}} fluid placeholder='Add a tag' 
                        value={tag} 
                        action={{ color:'teal',icon:'tag',content:'Add',onClick: (ev) => {
                            if(tag) {
                                let newTags = [tag];
                                newTags = newTags.concat(tags);
                                setTags(newTags);
                                setTag('');
                            }
                        } }}
                        onKeyDown={(ev) => {
                            if(ev.key === 'Enter') {
                                if(tag) {
                                    let newTags = [tag];
                                    newTags = newTags.concat(tags);
                                    setTags(newTags);
                                    setTag('');
                                }
                            }
                        }}                        
                        onChange={ (ev) => setTag(ev.target.value) }/>
                    <div style={{ marginBottom:'1em'}}>{tagList}</div>
                    <div style={{ marginBottom:'1em'}}>
                        <Dropdown selection fluid options={thoughtTypeOptions} value={thoughtType} onChange={(ev,dat) => setThoughtType(dat.value)}/>
                    </div>
                    <div style={{ marginBottom:'1em'}}>
                        <Showable showIf={!showProject}>
                            <Showable showIf={hasPremium(user)}>
                                <Button color='teal' icon onClick={(ev) => setShowProject(true)}><Icon name='book'/>Add Chapter</Button>
                            </Showable>
                            <Showable showIf={!hasPremium(user)}>
                                <Button as={Link} to='/getpremium' color='orange' onClick={() => dispatch(closeNewThoughtModal())}>Get Premium To Share Project Chapters</Button>
                            </Showable>
                        </Showable>
                        <Showable showIf={showProject}>
                            <Button icon onClick={() => {
                                setShowProject(false);
                                setProject('');
                                setChapter('');
                            }}><Icon name='cancel'/>Cancel</Button>
                            <Dropdown selection placeholder='Select a project...' options={projectOptions} value={project} onChange={(ev,dat) => {
                                setProject(dat.value);
                                setChapter('');
                                }} />
                            <Dropdown selection placeholder='Select a chapter...' options={chapterOptions} value={chapter} onChange={(ev,dat) => {
                                setChapter(dat.value);
                                }} />
                        </Showable>
                        <Showable showIf={!showImage && !showVideo}>
                            <Button color='teal' icon onClick={(ev) => setShowImage(true)}><Icon name='image'/>Add Image</Button>
                            <Button color='teal' icon onClick={() => setShowVideo(true)}><Icon name='video'/>Add Video</Button>
                        </Showable>
                        <Showable showIf={showImage}>
                            <Button icon onClick={() => {
                                    setShowImage(false);
                                    setMedia('');
                                    setMediaType('');
                                }}><Icon name='cancel'/>Cancel</Button>
                            <UploadedImage 
                                    src={media}
                                    forThought={true}
                                    stock='/img/ceta-persona-default.png'
                                    onDelete={ () => {
                                        setMedia('');
                                        setMediaType('');
                                    }}
                                    onSuccess={ (result) => {
                                        setMedia(result);
                                        setMediaType('Image');
                                    }}
                                />
                        </Showable>
                        <Showable showIf={showVideo}>
                            <Button icon onClick={() => {
                                        setShowVideo(false);
                                        setMedia('');
                                        setMediaType('');
                                    }}><Icon name='cancel'/>Cancel</Button>

                            <Input placeholder='Video URL...' value={media} onChange={(ev) => {
                                setMedia(ev.target.value);
                                setMediaType('Video');
                                }}/>
                            <div className='ceta-video-wrapper'>
                                <ReactPlayer url={media} width='100%' height='100%' className='ceta-video'/>
                            </div>
                        </Showable>
                    </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    dispatch(closeNewThoughtModal());
                    setText('');
                    setTags([]);
                    setTag('');
                    setThoughtType('Public');
                    setMedia('');
                    setMediaType('');
                    setProject('');
                    setChapter('');
                    setShowImage(false);
                    setShowVideo(false);     
                    setShowProject(false);       
                }}>Cancel</Button>
                <Button color='teal' disabled={!(text || media)} loading={isCreatingThought} onClick={() => {
                    const wordCountOptions = {};
                    if(wordCountProject) {
                        wordCountOptions.progressName = wordCountProject.name;
                        wordCountOptions.progressWordCount = wordCountProject.wordCount;
                        wordCountOptions.progressWordGoal = wordCountProject.wordGoal;
                    }
                    const documentOptions = {};
                    if(project && chapter) {
                        documentOptions.project = project;
                        documentOptions.chapter = chapter;
                    }
                    dispatch(createThought({
                        thoughtType,
                        text,
                        tags,
                        mediaType,
                        media,
                        ...wordCountOptions,
                        ...documentOptions
                    }));
                    setText('');
                    setTags([]);
                    setTag('');
                    setThoughtType('Public');
                    setMedia('');
                    setMediaType('');
                    setProject('');
                    setChapter('');
                    setShowImage(false);
                    setShowVideo(false);
                    setShowProject(false);          
                    }}>Post</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default NewThoughtModal;