import React,{useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Modal,Icon,Form,Radio} from 'semantic-ui-react';
import {exportToOpenXml} from '../../../util/exportToOpenXml';
import {exportToPlainText} from '../../../util/exportToPlainText';
import {hideExportModal,exportEpub,exportPdf} from '../../../project/actions';
import {hasPremium} from '../../../services/roles';

const ExportModal = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const showExportModal = useSelector(state => state.projects.showExportModal);
    const isFetchingProject = useSelector(state => state.projects.isFetchingProject);
    const openProject = useSelector(state => state.projects.openProject);
    const [format,setFormat] = useState('docx');

    return (
        <Modal open={showExportModal}>
            <Modal.Header style={{backgroundColor:'#363636', color:'white'}}>Select Document Format</Modal.Header>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
                <Form>
                    <Form.Field>
                        Choose a format to export to:
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label={(<label style={{color:'white'}}>Word Document (.docx)</label>)}
                            name='radioGroup'
                            value='docx'
                            checked={format === 'docx'}
                            onChange={() => setFormat('docx')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio 
                            label={(<label style={{color:'white'}}>Plain Text (.txt)</label>)}
                            name='radioGroup'
                            value='txt'
                            checked={format === 'txt'}
                            onChange={() => setFormat('txt')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio 
                            label={(<label style={{color:'white'}}>ePub (.epub){!hasPremium(user)?' PREMIUM ONLY':null}</label>)}
                            disabled={!hasPremium(user)}
                            name='radioGroup'
                            value='epub'
                            checked={format === 'epub'}
                            onChange={() => setFormat('epub')}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio 
                            label={(<label style={{color:'white'}}>PDF (.pdf){!hasPremium(user)?' PREMIUM ONLY':null}</label>)}
                            disabled={!hasPremium(user)}
                            name='radioGroup'
                            value='pdf'
                            checked={format === 'pdf'}
                            onChange={() => setFormat('pdf')}
                        />
                    </Form.Field>

                </Form>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
                <Button onClick={() => dispatch(hideExportModal())}>Cancel</Button>
                <Button color='teal' onClick={()=>{
                    if(format === 'docx') {
                        exportToOpenXml(openProject);
                    }
                    else if (format === 'txt') {
                        exportToPlainText(openProject);
                    }
                    else if (format == 'epub') {
                        dispatch(exportEpub(openProject._id,openProject.name));
                    }
                    else if (format == 'pdf') {
                        dispatch(exportPdf(openProject._id,openProject.name));
                    }
                    dispatch(hideExportModal());
                }}><Icon name='print' loading={isFetchingProject}/>Export</Button>
                
            </Modal.Actions>
        </Modal>

    );
}

export default ExportModal;