import React,{useState} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import {Grid,Button,Message,Icon} from 'semantic-ui-react';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Link} from 'react-router-dom';
import {hasRole,ROLES} from '../../services/roles';
import Showable from '../../components/Showable';
import ConfirmModal from '../../components/ConfirmModal';
import {unsubscribePremium} from '../../payment/actions';

const ManageSubscription = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isUnsubscribing = useSelector(state => state.payment.isUnsubscribing);
    const isPremium = hasRole(user,ROLES.Premium);
    const [showConfirm,setShowConfirm] = useState(false);
    let subEndDate = new Date();
    if(user.stripeSubscriptionEnd) {
        subEndDate = new Date((user.stripeSubscriptionEnd * 1000));
    }
    return (
        <div>
            <Showable showIf={(isPremium && user.stripeSubscriptionCanceled)}>
                <Message>
                    <Message.Header>
                        Subscription Canceled
                    </Message.Header>
                    Your Cetacean Premium subscription ends on:&nbsp;
                    {format(utcToZonedTime(subEndDate, user.timezone), 'MMM dd, yyyy', { timezone:user.timezone })}
                </Message>
            </Showable>
            <Showable showIf={(isPremium && !user.stripeSubscriptionCanceled)}>
                <ConfirmModal 
                        show={showConfirm} 
                        title='Cancel Subscription'
                        message='Are you sure you want to your subscription? You will no longer have access premium features and all but one of your projects will become locked and unaccessable.'
                        onCancel={() => setShowConfirm(false)} 
                        onConfirm={ () => {
                            dispatch(unsubscribePremium());
                            setShowConfirm(false);                        
                        }}/>

                    <Button loading={isUnsubscribing} color='teal' onClick={() => setShowConfirm(true)}><Icon name='attention'/>Cancel Subscription</Button>
            </Showable>
            <Showable showIf={!isPremium}>
                <Grid inverted textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Message>
                            <Message.Header>You do not have an active subscription</Message.Header>
                            For $4.99 (USD) a month, you gain access to all of Cetacean Premium's features.
                        </Message>
                        <Button as={Link} to='/getpremium/payment' color='teal' size='large'>
                            Subscribe To Cetacean Premium
                        </Button>
                    </Grid.Column>
                </Grid>
            </Showable>
        </div>
    );
}

export default ManageSubscription;