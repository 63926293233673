import React from 'react';
import {useSelector} from 'react-redux';
import RichTextEditor from '../../editor/RichTextEditor';

const ChapterReadPanel = (props) =>  {
    const projects = useSelector(state => state.projects);

    const textElement = (projects.openProjectChapter.text) 
    ? JSON.parse(projects.openProjectChapter.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
            <RichTextEditor 
                key={projects.openProjectChapter._id}
                content={textElement}
                footer={true}
                centered={true}
                readOnly={true}
            />
    );
}

export default ChapterReadPanel;