import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Transition } from 'react-transition-group'
import {Dimmer,Loader,Menu,Icon,Sticky,Ref} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {toggleDistractionFree} from '../../../user/actions';
import {hideMainSideBar,openReadChaptersSideBar, openProject, openProjectDiff} from '../../../project/actions';

const duration = 200;
const sidebarStyle = {
  transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
  entering: { width: 0 },
  entered: { width: '200px' },
  exiting: { width: '200px' },
  exited: { width: 0 }
}
const linkStyle = {
  transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
}


const ReadSideBar = (props) => {
    const dispatch = useDispatch();
    const showMain = useSelector(state => state.projects.showMainSideBar);    
    const isProjectDirty = useSelector(state => state.projects.isProjectDirty);
    const isDistractionFree = useSelector(state => state.user.isDistractionFree);
    const openProjectPrevious = useSelector(state => state.projects.openProjectPrevious);
    
    const backMenuItem =openProjectPrevious ? (
        <Menu.Item onClick={() => dispatch(openProject(openProjectPrevious))}>Go Back<Icon name='reply'/></Menu.Item>
    ) : (
        <Menu.Item as={Link} to='/'>Return to Projects<Icon name='reply'/></Menu.Item>
    );

    return(
        <Transition in={showMain} timeout={duration}>
        {(state) => (
        <div style={{
            minHeight: '100vh',
          ...sidebarStyle,
          ...sidebarTransitionStyles[state]
        }}>
            <Transition in={showMain} timeout={duration}>
            {(state) => (
                <div style={{
                ...linkStyle,
                ...linkTransitionStyles[state]
                }}>
                    <div style={{ width: '200px'}}>
                    <Menu vertical fluid inverted style={{ minHeight:'100vh'}}>
                        <Menu.Item onClick={() => dispatch(hideMainSideBar())}>Hide<Icon name='close'/></Menu.Item>
                        {backMenuItem}
                        <Menu.Item onClick={() => dispatch(toggleDistractionFree(!isDistractionFree))}>
                            Distraction Free <Icon name='expand' />
                        </Menu.Item>
                        <Menu.Item key='Chapters' onClick={() => dispatch(openReadChaptersSideBar())} >Chapters<Icon name='book'/></Menu.Item>
                        <Menu.Item key='Diff' onClick={() => dispatch(openProjectDiff(openProjectPrevious))}>Changes<Icon name='exchange'/></Menu.Item>
                    </Menu>
                    </div>
                </div>
            )}
            </Transition>            
          
        </div>

        )}            
        
    </Transition>
    );
}

export default ReadSideBar;