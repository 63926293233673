import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Button, Card, Dimmer,Loader,Icon,Popup,Container,Transition} from 'semantic-ui-react';
import {fetchSharedCheckpoints,fetchLentCheckpoints,openCheckpointSharing} from '../../checkpoint/actions';
import {editBook,readBook} from '../../project/actions';
import Toolbar from '../header/Toolbar';

const OtherBookCard = ({share}) => {
    const dispatch = useDispatch();
    const project = share.project;
    
    const coverUrl = project.cover ? project.cover : '/img/stock-cover.png';
    const actionButton = share.editor ? <Button color='orange' onClick={() => dispatch(editBook(project._id))}>Edit</Button> 
        : <Button color='teal' onClick={() => dispatch(readBook(project._id))}>Read</Button>;
    return (
        <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={project._id}>
        <div className='ceta-dashboard-book'>            
            <div className='image-cell'>
                <img src={coverUrl} width='100' height='160' style={{ objectFit: 'cover',cursor:'pointer'}}/>
            </div>
            <div className='content-cell'>
                <div className='title'>{project.name}</div>
                <div className='meta'>Written by: {share.owner}</div>
                <div className='meta'>Version: {project.version}</div>
                <div className='meta'>{project.genre}</div>
                <div className='meta'>{project.chapters.length} chapters</div>
                <div className='meta'>{project.wordCount} words</div>
                {actionButton}
            </div>
        </div>
        </Transition>
    );
};

const NoOthers = (props) => {
    const dispatch = useDispatch();
    return (
        <Container text textAlign='center'>
            <div>
                <h1>Nobody has shared a project with you</h1>
            </div>
            <p/>

        </Container>
    );
}

const OtherProjectsPage = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const allProjects = useSelector(state => state.projects.all);
    const shared = useSelector(state => state.checkpoints.shared);
    const isFetchingProjects = useSelector(state => state.projects.isFetchingProjects);

    useEffect( () => {
        dispatch(fetchSharedCheckpoints());
    },[dispatch]);

    const bodyContent = shared.length === 0 ? <NoOthers /> 
        : shared.map( share => <OtherBookCard key={share._id} share={share} />);

    return (
        <Toolbar>
            <Dimmer active={isFetchingProjects}>
                <Loader>Loading</Loader>
            </Dimmer>
            <div className='ceta-dashboard'>
                {bodyContent}
            </div>
        </Toolbar>
    );
};

export default OtherProjectsPage;