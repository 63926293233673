import React,{useState,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Modal,Input,Form} from 'semantic-ui-react';

import {renameLocalResearch,hideResearchRenameModal} from '../../../project/actions';

const RenameChapterModal = (props) => {
    const dispatch = useDispatch();
    const showRenameResearchModal = useSelector(state => state.projects.showRenameResearchModal);
    const renameResearchModalValue = useSelector(state => state.projects.renameResearchModalValue);
    const [text,setText] = useState('');

    useEffect( () => {
        setText(renameResearchModalValue);
    },[renameResearchModalValue]);


    return (
        <Modal open={showRenameResearchModal}>
            <Modal.Header style={{backgroundColor:'#363636', color:'white'}}>Enter New Name</Modal.Header>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
                <Form>
                    <Form.Field>
                        <Form.Input 
                            placeholder="New name here" 
                            value={text} 
                            onChange={(ev) => setText(ev.target.value)}/>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
                <Button onClick={() => dispatch(hideResearchRenameModal())}>Cancel</Button>
                <Button disabled={(text==='')}color='teal' onClick={()=>{
                    dispatch(renameLocalResearch(text));
                    dispatch(hideResearchRenameModal());
                }}>Save</Button>
                
            </Modal.Actions>
        </Modal>

    );
}

export default RenameChapterModal;