import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';
import * as ActionType from './constants';
import {
    fetchUserThoughtsSuccess,fetchUserThoughtsError,
    createThoughtSuccess,createThoughtError,
    reblogThoughtSuccess,reblogThoughtError,
    deleteThoughtSuccess,deleteThoughtError,
    likeThoughtSuccess,likeThoughtError,
    unlikeThoughtSuccess,unlikeThoughtError,
    fetchUserProfileSuccess,fetchUserProfileError,
    openProfileModalSuccess,openProfileModalError,
    fetchPublicFeedSuccess,fetchPublicFeedError,
    fetchFollowedFeedSuccess,fetchFollowedFeedError,
    fetchThoughtSuccess,fetchThoughtError,
    openConversationSuccess,openConversationError,
    createCommentSuccess,createCommentError,
    followUserSuccess,followUserError,
    unfollowUserSuccess,unfollowUserError,
    fetchFollowingSuccess,fetchFollowingError,
    fetchFollowersSuccess,fetchFollowersError
    } from './actions';

function* getUserThoughts(action) {
    try {
        let response = yield call(() => api.fetchUserThoughts(action.offset, action.username));
        if(response.success) {
            yield put(fetchUserThoughtsSuccess(response));
        }
        else {
            yield put(fetchUserThoughtsError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(fetchUserThoughtsError(err.message));
    }
}

function* createThought(action) {
    try {
        let response = yield call(() => api.createThought(action.thought));
        if(response.success) {
            yield put(createThoughtSuccess(response));
        }
        else {
            yield put(createThoughtError(response.message));
        }
    }
    catch(err) {
        yield put(createThoughtError(err.message));
    }
}

function* reblogThought(action) {
    try {
        let response = yield call(() => api.reblogThought(action.thoughtId,action.thought));
        if(response.success) {
            yield put(reblogThoughtSuccess(response));
        }
        else {
            yield put(reblogThoughtError(response.message));
        }
    }
    catch(err) {
        yield put(reblogThoughtError(err.message));
    }
}

function* deleteThought(action) {
    try {
        let response = yield call(() => api.deleteThought(action.thoughtId));
        if(response.success) {
            yield put(deleteThoughtSuccess(action.thoughtId));
        }
        else {
            yield put(deleteThoughtError(response.message));
        }
    }
    catch(err) {
        yield put(deleteThoughtError(err.message));
    }
}

function* likeThought(action) {
    try {
        let response = yield call(() => api.likeThought(action.thought));
        if(response.success) {
            yield put(likeThoughtSuccess(response));
        }
        else {
            yield put(likeThoughtError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(likeThoughtError(err.message));
    }
}
function* unlikeThought(action) {
    try {
        let response = yield call(() => api.unlikeThought(action.thought));
        if(response.success) {
            yield put(unlikeThoughtSuccess(response));
        }
        else {
            yield put(unlikeThoughtError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(unlikeThoughtError(err.message));
    }
}

function* openProfile(action) {
    try {
        let response = yield call(() => api.fetchUserProfile(action.username));
        if(response.success) {
            yield put(openProfileModalSuccess(response));
        }
        else {
            yield put(openProfileModalError(response.message));
        }
    }
    catch(err) {
        yield put(openProfileModalError(err.message));
    }
}


function* getUserProfile(action) {
    try {
        let response = yield call(() => api.fetchUserProfile(action.username));
        if(response.success) {
            yield put(fetchUserProfileSuccess(response));
        }
        else {
            yield put(fetchUserProfileError(response.message));
        }
    }
    catch(err) {
        yield put(fetchUserProfileError(err.message));
    }
}

function* getPublicFeed(action) {
    try {
        let response = yield call(() => api.fetchPublicFeed(action.offset,action.tag));
        if(response.success) {
            yield put(fetchPublicFeedSuccess(response));
        }
        else {
            yield put(fetchPublicFeedError(response.message));
        }
    }
    catch(err) {
        yield put(fetchPublicFeedError(err.message));
    }
}

function* getFollowedFeed(action) {
    try {
        let response = yield call(() => api.fetchFollowedFeed(action.offset));
        if(response.success) {
            yield put(fetchFollowedFeedSuccess(response));
        }
        else {
            yield put(fetchFollowedFeedError(response.message));
        }
    }
    catch(err) {
        yield put(fetchFollowedFeedError(err.message));
    }
}

function* getThought(action) {
    try {
        let response = yield call(() => api.fetchThought(action.thoughtId));
        if(response.success) {
            yield put(fetchThoughtSuccess(response));
        }
        else {
            yield put(fetchThoughtError(response.message));
        }
    }
    catch(err) {
        yield put(fetchThoughtError(err.message));
    }
}

function* openConversation(action) {
    try {
        let response = yield call(() => api.fetchThought(action.thoughtId));
        if(response.success) {
            yield put(openConversationSuccess(response));
        }
        else {
            yield put(openConversationError(response.message));
        }
    }
    catch(err) {
        yield put(openConversationError(err.message));
    }
}

function* createComment(action) {
    try {
        let response = yield call(() => api.createComment(action.thoughtId,action.comment));
        if(response.success) {
            yield put(createCommentSuccess(response));
        }
        else {
            yield put(createCommentError(response.message));
        }
    }
    catch(err) {
        yield put(createCommentError(err.message));
    }
}

function* followUser(action) {
    try {
        let response = yield call(() => api.followUser(action.username));
        if(response.success) {
            yield put(followUserSuccess(response));
        }
        else {
            yield put(followUserError(response.message));
        }
    }
    catch(err) {
        yield put(followUserError(err.message));
    }
}

function* unfollowUser(action) {
    try {
        let response = yield call(() => api.unfollowUser(action.username));
        if(response.success) {
            yield put(unfollowUserSuccess(response));
        }
        else {
            yield put(unfollowUserError(response.message));
        }
    }
    catch(err) {
        yield put(unfollowUserError(err.message));
    }
}

function* getFollowing(action) {
    try {
        let response = yield call(() => api.fetchFollowing());
        if(response.success) {
            yield put(fetchFollowingSuccess(response));
        }
        else {
            yield put(fetchFollowingError(response.message));
        }
    }
    catch(err) {
        yield put(fetchFollowingError(err.message));
    }
}

function* getFollowers(action) {
    try {
        let response = yield call(() => api.fetchFollowers());
        if(response.success) {
            yield put(fetchFollowersSuccess(response));
        }
        else {
            yield put(fetchFollowersError(response.message));
        }
    }
    catch(err) {
        yield put(fetchFollowersError(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(ActionType.FETCH_USER_THOUGHTS_SEND,getUserThoughts);
    yield takeLatest(ActionType.CREATE_THOUGHT_SEND,createThought);
    yield takeLatest(ActionType.REBLOG_THOUGHT_SEND,reblogThought);
    yield takeLatest(ActionType.DELETE_THOUGHT_SEND,deleteThought);
    yield takeLatest(ActionType.LIKE_THOUGHT_SEND,likeThought);
    yield takeLatest(ActionType.UNLIKE_THOUGHT_SEND,unlikeThought);
    yield takeLatest(ActionType.FETCH_USER_PROFILE_SEND,getUserProfile);
    yield takeLatest(ActionType.OPEN_PROFILE_MODAL,openProfile);
    yield takeLatest(ActionType.FETCH_PUBLIC_FEED_SEND,getPublicFeed);
    yield takeLatest(ActionType.FETCH_FOLLOWED_FEED_SEND,getFollowedFeed);
    yield takeLatest(ActionType.FETCH_THOUGHT_SEND,getThought);
    yield takeLatest(ActionType.OPEN_CONVERSATION,openConversation);
    yield takeLatest(ActionType.CREATE_COMMENT_SEND,createComment);
    yield takeLatest(ActionType.FOLLOW_USER_SEND,followUser);
    yield takeLatest(ActionType.UNFOLLOW_USER_SEND,unfollowUser);
    yield takeLatest(ActionType.FETCH_FOLLOWING_SEND,getFollowing);
    yield takeLatest(ActionType.FETCH_FOLLOWERS_SEND,getFollowers);

}
