import React, { useCallback,useRef,useState,useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Container,Icon,Dropdown, Menu} from 'semantic-ui-react';
import { useDrag, useDrop } from 'react-dnd';
import DeleteModal from '../../../components/DeleteModal';
import ResearchCard from './ResearchCard';
import NewLinkModal from './NewLinkModal';
import {removeResearch,hideDeleteResearchModal,addResearch,showNewReasearchLinkModal,moveResearch} from '../../../project/actions';
import {hasPremium} from '../../../services/roles';

const IdeaCard = ({research,moveCard,index}) => {
    let id = research._id;
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: 'card',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Get horizontal middle
        const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
        // Get pixels to the left
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
  
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'card', id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (<div ref={ref} style={{ opacity,margin:'1em' }}><ResearchCard research={research} key={research._id}/></div>);
}

const IdeaBoardPanel = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const [filter,setFilter] = useState('');

    const loadPinterestWidgetScript = () => {
        const script = document.createElement('script');
        script.async = true;
        script.dataset.pinBuild = 'doBuild';
        script.src = '//assets.pinterest.com/js/pinit.js';
        document.body.appendChild(script);
    };

    useEffect(() => {
        if(!window.doBuild) {
            loadPinterestWidgetScript();
        }
        else {
            window.doBuild();
        }
    },[]);

    const moveCard = useCallback(
        (dragIndex,hoverIndex) => {
            dispatch(moveResearch(dragIndex,hoverIndex));
        }
    );

    let tagSet = new Set();
    openProject.research.forEach( research => research.tags.forEach(tag => tagSet.add(tag)));
    const researchCards = openProject.research
        .filter( research => {
            if (!filter) {
                return true;
            }
            return (research.tags.indexOf(filter) > -1);
        })
        .map( (research,index) => <IdeaCard key={research._id} research={research} moveCard={moveCard} index={index}/>);
    let tagList = Array.from(tagSet).map(tag => <Menu.Item key={tag} onClick={() => setFilter(tag)}>{tag}</Menu.Item>)

    let bodyContent = (openProject.research.length === 0) ? (
        <Container text textAlign='center'>
            <div><Icon name='idea' size='huge'/></div>
            <p/>
            <div>Brainstorm ideas for your book. Add sticky notes, images, and URL links and tag them by subject.</div>
        </Container>
    ) : (<div className='idea-card-group'>{researchCards}</div>);


    return (
        <div className='ceta-idea-board'>
            <NewLinkModal />
            <DeleteModal 
                researchId={projects.deleteResearchModalResearchId}
                projectId={projects.deleteResearchModalProjectId}
                show={projects.showDeleteResearchModal}                     
                onCancel={() => dispatch(hideDeleteResearchModal())} 
                onConfirm={(data) => {
                    dispatch(removeResearch(data.projectId,data.researchId));
                    dispatch(hideDeleteResearchModal());
                }}
            />

            <div className='idea-toolbar'>
            <Dropdown                
                text='New Idea'
                icon='idea'
                floating
                labeled
                button
                className='icon teal'
                >
                <Dropdown.Menu>
                    <Dropdown.Item icon='sticky note' onClick={() => dispatch(addResearch('Notes'))} text='Sticky Note' />
                    <Dropdown.Item icon='linkify' onClick={() => dispatch(showNewReasearchLinkModal('Website'))} text='Link' />
                    <Dropdown.Item icon='image' onClick={() => dispatch(showNewReasearchLinkModal('Image'))}  text='Image Link' />
                    <Dropdown.Item icon='file image' disabled={!hasPremium(user)} onClick={() => {
                        let win = window.cloudinary.openUploadWidget({ 
                            cloud_name: 'cetacean-app', 
                            upload_preset: 'up_usr_pre',
                            multiple: false,
                            folder: 'user/'+user.userId,
                            tags: [user.userId],
                            cropping: false,
                            showSkipCropButton: false,
                            croppingAspectRatio: 1,
                            croppingCoordinatesMode: 'custom',
                            defaultSource: "local",
                            styles: {
                                palette: {
                                    window: "#141414",
                                    windowBorder: "#90A0B3",
                                    tabIcon: "#6DFFFF",
                                    menuIcons: "#5A616A",
                                    textDark: "#000000",
                                    textLight: "#FFFFFF",
                                    link: "#6DFFFF",
                                    action: "#FF620C",
                                    inactiveTabIcon: "#888888",
                                    error: "#F44235",
                                    inProgress: "#0078FF",
                                    complete: "#20B832",
                                    sourceBg: "#272727"
                                },
                                fonts: {
                                    default: {
                                        active: true
                                    }
                                }
                            }            
                            },
                            (error, result) => {
                                //console.log(result);
                                if(result.event === 'success') {
                                    // Uploaded successfully. Time to update.
                                    dispatch(addResearch('Image',result.info.secure_url));
                                    
                                }
                            });
                    }} text='Image File' />
                    <Dropdown.Item icon='pinterest' onClick={() => dispatch(showNewReasearchLinkModal('Pinterest Board'))} text='Pinterest Board' />
                </Dropdown.Menu>
            </Dropdown>   
                
            <Dropdown                
                text='Filter'
                icon='filter'
                floating
                labeled
                button
                className='icon teal'
                >
                <Dropdown.Menu>
                    <Dropdown.Header icon='tags' content='Filter by tag' />
                    <Dropdown.Divider />
                    <Dropdown.Item icon='delete' onClick={() => setFilter('')} text='No Filter' />
                    {tagList}
                </Dropdown.Menu>
            </Dropdown>   
            </div>
            <div className='idea-cards'>
                {bodyContent}
            </div>
        </div>
    );
}

export default IdeaBoardPanel;