import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Form,Menu,Tab,Icon,Input,Label,Grid,Dropdown} from 'semantic-ui-react';
import EmbeddedRichTextEditor from '../../editor/EmbeddedRichTextEditor';
import RelationshipsPanel from './RelationshipsPanel';
import {updateLocalCharacter} from '../../../project/actions';
import UploadedImage from '../../../components/UploadedImage';
import MotivePanel from './MotivePanel';
import ClosePanelWidget from '../ClosePanelWidget';
import CharacterTraitsPanel from './CharacterTraitsPanel';

function unNull(val) {
    return val ? val : undefined;
}

const CharacterEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const openProjectCharacter = useSelector(state => state.projects.openProjectCharacter);
    const showMain = useSelector(state => state.projects.showMainSideBar);

    const descElement = (openProjectCharacter.description) 
        ? JSON.parse(openProjectCharacter.description) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const valuesElement = (openProjectCharacter.values) 
        ? JSON.parse(openProjectCharacter.values) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const demeanorElement = (openProjectCharacter.demeanor) 
        ? JSON.parse(openProjectCharacter.demeanor) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const needsElement = (openProjectCharacter.needs) 
        ? JSON.parse(openProjectCharacter.needs) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const fearsElement = (openProjectCharacter.fears) 
        ? JSON.parse(openProjectCharacter.fears) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    const roleOptions = [
        { key: 'None', text:'None', value:'None'},
        { key: 'Protagonist', text:'Protagonist', value:'Protagonist'},
        { key: 'Antagonist', text:'Antagonist', value:'Antagonist'},
        { key: 'Supporting Character', text:'Supporting Character', value:'Supporting Character'},
        { key: 'Minor Character', text:'Minor Character', value:'Minor Character'}
    ];
    const archetypeOptions = [
        { key: 'None', text:'None', value:'None'},
        { key: 'The Hero', text:'The Hero', value:'The Hero'},
        { key: 'The Lover', text:'The Lover', value:'The Lover'},
        { key: 'The Jester', text:'The Jester', value:'The Jester'},
        { key: 'The Everyman', text:'The Everyman', value:'The Everyman'},
        { key: 'The Caregiver', text:'The Caregiver', value:'The Caregiver'},
        { key: 'The Ruler', text:'The Ruler', value:'The Ruler'},
        { key: 'The Artist', text:'The Artist', value:'The Artist'},
        { key: 'The Innocent', text:'The Innocent', value:'The Innocent'},
        { key: 'The Sage', text:'The Sage', value:'The Sage'},
        { key: 'The Explorer', text:'The Explorer', value:'The Explorer'},
        { key: 'The Outlaw', text:'The Outlaw', value:'The Outlaw'},
        { key: 'The Magician', text:'The Magician', value:'The Magician'}
    ];
    
    const panes = [
        { menuItem: (
            <Menu.Item key='basic' style={{color:'white',marginLeft:'3em'}}>
                Basic
            </Menu.Item>
        ), render: () => (
            <div style={{
                height:'calc(100vh - 60px)',display:'flex',flexDirection:'column',            
                }}>
                <div style={{flexGrow:1,overflowY:'auto'}}>   
                    <div style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap'}}>
                        <div style={{ width: '320px',marginLeft:'auto',marginRight:'auto'}}>
                            <UploadedImage 
                                src={openProjectCharacter.image}
                                dim='300px'
                                stock='/img/ceta-persona-default.png'
                                onDelete={ () => dispatch(updateLocalCharacter('image',''))}
                                onSuccess={ (result) => dispatch(updateLocalCharacter('image',result))}
                            />
                        </div>
                        <div style={{ width: '100%',maxWidth:'400px',marginLeft:'auto',marginRight:'auto'}}>
                            <p/>
                            <div style={{ display:'table', width:'100%'}}>
                                <div style={{ display:'table-row'}}>
                                    <div style={{ display:'table-cell',width:'80px'}}>
                                        <span>Name</span>
                                    </div>
                                    <div style={{ display:'table-cell'}}>
                                    <Input fluid 
                                        placeholder='Name' 
                                        value={unNull(openProjectCharacter.name)}
                                        onChange={(ev) => dispatch(updateLocalCharacter('name',ev.target.value))}
                                    />
                                    </div>
                                </div>
                            </div>
                            <p/>
                            <div style={{ display:'table', width:'100%'}}>
                                <div style={{ display:'table-row'}}>
                                    <div style={{ display:'table-cell',width:'80px'}}>Role</div>
                                    <div style={{ display:'table-cell'}}>
                                        <Dropdown selection fluid 
                                            value={unNull(openProjectCharacter.role)}
                                            options={roleOptions} 
                                            onChange={(ev,dat) => dispatch(updateLocalCharacter('role',dat.value))}
                                        />
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        </div>
                        <p/>
                        <div style={{ paddingLeft: '1em',paddingRight:'1em'}}>
                            <Form.Field>
                                <EmbeddedRichTextEditor 
                                    title='Physical Description'
                                    key='descriptionField'
                                    content={descElement}
                                    onUpdate={(value) => dispatch(updateLocalCharacter('description',JSON.stringify(value)))}
                                    placeholder='Describe what your character looks like...'
                                />
                            </Form.Field>
                        </div>
                </div>
            </div>
        )},
        { menuItem: (
            <Menu.Item key='personality' style={{color:'white'}}>
                Personality
            </Menu.Item>
        ), render: () => (
            <div style={{
                height:'calc(100vh - 60px)',display:'flex',flexDirection:'column',            
                }}>
                <div style={{flexGrow:1,overflowY:'auto'}}>

                <Form.Field>
                    <CharacterTraitsPanel character={openProjectCharacter}/>
                </Form.Field>
                <Form.Field>
                    <MotivePanel value={openProjectCharacter.motives} onChange={(val) => dispatch(updateLocalCharacter('motives',val))}/>
                </Form.Field>
                <Form.Field>
                    <EmbeddedRichTextEditor 
                        title='Demeanor'
                        key='demeanorField'
                        content={demeanorElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('demeanor',JSON.stringify(value)))}
                        placeholder='Describe the personality and persona the character presents to others...'
                    />
                </Form.Field>
                <Form.Field>
                    <EmbeddedRichTextEditor 
                        title='Values'
                        key='valuesField'
                        content={valuesElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('values',JSON.stringify(value)))}
                        placeholder='What does the character value as important in others or themself...'
                    />
                </Form.Field>
                <Form.Field>
                    <EmbeddedRichTextEditor 
                        title='Fears'
                        key='fearsField'
                        content={fearsElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('fears',JSON.stringify(value)))}
                        placeholder='What does the character fear...'
                    />
                </Form.Field>
                <Form.Field>
                    <EmbeddedRichTextEditor 
                        title='Needs'
                        key='needsField'
                        content={needsElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('needs',JSON.stringify(value)))}
                        placeholder='What does the character feel they need and what they actually need...'
                    />
                </Form.Field>
            </div>
            </div>
        )},
        { menuItem: (
            <Menu.Item key='relationships' style={{color:'white'}}>
                Relationships
            </Menu.Item>
        ), render: () => (
            <RelationshipsPanel />
        )}
    ];
    return (
        <Form stye={{ backgroundColor: '#272727'}}>
            <ClosePanelWidget />
            <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes} style={{paddingLeft:'0.5em'}} key={openProjectCharacter._id}/>
        </Form>
    );
};

export default CharacterEditPanel;