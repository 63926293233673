import React from 'react';
import {connect} from 'react-redux';
import MobileRichTextEditor from '../editor/MobileRichTextEditor';
import {updateLocalScene,updateProject} from '../../project/actions';

class MobileSceneEditPanel extends React.Component {

    onUpdate = (value) => {
        this.props.updateLocalScene(value);
    }

    onSave = () => {
        this.props.updateProject({project:this.props.projects.openProject});
    }

    render() {
        const sceneElement = (this.props.projects.openProjectScene.text) 
            ? JSON.parse(this.props.projects.openProjectScene.text) 
            : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
        return (
            <MobileRichTextEditor 
                key={this.props.projects.openProjectScene._id}
                content={sceneElement}
                onUpdate={this.onUpdate}
                focus={true}
                centered={true}
            />
        );
    }
};

export default connect( state => ({projects: state.projects}),{updateLocalScene,updateProject})(MobileSceneEditPanel);