import { call, put, takeLatest, select,throttle } from 'redux-saga/effects';
import { set,get,del } from 'idb-keyval';
import { saveAs } from 'file-saver';
import history from "../history";
import * as api from '../services/api';
import {countWordsInProject,MAX_BASIC_WORDCOUNT} from '../util/wordCount';
import {hasPremium} from '../services/roles';

import * as actions from './actions';
import * as actionType from './constants';

const getUser = (state) => state.user;
const getOpenProject = (state) => state.projects.openProject;
const isProjectDirty = (state) => state.projects.isProjectDirty;

function* create(action) {
    try {
        let response = yield call(() => api.createProject(action.payload));
        if(response.success) {
            yield put(actions.createProjectSuccess(response));
            yield put(actions.fetchProjects());
        }
        else {
            yield put(actions.createProjectError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createProjectError(err.message));
    }
}

function* getProjects(action) {
    try {
        let response = yield call(() => api.fetchProjects(action.payload));
        if(response.success) {
            yield put(actions.fetchProjectsSuccess(response));
        }
        else {
            yield put(actions.fetchProjectsError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(actions.fetchProjectsError(err.message));
    }

}

function* update(action) {
    try {
        let wordCount = yield call(() => countWordsInProject(action.payload.project));
        let user = yield select(getUser);
        let isPremium = hasPremium(user);
        if(!isPremium && wordCount > MAX_BASIC_WORDCOUNT) {
            yield put(actions.exceededWordCountError());
        }
        else {
            action.payload.project.wordCount = wordCount;
            let response = yield call(() => api.updateProject(action.payload));
            if(response.success) {
                del(action.payload.project._id);
                yield put(actions.updateProjectSuccess(response));
            }
            else {
                yield put(actions.updateProjectError(response.message));
            }
        }
    }
    catch(err) {
        console.log(err);
        yield put(actions.updateProjectError(err.message));
    }
}

function* autoSave(action) {
    try {
        let openProject = yield select(getOpenProject);
        let isDirty = yield select(isProjectDirty);
        let wordCount = yield call(() => countWordsInProject(openProject));
        let user = yield select(getUser);
        let isPremium = hasPremium(user);
        if(!isPremium && wordCount > MAX_BASIC_WORDCOUNT) {
            yield put(actions.exceededWordCountError());
        }
        else {
            openProject.wordCount = wordCount;
            let response = yield call(() => api.autoSaveProject({project: openProject}));
            if(response.success) {
                //del(action.payload.project._id);
                yield put(actions.autoSaveProjectSuccess(response));
            }
            else {
                yield put(actions.autoSaveProjectError(response.message));
            }
        }
    }
    catch(err) {
        if(err.message && (err.message.indexOf('offline') > -1)) {
            yield put(actions.offlineMode());
        }
        else {
            console.log(err);
            yield put(actions.autoSaveProjectError(err.message));
        }
    }
}

function* deleteProject(action) {
    try {
        let response = yield call(() => api.deleteProject(action.payload));
        if(response.success) {
            yield put(actions.deleteProjectSuccess(response));
            yield put(actions.fetchProjects());
        }
        else {
            yield put(actions.deleteProjectError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteProjectError(err.message));
    }
}

function* openProject(action) {
    try {
        const openProject = yield call( () => get(action.payload));
        let response = yield call(() => api.fetchFullProject(action.payload));
        if(response.success) {
            // check versions
            if(openProject) {
                if(response.data.project.version > openProject.version) {
                    yield put(actions.openProjectSuccess(response.data.project,false));
                }
                else {
                    yield put(actions.openProjectSuccess(openProject,true));
                }
            }
            else {
                yield put(actions.openProjectSuccess(response.data.project,false));
            }
            yield call(() => history.push("/project"));
        }
        else {
            yield put(actions.openProjectError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(actions.openProjectError(err.message));
    }
}

function* readProject(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.payload));
        if(response.success) {
            yield put(actions.readProjectSuccess(response));
        }
        else {
            yield put(actions.readProjectError(response.message));
        }
    }
    catch(err) {
        yield put(actions.readProjectError(err.message));
    }
}


function* createChapter(action) {
    try {
        let response = yield call(() => api.createChapter(action.projectId,action.payload));
        if(response.success) {
            yield put(actions.createChapterSuccess(response));
        }
        else {
            yield put(actions.createChapterError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createChapterError(err.message));
    }
}

function* createScene(action) {
    try {
        let response = yield call(() => api.createScene(action.projectId,action.chapterId,action.payload));
        if(response.success) {
            yield put(actions.createSceneSuccess(response));
        }
        else {
            yield put(actions.createSceneError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createSceneError(err.message));
    }
}

function* deleteScene(action) {
    try {
        let response = yield call(() => api.deleteScene(action.projectId,action.chapterId,action.sceneId));
        if(response.success) {
            yield put(actions.deleteSceneSuccess(response));
        }
        else {
            yield put(actions.deleteSceneError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteSceneError(err.message));
    }
}

function* deleteChapter(action) {
    try {
        let response = yield call(() => api.deleteChapter(action.projectId,action.chapterId));
        if(response.success) {
            yield put(actions.deleteChapterSuccess(response));
        }
        else {
            yield put(actions.deleteChapterError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteChapterError(err.message));
    }
}

function* createCharacter(action) {
    try {
        let response = yield call(() => api.createCharacter(action.projectId,action.payload));
        if(response.success) {
            yield put(actions.createCharacterSuccess(response));
        }
        else {
            yield put(actions.createCharacterError,
                actionType.CREATE_RELATIONSHIP_SEND,
                actions.createRelationshipSuccess,actions.createRelationshipError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createCharacterError,
            actionType.CREATE_RELATIONSHIP_SEND,
            actions.createRelationshipSuccess,actions.createRelationshipError(err.message));
    }
}

function* createRelationship(action) {
    try {
        let response = yield call(() => api.createRelationship(action.projectId,action.characterId,action.payload));
        if(response.success) {
            yield put(actions.createRelationshipSuccess(response));
        }
        else {
            yield put(actions.createRelationshipError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createRelationshipError(err.message));
    }
}

function* deleteRelationship(action) {
    try {
        let response = yield call(() => api.deleteRelationship(action.projectId,action.characterId,action.relationshipId));
        if(response.success) {
            yield put(actions.deleteRelationshipSuccess(response));
        }
        else {
            yield put(actions.deleteRelationshipError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteRelationshipError(err.message));
    }
}

function* deleteCharacter(action) {
    try {
        let response = yield call(() => api.deleteCharacter(action.projectId,action.characterId));
        if(response.success) {
            yield put(actions.deleteCharacterSuccess(response));
        }
        else {
            yield put(actions.deleteCharacterError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteCharacterError(err.message));
    }
}

function* createLocation(action) {
    try {
        let response = yield call(() => api.createLocation(action.projectId,action.payload));
        if(response.success) {
            yield put(actions.createLocationSuccess(response));
        }
        else {
            yield put(actions.createLocationError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createLocationError(err.message));
    }
}

function* deleteLocation(action) {
    try {
        let response = yield call(() => api.deleteLocation(action.projectId,action.locationId));
        if(response.success) {
            yield put(actions.deleteLocationSuccess(response));
        }
        else {
            yield put(actions.deleteLocationError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteLocationError(err.message));
    }
}

function* createEvent(action) {
    try {
        let response = yield call(() => api.createEvent(action.projectId,action.payload));
        if(response.success) {
            yield put(actions.createEventSuccess(response));
        }
        else {
            yield put(actions.createEventError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createEventError(err.message));
    }
}

function* deleteEvent(action) {
    try {
        let response = yield call(() => api.deleteEvent(action.projectId,action.eventId));
        if(response.success) {
            yield put(actions.deleteEventSuccess(response));
        }
        else {
            yield put(actions.deleteEventError(response.message));
        }
    }
    catch(err) {
        yield put(actions.deleteEventError(err.message));
    }
}

function* getProject(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.projectId));
        if(response.success) {
            yield put(actions.fetchProjectSuccess(response));
        }
        else {
            yield put(actions.fetchProjectError(response.message));
        }
    }
    catch(err) {
        yield put(actions.fetchProjectError(err.message));
    }
}

function* showExportModal(action) {
    yield put(actions. fetchProject(action.projectId));
}

function* rename(action) {
    try {
        let response = yield call(() => api.renameProject(action.payload));
        if(response.success) {
            yield put(actions.renameProjectSuccess(response));
            yield put(actions.fetchProjects());
        }
        else {
            yield put(actions.renameProjectError(response.message));
        }
    }
    catch(err) {
        yield put(actions.renameProjectError(err.message));
    }
}

function* importDoc(action) {
    try {
        let response = yield call(() => api.importDoc(action.formData));
        if(response.success) {
            yield put(actions.importDocSuccess(response));
            yield put(actions.fetchProjects());
        }
        else {
            yield put(actions.importDocError(response.message));
        }
    }
    catch(err) {
        yield put(actions.importDocError(err.message));
    }
}

function* updateLocalDB(action) {
    // fetch the open project and save it
    const openProject = yield select( (state) => state.projects.openProject);
    if(openProject._id) {
        set(openProject._id,openProject);
    }
}

function* readBook(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.payload));
        if(response.success) {
            // check versions
            yield put(actions.openProjectSuccess(response.data.project,false));
            yield call(() => history.push("/project/read"));
        }
        else {
            yield put(actions.openProjectError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(actions.openProjectError(err.message));
    }
}

function* editBook(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.payload));
        if(response.success) {
            // check versions
            yield put(actions.editBookSuccess(response.data.project,false));
            yield call(() => history.push("/project/edit"));
        }
        else {
            yield put(actions.editBookError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        yield put(actions.editBookError(err.message));
    }
}

function* openProjectDiff(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.projectId));
        if(response.success) {
            yield put(actions.openProjectDiffSuccess(response));
        }
        else {
            yield put(actions.openProjectDiffError(response.message));
        }
    }
    catch(err) {
        yield put(actions.openProjectDiffError(err.message));
    }
}

function* exportEpub(action) {
    try {
        let response = yield call(() => api.exportEpub(action.projectId));
            let filename = action.projectName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            saveAs(response,filename);
            yield put(actions.exportEpubSuccess(response));
    }
    catch(err) {
        yield put(actions.exportEpubError(err.message));
    }
}

function* exportPdf(action) {
    try {
        let response = yield call(() => api.exportPdf(action.projectId));
            let filename = action.projectName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
            saveAs(response,filename);
            yield put(actions.exportPdfSuccess(response));
    }
    catch(err) {
        yield put(actions.exportPdfError(err.message));
    }
}

function* createSequel(action) {
    try {
        let response = yield call(() => api.createProjectSequel(action.projectId));
        if(response.success) {
            yield put(actions.createProjectSequelSuccess(response));
            yield put(actions.fetchProjects());
        }
        else {
            yield put(actions.createProjectSequelError(response.message));
        }
    }
    catch(err) {
        yield put(actions.createProjectSequelError(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(actionType.CREATE_PROJECT_SEND,create);
    yield takeLatest(actionType.FETCH_PROJECTS_SEND,getProjects);
    yield takeLatest(actionType.UPDATE_PROJECT_SEND,update);
    yield takeLatest(actionType.AUTO_SAVE_PROJECT_SEND,autoSave);
    yield takeLatest(actionType.DELETE_PROJECT_SEND,deleteProject);
    yield takeLatest(actionType.OPEN_PROJECT, openProject);
    yield takeLatest(actionType.READ_PROJECT_SEND, readProject);
    yield takeLatest(actionType.CREATE_CHAPTER_SEND,createChapter);
    yield takeLatest(actionType.CREATE_SCENE_SEND,createScene);
    yield takeLatest(actionType.DELETE_SCENE_SEND,deleteScene);
    yield takeLatest(actionType.DELETE_CHAPTER_SEND,deleteChapter);
    yield takeLatest(actionType.CREATE_CHARACTER_SEND,createCharacter);
    yield takeLatest(actionType.CREATE_RELATIONSHIP_SEND,createRelationship);
    yield takeLatest(actionType.DELETE_RELATIONSHIP_SEND,deleteRelationship);
    yield takeLatest(actionType.DELETE_CHARACTER_SEND,deleteCharacter);
    yield takeLatest(actionType.CREATE_LOCATION_SEND,createLocation);
    yield takeLatest(actionType.DELETE_LOCATION_SEND,deleteLocation);
    yield takeLatest(actionType.CREATE_EVENT_SEND,createEvent);
    yield takeLatest(actionType.DELETE_EVENT_SEND,deleteEvent);
    yield takeLatest(actionType.FETCH_PROJECT_SEND,getProject);
    yield takeLatest(actionType.SHOW_EXPORT_MODAL, showExportModal);
    yield takeLatest(actionType.RENAME_PROJECT_SEND, rename);
    yield takeLatest(actionType.IMPORT_DOC_SEND, importDoc);
    yield takeLatest(actionType.READ_BOOK, readBook);
    yield takeLatest(actionType.EDIT_BOOK, editBook);
    yield takeLatest(actionType.OPEN_PROJECT_DIFF, openProjectDiff);
    yield takeLatest(actionType.EXPORT_EPUB_SEND, exportEpub);
    yield takeLatest(actionType.EXPORT_PDF_SEND, exportPdf);
    yield takeLatest(actionType.CREATE_SEQUEL_SEND,createSequel);
    yield takeLatest([ 
        actionType.UPDATE_LOCAL_CHAPTER,
        actionType.ADD_CHAPTER,
        actionType.RENAME_LOCAL_CHAPTER,
        actionType.MOVE_CHAPTER_UP,
        actionType.MOVE_CHAPTER_DOWN,
        actionType.MOVE_CHAPTER,
        actionType.REMOVE_CHAPTER,
        actionType.ADD_CHARACTER,
        actionType.ADD_RELATIONSHIP,
        actionType.UPDATE_LOCAL_CHARACTER,
        actionType.UPDATE_LOCAL_RELATIONSHIP,
        actionType.REMOVE_RELATIONSHIP,
        actionType.REMOVE_CHARACTER,
        actionType.ADD_LOCATION,
        actionType.REMOVE_LOCATION,
        actionType.UPDATE_LOCAL_LOCATION,
        actionType.ADD_EVENT,
        actionType.REMOVE_EVENT,
        actionType.UPDATE_LOCAL_EVENT,
        actionType.SET_WORD_COUNT_GOAL,
        actionType.ADD_RESEARCH,
        actionType.UPDATE_LOCAL_RESEARCH_NOTES,
        actionType.REMOVE_RESEARCH,
        actionType.RENAME_LOCAL_RESEARCH,
        actionType.UPDATE_LOCAL_RESEARCH,
        actionType.UPDATE_LOCAL_PLOTPOINT,
        actionType.ADD_PLOTPOINT,
        actionType.ADD_ACT,
        actionType.REMOVE_PLOTPOINT,
        actionType.RESET_OUTLINE,
        actionType.APPLY_TEMPLATE,
        actionType.SET_LINE_SPACING,
        actionType.SET_EDITOR_PALLETE,
        actionType.SET_RESEARCH_PALLETE,
        actionType.RESIZE_RESEARCH
    ], updateLocalDB);
    yield throttle(10000,[
        actionType.UPDATE_LOCAL_CHAPTER,
        actionType.ADD_CHAPTER,
        actionType.RENAME_LOCAL_CHAPTER,
        actionType.MOVE_CHAPTER_UP,
        actionType.MOVE_CHAPTER_DOWN,
        actionType.MOVE_CHAPTER,
        actionType.REMOVE_CHAPTER,
        actionType.ADD_CHARACTER,
        actionType.ADD_RELATIONSHIP,
        actionType.UPDATE_LOCAL_CHARACTER,
        actionType.UPDATE_LOCAL_RELATIONSHIP,
        actionType.REMOVE_RELATIONSHIP,
        actionType.REMOVE_CHARACTER,
        actionType.ADD_LOCATION,
        actionType.REMOVE_LOCATION,
        actionType.UPDATE_LOCAL_LOCATION,
        actionType.ADD_EVENT,
        actionType.REMOVE_EVENT,
        actionType.UPDATE_LOCAL_EVENT,
        actionType.SET_WORD_COUNT_GOAL,
        actionType.ADD_RESEARCH,
        actionType.UPDATE_LOCAL_RESEARCH_NOTES,
        actionType.REMOVE_RESEARCH,
        actionType.RENAME_LOCAL_RESEARCH,
        actionType.UPDATE_LOCAL_RESEARCH,
        actionType.UPDATE_LOCAL_PLOTPOINT,
        actionType.ADD_PLOTPOINT,
        actionType.ADD_ACT,
        actionType.REMOVE_PLOTPOINT,
        actionType.RESET_OUTLINE,
        actionType.APPLY_TEMPLATE,
        actionType.SET_LINE_SPACING,
        actionType.SET_EDITOR_PALLETE,
        actionType.SET_RESEARCH_PALLETE,
        actionType.RESIZE_RESEARCH
    ], autoSave);
    
}