import React,{useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Modal,Form} from 'semantic-ui-react';

import {addRelationship,hideNewRelationshipModal} from '../../../project/actions';

const NewRelationshipModal = (props) => {
    const dispatch = useDispatch();
    const showNewRelationshipModal = useSelector(state => state.projects.showNewRelationshipModal);
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectCharacter = useSelector(state => state.projects.openProjectCharacter);
    const [target,setTarget] = useState('');
    const [status,setStatus] = useState('');



    const options = openProject.characters
        .filter( character => {
            if(character._id === openProjectCharacter._id) {
                return false;
            }            
            for(let i=0;i<openProject.relationships.length;i++) {
                if((openProject.relationships[i].subject === openProjectCharacter._id) 
                    && (openProject.relationships[i].target === character._id)) {
                    return false;
                }
            }
            return true;
        })
        .map( character => { 
            return ({
                text: character.name,
                value: character._id
            });
        });

    return (
        <Modal open={showNewRelationshipModal}>
            <Modal.Header style={{backgroundColor:'#363636', color:'white'}}>Create A New Relationship</Modal.Header>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
                <Form>
                    <Form.Select 
                            placeholder='Select target character...' 
                            onChange={ (ev,data) => setTarget(data.value)}
                            options={options} />

                    <Form.Input 
                        placeholder="Status of relationship" 
                        value={status} 
                        onChange={(ev) => setStatus(ev.target.value)}/>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
                <Button onClick={() => dispatch(hideNewRelationshipModal())}>Cancel</Button>
                <Button color='teal' disabled={ (!target)}
                    onClick={()=>{
                    dispatch(addRelationship({
                        subject: openProjectCharacter._id,
                        status: status,
                        target: target
                    }));
                    dispatch(hideNewRelationshipModal());
                }}>Save</Button>
                
            </Modal.Actions>
        </Modal>

    );
}

export default NewRelationshipModal;