import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Input,Button} from 'semantic-ui-react';
import {closeImportModal,importDoc} from '../../../project/actions';


const ImportModal = (props) => {
    const dispatch = useDispatch();
    const showImportModal = useSelector(state => state.projects.showImportModal);
    const isImporting = useSelector(state => state.projects.isImporting);
    const [files,setFiles] = useState();
    return (
        <Modal open={showImportModal}>
            <Modal.Header style={modalStyle}>Import Document Into Cetacean</Modal.Header>
            <Modal.Content style={modalStyle}>
                <Input fluid accept='.docx' type='file' name='importFile' onChange={(ev,dat) => setFiles(ev.target.files)}/>
                <p/>
                <div>
                    Supported file types: Word (.docx)
                </div>

            </Modal.Content>
            <Modal.Actions style={modalStyle}>
                <Button onClick={() => dispatch(closeImportModal())}>Cancel</Button>
                <Button loading={isImporting} disabled={!files} color='teal' onClick={() => {
                    let formData = new FormData();
                    for( let key in files) {
                        if(files.hasOwnProperty(key) && files[key] instanceof File) {
                            formData.append('data', files[key]);
                        }
                    }
                    dispatch(importDoc(formData));
                }}>Import</Button>
            </Modal.Actions>
        </Modal>
    );
}

const modalStyle = {
    backgroundColor: '#363636',
    color:'white'
};

export default ImportModal;