import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

class PublicRoute extends React.Component {

    render() {
        const {
            component: Component,
            isAuthenticated,
            location,
            children,
            ...rest
        } = this.props;
        
        if(!this.props.isAuthenticated) {
                return <Route {...rest} render={ (props) => <Component {...props}/>}/>;
        }
        
        return <Route {...rest} render={ (props) => 
            <Redirect
                to={{
                pathname: "/",
                state: { from: this.props.location }
                }}
            />            
        }/>;        
    }
}

export default withRouter(connect( 
    (state)=> ({
        isAuthenticated: state.user.isAuthenticated
    })
)(PublicRoute));