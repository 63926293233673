import * as ActionTypes from './constants';

export const handleResearch = () => {
    return {

    [ActionTypes.OPEN_RESEARCH_PANEL]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'ResearchPanel'
        };
    },
    [ActionTypes.ADD_RESEARCH]: (state,action) => {
        let addResearchProject = {...state.openProject};
        let researchDate = new Date();
        let addedResearch = {
            _id: 'NEW_'+researchDate.getTime(),
            name: '',
            researchType: action.projectType,
            text: '',
            url: action.url || '',
            size: 'regular',
            tags: []
        };
        if(!addResearchProject.research) {
            addResearchProject.research = [];
        }
        addResearchProject.research.push(addedResearch);
        return {
            ...state,
            openProject: addResearchProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_RESEARCH_NOTES]: (state,action) => {
        let newOpenResearch = state.openProject.research.filter(research => research._id === action.researchId)[0];
        return {
            ...state,
            openProjectResearch: newOpenResearch,
            openProjectPanel: 'ResearchNotes'
        };
    },
    [ActionTypes.UPDATE_LOCAL_RESEARCH_NOTES]: (state,action) => {
        let updatedLocalResearchProject = {...state.openProject};
        let updatedLocalResearch = {...state.openProjectResearch};
        for(let i=0;i<updatedLocalResearchProject.research.length;i++) {
            let research = updatedLocalResearchProject.research[i];
            if(research._id === state.openProjectResearch._id) {
                research.text = JSON.stringify(action.payload);
                updatedLocalResearch.text = JSON.stringify(action.payload);
            }
        }
        return {
            ...state,
            openProject: updatedLocalResearchProject,
            openProjectResearch: updatedLocalResearch,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_DELETE_RESEARCH_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteResearchModal:true,
            deleteResearchModalResearchId: action.researchId,
            deleteCharacterModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_RESEARCH_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteResearchModal:false
        };
    },
    [ActionTypes.REMOVE_RESEARCH]: (state,action) => {
        let remResOpenProject = {...state.openProject};
        let remResIndex = remResOpenProject.research.findIndex( research => {
            return (research._id === state.deleteResearchModalResearchId);
        });
        if(!state.deleteResearchModalResearchId.startsWith('NEW_')) {
            if(!remResOpenProject.deletedResearch) {
                remResOpenProject.deletedResearch = [];
            }
            remResOpenProject.deletedResearch.push(state.deleteResearchModalResearchId);
        }
        remResOpenProject.research.splice(remResIndex,1);
        return {
            ...state,
            isDeletingResearch: false,
            openProject: remResOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_RESEARCH_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameResearchModal: true,
            renameResearchModalValue: action.initialValue,
            renameResearchModalResearchId: action.researchId        
        };
    },
    [ActionTypes.HIDE_RESEARCH_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameResearchModal: false
        };
    },
    [ActionTypes.RENAME_LOCAL_RESEARCH]: (state,action) => {
        let renameResOpenProject = {...state.openProject};
        for(let i=0;i<renameResOpenProject.research.length;i++) {
            if(renameResOpenProject.research[i]._id === state.renameResearchModalResearchId) {
                renameResOpenProject.research[i].name = action.value;
            }
        }
        return {
            ...state,
            showRenameResearchModal:false,
            openProject: renameResOpenProject,
            renameResearchModalValue: '',
            renameResearchModalResearchId: '',
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_NEW_RESEARCH_LINK_MODAL]: (state,action) => {
        return {
            ...state,
            showNewLinkModal: true,
            newLinkModalType: action.linkType
        };
    },
    [ActionTypes.HIDE_NEW_RESEARCH_LINK_MODAL]: (state,action) => {
        return {
            ...state,
            showNewLinkModal: false
        };
    },
    [ActionTypes.OPEN_RESEARCH_NOTES_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showResearchNotesBreakout: true,
            openBreakoutResearch: action.research
        };
    },
    [ActionTypes.CLOSE_RESEARCH_NOTES_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showResearchNotesBreakout: false,
            openBreakoutResearch: {}
        };
    },
    [ActionTypes.OPEN_IDEA_BOARD]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'IdeaBoard'
        };
    },
    [ActionTypes.OPEN_RESEARCH_NOTE_MODAL]: (state,action) => {
        return {
            ...state,
            showResearchNoteModal: true,
            openProjectResearch: action.research
        };
    },
    [ActionTypes.CLOSE_RESEARCH_NOTE_MODAL]: (state,action) => {
        return {
            ...state,
            showResearchNoteModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_RESEARCH]: (state,action) => {
        let updatedLocalResProject = {...state.openProject};
        let updatedLocalRes = {...state.openProjectResearch};
        for(let i=0;i<updatedLocalResProject.research.length;i++) {
            let research = updatedLocalResProject.research[i];
            if(research._id === action.researchId) {
                research[action.field] = action.value;
            }
        }
        return {
            ...state,
            openProject: updatedLocalResProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_RESEARCH_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Research'
        };
    },
    [ActionTypes.MOVE_RESEARCH]: (state,action) => {
        let moveResearchProject = {...state.openProject};
        let researchToMove = moveResearchProject.research[action.sourceIndex];
        moveResearchProject.research.splice(action.sourceIndex,1);
        moveResearchProject.research.splice(action.destinationIndex,0,researchToMove);

        return {
            ...state,
            openProject: moveResearchProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.RESIZE_RESEARCH]: (state,action) => {
        let updatedLocalResearchProject = {...state.openProject};
        let updatedLocalResearch = {...state.openProjectResearch};
        for(let i=0;i<updatedLocalResearchProject.research.length;i++) {
            let research = updatedLocalResearchProject.research[i];
            if(research._id === action.researchId) {
                research.size = action.size;
            }
        }
        return {
            ...state,
            openProject: updatedLocalResearchProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SET_RESEARCH_PALLETE]: (state,action) => {
        let newOpenProject = {...state.openProject, researchBackgroundColor: action.backgroundColor, researchTextColor: action.textColor};
        return {
            ...state,
            openProject: newOpenProject
        }
    },
}
};
