import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Table,Button,Icon} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {fetchActiveUsers} from '../../admin/actions';

const formatDate = (date,timezone) => {
    if(!date) {
        return '';
    }
    return format(utcToZonedTime(date, timezone), 'MM/dd/yy h:mm:ss a', { timezone: timezone });
}

const ActivityMonitorPage = (props) => {
    const dispatch = useDispatch();
    const activeUsers = useSelector(state => state.admin.activeUsers);
    const admin = useSelector(state => state.user);

    useEffect( () => {
        dispatch(fetchActiveUsers())
    },[dispatch]);
    
    const userList = activeUsers.map( user => (
        <Table.Row key={user.id}>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.name}</Table.Cell>
            <Table.Cell>{user.ip}</Table.Cell>
            <Table.Cell>{user.action}</Table.Cell>
            <Table.Cell>{user.browser} {user.os} {user.device}</Table.Cell>
            <Table.Cell>{formatDate(user.timestamp, admin.timezone)}</Table.Cell>
            <Table.Cell>{formatDate(user.lastLogin,admin.timezone)}</Table.Cell>
            <Table.Cell>{formatDate(user.userCreated, admin.timezone)}</Table.Cell>
        </Table.Row>
    ));

    return (
        <div>
        <Toolbar/>
        <div style={{margin:'1em'}}>
            <Button color='teal' onClick={() => dispatch(fetchActiveUsers())}><Icon name='refresh'/></Button>
            <Table inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>IP</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <Table.HeaderCell>Browser</Table.HeaderCell>
                        <Table.HeaderCell>Timestamp</Table.HeaderCell>
                        <Table.HeaderCell>Last Login</Table.HeaderCell>
                        <Table.HeaderCell>User Created</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {userList}
                </Table.Body>
            </Table>
        </div>
    </div>        
    );
};

export default ActivityMonitorPage;