import * as ActionTypes from './constants';

export const handleLocations = () => {
    return {

    [ActionTypes.ADD_LOCATION]: (state,action) => {
        let addLocProject = {...state.openProject};
        let locDate = new Date();
        let addedLoc = {
            _id: 'NEW_'+locDate.getTime(),
            name: 'New Location'
        };
        addLocProject.locations.push(addedLoc);
        return {
            ...state,
            openProject: addLocProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_LOCATION_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingLocation: true,
            createLocationError: false,
            createLocationErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_LOCATION_SUCCESS]: (state,action) => {
        let locationAddedProject = {...state.openProject};
        locationAddedProject.locations.push(action.payload.data.location);
        return {
            ...state,
            isCreatingLocation: false,
            openProject: locationAddedProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_LOCATION_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingLocation: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_DELETE_LOCATION_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteLocationModal:true,
            deleteLocationModalLocationId: action.locationId,
            deleteLocationModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_LOCATION_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteLocationModal:false
        };
    },
    [ActionTypes.REMOVE_LOCATION]: (state,action) => {
        let remLocOpenProject = {...state.openProject};
        let remLocIndex = remLocOpenProject.locations.findIndex( location => {
            return (location._id === state.deleteLocationModalLocationId);
        });
        if(!state.deleteLocationModalLocationId.startsWith('NEW_')) {
            if(!remLocOpenProject.deletedLocations) {
                remLocOpenProject.deletedLocations = [];
            }
            remLocOpenProject.deletedLocations.push(state.deleteLocationModalLocationId);
        }
        remLocOpenProject.locations.splice(remLocIndex,1);
        let remLocOptional = {};
        if(state.openProjectLocation._id === state.deleteLocationModalLocationId) {
            remLocOptional.openProjectLocation = remLocOpenProject.locations[0];
        }
        return {
            ...state,
            ...remLocOptional,
            isDeletingLocation: false,
            openProject: remLocOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_LOCATION_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingLocation: true,
            deleteLocationError: false,
            deleteLocationErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_LOCATION_SUCCESS]: (state,action) => {
        let delLocOpenProject = {...state.openProject};
        let delLocIndex = delLocOpenProject.locations.findIndex( location => {
            return (location._id === state.deleteLocationModalLocationId);
        });
        
        delLocOpenProject.locations.splice(delLocIndex,1);
        let delLocOptional = {};
        if(state.openProjectLocation._id === state.deleteLocationModalLocationId) {
            delLocOptional.openProjectLocation = delLocOpenProject.locations[0];
        }
        return {
            ...state,
            ...delLocOptional,
            isDeletingLocation: false,
            openProject: delLocOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_LOCATION_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingLocation: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_LOCATIONS_PANEL]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'LocationsPanel'
        };
    },
    [ActionTypes.OPEN_LOCATION]: (state,action) => {
        let newOpenLocation = state.openProject.locations.filter(location => location._id === action.locationId)[0];
        return {
            ...state,
            openProjectLocation: newOpenLocation,
            openProjectPanel: 'Location'
        };
    },
    [ActionTypes.UPDATE_LOCAL_LOCATION]: (state,action) => {
        let updateLocalLocationProject = {...state.openProject};
        let updatedLocation = {...state.openProjectLocation};        
        for(let i=0;i<updateLocalLocationProject.locations.length;i++) {
            let location = updateLocalLocationProject.locations[i];
            if(location._id === state.openProjectLocation._id) {
                location[action.field] = action.value;
                updatedLocation[action.field] = action.value;
            }
        }
        return {
            ...state,
            openProject: updateLocalLocationProject,
            openProjectLocation: updatedLocation,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_LOCATION_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showLocationBreakout: true ,  
            openBreakoutLocation: action.location
        };
    },
    [ActionTypes.CLOSE_LOCATION_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showLocationBreakout: false,
            openBreakoutLocation: {}
        };
    },
    [ActionTypes.OPEN_LOCATIONS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Locations'
        };
    }
}
};
