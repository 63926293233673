import * as ActionTypes from './constants';

export const handleChapters = () => {
    return {

    [ActionTypes.ADD_CHAPTER]: (state,action) => {
        let addChapterProject = {...state.openProject};
        let chapterDate = new Date();
        let addedChapter = {
            _id: 'NEW_'+chapterDate.getTime(),
            name: 'Untitled',
            scenes: []
        };
        addChapterProject.chapters.push(addedChapter);
        let addChapterOptional = {};
        if(addChapterProject.projectType !== 'BookByScene') {
            addChapterOptional.openProjectChapter = addedChapter;
            addChapterOptional.openProjectPanel = 'Chapter';
        }
        return {
            ...state,
            openProject: addChapterProject,
            isProjectDirty: true,
            ...addChapterOptional
        };
    },
    [ActionTypes.CREATE_CHAPTER_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingChapter: true,
            createChapterError: false,
            createChapterErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_CHAPTER_SUCCESS]: (state,action) => {
        let chapterAddedProject = {...state.openProject};
        chapterAddedProject.chapters.push(action.payload.data.chapter);
        return {
            ...state,
            isCreatingChapter: false,
            showCreateChapterModal: false,
            openProject: chapterAddedProject,
            isProjectDirty: true,
        };
    },
    [ActionTypes.CREATE_CHAPTER_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingChapter: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_CREATE_CHAPTER_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateChapterModal:true
        };
    },
    [ActionTypes.HIDE_CREATE_CHAPTER_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateChapterModal:false
        };
    },
    [ActionTypes.OPEN_CHAPTERS_PANEL]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'ChaptersPanel'
        };
    },
    [ActionTypes.OPEN_CHAPTER]: (state,action) => {
        let newChapterOpen = state.openProject.chapters.filter(chapter => chapter._id === action.chapterId)[0];
        return {
            ...state,
            openProjectChapter: newChapterOpen,
            openProjectPanel: 'Chapter'
        };
    },
    [ActionTypes.UPDATE_LOCAL_CHAPTER]: (state,action) => {
        let updatedLocalProject = {...state.openProject};
        let updatedLocalChapter = {...state.openProjectChapter};
        for(let i=0;i<updatedLocalProject.chapters.length;i++) {
            let chapter = updatedLocalProject.chapters[i];
            if(chapter._id === state.openProjectChapter._id) {
                chapter.text = JSON.stringify(action.payload);
                updatedLocalChapter.text = JSON.stringify(action.payload);
            }
        }
        return {
            ...state,
            openProject: updatedLocalProject,
            openProjectChapter: updatedLocalChapter,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_CHAPTER_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameChapterModal: true,
            renameChapterModalValue: action.initialValue,
            renameChapterModalChapterId: action.chapterId    
        };
    },
    [ActionTypes.HIDE_CHAPTER_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameChapterModal: false
        };
    },
    [ActionTypes.RENAME_LOCAL_CHAPTER]: (state,action) => {
        let renameChapterOpenProject = {...state.openProject};
        for(let i=0;i<renameChapterOpenProject.chapters.length;i++) {
            if(renameChapterOpenProject.chapters[i]._id === state.renameChapterModalChapterId) {
                renameChapterOpenProject.chapters[i].name = action.value;
            }
        }
        let newOpenProjectChapter = {...state.openProjectChapter}
        if(newOpenProjectChapter._id === state.renameChapterModalChapterId) {
            newOpenProjectChapter.name = action.value;
        }
        return {
            ...state,
            showRenameChapterModal:false,
            openProject: renameChapterOpenProject,
            openProjectChapter: newOpenProjectChapter,
            renameChapterModalValue: '',
            renameChapterModalChapterId: '',
            isProjectDirty: true
        };
    },
    [ActionTypes.MOVE_CHAPTER_UP]: (state,action) => {
        let moveChapterUpProject = {...state.openProject};
        let chapterUpIndex = moveChapterUpProject.chapters.findIndex( (chapter) => {
            return (chapter._id === action.chapterId);
        });
        if(chapterUpIndex >= 1) {
            let movedChapter = moveChapterUpProject.chapters.splice(chapterUpIndex,1)[0];
            moveChapterUpProject.chapters.splice(chapterUpIndex-1,0,movedChapter);
        }
        return {
            ...state,
            openProject: moveChapterUpProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.MOVE_CHAPTER_DOWN]: (state,action) => {
        let moveChapterDownProject = {...state.openProject};
        let chapterDownIndex = moveChapterDownProject.chapters.findIndex( (chapter) => {
            return (chapter._id === action.chapterId);
        });
        if(chapterDownIndex < moveChapterDownProject.chapters.length) {
            let movedChapter = moveChapterDownProject.chapters.splice(chapterDownIndex,1)[0];
            moveChapterDownProject.chapters.splice(chapterDownIndex+1,0,movedChapter);
        }
        return {
            ...state,
            openProject: moveChapterDownProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.MOVE_CHAPTER]: (state,action) => {
        let moveChapterProject = {...state.openProject};
        let chapterToMove = moveChapterProject.chapters[action.sourceIndex];
        moveChapterProject.chapters.splice(action.sourceIndex,1);
        moveChapterProject.chapters.splice(action.destinationIndex,0,chapterToMove);

        return {
            ...state,
            openProject: moveChapterProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_RECHAPTER_SCENE_MODAL]: (state,action) => {
        let chapterOptions =[];
        state.openProject.chapters.map( chapter => chapterOptions.push({
            key:chapter._id,
            text:chapter.name,
            value:chapter._id    
        }));
        return {
            ...state,
            showRechapterSceneModal: true,
            rechapterModalValue: action.initialValue,
            rechapterModalSceneId: action.sceneId,
            rechapterModalOptions: chapterOptions
        };
    },
    [ActionTypes.SHOW_DELETE_CHAPTER_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteChapterModal:true,
            deleteChapterModalChapterId: action.chapterId,
            deleteChapterModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_CHAPTER_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteChapterModal:false
        };
    },
    [ActionTypes.REMOVE_CHAPTER]: (state,action) => {
        let remChapterOpenProject = {...state.openProject};
        let remChapterIndex = remChapterOpenProject.chapters.findIndex( chapter => {
            return (chapter._id === state.deleteChapterModalChapterId);
        });
        if(!state.deleteChapterModalChapterId.startsWith('NEW_')) {
            if(!remChapterOpenProject.deletedChapters) {
                remChapterOpenProject.deletedChapters = [];
            }
            remChapterOpenProject.deletedChapters.push(state.deleteChapterModalChapterId);
        }
        remChapterOpenProject.chapters.splice(remChapterIndex,1);
        let remChapterOptional = {};
        if(state.openProjectChapter._id === state.deleteChapterModalChapterId) {
            if(remChapterOpenProject.chapters[0]) {
                remChapterOptional.openProjectChapter = remChapterOpenProject.chapters[0];
                remChapterOptional.openProjectScene = remChapterOpenProject.chapters[0].scenes[0];
            }
        }
        return {
            ...state,
            ...remChapterOptional,
            isDeletingChapter: false,
            openProject: remChapterOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_CHAPTER_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingChapter: true,
            deleteChapterError: false,
            deleteChapterErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_CHAPTER_SUCCESS]: (state,action) => {
        let delChapterOpenProject = {...state.openProject};
        let delChapterIndex = delChapterOpenProject.chapters.findIndex( chapter => {
            return (chapter._id === state.deleteChapterModalChapterId);
        });
        delChapterOpenProject.chapters.splice(delChapterIndex,1);
        let delChapterOptional = {};
        if(state.openChapter._id === state.deleteChapterModalChapterId) {
            delChapterOptional.openProjectChapter = delChapterOpenProject.chapters[0];
            delChapterOptional.openProjectScene = delChapterOpenProject.chapters[0].scenes[0];
        }
        return {
            ...state,
            ...delChapterOptional,
            isDeletingChapter: false,
            openProject: delChapterOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_CHAPTER_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingChapter: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_CHAPTERS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Chapters'
        };
    },
    [ActionTypes.OPEN_READ_CHAPTERS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'ReadChapters'
        };
    },
    [ActionTypes.OPEN_EDIT_CHAPTERS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'EditChapters'
        };
    }
}
};
