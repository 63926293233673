import React from 'react';
import RichTextEditor from '../editor/RichTextEditor';

const SceneView = ({scene}) => {
    const sceneElement = (scene.text) 
    ? JSON.parse(scene.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
        <div>
            <RichTextEditor 
                key={scene._id}
                content={sceneElement}
                footer={false}
                centered={true}
                readOnly={true}
            />
        </div>
    );
}

export default SceneView;