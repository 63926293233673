import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Popup,Card,Breadcrumb,Responsive,Segment,Image,Transition,Dropdown} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import {
    showDeleteResearchModal,openResearchNotes,showResearchRenameModal,openResearchNotesBreakout
} from '../../../project/actions';

const NotesCard = ({research,readOnly}) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = readOnly ? viewer.openProject : projects.openProject;

    const editButton = (<Button icon onClick={() => dispatch(openResearchNotes(research._id))}>
        <Icon name='edit'/></Button>);
    const breakoutButton = (<Button icon onClick={() => dispatch(openResearchNotesBreakout(research))}>
        <Icon name='external alternate'/></Button>);
    const renameButton = (<Button icon onClick={() => dispatch(showResearchRenameModal(research._id,research.name))}>
        <Icon name='eraser'/></Button>);
    const deleteButton = (<Button icon onClick={() => dispatch(showDeleteResearchModal(openProject._id,research._id))}>
        <Icon name='delete'/></Button>);

    return (
        <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={research._id}>
            <Card  style={{backgroundColor:'#363636'}}>
                <div style={{textAlign:'center', height: '10em',cursor: 'pointer'}} onClick={() => dispatch(openResearchNotes(research._id))}>
                    <Icon name='sticky note'color='teal' size='massive' style={{margin:'0.25em'}}/>
                </div>
                <Card.Content style={{color:'white'}}>
                    <Card.Header style={{color:'white',paddingTop:'1em'}}><h3>{research.name}</h3></Card.Header>
                </Card.Content>
                <Card.Content extra>
                    <Showable showIf={!readOnly}>
                        <Button.Group>
                            <Popup trigger={editButton}>Edit</Popup>
                            <Popup trigger={breakoutButton}>Beakout Notes</Popup>
                            <Popup trigger={renameButton}>Rename</Popup>
                            <Popup trigger={deleteButton}>Delete</Popup>
                        </Button.Group>
                    </Showable>
                </Card.Content>
            </Card>
        </Transition>
    );
}

export default NotesCard;