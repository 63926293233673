import React from 'react'
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

import Home from './containers/main';
import Login from './containers/login';
import Register from './containers/register';
import Settings from './containers/settings';
import PasswordReset from './containers/reset';
import Reverify from './containers/register/Reverify';
import ProjectPage from './containers/project';
import ProjectCheckpointsPage from './containers/project/ProjectCheckpointsPage';
import CodesPage from './containers/admin/CodesPage';
import MotdPage from './containers/admin/MotdPage';
import UsersPage from './containers/admin/UsersPage';
import ActivityMonitorPage from './containers/admin/ActivityMonitorPage';
import AnalyticsPage from './containers/admin/AnalyticsPage';
import BlogPage from './containers/admin/BlogPage';
import BroadcastPage from './containers/admin/BroadcastPage';
import ProjectViewer from './containers/viewer/ProjectViewer';
import SharedCheckpointViewer from './containers/viewer/SharedCheckpointViewer';
import InvitedPage from './containers/register/InvitedPage';
import ContactsPage from './containers/contact/ContactsPage';
import MessageInbox from './containers/inbox/MessageInbox';
import GetPremium from './containers/premium/GetPremium';
import PaymentPage from './containers/premium/PaymentPage';
import CheckpointSharingPage from './containers/project/CheckpointSharingPage';
import BookReader from './containers/reader/BookReader';
import FAQ from './containers/main/FAQ';
import OutagePage from './containers/login/OutagePage';
import AdReferralsPage from './containers/admin/AdReferralsPage';
import SharedToListPage from './containers/project/shared/SharedToListPage';
import SharedFromListPage from './containers/project/shared/SharedFromListPage';
import BookPage from './containers/book/BookPage';
import ReadBookPage from './containers/book/read/ReadBookPage';
import EditBookPage from './containers/book/edit/EditBookPage';
import OtherProjectsPage  from './containers/main/OtherProjectsPage';
import PublicBlogPage from './containers/blog/PublicBlogPage';
import UserFeed from './containers/feed/UserFeed';
import PublicFeed from './containers/feed/PublicFeed';
import FollowedFeed from './containers/feed/FollowedFeed';
import ThoughtComments from './containers/feed/ThoughtComments';
import Following from './containers/feed/Following';
import Followers from './containers/feed/Followers';

const routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/register" component={Register} />
      <PublicRoute path="/register/invited" component={InvitedPage} />
      <Route exact path="/reset" component={PasswordReset} />
      <Route exact path="/reverify" component={Reverify} />
      <Route exact path="/faq" component={FAQ} />
      <Route exact path="/outage" component={OutagePage} />
      <Route exact path="/landing" component={Home} />
      <Route path="/blog/:postId" component={PublicBlogPage} />
      <Route path="/blog" component={PublicBlogPage} />
      <PrivateRoute exact path="/projects/shared" component={SharedToListPage} />
      <PrivateRoute exact path="/projects/others" component={OtherProjectsPage} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/project" component={BookPage} />
      <PrivateRoute exact path="/project/read" component={ReadBookPage} />
      <PrivateRoute exact path="/project/edit" component={EditBookPage} />
      <PrivateRoute exact path="/book" component={BookPage} />
      <PrivateRoute exact path="/project/:projectId/checkpoints" component={ProjectCheckpointsPage} />
      <PrivateRoute exact path="/project/:projectId/viewer" component={ProjectViewer} />
      <PrivateRoute exact path="/shared/viewer" component={SharedCheckpointViewer} />
      <PrivateRoute exact path="/admin/codes" component={CodesPage} />
      <PrivateRoute exact path="/admin/blog" component={BlogPage} />
      <PrivateRoute exact path="/admin/motd" component={MotdPage} />
      <PrivateRoute exact path="/admin/users" component={UsersPage} />
      <PrivateRoute exact path="/admin/referrals/ad" component={AdReferralsPage} />
      <PrivateRoute exact path="/admin/activity" component={ActivityMonitorPage} />
      <PrivateRoute exact path="/admin/analytics" component={AnalyticsPage} />
      <PrivateRoute exact path="/admin/broadcast" component={BroadcastPage} />
      <PrivateRoute exact path="/contacts" component={ContactsPage} />
      <PrivateRoute exact path="/inbox" component={MessageInbox} />
      <PrivateRoute exact path="/getpremium" component={GetPremium} />
      <PrivateRoute exact path="/getpremium/payment" component={PaymentPage} />
      <PrivateRoute exact path="/shared" component={CheckpointSharingPage} />
      <PrivateRoute exact path="/project/:projectId/reader" component={BookReader} />
      <PrivateRoute exact path="/thoughts/user/:username" component={UserFeed} />
      <PrivateRoute exact path="/thoughts/user" component={UserFeed} />
      <PrivateRoute exact path="/thoughts/public" component={PublicFeed} />
      <PrivateRoute exact path="/thoughts" component={FollowedFeed} />
      <PrivateRoute exact path="/thought/:thoughtId" component={ThoughtComments} />
      <PrivateRoute exact path="/following" component={Following} />
      <PrivateRoute exact path="/followers" component={Followers} />
    </Switch>
)

export default routes