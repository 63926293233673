import {
    DISMISS_CHECKPOINT_ERROR,
    FORK_PROJECT_SEND,FORK_PROJECT_SUCCESS,FORK_PROJECT_ERROR,
    FETCH_PROJECT_CHECKPOINTS_SEND,FETCH_PROJECT_CHECKPOINTS_SUCCESS,FETCH_PROJECT_CHECKPOINTS_ERROR,
    DELETE_PROJECT_CHECKPOINT_SEND,DELETE_PROJECT_CHECKPOINT_SUCCESS,DELETE_PROJECT_CHECKPOINT_ERROR,
    SHARE_CHECKPOINT_SEND,SHARE_CHECKPOINT_SUCCESS,SHARE_CHECKPOINT_ERROR,
    FETCH_SHARED_CHECKPOINTS_SEND,FETCH_SHARED_CHECKPOINTS_SUCCESS,FETCH_SHARED_CHECKPOINTS_ERROR,
    OPEN_SHARE_CHECKPOINT_MODAL,CLOSE_SHARE_CHECKPOINT_MODAL,
    MERGE_CHECKPOINT_SEND,MERGE_CHECKPOINT_SUCCESS,MERGE_CHECKPOINT_ERROR,
    FETCH_LENT_CHECKPOINTS_SEND,FETCH_LENT_CHECKPOINTS_SUCCESS,FETCH_LENT_CHECKPOINTS_ERROR,
    OPEN_CHECKPOINT_SHARING,OPEN_CHECKPOINT_SHARING_SUCCESS,OPEN_CHECKPOINT_SHARING_ERROR,
    UNSHARE_CHECKPOINT_SEND,UNSHARE_CHECKPOINT_SUCCESS,UNSHARE_CHECKPOINT_ERROR,
    FETCH_ALL_SHARES_SEND, FETCH_ALL_SHARES_SUCCESS, FETCH_ALL_SHARES_ERROR
} from './actions';

const initialState = {
    parentCheckpoint: {},
    checkpoints: [],
    shared: [],
    lent: [],
    shares: [],
    showError:false,
    errorMessage: '',
    isForkingProject: false,
    forkProjectError: false,
    forkProjectErrorMessage: '',
    isFetchingProjectCheckpoints: false,
    fetchProjectCheckpointsError: false,
    fetchProjectCheckpointErrorMessage: '',
    isDeletingProjectCheckpoint: false,
    deleteProjectCheckpointError: false,
    deleteProjectCheckpointErrorMessage: '',
    isSharingCheckpoint: false,
    shareCheckpointError: false,
    shareCheckpointErrorMessage: '',
    showShareModal: false,
    isFetchingSharedCheckpoints: false,
    fetchSharedCheckpointsError: false,
    fetchSharedCheckpointErrorMessage: '',
    isMergingCheckpoint: false,
    mergeCheckpointError: false,
    mergeCheckpointErrorMessage: '',
    isFetchingLentCheckpoints: false,
    fetchLentCheckpointsError: false,
    fetchLentCheckpointErrorMessage: '',
    isOpeningCheckpointSharing: false,
    openCheckpointSharing: {},
    currentCheckpointLendees: [],
    isUnsharingCheckpoint: false,
    isFetchingAllShares: false
};

export default (state = initialState, action) => {
switch(action.type) {

    case DISMISS_CHECKPOINT_ERROR:
        return {
            ...state,
            showError: false,
            errorMessage: ''
        }
    case FORK_PROJECT_SEND:
        return {
            ...state,
            isForkingProject: true,
            forkProjectError: false,
            forkProjectErrorMessage: ''
        };
    case FORK_PROJECT_SUCCESS:
        return {
            ...state,
            isForkingProject: false
        };
    case FORK_PROJECT_ERROR:
        return {
            ...state,
            isForkingProject: false,
            showError: true,
            errorMessage: action.payload
        };  

    case FETCH_PROJECT_CHECKPOINTS_SEND:
        return {
            ...state,
            isFetchingProjectCheckpoints: true,
            fetchProjectCheckpointsError: false,
            fetchProjectCheckpointsErrorMessage: ''
        };
    case FETCH_PROJECT_CHECKPOINTS_SUCCESS:
        return {
            ...state,
            parentCheckpoint: action.payload.data.parent,
            checkpoints: action.payload.data.checkpoints,
            isFetchingProjectCheckpoints: false
        };
    case FETCH_PROJECT_CHECKPOINTS_ERROR:
        return {
            ...state,
            isFetchingProjectCheckpoints: false,
            showError: true,
            errorMessage: action.payload
        };

    case DELETE_PROJECT_CHECKPOINT_SEND:
        return {
            ...state,
            isDeletingProjectCheckpoint: true,
            deleteProjectCheckpointError: false,
            deleteProjectCheckpointErrorMessage: ''
        };
    case DELETE_PROJECT_CHECKPOINT_SUCCESS:
        return {
            ...state,
            isDeletingProjectCheckpoint: false
        };
    case DELETE_PROJECT_CHECKPOINT_ERROR:
        return {
            ...state,
            isDeletingProjectCheckpoint: false,
            showError: true,
            errorMessage: action.payload
        };

    case SHARE_CHECKPOINT_SEND:
        return {
            ...state,
            isSharingCheckpoint: true,
            shareCheckpointError: false,
            shareCheckpointErrorMessage: ''
        };
    case SHARE_CHECKPOINT_SUCCESS:
        return {
            ...state,
            isSharingCheckpoint: false,
            showShareModal: false
        };
    case SHARE_CHECKPOINT_ERROR:
        return {
            ...state,
            isSharingCheckpoint: false,
            showError: true,
            errorMessage: action.payload
        };  

    case FETCH_SHARED_CHECKPOINTS_SEND:
        return {
            ...state,
            isFetchingSharedCheckpoints: true,
            fetchSharedCheckpointsError: false,
            fetchSharedCheckpointsErrorMessage: ''
        };
    case FETCH_SHARED_CHECKPOINTS_SUCCESS:
        return {
            ...state,
            shared: action.payload.data.sharedProjects,
            isFetchingSharedCheckpoints: false
        };
    case FETCH_SHARED_CHECKPOINTS_ERROR:
        return {
            ...state,
            isFetchingSharedCheckpoints: false,
            showError: true,
            errorMessage: action.payload
        };
    case OPEN_SHARE_CHECKPOINT_MODAL:
        return {
            ...state,
            showShareModal: true,
            sharedCheckpointId: action.checkpointId,
            sharedCheckpointIsEditors: action.isEditors
        };
    case CLOSE_SHARE_CHECKPOINT_MODAL:
        return {
            ...state,
            showShareModal: false
        };
    case MERGE_CHECKPOINT_SEND:
        return {
            ...state,
            isMergingCheckpoint: true,
            mergeCheckpointError: false,
            mergeCheckpointErrorMessage: ''
        };
    case MERGE_CHECKPOINT_SUCCESS:
        return {
            ...state,
            isMergingCheckpoint: false
        };
    case MERGE_CHECKPOINT_ERROR:
        return {
            ...state,
            isMergingCheckpoint: false,
            showError: true,
            errorMessage: action.payload
        };  
  
    case FETCH_LENT_CHECKPOINTS_SEND:
        return {
            ...state,
            isFetchingLentCheckpoints: true,
            fetchLentCheckpointsError: false,
            fetchLentCheckpointsErrorMessage: ''
        };
    case FETCH_LENT_CHECKPOINTS_SUCCESS:
        return {
            ...state,
            lent: action.payload.data.sharedProjects,
            isFetchingLentCheckpoints: false
        };
    case FETCH_LENT_CHECKPOINTS_ERROR:
        return {
            ...state,
            isFetchingLentCheckpoints: false,
            showError: true,
            errorMessage: action.payload
        };
  
    case OPEN_CHECKPOINT_SHARING:
        return {
            ...state,
            isOpeningCheckpointSharing: true,
            openCheckpointSharing: action.checkpoint,
            showError: false,
            errorMessage: ''
        };
    case OPEN_CHECKPOINT_SHARING_SUCCESS:
        return {
            ...state,
            isOpeningCheckpointSharing: false,
            currentCheckpointLendees: action.payload.data.lendees
        };
    case OPEN_CHECKPOINT_SHARING_ERROR:
        return {
            ...state,
            isOpeningCheckpointSharing: false,
            showError: true,
            errorMessage: action.payload
        };  
    case UNSHARE_CHECKPOINT_SEND:
        return {
            ...state,
            isUnsharingCheckpoint: true
        };
    case UNSHARE_CHECKPOINT_SUCCESS:
        let unshared = state.currentCheckpointLendees.filter( share => share._id !== action.payload.data.deleted);
        return {
            ...state,
            isUnsharingCheckpoint: false,
            currentCheckpointLendees: unshared
        };
    case UNSHARE_CHECKPOINT_ERROR:
        return {
            ...state,
            isUnsharingCheckpoint: false,
            showError: true,
            errorMessage: action.payload
        };  
    case FETCH_ALL_SHARES_SEND:
        return {
            ...state,
            isFetchingAllShares: true,
            showError: false,
            errorMessage: ''
        };
    case FETCH_ALL_SHARES_SUCCESS:
        return {
            ...state,
            shares: action.payload.data.shares,
            isFetchingAllShares: false
        };
    case FETCH_ALL_SHARES_ERROR:
        return {
            ...state,
            isFetchingAllShares: false,
            showError: true,
            errorMessage: action.payload
        };
        
    default:
        return {...state};
}
};