import { call, put, takeLatest } from 'redux-saga/effects';
import history from "../history";
import * as api from '../services/api';

import {
        OPEN_VIEW,
        openViewSuccess,openViewError,
        OPEN_DIFF_VIEW,
        openDiffViewSuccess,openDiffViewError
    } from './actions';


function* openProjectView(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.projectId));
        if(response.success) {
            yield put(openViewSuccess(response));
            if(action.mode === 'read') {
                yield call(() => history.push("/shared/viewer"));
            }
            if(action.chapterOnly === 'edit') {
                yield call(() => history.push("/shared/viewer"));
            }
            else {
                yield call(() => history.push("/project/"+action.projectId+"/viewer"));
            }
        }
        else {
            yield put(openViewError(response.message));
        }
    }
    catch(err) {
        yield put(openViewError(err.message));
    }
}

function* openDiffView(action) {
    try {
        let response = yield call(() => api.fetchFullProject(action.projectId));
        if(response.success) {
            yield put(openDiffViewSuccess(response));
        }
        else {
            yield put(openDiffViewError(response.message));
        }
    }
    catch(err) {
        yield put(openDiffViewError(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(OPEN_VIEW, openProjectView);
    yield takeLatest(OPEN_DIFF_VIEW, openDiffView);
}