import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Menu,Sidebar,Icon} from 'semantic-ui-react';
import Showable from '../../components/Showable';
import {
    openView,openDiffView,openTimeline,
    openLocationsPanel,openCharactersPanel
} from '../../viewer/actions';

const ViewerBar = ({projectId,checkpoint,children}) => {
    const dispatch = useDispatch();
    const [sidebarOpened,setSidebarOpened] = useState(false);
    const [active,setActive] = useState('');
    const openProject = useSelector(state => state.projects.openProject);
    const openMode = useSelector(state => state.viewer.openMode);
    const ownerOptions = (openMode === 'owner');


    return (
        <div style={{ minHeight: '95vh'}}>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSidebarOpened(false)}
                vertical
                visible={sidebarOpened}
            >
                <Menu.Item
                    active={active === 'Chapters'}
                    onClick={() => {
                        setActive('Chapters');
                        dispatch(openView(checkpoint._id));
                        setSidebarOpened(false);
                        }}><Icon name='book'/>Chapters</Menu.Item>
                <Showable showIf={ownerOptions}>
                <Menu.Item
                    active={active === 'Changes'}
                    onClick={() => {
                        setActive('Changes');
                        dispatch(openDiffView(projectId));
                        setSidebarOpened(false);
                        }}><Icon name='book'/>Changes</Menu.Item>
                <Menu.Item 
                    active={active === 'Characters'}
                    onClick={() => {
                        setActive('Characters');
                        dispatch(openCharactersPanel(openProject._id));
                        setSidebarOpened(false);
                        }}><Icon name='users'/>Characters
                </Menu.Item>
                <Menu.Item
                    active={active === 'Locations'}
                    onClick={() => {
                        setActive('Locations');
                        dispatch(openLocationsPanel(openProject._id));
                        setSidebarOpened(false);
                        }}
                    ><Icon name='globe'/> Locations</Menu.Item>
                <Menu.Item
                    active={active === 'Timeline'}
                    onClick={() => {
                        setActive('Timeline');
                        dispatch(openTimeline(openProject._id));
                        setSidebarOpened(false);
                        }}
                ><Icon name='calendar'/> Timeline</Menu.Item>
                </Showable>
            </Sidebar>

            <div style={{
                background:'black',
                color:'white',
                width: '100%',
                height: '3em',
                lineHeight: '3em',
                display: 'inline-block'
            }}>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name='cog' />
                </Menu.Item>
                <Menu.Item>{active}</Menu.Item>
              </Menu>
            </div>
            <div>
                {children}
            </div>            
        </div>
    );
}

export default ViewerBar;