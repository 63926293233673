import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Modal,Loader} from 'semantic-ui-react';

import UtilityBar from './UtilityBar';

const PinterestBoardCard = ({research}) => {
    return (
        <div style={{ width: '100%',paddingTop:'5px'}}>
            <a href={research.url}
                data-pin-do="embedBoard"
                >
            </a>                
        </div>)
}

export default PinterestBoardCard;