import * as ActionTypes from './constants';

export const handlePlotpoints = () => {
    return {

    [ActionTypes.OPEN_OUTLINE]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'Outline',
            showContextSideBar: false,
            openContextPanel: ''
        };
    },
    [ActionTypes.ADD_PLOTPOINT]: (state,action) => {
        let addPlotpointProject = {...state.openProject};        
        let plotpointDate = new Date();
        let addedPlotpoint = {
            _id: 'NEW_'+plotpointDate.getTime(),
            synopsis: '',
            scenes: [],
            tags: []
        };
        if(!addPlotpointProject.plotpoints) {
            addPlotpointProject.plotpoints = [];
        }
        addPlotpointProject.plotpoints.push(addedPlotpoint);
        for(let k=0;k<addPlotpointProject.acts.length;k++) {
            if(action.actId === addPlotpointProject.acts[k]._id) {
                addPlotpointProject.acts[k].plotpoints.push(addedPlotpoint._id);
            }
        }
        return {
            ...state,
            openProject: addPlotpointProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_EDIT_PLOTPOINT_MODAL]: (state,action) => {
        return {
            ...state,
            openProjectPlotPoint: action.plotpoint,
            showEditPlotPointModal: true
        };
    },
    [ActionTypes.HIDE_EDIT_PLOTPOINT_MODAL]: (state,action) => {
        return {
            ...state,
            showEditPlotPointModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_PLOTPOINT]: (state,action) => {
        let updatedLocalPPProject = {...state.openProject};
        let updatedLocalPP = {...state.openProjectPlotPoint};
        for(let i=0;i<updatedLocalPPProject.plotpoints.length;i++) {
            let plotpoint = updatedLocalPPProject.plotpoints[i];
            if(plotpoint._id === action.plotpointId) {
                plotpoint[action.field] = action.value;
            }
        }
        return {
            ...state,
            openProject: updatedLocalPPProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.MOVE_PLOTPOINT]: (state,action) => {
        let movePlotpointProject = {...state.openProject};
        let plotpointToMove = movePlotpointProject.acts[action.sourceActIndex].plotpoints[action.sourceIndex];
        movePlotpointProject.acts[action.sourceActIndex].plotpoints.splice(action.sourceIndex,1);
        movePlotpointProject.acts[action.destinationActIndex].plotpoints.splice(action.destinationIndex,0,plotpointToMove);
        return {
            ...state,
            openProject: movePlotpointProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_DELETE_PLOTPOINT_MODAL]: (state,action) => {
        return {
            ...state,
            showDeletePlotPointModal:true,
            deletePlotPointModalPlotPointId: action.plotpointId,
            deletePlotPointModalActId: action.actId
        };
    },
    [ActionTypes.HIDE_DELETE_PLOTPOINT_MODAL]: (state,action) => {
        return {
            ...state,
            showDeletePlotPointModal:false
        };
    },
    [ActionTypes.REMOVE_PLOTPOINT]: (state,action) => {
        let remPPOpenProject = {...state.openProject};
        let remPPIndex = remPPOpenProject.plotpoints.findIndex( pp => {
            return (pp._id === state.deletePlotPointModalPlotPointId);
        });
        if(!state.deletePlotPointModalPlotPointId.startsWith('NEW_')) {
            if(!remPPOpenProject.deletedPlotPoints) {
                remPPOpenProject.deletedPlotPoints = [];
            }
            remPPOpenProject.deletedPlotPoints.push(state.deletePlotPointModalPlotPointId);
        }
        remPPOpenProject.plotpoints.splice(remPPIndex,1);

        let remPPActIndex = remPPOpenProject.acts.findIndex( act => {
            return (act._id === state.deletePlotPointModalActId);
        });
        remPPIndex = remPPOpenProject.acts[remPPActIndex].plotpoints.findIndex( pp => {
            return (pp === state.deletePlotPointModalPlotPointId);
        });
        remPPOpenProject.acts[remPPActIndex].plotpoints.splice(remPPIndex,1);
        return {
            ...state,
            isDeletingPlotPoint: false,
            openProject: remPPOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.ADD_ACT]: (state,action) => {
        let newActOpenProject = {...state.openProject};    
        newActOpenProject.acts.push({
            _id: 'NEW_'+new Date().getTime(),
            plotpoints: [],
            tags: []    
        });
        return {
            ...state,
            openProject: newActOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.APPLY_TEMPLATE]: (state,action) => {
        return {
            ...state,
            openProject: action.project,
            isProjectDirty: true
        };
    },
    [ActionTypes.RESET_OUTLINE]: (state,action) => {
        let resetOutlineOpenProject = {...state.openProject};
        if(!resetOutlineOpenProject.deletedPlotPoints) {
            resetOutlineOpenProject.deletedPlotPoints = [];
        }
        resetOutlineOpenProject.plotpoints.forEach( pp => resetOutlineOpenProject.deletedPlotPoints.push(pp._id));
        resetOutlineOpenProject.acts.forEach( act => resetOutlineOpenProject.deletedPlotPoints.push(act._id));
        resetOutlineOpenProject.plotpoints = [];
        resetOutlineOpenProject.acts = [];
        resetOutlineOpenProject.outlineTemplate = 'None';

        return {
            ...state,
            openProject: resetOutlineOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_OUTLINE_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Outline'
        };
    }
}
};
