import React, { useCallback,useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Grid,Popup,Header,Responsive,Dropdown,Menu} from 'semantic-ui-react';
import DeleteModal from '../../../components/DeleteModal';
import RenameChapterModal from '../../project/modals/RenameChapterModal';
import {
    addLocation,showDeleteLocationModal,openLocation,
    removeLocation,hideDeleteLocationModal,hideContextSideBar
} from '../../../project/actions';

const LocationCard = ({location,index,readOnly}) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);

    const avatar = location.image ? location.image : '/img/ceta-persona-default.png';

    return (
        <div onClick={() => dispatch(openLocation(location._id))} style={{ cursor:'pointer'}}>
            <div style={{ width: '100px',display:'inline-block'}}>
                <img src={avatar} onClick={() => dispatch(openLocation(location._id))} style={{cursor: 'pointer',objectFit: 'contain',width:'100%',height:'100%'}}/>
            </div>
            <div style={{ width: '190px',display:'inline-block',verticalAlign: 'top', paddingLeft: '0.5em'}}>
                <div style={{ float: 'right'}}>
                    <Popup position='left center' trigger={<Icon name='delete' onClick={() => dispatch(showDeleteLocationModal(openProject._id,location._id))}/>}>
                        Delete
                    </Popup>
                </div>
                <div style={{color:'white',paddingTop:'1em'}}><h3>{location.name}</h3></div>
                <div style={{color: '#cdcdcd'}}>{location.overview}</div>
            </div>
        </div>
    );
}
const LocationsContext = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const projects = useSelector(state => state.projects);

    return (
        <div>
            <DeleteModal 
                locationId={projects.deleteLocationModalLocationId}
                projectId={projects.deleteLocationModalProjectId}
                show={projects.showDeleteLocationModal}                     
                onCancel={() => dispatch(hideDeleteLocationModal())} 
                onConfirm={(data) => {
                    dispatch(removeLocation(data.projectId,data.locationId));
                    dispatch(hideDeleteLocationModal());
                }}
            />

            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item>
                            <Header color='teal'>Locations</Header>
                        </Menu.Item>
                        <Menu.Item>
                            <Icon color='teal' name='plus circle' size='large'
                                onClick={() => dispatch(addLocation(openProject._id))}
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div style={{ height:'calc(100vh - 70px)', overflowY: 'auto',overflowX: 'hidden'}}>
                {openProject.locations.map( (location,index) => <LocationCard key={location._id} location={location} index={index}/>)}
            </div>
        </div>
    );
}

export default LocationsContext;