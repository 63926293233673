import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Icon,Loader} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import {getUrlPreview} from '../../../services/api';
import UtilityBar from './UtilityBar';

const getImageStyle = (url) => {
    return {
        backgroundImage: 'url('+url+')',
        backgroundSize: 'cover',
        height: '10em'
    };
}

const WebsiteCard = ({research,readOnly}) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = readOnly ? viewer.openProject : projects.openProject;
    const [loading,setLoading] = useState(false); 

    useEffect( () => {
        if(!research.previewChecked && research.url) {
            setLoading(true);
            getUrlPreview(research.url).then( response => {
                research.preview = response.data.preview;
                setLoading(false);
            });
            research.previewChecked = true;
        }
    },[research.preview]);

    const cardMarquis = research.preview && research.preview.images ? (
        
        <div style={getImageStyle(research.preview.images[0])}>
        </div>
    ) : (
        <div style={{textAlign:'center'}}>
            <Showable showIf={!loading}>
                <Icon name='linkify'color='teal' size='huge' style={{marginTop:'0.5em'}}/>
            </Showable>
            <Showable showIf={loading}>
                <Loader active inline />
            </Showable>
        </div>
    );    
    return (
        <a href={research.url} target='_blank' style={{ whiteSpace: 'pre-wrap'}}>
            <div>
                <strong>{research.preview ? research.preview.title:''}</strong>
            </div>
            {cardMarquis}
            <div style={{ overflow: 'hidden'}}>
            {research.url}
            </div>
        </a>
    );
}

export default WebsiteCard;