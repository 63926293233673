import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Grid,Icon,Container} from 'semantic-ui-react';
import {hideProjectViewModal,updateLocalProjectView} from '../../project/actions';

const BookViewModal = (props) => {
    const dispatch = useDispatch();
    const openProjectView = useSelector(state => state.projects.openProject.view);
    const showProjectViewModal = useSelector(state => state.projects.showProjectViewModal);
    const [selected,setSelected] = useState('');

    useEffect( () => {
        setSelected(openProjectView);
    },[openProjectView])
    
    return (
        <Modal open={showProjectViewModal} className='ceta-modal'>
            <Modal.Header>Select Project View</Modal.Header>
            <Modal.Content className='ceta-modal-content'>
                <Grid columns={2}>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button color='teal' fluid 
                                onClick={() => dispatch(updateLocalProjectView('Brainstorm'))} >
                                <Icon name='idea'/>Brainstorm
                            </Button>                            
                        </Grid.Column>
                        <Grid.Column>
                            <div><strong>Use the Idea Board to organize research and dream up ideas for your book</strong></div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button color='teal' fluid
                                onClick={() => dispatch(updateLocalProjectView('Outline'))} >
                                <Icon name='list'/>Outline
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <div><strong>Turn your ideas into plot points and a story structure</strong></div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button color='teal' fluid
                                onClick={() => dispatch(updateLocalProjectView('Writing'))} >
                                <Icon name='pencil'/>Writing
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <div><strong>Get your first draft done</strong></div>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column>
                            <Button color='teal' fluid
                                onClick={() => dispatch(updateLocalProjectView('Revision'))} >
                                <Icon name='recycle'/>Revision
                            </Button>
                        </Grid.Column>
                        <Grid.Column>
                            <div><strong>Backup, share, and get feedback on your book</strong></div>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Modal.Content>
            
        </Modal>
    );
}

export default BookViewModal;