import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Segment,Popup,Card,Dimmer,Loader} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import ShareModal from '../viewer/ShareModal';
import {fetchContacts} from '../../contact/actions';
import {openShareCheckpointModal,unshareCheckpoint} from '../../checkpoint/actions';
import {maxWidth} from '../../util/stringUtils';

const CheckpointSharingPage = (props) => {
    const dispatch = useDispatch();
    const currentCheckpointLendees = useSelector(state => state.checkpoints.currentCheckpointLendees);
    const openCheckpointSharing = useSelector(state => state.checkpoints.openCheckpointSharing);
    const isFetchingContacts = useSelector(state => state.contacts.isFetchingContacts);

    useEffect( () => {
        dispatch(fetchContacts())
    },[dispatch]);

    const readers = currentCheckpointLendees
        .filter( share => !share.editor)
        .map( share => {
            const button = (
                <Button animated onClick={() => dispatch(unshareCheckpoint(share._id))}>
                    <Button.Content visible>{share.lendeeName}</Button.Content>
                    <Button.Content hidden>Unshare</Button.Content>
                </Button>);
            return (
                <Popup key={share._id}trigger={button}>Unshare</Popup>
            );
        });

    const editors = currentCheckpointLendees
        .filter( share => share.editor)
        .map( share => {
            const button = (
                <Button animated color='teal' 
                    style={{margin: '2px'}} 
                    onClick={() => dispatch(unshareCheckpoint(share._id))}>
                    <Button.Content visible>{share.lendeeName}</Button.Content>
                    <Button.Content hidden>Unshare</Button.Content>
                </Button>);
            return (
                <Popup key={share._id}trigger={button}>Unshare</Popup>
            );
        });

    return (
        <div>
        <Toolbar>
        <div style={{margin:'1em'}}>
            <Dimmer active={isFetchingContacts}>
                <Loader>Loading</Loader>
            </Dimmer>
            <ShareModal reloadLendees={true}/>
            <p/>
            <Card style={{backgroundColor:'#363636'}}>
                <Card.Content>
                    <Card.Header style={{color:'white'}}>{maxWidth(openCheckpointSharing.name,30)}</Card.Header>
                    <div style={{width:'100%', textAlign:'center'}}>
                        <Icon name='share square'color='teal' size='massive'/>
                    </div>
                    <Card.Meta style={{color: '#cdcdcd'}}>
                    <span>Checkpoint: {openCheckpointSharing.versionName}</span><br/>
                        <span>Version: {openCheckpointSharing.version}</span><br/>
                        <span>Chapters: {openCheckpointSharing.chapters.length}</span><br/>
                    </Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    <div style={{width:'100%',textAlign:'center'}}>
                    <Button onClick={() => 
                        dispatch(openShareCheckpointModal(openCheckpointSharing._id))}
                    >Share With Contact</Button>                    
                    </div>
                </Card.Content>
            </Card>

            <p/>
            <div>
                <h2 style={{color:'white'}}>Editors</h2>
                <Segment inverted>
                    {editors}
                    {(editors.length === 0 && (<h2>No contacts are editors</h2>))}
                </Segment>
            </div>
            <div>
                <h2 style={{color:'white'}}>Readers</h2>
                <Segment inverted>
                    {readers}
                    {(readers.length === 0 && (<h2>No contacts are readers</h2>))}
                </Segment>
            </div>            
        </div>
    </Toolbar>
    </div>

    );
}

export default CheckpointSharingPage;