import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Dropdown, Input,Form, Grid,TextArea} from 'semantic-ui-react';
import Epoch from './Epoch';
import {
    addEvent,hideCreateEventModal
} from '../../../project/actions';


const NewEventModal = (props) => {
    const dispatch = useDispatch();
    const showCreateEventModal = useSelector(state => state.projects.showCreateEventModal);
    const openProject = useSelector(state => state.projects.openProject);
    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [epoch,setEpoch] = useState('0,0,0,0,0,0');
    const [chapter,setChapter] = useState(1);
    const [scene,setScene] = useState(1);
    const [act,setAct] = useState(1);
    const [characters,setCharacters] = useState([]);

    const actOptions = Array.from(Array(7).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const chapterOptions = Array.from(Array(50).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const sceneOptions = Array.from(Array(30).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const characterOptions = openProject.characters.map( character => ({
        key: character._id,
        text: character.name,
        value: character._id
    }));

    return (
        <Modal open={showCreateEventModal} onMount={() => {
            setName('');
            setDescription('');
            setEpoch('0,0,0,0,0,0');
            setAct(1);
            setChapter(1);
            setScene(1);
        }}>
            <Modal.Header style={{ backgroundColor:'#272727',color:'white'}}>
                New Event
            </Modal.Header>
            <Modal.Content style={{ backgroundColor:'#272727',color:'white'}}>
                <Form>
                    <Form.Field>
                    <Input 
                            label='Name'
                            placeholder='Name' 
                            value={name}
                            onChange={(ev) => setName(ev.target.value)}
                        />
                    </Form.Field>
                    <p/>
                    <TextArea
                        value={description}
                        onChange={(ev) => setDescription(ev.target.value)}
                        placeholder='Describe what happened on the event...'
                    />
                    <p/>
                    <Epoch label='Time from day 0' value={epoch} onChange={(val) => setEpoch(val)}/>
                    <p/>
                    Act&nbsp;
                    <Dropdown compact selection value={act} options={actOptions} onChange={(ev,dat) => setAct(dat.value)}/>
                    &nbsp;&nbsp;
                    Chapter&nbsp;
                    <Dropdown compact selection value={chapter} options={chapterOptions} onChange={(ev,dat) => setChapter(dat.value)}/>
                    &nbsp;&nbsp;
                    Scene&nbsp;
                    <Dropdown compact selection value={scene} options={sceneOptions} onChange={(ev,dat) => setScene(dat.value)}/>
                    <p/>
                    <Form.Field>
                        <Dropdown options={characterOptions} fluid selection multiple 
                        value={characters}
                        onChange={ (ev,dat) => setCharacters(dat.value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#272727',color:'white'}}>
                <Button onClick={() => dispatch(hideCreateEventModal())}>Cancel</Button>
                <Button color='teal' disabled={(name==='')} onClick={ 
                    () => {
                        dispatch(addEvent(openProject._id,{
                            event: {
                                name,
                                description,
                                epoch,
                                act,
                                chapter,
                                scene,
                                characters
                            }}));
                        dispatch(hideCreateEventModal());
                }}>Create</Button>

            </Modal.Actions>
        </Modal>
    );
}

export default NewEventModal;