import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Transition } from 'react-transition-group'
import {Dimmer,Loader,Menu,Icon,Dropdown,Popup, Accordion} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {utcToZonedTime,format} from 'date-fns-tz';

import {toggleDistractionFree} from '../../user/actions';
import {
    hideMainSideBar,openChaptersSideBar, updateProject,
    showProjectViewModal,openDictionarySideBar, openProgressSideBar,
    openIdeaBoard,openCharactersSideBar, openLocationsSideBar, openOutline,
    openResearchSideBar, openOutlineSideBar, toggleGrammarCheck,openTimeline,
    openEditorSettingsSideBar,openBrainstormSettingsSideBar
} from '../../project/actions';

const duration = 200;
const sidebarStyle = {
  transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
  entering: { width: 0 },
  entered: { width: '200px' },
  exiting: { width: '200px' },
  exited: { width: 0 }
}
const linkStyle = {
  transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
}

const formatTime = (date,user) => {
    const timeZone = user.timezone;
    const zonedDate = utcToZonedTime(date, timeZone);
    const pattern = 'MM/dd/yyyy h:mm:ss a';
    const output = format(zonedDate, pattern, { timeZone })        
    return output;
}

const getViewMenuItem = (dispatch,projectView) => {
    let name = 'Writing';
    let icon = 'pencil';
    if(projectView === 'Brainstorm') {
        name = 'Brainstorm';
        icon = 'idea';
    }
    else if (projectView === 'Outline') {
        name = 'Outline';
        icon = 'list';
    }
    else if (projectView === 'Revision') {
        name = 'Revision';
        icon = 'sync alternate';
    }
    return (
        <Popup trigger={<Menu.Item onClick={() => dispatch(showProjectViewModal())}>Change View<Icon name={icon}/></Menu.Item>}>
            You can change your Project View when you are ready to progress to the next step of your book or go back to reference a previous step.
        </Popup>
        );
}

const getMainSidebarItems = (dispatch,projectView) => {
    switch (projectView) {
        case 'Brainstorm': {
            return [
                (<Menu.Item key='Characters' onClick={() => dispatch(openCharactersSideBar())} >Characters<Icon name='users'/></Menu.Item>),
                (<Menu.Item key='Places' onClick={() => dispatch(openLocationsSideBar())} >Locations<Icon name='map'/></Menu.Item>),
                (<Menu.Item key='Settings' onClick={() => dispatch(openBrainstormSettingsSideBar())}>Settings<Icon name='setting'/></Menu.Item>)
            ]
        }
        case 'Outline': {
            return [
                (<Menu.Item key='Ideas' onClick={() => dispatch(openResearchSideBar())} >Ideas<Icon name='idea'/></Menu.Item>),
                (<Menu.Item key='Characters' onClick={() => dispatch(openCharactersSideBar())} >Characters<Icon name='users'/></Menu.Item>),
                (<Menu.Item key='Places' onClick={() => dispatch(openLocationsSideBar())} >Locations<Icon name='map'/></Menu.Item>),
                (<Menu.Item key='Timeline' onClick={() => dispatch(openTimeline())} >Timeline<Icon name='hourglass'/></Menu.Item>)
            ]
        }
        case 'Revision': {
            return [
            ]
        }
        case 'Read': {
            return [
                (<Menu.Item key='Chapters' onClick={() => dispatch(openChaptersSideBar())} >Chapters<Icon name='book'/></Menu.Item>)
            ];
        }
        default: {
            return [
                (<Menu.Item key='Progress' onClick={() => dispatch(openProgressSideBar())}>Progress<Icon name='chart line'/></Menu.Item>),
                (<Menu.Item key='Chapters' onClick={() => dispatch(openChaptersSideBar())} >Chapters<Icon name='book'/></Menu.Item>),
                (<Menu.Item key='Dictionary' onClick={() => dispatch(openDictionarySideBar())}>Dictionary<Icon name='keyboard'/></Menu.Item>),
                (<Menu.Item key='Outline' onClick={() => dispatch(openOutlineSideBar())}>Outline<Icon name='list'/></Menu.Item>),
                (<Menu.Item key='Characters' onClick={() => dispatch(openCharactersSideBar())} >Characters<Icon name='users'/></Menu.Item>),
                (<Menu.Item key='Places' onClick={() => dispatch(openLocationsSideBar())} >Locations<Icon name='map'/></Menu.Item>),
                (<Menu.Item key='Settings' onClick={() => dispatch(openEditorSettingsSideBar())}>Settings<Icon name='setting'/></Menu.Item>)
            ];
        }
    }
}

const MainSideBar = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const showMain = useSelector(state => state.projects.showMainSideBar);
    const openProject = useSelector(state => state.projects.openProject);
    const isProjectDirty = useSelector(state => state.projects.isProjectDirty);
    const isOffline = useSelector(state => state.projects.isOffline);
    const isDistractionFree = useSelector(state => state.user.isDistractionFree);
    const saveClass= isProjectDirty ? 'ceta-unsaved' : '';

    const saveMenuItem = isProjectDirty ? (
        <Popup position='right center' trigger={
        <Menu.Item
            onClick={(ev) => {
                ev.preventDefault();
                dispatch(updateProject({project: openProject}));
            }}
            ><span className={saveClass}>Unsaved Changes</span><Icon className={saveClass} name='cloud upload'/>
        </Menu.Item>}>Last saved: {formatTime(openProject.updateDate,user)}</Popup>
    ) : (
        <Menu.Item>Saved <Icon className={saveClass} name='cloud upload'/></Menu.Item>
    );

    const offlineMessage = isOffline ? 
        <Popup trigger={<Menu.Header className='ceta-offline-message'>OFFLINE MODE</Menu.Header>}>The network connection to the servers is currently down. Continue working and your changes will be saved locally and sync'd with the servers once the connection is good.</Popup>
        : null;
    return(
        <Transition in={showMain} timeout={duration}>
        {(state) => (
        <div style={{
            minHeight: '100vh',
          ...sidebarStyle,
          ...sidebarTransitionStyles[state]
        }}>
            <Transition in={showMain} timeout={duration}>
            {(state) => (
                <div style={{
                ...linkStyle,
                ...linkTransitionStyles[state]
                }}>
                    <div style={{ width: '200px'}}>
                    <Menu vertical fluid inverted style={{ minHeight:'100vh'}}>
                        {offlineMessage}
                        <Menu.Item onClick={() => dispatch(hideMainSideBar())}>Hide<Icon name='close'/></Menu.Item>
                        <Menu.Item as={Link} to='/'>Return to Projects<Icon name='reply'/></Menu.Item>
                        {getViewMenuItem(dispatch,openProject.view)}
                        <Menu.Item onClick={() => dispatch(toggleDistractionFree(!isDistractionFree))}>
                            Distraction Free <Icon name='expand' />
                        </Menu.Item>
                        {getMainSidebarItems(dispatch,openProject.view)}
                    </Menu>
                    </div>
                </div>
            )}
            </Transition>            
          
        </div>

        )}            
        
    </Transition>
    );
}

export default MainSideBar;