import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {reverify} from '../../user/actions';
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Segment, Dimmer, Loader, Message, Header } from 'semantic-ui-react';


const Reverify = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [email,setEmail] = useState('');
        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                <Header inverted as='h1'>C e t a c e a n</Header>
                <Form inverted size='large' error={user.reverifyError}>
                    <Segment style={{backgroundColor:'#272727',color:'white'}}>
                    <Message info>
                        Enter your email address below to resend the verification.
                    </Message>
                    <Form.Input fluid 
                        icon='user'
                        iconPosition='left'
                        placeholder='E-mail address' 
                        onChange={ (event) => setEmail(event.target.value)}
                        />
                    <Message
                        error
                        header='Unable To Send Reset Link'
                        content={user.reverifyErrorMessage}
                    />
                    <Message
                        hidden={!user.hasVerifyBeenSent}
                    >
                        <Message.Header>Verification Email Sent</Message.Header>
                        <p>Check your email and follow the link.</p>
                    </Message>

                    <Button color='teal' disabled={user.hasVerifyBeenSent} fluid size='large' onClick={() => dispatch(reverify(email))}>
                        Verify Email
                    </Button>
                    <Link style={{color:'#00b5ad'}} to="/register">Register</Link> &nbsp;|&nbsp;
                    <Link style={{color:'#00b5ad'}} to="/login">Log In</Link>
                    </Segment>
                </Form>
                </Grid.Column>
            </Grid>
        );
}

export default Reverify;
