// Project Checkpoints
export const DISMISS_CHECKPOINT_ERROR = 'DISMISS_CHECKPOINT_ERROR';

export const dismissCheckpointError = () => ({
    type: DISMISS_CHECKPOINT_ERROR
});

export const FORK_PROJECT_SEND = 'FORK_PROJECT_SEND';
export const FORK_PROJECT_SUCCESS = 'FORK_PROJECT_SUCCESS';
export const FORK_PROJECT_ERROR = 'FORK_PROJECT_ERROR';

export const forkProject = (projectId,payload) => ({
    type: FORK_PROJECT_SEND,
    projectId,
    payload
});

export const forkProjectSuccess = payload => {
    return {
    type: FORK_PROJECT_SUCCESS,
    payload
}};

export const forkProjectError = payload => ({
    type: FORK_PROJECT_ERROR,
    payload
});

export const FETCH_PROJECT_CHECKPOINTS_SEND = 'FETCH_PROJECT_CHECKPOINTS_SEND';
export const FETCH_PROJECT_CHECKPOINTS_SUCCESS = 'FETCH_PROJECT_CHECKPOINTS_SUCCESS';
export const FETCH_PROJECT_CHECKPOINTS_ERROR = 'FETCH_PROJECT_CHECKPOINTS_ERROR';

export const fetchProjectCheckpoints = payload => ({
    type: FETCH_PROJECT_CHECKPOINTS_SEND,
    payload
});

export const fetchProjectCheckpointsSuccess = payload => {
    return {
    type: FETCH_PROJECT_CHECKPOINTS_SUCCESS,
    payload
}};

export const fetchProjectCheckpointsError = payload => ({
    type: FETCH_PROJECT_CHECKPOINTS_ERROR,
    payload
});

export const DELETE_PROJECT_CHECKPOINT_SEND = 'DELETE_PROJECT_CHECKPOINT_SEND';
export const DELETE_PROJECT_CHECKPOINT_SUCCESS = 'DELETE_PROJECT_CHECKPOINT_SUCCESS';
export const DELETE_PROJECT_CHECKPOINT_ERROR = 'DELETE_PROJECT_CHECKPOINT_ERROR';

export const deleteProjectCheckpoint = (projectId,checkpointId) => ({
    type: DELETE_PROJECT_CHECKPOINT_SEND,
    projectId,
    checkpointId
});

export const deleteProjectCheckpointSuccess = payload => {
    return {
    type: DELETE_PROJECT_CHECKPOINT_SUCCESS,
    payload
}};

export const deleteProjectCheckpointError = payload => ({
    type: DELETE_PROJECT_CHECKPOINT_ERROR,
    payload
});

// Share Checkpoints
export const SHARE_CHECKPOINT_SEND = 'SHARE_CHECKPOINT_SEND';
export const SHARE_CHECKPOINT_SUCCESS = 'SHARE_CHECKPOINT_SUCCESS';
export const SHARE_CHECKPOINT_ERROR = 'SHARE_CHECKPOINT_ERROR';

export const shareCheckpoint = (checkpointId,payload) => ({
    type: SHARE_CHECKPOINT_SEND,
    checkpointId,
    payload
});

export const shareCheckpointSuccess = payload => {
    return {
    type: SHARE_CHECKPOINT_SUCCESS,
    payload
}};

export const shareCheckpointError = payload => ({
    type: SHARE_CHECKPOINT_ERROR,
    payload
});

export const FETCH_SHARED_CHECKPOINTS_SEND = 'FETCH_SHARED_CHECKPOINTS_SEND';
export const FETCH_SHARED_CHECKPOINTS_SUCCESS = 'FETCH_SHARED_CHECKPOINTS_SUCCESS';
export const FETCH_SHARED_CHECKPOINTS_ERROR = 'FETCH_SHARED_CHECKPOINTS_ERROR';

export const fetchSharedCheckpoints = payload => ({
    type: FETCH_SHARED_CHECKPOINTS_SEND,
    payload
});

export const fetchSharedCheckpointsSuccess = payload => {
    return {
    type: FETCH_SHARED_CHECKPOINTS_SUCCESS,
    payload
}};

export const fetchSharedCheckpointsError = payload => ({
    type: FETCH_SHARED_CHECKPOINTS_ERROR,
    payload
});

export const OPEN_SHARE_CHECKPOINT_MODAL = 'OPEN_SHARE_CHECKPOINT_MODAL';
export const CLOSE_SHARE_CHECKPOINT_MODAL = 'CLOSE_SHARE_CHECKPOINT_MODAL';

export const openShareCheckpointModal = (checkpointId,isEditors) => ({
    type: OPEN_SHARE_CHECKPOINT_MODAL,
    checkpointId,
    isEditors
});

export const closeShareCheckpointModal = () => ({
    type: CLOSE_SHARE_CHECKPOINT_MODAL
});


export const MERGE_CHECKPOINT_SEND = 'MERGE_CHECKPOINT_SEND';
export const MERGE_CHECKPOINT_SUCCESS = 'MERGE_CHECKPOINT_SUCCESS';
export const MERGE_CHECKPOINT_ERROR = 'MERGE_CHECKPOINT_ERROR';

export const mergeCheckpoint = (projectId,checkpointId) => ({
    type: MERGE_CHECKPOINT_SEND,
    projectId,
    checkpointId
});

export const mergeCheckpointSuccess = payload => {
    return {
    type: MERGE_CHECKPOINT_SUCCESS,
    payload
}};

export const mergeCheckpointError = payload => ({
    type: MERGE_CHECKPOINT_ERROR,
    payload
});


export const FETCH_LENT_CHECKPOINTS_SEND = 'FETCH_LENT_CHECKPOINTS_SEND';
export const FETCH_LENT_CHECKPOINTS_SUCCESS = 'FETCH_LENT_CHECKPOINTS_SUCCESS';
export const FETCH_LENT_CHECKPOINTS_ERROR = 'FETCH_LENT_CHECKPOINTS_ERROR';

export const fetchLentCheckpoints = payload => ({
    type: FETCH_LENT_CHECKPOINTS_SEND,
    payload
});

export const fetchLentCheckpointsSuccess = payload => {
    return {
    type: FETCH_LENT_CHECKPOINTS_SUCCESS,
    payload
}};

export const fetchLentCheckpointsError = payload => ({
    type: FETCH_LENT_CHECKPOINTS_ERROR,
    payload
});


export const OPEN_CHECKPOINT_SHARING = 'OPEN_CHECKPOINT_SHARING';
export const OPEN_CHECKPOINT_SHARING_SUCCESS = 'OPEN_CHECKPOINT_SHARING_SUCCESS';
export const OPEN_CHECKPOINT_SHARING_ERROR = 'OPEN_CHECKPOINT_SHARING_ERROR';

export const openCheckpointSharing = (checkpoint) => ({
    type: OPEN_CHECKPOINT_SHARING,
    checkpoint
});

export const openCheckpointSharingSuccess = payload => {
    return {
    type: OPEN_CHECKPOINT_SHARING_SUCCESS,
    payload
}};

export const openCheckpointSharingError = payload => ({
    type: OPEN_CHECKPOINT_SHARING_ERROR,
    payload
});

// Unshare Checkpoints
export const UNSHARE_CHECKPOINT_SEND = 'UNSHARE_CHECKPOINT_SEND';
export const UNSHARE_CHECKPOINT_SUCCESS = 'UNSHARE_CHECKPOINT_SUCCESS';
export const UNSHARE_CHECKPOINT_ERROR = 'UNSHARE_CHECKPOINT_ERROR';

export const unshareCheckpoint = (shareId) => ({
    type: UNSHARE_CHECKPOINT_SEND,
    shareId
});

export const unshareCheckpointSuccess = payload => {
    return {
    type: UNSHARE_CHECKPOINT_SUCCESS,
    payload
}};

export const unshareCheckpointError = payload => ({
    type: UNSHARE_CHECKPOINT_ERROR,
    payload
});

export const FETCH_ALL_SHARES_SEND = 'FETCH_ALL_SHARES_SEND';
export const FETCH_ALL_SHARES_SUCCESS = 'FETCH_ALL_SHARES_SUCCESS';
export const FETCH_ALL_SHARES_ERROR = 'FETCH_ALL_SHARES_ERROR';

export const fetchAllShares = () => ({
    type: FETCH_ALL_SHARES_SEND
});

export const fetchAllSharesSuccess = payload => {
    return {
    type: FETCH_ALL_SHARES_SUCCESS,
    payload
}};

export const fetchAllSharesError = payload => ({
    type: FETCH_ALL_SHARES_ERROR,
    payload
});

