import superagent from 'superagent';
import * as storage from './storage';
import CONFIG from '../config';

const ENDPOINTS = {
    USERS: CONFIG.apiServer+'/api/users',
    AUTHENTICATE: CONFIG.apiServer+'/api/users/login',
    REGISTER: CONFIG.apiServer+'/api/users/register',
    FETCH_USER: CONFIG.apiServer+'/api/users/current',
    UPDATE_PASSWORD: CONFIG.apiServer+'/api/users/password/update',
    RESET_PASSWORD: CONFIG.apiServer+'/api/users/password/reset/request',
    REVERIFY:  CONFIG.apiServer+'/api/users/reverify',
    USERNAME_CHECK: CONFIG.apiServer+'/api/users/namecheck/',
    PROJECTS: CONFIG.apiServer+'/api/projects',
    CODES: CONFIG.apiServer+'/api/codes',
    MOTD: CONFIG.apiServer+'/api/motd',
    CONTACTS: CONFIG.apiServer+'/api/contacts',
    INBOX: CONFIG.apiServer+'/api/inbox',
    SHARE: CONFIG.apiServer+'/api/share',
    PAYMENT: CONFIG.apiServer+'/api/payment',
    FEEDBACK: CONFIG.apiServer+'/api/feedback',
    RESEARCH: CONFIG.apiServer+'/api/research',
    REFERRAL_METRIC: CONFIG.apiServer+'/api/referral/metric',
    AD_REFERRALS: CONFIG.apiServer+'/api/referral/ad',
    ANALYTICS: CONFIG.apiServer+'/api/health/analytics',
    POSTS: CONFIG.apiServer+'/api/posts',
    THOUGHTS: CONFIG.apiServer+'/api/thoughts'
}

export const authenticate = (payload) => {
    return superagent.post(ENDPOINTS.AUTHENTICATE).send(payload).then(response => response.body.results);
}

export const register = (payload) => 
    superagent.post(ENDPOINTS.REGISTER).send(payload).then(response => response.body.results);

export const fetchUser = () => 
    superagent.get(ENDPOINTS.FETCH_USER)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const updatePassword = (payload) => 
    superagent.post(ENDPOINTS.UPDATE_PASSWORD)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const resetPassword = (payload) => 
    superagent.post(ENDPOINTS.RESET_PASSWORD).send(payload).then(response => response.body.results);

export const reverify = (payload) => 
    superagent.post(ENDPOINTS.REVERIFY)
        .send(payload)
        .then(response => response.body.results);

export const checkUsername = (payload) => 
    superagent.get(ENDPOINTS.USERNAME_CHECK+payload)
        .then(response => response.body.results);

export const updatePreferences = (payload) => 
    superagent.put(ENDPOINTS.USERS+'/prefs')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const searchUsers = (searchEmail,searchRole,searchLastLogin) => 
    superagent.get(ENDPOINTS.USERS+'/admin/search?email='+searchEmail+'&role='+searchRole+ (searchLastLogin ? '&lastLogin='+searchLastLogin:''))
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const adminUpdateUser = (payload) => 
    superagent.put(ENDPOINTS.USERS+'/admin/update')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const sendFeedback = (payload) => 
    superagent.post(ENDPOINTS.FEEDBACK)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const fetchActiveUsers = () => 
    superagent.get(ENDPOINTS.USERS+'/admin/activity')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchUserProfile = (username) => 
    superagent.get(ENDPOINTS.USERS+'/profile/'+username)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const followUser = (username) => 
    superagent.put(ENDPOINTS.USERS+'/follow/'+username)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const unfollowUser = (username) => 
    superagent.put(ENDPOINTS.USERS+'/unfollow/'+username)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchFollowing = () => 
    superagent.get(ENDPOINTS.USERS+'/following')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchFollowers = () => 
    superagent.get(ENDPOINTS.USERS+'/followers')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
            
// Projects        
export const fetchProjects = () => 
    superagent.get(ENDPOINTS.PROJECTS)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchFullProject = (payload) => 
    superagent.get(ENDPOINTS.PROJECTS+'/'+payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const createProject = (payload) => 
    superagent.post(ENDPOINTS.PROJECTS)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const createProjectSequel = (projectId) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/sequel')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const updateProject = (payload) => 
    superagent.put(ENDPOINTS.PROJECTS)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const autoSaveProject = (payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/autosave')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const renameProject = (payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/rename')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteProject = (manuscriptId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+manuscriptId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const forkProject = (payload) =>
    superagent.post(ENDPOINTS.PROJECTS+'/'+payload.fork.id+'/fork')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchProjectCheckpoints = (projectId) => 
    superagent.get(ENDPOINTS.PROJECTS+'/'+projectId+'/checkpoints')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const deleteProjectCheckpoint = (projectId,checkpointId) =>
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/checkpoints/'+checkpointId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const mergeCheckpoint = (projectId,checkpointId) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/merge/'+checkpointId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const importDoc = (formData) => 
    superagent.post(ENDPOINTS.PROJECTS+'/import')
        .send(formData)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const exportEpub = (projectId) => 
    superagent.get(ENDPOINTS.PROJECTS+'/'+projectId+'/epub')
        .set('Authorization','Bearer '+storage.getToken())
        .responseType('blob')
        .then(response => response.body);

export const exportPdf = (projectId) => 
    superagent.get(ENDPOINTS.PROJECTS+'/'+projectId+'/pdf')
        .set('Authorization','Bearer '+storage.getToken())
        .responseType('blob')
        .then(response => response.body);
    
// Sharing Projects
export const shareCheckpoint = (checkpointId,payload) =>
    superagent.post(ENDPOINTS.SHARE+'/project/'+checkpointId)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchProjectLendees = (projectId) => 
    superagent.get(ENDPOINTS.SHARE+'/project/'+projectId+'/lendees')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    

export const fetchSharedProjects = () => 
    superagent.get(ENDPOINTS.SHARE)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchLentProjects = () => 
    superagent.get(ENDPOINTS.SHARE+'/lent')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const unshareCheckpoint = (shareId) => 
    superagent.delete(ENDPOINTS.SHARE+'/lent/'+shareId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchAllShares = () => 
    superagent.get(ENDPOINTS.SHARE+'/shares')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
            
// Scenes        
export const updateScene = (projectId,chapterId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters/'+chapterId+'/scenes')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
        
export const createScene = (projectId,chapterId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters/'+chapterId+'/scenes')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteScene = (projectId,chapterId,sceneId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters/'+chapterId+'/scenes/'+sceneId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
// Chapters
export const createChapter = (projectId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const updateChapter = (projectId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteChapter = (projectId,chapterId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/chapters/'+chapterId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

//Characters
export const createCharacter = (projectId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/characters')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const updateCharacter = (projectId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/characters')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteCharacter = (projectId,characterId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/characters/'+characterId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

// Relationships
export const updateRelationship = (projectId,characterId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/characters/'+characterId+'/relationships')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
        
export const createRelationship = (projectId,characterId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/characters/'+characterId+'/relationships')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteRelationship = (projectId,characterId,relationshipId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/characters/'+characterId+'/relationships/'+relationshipId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

//Locations
export const createLocation = (projectId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/locations')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const updateLocation = (projectId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/locations')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteLocation = (projectId,locationId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/locations/'+locationId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

//Events
export const createEvent = (projectId,payload) => 
    superagent.post(ENDPOINTS.PROJECTS+'/'+projectId+'/events')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const updateEvent = (projectId,payload) => 
    superagent.put(ENDPOINTS.PROJECTS+'/'+projectId+'/events')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
export const deleteEvent = (projectId,eventId) => 
    superagent.delete(ENDPOINTS.PROJECTS+'/'+projectId+'/events/'+eventId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

// Admin
export const fetchCodes = () => 
    superagent.get(ENDPOINTS.CODES)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const createCode = (payload) => 
    superagent.post(ENDPOINTS.CODES)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const deleteCode = (codeId) => 
    superagent.delete(ENDPOINTS.CODES+'/'+codeId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const sendCode = (codeId) => 
    superagent.get(ENDPOINTS.CODES+'/'+codeId+'/send')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const fetchMotds = () => 
    superagent.get(ENDPOINTS.MOTD+'/all')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchMotd = () => 
    superagent.get(ENDPOINTS.MOTD)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const createMotd = (payload) => 
    superagent.post(ENDPOINTS.MOTD)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const updateMotd = (payload) => 
    superagent.put(ENDPOINTS.MOTD)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteMotd = (motdId) => 
    superagent.delete(ENDPOINTS.MOTD+'/'+motdId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const logReferralMetric = (payload) => 
    superagent.put(ENDPOINTS.REFERRAL_METRIC)
        .send(payload)
        .then(response => response.body.results);

export const fetchAdReferrals = () => 
        superagent.get(ENDPOINTS.AD_REFERRALS)
            .set('Authorization','Bearer '+storage.getToken())
            .then(response => response.body.results);
        
export const createAdReferral = (payload) => 
    superagent.post(ENDPOINTS.AD_REFERRALS)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteAdReferral = (id) => 
    superagent.delete(ENDPOINTS.AD_REFERRALS+'/'+id)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchAnalytics = () => 
    superagent.get(ENDPOINTS.ANALYTICS)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchProjectsReport = () => 
    superagent.get(ENDPOINTS.ANALYTICS+'/projects/report')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
            
// Contacts
export const fetchContacts = () => 
    superagent.get(ENDPOINTS.CONTACTS)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const requestContact = (payload) => 
    superagent.post(ENDPOINTS.CONTACTS)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const approveContact = (contactId) => 
    superagent.put(ENDPOINTS.CONTACTS+'/'+contactId+'/approve')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteContact = (contactId) => 
    superagent.delete(ENDPOINTS.CONTACTS+'/'+contactId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

// Inbox
export const fetchInbox = () => 
    superagent.get(ENDPOINTS.INBOX)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchMessages = () => 
    superagent.get(ENDPOINTS.INBOX+'/messages')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchUnread = () => 
    superagent.get(ENDPOINTS.INBOX+'/unread')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const sendMessage = (payload) => 
    superagent.post(ENDPOINTS.INBOX)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteMessage = (messageId) => 
    superagent.delete(ENDPOINTS.INBOX+'/'+messageId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const readMessage = (messageId) => 
    superagent.put(ENDPOINTS.INBOX+'/'+messageId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const broadcastMessage = (payload) => 
    superagent.post(ENDPOINTS.INBOX+'/cast')
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    

export const subscribeToPremium = (payload) => 
    superagent.post(ENDPOINTS.PAYMENT)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const confirmPayment = () => 
    superagent.get(ENDPOINTS.PAYMENT+'/confirm')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const unsubscribeToPremium = (payload) => 
    superagent.delete(ENDPOINTS.PAYMENT)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const getUrlPreview = (url) => 
    superagent.get(ENDPOINTS.RESEARCH+'?url='+encodeURI(url))
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
// POSTS
export const fetchPosts = () => 
    superagent.get(ENDPOINTS.POSTS)
    .then(response => response.body.results);

export const createPost = (payload) => 
    superagent.post(ENDPOINTS.POSTS)
        .send(payload)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const deletePost = (postId) => 
    superagent.delete(ENDPOINTS.POSTS+'/'+postId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

// THOUGHTS
export const fetchUserThoughts = (offset,username) => 
    superagent.get(ENDPOINTS.THOUGHTS+'/user/'+(username?username:''+'?offset='+offset))
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const createThought = (thought) => 
    superagent.post(ENDPOINTS.THOUGHTS)
        .send({ thought })
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const reblogThought = (thoughtId,thought) => 
    superagent.post(ENDPOINTS.THOUGHTS+'/reblog/'+thoughtId)
        .send({ thought })
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    
export const deleteThought = (thoughtId) => 
    superagent.delete(ENDPOINTS.THOUGHTS+'/'+thoughtId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const likeThought = (thoughtId) => 
    superagent.put(ENDPOINTS.THOUGHTS+'/'+thoughtId+'/like')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
        
export const unlikeThought = (thoughtId) => 
    superagent.put(ENDPOINTS.THOUGHTS+'/'+thoughtId+'/unlike')
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
        
export const fetchPublicFeed = (offset,tag) => 
    superagent.get(ENDPOINTS.THOUGHTS+'/feed/public?offset='+offset+(tag?'&tag='+tag:''))
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const fetchFollowedFeed = (offset) => 
    superagent.get(ENDPOINTS.THOUGHTS+'/feed?offset='+offset)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
            
export const fetchThought = (thoughtId) => 
    superagent.get(ENDPOINTS.THOUGHTS+'/'+thoughtId)
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);

export const createComment = (thoughtId,comment) => 
    superagent.post(ENDPOINTS.THOUGHTS+'/'+thoughtId+'/comment')
        .send({ comment })
        .set('Authorization','Bearer '+storage.getToken())
        .then(response => response.body.results);
    