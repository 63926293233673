import React,{forwardRef} from 'react';
import {connect} from 'react-redux';
import Parser from 'html-react-parser';
import Toolbar from '../header/Toolbar';
import {readProject} from '../../project/actions';
import {formatBookChapter} from '../../util/exportToHtml';

const ChapterPage = forwardRef((props,ref) => {

    return (
        <div ref={ref} style={{ 
            backgroundColor: 'white',
            color: 'black', width:'70vw',
            marginLeft:'auto',
            marginRight:'auto',
            padding: '1em'
        }}>
            {props.text}
        </div>
    )
});

function getPage(project,chapterIndex,pageIndex) {
    if(project && project._id) {
        const currentChapter = project.chapters[chapterIndex];
        if(currentChapter.text) {
            //let json = JSON.parse(currentChapter.text);
            return formatBookChapter(currentChapter);
        }
    }
    return '';
}

class BookReader extends React.Component {

    pageRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            chapterIndex: 0,
            pageIndex: 0
        };        
    }

    componentDidMount() {
        const projectId = this.props.match.params.projectId;
        this.props.readProject(projectId)
    }

    render() {
        if(this.pageRef.current) {
            console.log('height',this.pageRef.current.scrollWidth)
        }
        const page = Parser(getPage(this.props.openProject,this.state.chapterIndex,this.state.pageIndex));

        return (
            <div>
                <Toolbar />
                <div>
                    <ChapterPage ref={this.pageRef} text={page} />
                </div>
            </div>
        );
    }
};

export default connect(state => ({
    openProject: state.projects.openProject
}),
{readProject})(BookReader);