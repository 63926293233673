import React from 'react';
import {useDispatch} from 'react-redux';
import {Segment,Label} from 'semantic-ui-react';
import {updateLocalCharacter} from '../../../project/actions';

const CharacterTraitsPanel = ({character}) => {
    const dispatch = useDispatch();
    return (
        <Segment inverted>
            <Label attached='top' color='teal'>Traits</Label>
            <div style={{ display: 'table', width: '100%'}}>
                <div style={{ display: 'table-row'}}>
                    <div style={{ display: 'table-cell'}}>
                        <strong>Likable</strong>    
                    </div>
                    <div style={{ display: 'table-cell'}}>
                        <strong>0</strong>&nbsp;&nbsp;
                        <input type='range' 
                            value={character.likable} 
                            onChange={(ev) => dispatch(updateLocalCharacter('likable',ev.target.value))}
                            step='1' min={0} max={10} style={{ width: '60%'}}/>&nbsp;
                        <strong>10</strong>
                    </div>
                </div>
                <div style={{ display: 'table-row'}}>
                    <div style={{ display: 'table-cell'}}>
                        <strong>Proactive</strong>    
                    </div>
                    <div style={{ display: 'table-cell'}}>
                        <strong>0</strong>&nbsp;&nbsp;
                        <input type='range' 
                            value={character.proactive} 
                            onChange={(ev) => dispatch(updateLocalCharacter('proactive',ev.target.value))}
                            step='1' min={0} max={10} style={{ width: '60%'}}/>&nbsp;
                        <strong>10</strong>
                    </div>
                </div>
                <div style={{ display: 'table-row'}}>
                    <div style={{ display: 'table-cell'}}>
                        <strong>Competent</strong>    
                    </div>
                    <div style={{ display: 'table-cell'}}>
                        <strong>0</strong>&nbsp;&nbsp;
                        <input type='range' 
                            value={character.competent} 
                            onChange={(ev) => dispatch(updateLocalCharacter('competent',ev.target.value))}
                            step='1' min={0} max={10} style={{ width: '60%'}}/>&nbsp;
                        <strong>10</strong>
                    </div>
                </div>

            </div>

        </Segment>
    );
}

export default CharacterTraitsPanel;