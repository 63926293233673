import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Button, Modal, Dimmer,Loader,Icon,Popup,Input,Menu,Progress,Message,Transition, Dropdown, Container} from 'semantic-ui-react';
import {
    openProject, fetchProjects, createProject,showCreateProjectModal,renameProject,
    hideCreateProjectModal,deleteProject, showExportModal,showProjectRenameModal,openImportModal,
    createProjectSequel
} from '../../project/actions';
import {fetchMotd,dismissMotd} from '../../admin/actions';
import {openView} from '../../viewer/actions';
import {fetchSharedCheckpoints,fetchLentCheckpoints,openCheckpointSharing} from '../../checkpoint/actions';
import DeleteModal from '../../components/DeleteModal';
import RenameProjectModal from '../project/modals/RenameProjectModal';
import ExportModal from '../project/modals/ExportModal';
import Showable from '../../components/Showable';
import Toolbar from '../header/Toolbar';
import {maxWidth} from '../../util/stringUtils';
import {hasPremium} from '../../services/roles';

const BookCoverModal = ({project, show, onClose}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [url,setUrl] = useState('');

    const uploadButton = hasPremium(user) ? <Button color='teal'
    onClick={() => {
        let win = window.cloudinary.openUploadWidget({ 
            cloud_name: 'cetacean-app', 
            upload_preset: 'up_usr_pre',
            multiple: false,
            folder: 'user/'+user.userId,
            tags: [user.userId],
            cropping: true,
            showSkipCropButton: false,
            croppingAspectRatio: 0.625,
            croppingCoordinatesMode: 'custom',
            defaultSource: "local",
            styles: {
                palette: {
                    window: "#141414",
                    windowBorder: "#90A0B3",
                    tabIcon: "#6DFFFF",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link: "#6DFFFF",
                    action: "#FF620C",
                    inactiveTabIcon: "#888888",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#272727"
                },
                fonts: {
                    default: {
                        active: true
                    }
                }
            }            
            },
            (error, result) => {
                //console.log(result);
                if(result.event === 'success') {
                    // Uploaded successfully. Time to update.
                    //setUrl(result.info.secure_url);
                    dispatch(renameProject({projectId:project._id,cover: result.info.secure_url}));
                    onClose();
                }
            });
    }}    
        >Upload Your Image</Button> : 
        <Button as={Link} to='/getpremium' color='orange'>Upload Your Own Image With Premium</Button>;

    return (
        <Modal open={show} className='ceta-modal'>
            <Modal.Content>
                <Input fluid label='Image URL' value={url} onChange={(ev) => setUrl(ev.target.value)}/>
                <p/>
                {uploadButton}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button color='teal' onClick={() => {
                    dispatch(renameProject({projectId:project._id,cover: url}));
                    onClose();
                }}>Save</Button>
            </Modal.Actions>
        </Modal>
    );
}

const OnboardUser = () => {
    const dispatch = useDispatch();
    return (
        <Container text textAlign='center'>
            <div>
                <h1>Welcome&nbsp;&nbsp;to&nbsp;&nbsp;Cetacean</h1>
            </div>
            <p/>
            <div>
                <Button color='teal' onClick={() => dispatch(showCreateProjectModal())}>
                    Click Here To Start A New Book
                </Button>
            </div>

            <p/>
            <div>
                <Button color='teal' onClick={() => dispatch(openImportModal())}>
                    Click Here To Import An Existing Book
                </Button>
            </div>

        </Container>
    );
}

const BookCard = ({project,hasPremium}) => {
    const dispatch = useDispatch();
    const [showDelete, setShowDelete] = useState(false);
    const [showCover, setShowCover] = useState(false);
    
    const coverUrl = project.cover ? project.cover : '/img/stock-cover.png';
    const progressPercent = Math.ceil((project.wordCount / project.wordGoal) * 100);
    return (
        <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={project._id}>
        <div className='ceta-dashboard-book'>            
            <div className='image-cell' onClick={() => dispatch(openProject(project._id))}>
                <img src={coverUrl} width='100' height='160' style={{ objectFit: 'cover',cursor:'pointer'}}/>
            </div>
            <div className='content-cell'>
                <p/>
                <div className='title'>{project.name}</div>
                <p/>
                <div className='meta'>{project.genre}</div>
                <div className='meta'><strong>Chapters:</strong> {project.chapters?.length}</div>
                <div className='meta'><strong>Words:</strong> {project.wordCount}</div>
                <div className='prog'>
                    <Progress progress color='teal' percent={progressPercent} />
                </div>
            </div>
            <div className='action-cell'>
                <div className='action'>
                    <Button color='teal' icon onClick={() => dispatch(openProject(project._id))}>
                        <Icon name='pencil'/> Write</Button>
                </div>
                <div className='action'>
                    <Dropdown icon='ellipsis horizontal' button className='icon'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setShowCover(true)}><Icon name='picture'/>Choose a Cover</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(showProjectRenameModal(project._id,project.name))}><Icon name='edit'/>Rename</Dropdown.Item>
                            <Dropdown.Item disabled={!hasPremium} onClick={() => dispatch(createProjectSequel(project._id))}><Icon name='copy'/>Create a Sequel</Dropdown.Item>
                            <Dropdown.Item onClick={() => setShowDelete(true)}><Icon name='delete'/>Delete</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(showExportModal(project._id))}><Icon name='print'/>Export to File</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <BookCoverModal project={project} show={showCover} onClose={() => setShowCover(false)} />
            <DeleteModal 
                show={showDelete}
                onCancel={() => setShowDelete(false)} 
                onConfirm={ () => {
                    dispatch(deleteProject(project._id));
                    setShowDelete(false);
                }}/>
        </div>
        </Transition>
    );
};

const Dashboard = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const allProjects = useSelector(state => state.projects.all);
    const isFetchingProjects = useSelector(state => state.projects.isFetchingProjects);

    useEffect( () => {
        dispatch(fetchProjects());
    },[dispatch]);

    const bodyContent = (allProjects.length === 0 && !isFetchingProjects) ? <OnboardUser /> 
        : allProjects.map( project => <BookCard key={project._id} project={project} hasPremium={hasPremium(user)}/>);

    return (
        <Toolbar>
            <Dimmer active={isFetchingProjects}>
                <Loader>Loading</Loader>
            </Dimmer>
            <RenameProjectModal />
            <ExportModal />
            <div className='ceta-dashboard'>
                {bodyContent}
            </div>
        </Toolbar>
    );
};

export default Dashboard;