import React,{useState,useEffect} from 'react';
import {Button, Modal,Input} from 'semantic-ui-react';

const RenameModal = (props) => {
    const [text,setText] = useState('');
    useEffect( () => setText(props.initialValue),[]);
    return (
        <Modal open={props.show}>
            <Modal.Header>Enter new Name</Modal.Header>
            <Modal.Content>
                <Input placeholder="New name here" value={text} onChange={(ev) => setText(ev.target.value)}/>
            </Modal.Content>
            <Modal.Actions>
                <Button positive 
                    disabled={text===''}
                    onClick={()=>{
                    props.target.name = text;
                    props.onSubmit();
                    props.onCancel();
                }}>Save</Button>
                <Button negative onClick={props.onCancel}>Cancel</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default RenameModal;