import React from 'react';
import {Modal,Header,Button,Icon} from 'semantic-ui-react';

const ErrorModal = (props) => {
    return (
        <Modal open={props.show} size='small'>
            <Header icon='bug' content='ERROR' style={{backgroundColor:'#363636', color:'white'}}/>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
            <p>
                {props.message}
            </p>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
            <Button color='teal' onClick={() => props.onConfirm(props)}>
                <Icon name='checkmark' /> Okay
            </Button>
            </Modal.Actions>
        </Modal>        
    );
};

export default ErrorModal;