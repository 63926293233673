export const FETCH_USER_THOUGHTS_SEND = 'FETCH_USER_THOUGHTS_SEND';
export const FETCH_USER_THOUGHTS_SUCCESS = 'FETCH_USER_THOUGHTS_SUCCESS';
export const FETCH_USER_THOUGHTS_ERROR = 'FETCH_USER_THOUGHTS_ERROR';

export const CREATE_THOUGHT_SEND = 'CREATE_THOUGHT_SEND';
export const CREATE_THOUGHT_SUCCESS = 'CREATE_THOUGHT_SUCCESS';
export const CREATE_THOUGHT_ERROR = 'CREATE_THOUGHT_ERROR';

export const REBLOG_THOUGHT_SEND = 'REBLOG_THOUGHT_SEND';
export const REBLOG_THOUGHT_SUCCESS = 'REBLOG_THOUGHT_SUCCESS';
export const REBLOG_THOUGHT_ERROR = 'REBLOG_THOUGHT_ERROR';

export const DELETE_THOUGHT_SEND = 'DELETE_THOUGHT_SEND';
export const DELETE_THOUGHT_SUCCESS = 'DELETE_THOUGHT_SUCCESS';
export const DELETE_THOUGHT_ERROR = 'DELETE_THOUGHT_ERROR';

export const OPEN_NEW_THOUGHT_MODAL = 'OPEN_NEW_THOUGHT_MODAL';
export const CLOSE_NEW_THOUGHT_MODAL = 'CLOSE_NEW_THOUGHT_MODAL';

export const LIKE_THOUGHT_SEND = 'LIKE_THOUGHT_SEND';
export const LIKE_THOUGHT_SUCCESS = 'LIKE_THOUGHT_SUCCESS';
export const LIKE_THOUGHT_ERROR = 'LIKE_THOUGHT_ERROR';

export const UNLIKE_THOUGHT_SEND = 'UNLIKE_THOUGHT_SEND';
export const UNLIKE_THOUGHT_SUCCESS = 'UNLIKE_THOUGHT_SUCCESS';
export const UNLIKE_THOUGHT_ERROR = 'UNLIKE_THOUGHT_ERROR';

export const OPEN_PROFILE_MODAL = 'OPEN_PROFILE_MODAL';
export const OPEN_PROFILE_MODAL_SUCCESS = 'OPEN_PROFILE_MODAL_SUCCESS';
export const OPEN_PROFILE_MODAL_ERROR = 'OPEN_PROFILE_MODAL_ERROR';
export const CLOSE_PROFILE_MODAL = 'CLOSE_PROFILE_MODAL';

export const FETCH_USER_PROFILE_SEND = 'FETCH_USER_PROFILE_SEND';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';

export const FETCH_PUBLIC_FEED_SEND = 'FETCH_PUBLIC_FEED_SEND';
export const FETCH_PUBLIC_FEED_SUCCESS = 'FETCH_PUBLIC_FEED_SUCCESS';
export const FETCH_PUBLIC_FEED_ERROR = 'FETCH_PUBLIC_FEED_ERROR';

export const FETCH_FOLLOWED_FEED_SEND = 'FETCH_FOLLOWED_FEED_SEND';
export const FETCH_FOLLOWED_FEED_SUCCESS = 'FETCH_FOLLOWED_FEED_SUCCESS';
export const FETCH_FOLLOWED_FEED_ERROR = 'FETCH_FOLLOWED_FEED_ERROR';

export const FETCH_THOUGHT_SEND = 'FETCH_THOUGHT_SEND';
export const FETCH_THOUGHT_SUCCESS = 'FETCH_THOUGHT_SUCCESS';
export const FETCH_THOUGHT_ERROR = 'FETCH_THOUGHT_ERROR';

export const OPEN_CONVERSATION = 'OPEN_CONVERSATION';
export const OPEN_CONVERSATION_SUCCESS = 'OPEN_CONVERSATION_SUCCESS';
export const OPEN_CONVERSATION_ERROR = 'OPEN_CONVERSATION_ERROR';
export const CLOSE_CONVERSATION = 'CLOSE_CONVERSATION';

export const CREATE_COMMENT_SEND = 'CREATE_COMMENT_SEND';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_ERROR = 'CREATE_COMMENT_ERROR';

export const DISMISS_FEED_ERROR = 'DISMISS_FEED_ERROR';

export const FOLLOW_USER_SEND = 'FOLLOW_USER_SEND';
export const FOLLOW_USER_SUCCESS = 'FOLLOW_USER_SUCCESS';
export const FOLLOW_USER_ERROR = 'FOLLOW_USER_ERROR';

export const UNFOLLOW_USER_SEND = 'UNFOLLOW_USER_SEND';
export const UNFOLLOW_USER_SUCCESS = 'UNFOLLOW_USER_SUCCESS';
export const UNFOLLOW_USER_ERROR = 'UNFOLLOW_USER_ERROR';

export const FETCH_FOLLOWING_SEND = 'FETCH_FOLLOWING_SEND';
export const FETCH_FOLLOWING_SUCCESS = 'FETCH_FOLLOWING_SUCCESS';
export const FETCH_FOLLOWING_ERROR = 'FETCH_FOLLOWING_ERROR';

export const FETCH_FOLLOWERS_SEND = 'FETCH_FOLLOWERS_SEND';
export const FETCH_FOLLOWERS_SUCCESS = 'FETCH_FOLLOWERS_SUCCESS';
export const FETCH_FOLLOWERS_ERROR = 'FETCH_FOLLOWERS_ERROR';

export const OPEN_REBLOG_MODAL = 'OPEN_REBLOG_MODAL';
export const CLOSE_REBLOG_MODAL = 'CLOSE_REBLOG_MODAL';

export const OPEN_READ_THOUGHT_CHAPTER_MODAL = 'OPEN_READ_THOUGHT_CHAPTER_MODAL';
export const CLOSE_READ_THOUGHT_CHAPTER_MODAL = 'CLOSE_READ_THOUGHT_CHAPTER_MODAL';
