import React from 'react';
import {Responsive} from 'semantic-ui-react';
import ContactsPanel from './ContactsPanel';
import Toolbar from '../header/Toolbar';

const ContactsPage = (props) => {
    return (
        <div>
            <Toolbar/>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <ContactsPanel />
                </div>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <ContactsPanel mobile={true}/>
                </div>
            </Responsive>
        </div>
    );
};

export default ContactsPage;