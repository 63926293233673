import React,{useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {Segment,Button,Icon,Input,List} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import {closeThesaurusBreakout,searchThesaurus} from '../../../dictionary/actions';

const ThesaurusBreakoutWindow = (props) => {
    const dispatch = useDispatch();
    const isSearchingThesaurus = useSelector(state => state.dictionary.isSearchingThesaurus);
    const showThesaurusBreakout = useSelector(state => state.dictionary.showThesaurusBreakout);
    const synonyms = useSelector(state => state.dictionary.synonyms);
    const [word,setWord] = useState('');

    const wordList = synonyms.map( (synonym) => (
        <List.Item key={synonym}>
            {synonym}
        </List.Item>
    ));

    return (
        <Showable showIf={showThesaurusBreakout}>
            <Draggable bounds='parent'>
                <div style={{ position: 'absolute', zIndex: 2,top:5,left:5, width: '250px', height: '50vh', color:'white'}}>
                    <Segment inverted style={{ 
                            backgroundColor:'#141414',
                            height:'100%', 
                            width:'100%',
                            boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                            padding:0}}>
                        <Button style={{ marginTop:'0.5em',marginLeft:'0.5em'}} compact icon size='mini' onClick={() => dispatch(closeThesaurusBreakout())}><Icon name='close'/></Button>
                        <div style={{ margin:'0.5em'}}>
                            <Input 
                                placeholder='Look up word' 
                                value={word}
                                fluid
                                onChange={(ev,dat) => setWord(dat.value)}
                                onKeyPress={ (ev) => {
                                    if(ev.key === 'Enter' && word !== '') {
                                        dispatch(searchThesaurus(word));
                                }}}
                                action={{
                                    color: 'teal',
                                    loading: isSearchingThesaurus,
                                    icon: 'search',
                                    onClick: () => {
                                        if(word !== '') {
                                        dispatch(searchThesaurus(word));
                                }}}}                              
                            />
                        </div>
                        <div style={{ overflowY:'auto',overflowX:'hidden', height:'40vh',padding:'0.5em'}}>
                            <List ordered>
                                {wordList}
                            </List>
                        </div>
                    </Segment>
                </div>
            </Draggable>
        </Showable>
    );
}

export default ThesaurusBreakoutWindow;