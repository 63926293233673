import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Icon,Header, Form,Input} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import { broadcastMessage } from '../../inbox/actions';

const BroadcastPage = (props) => {
    const dispatch = useDispatch();
    const isBroadcastingMessage = useSelector(state => state.inbox.isBroadcastingMessage);
    const [subject,setSubject] = useState('');
    const [text,setText] = useState('');
    
    return (
        <div>
        <Toolbar/>
        <div style={{margin:'1em'}}>
            <Header style={{ color:'white'}}>Broadcast To All Users</Header>
            <Form>
                <Input className='ceta-field'
                    placeholder='Subject...'
                    inverted
                    fluid
                    value={subject} onChange={(ev) => setSubject(ev.target.value)}/>
                <p/>
                <Form.TextArea 
                    style={{background:'#363636',color:'white'}}
                    placeholder='Compose your message here...'
                    rows='10'
                    value={text} 
                    onChange={(ev) => setText(ev.target.value)} />
            </Form>
            <p/>
            <Button color='teal' loading={isBroadcastingMessage} onClick={() => {
                dispatch(broadcastMessage({
                    message: {
                        text,
                        subject
                    }}));
                setText('');
                setSubject('');
            }}>Broadcast</Button>
        </div>
    </div>        
    );
};

export default BroadcastPage;