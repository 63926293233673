import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Modal,Form,Dimmer,Loader,Icon,Popup,Breadcrumb,Input,Segment} from 'semantic-ui-react';
import {
    fetchProjectCheckpoints, forkProject, deleteProjectCheckpoint,openShareCheckpointModal,mergeCheckpoint
} from '../../checkpoint/actions';
import {openView} from '../../viewer/actions';
import DeleteModal from '../../components/DeleteModal';
import ConfirmModal from '../../components/ConfirmModal';
import ShareModal from '../viewer/ShareModal';
import Toolbar from '../header/Toolbar';
import {maxWidth} from '../../util/stringUtils';


class ProjectCheckpointsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newCheckpointName: '',
            showDelete: false,
            checkpointToDelete: '',
            showForkModal: false,
            checkpointToMerge: '',
            showMergeConfirm: false
        }
    }

    componentDidMount() {
        this.props.fetchProjectCheckpoints(this.props.match.params.projectId);
    }

    submitFork = () => {
        this.props.forkProject(
            this.props.match.params.projectId,{ fork: {
                id:this.props.match.params.projectId,
                name: this.state.newCheckpointName
            }});
        this.setState({...this.state,showForkModal:false,newCheckpointName:''})
    }

    delete = (checkpointId) => {
        this.setState({
            ...this.state,
            showDelete:true,
            checkpointToDelete: checkpointId
        })
    }

    formatTime = (date) => {
        return date.toString().slice(0, 19).replace(/-/g, "/").replace("T", " ");
    }

    render() {        
        const parentCard = this.props.parentCheckpoint._id ? (
            <Card key={this.props.parentCheckpoint._id} style={{backgroundColor:'#363636'}}>
            <Card.Content>
                <Card.Header style={{color:'white'}}>{maxWidth(this.props.parentCheckpoint.name,30)}</Card.Header>
                <div style={{width:'100%', textAlign:'center'}} >
                    <Icon name='folder open'color='teal' size='massive'/>
                </div>
                <Card.Meta style={{color: '#cdcdcd'}}>
                    <span>Version: {this.props.parentCheckpoint.version}</span><br/>
                    <span>Chapters: {this.props.parentCheckpoint.chapters.length}</span><br/>
                    <span>Last Updated: {this.formatTime(this.props.parentCheckpoint.updateDate)}</span><br/>
                </Card.Meta>
            </Card.Content>
            <Card.Content extra>
                <div style={{width:'100%',textAlign:'center'}}>
                    <Button color='teal' 
                        onClick={() => this.setState({...this.state,showForkModal:true})}
                        ><Icon name='plus circle'/>New Checkpoint</Button>
                </div>
            </Card.Content>
        </Card>

        ) :null;

        const projects = this.props.checkpoints.checkpoints.map( project => {
            const reviewButton = (
                <Button icon='desktop' onClick={() => this.props.openView(project._id,'owner')} />                
            );

            const deleteButton = (
                <Button icon='delete' onClick={() => this.delete(project._id)} />
            );

            const mergeButton = (
                <Button icon='code branch' onClick={() => this.setState({
                    ...this.state,
                    showMergeConfirm: true,
                    checkpointToMerge: project._id
                })} />
            );

            const shareButton = (
                <Button icon='share' onClick={() => this.props.openShareCheckpointModal(project._id)} />
            );

            return (
                <Card key={project._id} style={{backgroundColor:'#363636'}}>
                    <Card.Content>
                        <Card.Header style={{color:'white'}}>{maxWidth(project.versionName,30)}</Card.Header>
                        <div style={{width:'100%', textAlign:'center'}} draggable={true} key={project._id} onDragStart={(ev) => ev.dataTransfer.setData("projectId", project._id)}>
                            <Icon name='folder open' size='massive'/>
                        </div>
                        <Card.Meta style={{color: '#cdcdcd'}}>
                            <span>Version: {project.version}</span><br/>
                            <span>Chapters: {project.chapters.length}</span><br/>
                            <span>Last Updated: {this.formatTime(project.updateDate)}</span><br/>
                        </Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                        <div style={{width:'100%',textAlign:'center'}}>
                            <Popup trigger={reviewButton}>Review Checkpoint</Popup>
                            <Popup trigger={shareButton}>Share Checkpoint</Popup>
                            <Popup trigger={mergeButton}>Merge Checkpoint Into Main</Popup>
                            <Popup trigger={deleteButton}>Delete Checkpoint</Popup>
                        </div>
                    </Card.Content>
                </Card>
        )});

        return (
            <div>
                <Toolbar/>
            <div style={{margin:'1em'}}>
                <Dimmer active={this.props.checkpoints.isFetchingProjectCheckpoints 
                    || this.props.checkpoints.isMergingCheckpoint}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <DeleteModal 
                    show={this.state.showDelete} 
                    data={this.state.deleteProject} 
                    onCancel={() =>this.setState({...this.state,showDelete:false})} 
                    onConfirm={ () => {
                        this.props.deleteProjectCheckpoint(this.props.match.params.projectId,this.state.checkpointToDelete);
                        this.setState({...this.state,showDelete:false});                        
                    }}/>
                <ConfirmModal 
                    show={this.state.showMergeConfirm} 
                    title='Merge Checkpoint'
                    message='Are you sure you want to merge this checkpoint into the main project?'
                    onCancel={() =>this.setState({...this.state,showMergeConfirm:false})} 
                    onConfirm={ () => {
                        this.props.mergeCheckpoint(this.props.match.params.projectId,this.state.checkpointToMerge);
                        this.setState({...this.state,showMergeConfirm:false});                        
                    }}/>
                <ShareModal />
                <Modal open={this.state.showForkModal}>
                    <Modal.Header style={{background:'#363636',color:'white'}}>
                        New Project Checkpoint
                    </Modal.Header>
                    <Modal.Content style={{background:'#363636',color:'white'}}>
                        <Form>
                            <Form.Field>
                                <Input 
                                    label='Checkpoint'
                                    placeholder='Checkpoint Name' 
                                    value={this.state.newCheckpointName}
                                    onChange={(ev) => this.setState({...this.state,newCheckpointName:ev.target.value})}
                                />
                            </Form.Field>
                            <Button color='teal' 
                                onClick={this.submitFork}
                                loading={this.props.checkpoints.isForkingProject}
                                disabled={this.state.newCheckpointName === ''}
                                >Create</Button>
                            <Button onClick={() => {
                                this.setState({...this.state,showForkModal:false})
                            }}>Close</Button>
                        </Form>
                    </Modal.Content>
                </Modal>
                <div>
                    <div style={{color:'white'}}>
                        <Segment inverted>
                        <Breadcrumb style={{color:'white'}} size='large'>
                            <Breadcrumb.Section>Projects</Breadcrumb.Section>
                            <Breadcrumb.Divider style={{ color:'white'}}/>
                            <Breadcrumb.Section>{maxWidth(this.props.parentCheckpoint.name,30)}</Breadcrumb.Section>
                            <Breadcrumb.Divider style={{ color:'white'}}/>
                            <Breadcrumb.Section active>Checkpoints</Breadcrumb.Section>
                        </Breadcrumb>
                        </Segment>
                    </div>
                </div>
                <p/>                
                <Card.Group>
                    {parentCard}
                    {projects}
                </Card.Group>
            </div>
            </div>
        );
    }
}

export default connect(state=>({
    checkpoints: state.checkpoints,
    parentCheckpoint: state.checkpoints.parentCheckpoint
}),{
    fetchProjectCheckpoints, forkProject,deleteProjectCheckpoint,
    openView,openShareCheckpointModal,mergeCheckpoint
})(ProjectCheckpointsPage);