export const DISMISS_CONTACT_ERROR = 'DISMISS_CONTACT_ERROR';

export const dismissContactError = () => ({
    type: DISMISS_CONTACT_ERROR
});

export const FETCH_CONTACTS_SEND = 'FETCH_CONTACTS_SEND';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR';

export const fetchContacts = payload => ({
    type: FETCH_CONTACTS_SEND,
    payload
});

export const fetchContactsSuccess = payload => {
    return {
    type: FETCH_CONTACTS_SUCCESS,
    payload
}};

export const fetchContactsError = payload => ({
    type: FETCH_CONTACTS_ERROR,
    payload
});

export const REQUEST_CONTACT_SEND = 'REQUEST_CONTACT_SEND';
export const REQUEST_CONTACT_SUCCESS = 'REQUEST_CONTACT_SUCCESS';
export const REQUEST_CONTACT_ERROR = 'REQUEST_CONTACT_ERROR';

export const requestContact = payload => ({
    type: REQUEST_CONTACT_SEND,
    payload
});

export const requestContactSuccess = payload => {
    return {
    type: REQUEST_CONTACT_SUCCESS,
    payload
}};

export const requestContactError = payload => ({
    type: REQUEST_CONTACT_ERROR,
    payload
});

export const DELETE_CONTACT_SEND = 'DELETE_CONTACT_SEND';
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const deleteContact = payload => ({
    type: DELETE_CONTACT_SEND,
    payload
});

export const deleteContactSuccess = payload => {
    return {
    type: DELETE_CONTACT_SUCCESS,
    payload
}};

export const deleteContactError = payload => ({
    type: DELETE_CONTACT_ERROR,
    payload
});

export const APPROVE_CONTACT_SEND = 'APPROVE_CONTACT_SEND';
export const APPROVE_CONTACT_SUCCESS = 'APPROVE_CONTACT_SUCCESS';
export const APPROVE_CONTACT_ERROR = 'APPROVE_CONTACT_ERROR';

export const approveContact = contactId => ({
    type: APPROVE_CONTACT_SEND,
    contactId
});

export const approveContactSuccess = payload => {
    return {
    type: APPROVE_CONTACT_SUCCESS,
    payload
}};

export const approveContactError = payload => ({
    type: REQUEST_CONTACT_ERROR,
    payload
});

export const OPEN_CHAT = 'OPEN_CHAT';
export const openChat = contactId => ({
    type: OPEN_CHAT,
    contactId
})