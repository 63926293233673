import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Table,Button,Modal,Container,Image,Icon,Responsive} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {utcToZonedTime,format} from 'date-fns-tz';
import Toolbar from '../header/Toolbar';
import ComposeMessageModal from './ComposeMessageModal';
import ReadMessageModal from './ReadMessageModal';
import {openComposeModal,fetchMessages,deleteMessage,markRead,openReadMessageModal} from '../../inbox/actions';
import {hasPremium} from '../../services/roles';

const MessageInbox = (props) => {
    const dispatch = useDispatch();
    const inbox = useSelector(state => state.inbox);
    const user = useSelector(state => state.user);

    useEffect( () => {
        dispatch(fetchMessages())
    },[dispatch]);

    const messageRows = inbox.messages.map( message => {
        const fromAvatar = message.fromAvatar || '/img/default-persona.png';
        const unreadIcon = message.read ? null : <Icon name='mail'/>;
        const fontWeight = message.read ? 'norma' : 'bold';
        return (
        <Table.Row key={message._id} onClick={() => {
            dispatch(openReadMessageModal(message));
            dispatch(markRead(message));
            }} style={{cursor:'pointer'}}>
            <Table.Cell collapsing>
                {unreadIcon}
            </Table.Cell>
            <Table.Cell collapsing style={{ fontWeight: fontWeight}}>
                <Image avatar src={fromAvatar} /> {message.fromName}
            </Table.Cell>
            <Table.Cell style={{ fontWeight: fontWeight}}>{message.subject}</Table.Cell>
            <Table.Cell collapsing style={{ fontWeight: fontWeight}}>
                {format(utcToZonedTime(message.createDate, user.timezone), 'MMM dd, yyyy h:mm:ss a', { timezone:user.timezone })}
            </Table.Cell>
            <Table.Cell textAlign='right' collapsing>                    
                    <Button size='mini' icon onClick={() => dispatch(deleteMessage(message._id))}><Icon name='delete'/></Button>
            </Table.Cell>
        </Table.Row>
    )});

    const newMessageButton = hasPremium(user) ? (
        <Button color='teal' onClick={() => dispatch(openComposeModal())}><Icon name='mail'/> New Message</Button>        
    ) : (
        <Button color='teal' as={Link} to='/getpremium'><Icon name='mail'/> New Message</Button>
    );

    return (
        <div>
            <Toolbar>
                <Container style={{ marginTop: '1em'}}>
                    <ReadMessageModal />
                    <ComposeMessageModal />
                    {newMessageButton}
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Table selectable inverted>
                                <Table.Header>
                                    <Table.HeaderCell>Unread</Table.HeaderCell>
                                    <Table.HeaderCell>From</Table.HeaderCell>
                                    <Table.HeaderCell>Subject</Table.HeaderCell>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Header>
                            <Table.Body>
                                {messageRows}
                            </Table.Body>
                        </Table>
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                        <Table striped inverted>
                            <Table.Body>
                                {messageRows}
                            </Table.Body>
                        </Table>
                    </Responsive>
                </Container>
            </Toolbar>
        </div>
    );
};

export default MessageInbox;