import React from 'react';
import SceneView from './SceneView'

const ChapterView = ({chapter}) => {
    const sceneViews = chapter.scenes.map( scene => (
        <SceneView scene={scene} key={scene._id}/>
    ));
    return (
        <div>
            <div style={{color:'white'}}>
                <h2>{chapter.name}</h2>
            </div>
            {sceneViews}
        </div>
    );
}

export default ChapterView;