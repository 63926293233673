import {
    SUBSCRIBE_PREMIUM_SEND,SUBSCRIBE_PREMIUM_SUCCESS, SUBSCRIBE_PREMIUM_ERROR,SUBSCRIBE_PREMIUM_REJECTED,
    CONFIRM_PAYMENT_SEND,CONFIRM_PAYMENT_SUCCESS,CONFIRM_PAYMENT_ERROR,
    UNSUBSCRIBE_PREMIUM_SEND,UNSUBSCRIBE_PREMIUM_SUCCESS, UNSUBSCRIBE_PREMIUM_ERROR
} from './actions';

const initialState = {
    isSubscribing: false,
    isUnsubscribing: false,
    hasSubscribed: false,
    isRejected: false,
    showError: false,
    errorMessage: ''
};

export default (state = initialState, action) => {
    switch(action.type) {
        case SUBSCRIBE_PREMIUM_SEND:
            return {
                ...state,
                isSubscribing: true,
                hasSubscribed: false,
                isRejected: false,
                showError: false,
                errorMessage: ''
            }
        case SUBSCRIBE_PREMIUM_SUCCESS:
            return {
                ...state,
                isSubscribing: false,
                hasSubscribed: true,
                showError: false,
                errorMessage: ''
            }
        case SUBSCRIBE_PREMIUM_ERROR:
            return {
                ...state,
                isSubscribing: false,
                hasSubscribed: false,
                showError: true,
                errorMessage: action.payload    
            }
        case SUBSCRIBE_PREMIUM_REJECTED:
            return {
                ...state,
                isSubscribing: false,
                isRejected: true
            }
        case CONFIRM_PAYMENT_SEND:
            return {
                ...state,
                isSubscribing: true,
                hasSubscribed: false,
                isRejected: false,
                showError: false,
                errorMessage: ''
            }
        case CONFIRM_PAYMENT_SUCCESS:
            return {
                ...state,
                isSubscribing: false,
                hasSubscribed: true,
                showError: false,
                errorMessage: ''
            }
        case CONFIRM_PAYMENT_ERROR:
            return {
                ...state,
                isSubscribing: false,
                showError: true,
                errorMessage: action.payload    
            }
 
        case UNSUBSCRIBE_PREMIUM_SEND:
            return {
                ...state,
                isUnsubscribing: true,
                showError: false,
                errorMessage: ''
            }
        case UNSUBSCRIBE_PREMIUM_SUCCESS:
            return {
                ...state,
                isUnsubscribing: false,
                showError: false,
                errorMessage: ''
            }
        case UNSUBSCRIBE_PREMIUM_ERROR:
            return {
                ...state,
                isUnsubscribing: false,
                showError: true,
                errorMessage: action.payload    
            }
                
        default:
            return state;
    }
}