import {createReducer} from '../util/reduxHelper';
import {handleProjects} from './project.handler';
import {handleChapters} from './chapter.handler';
import {handleCharacters} from './character.handler';
import {handleLocations} from './location.handler';
import {handleEvents} from './event.handler';
import {handleResearch} from './research.handler';
import {handlePlotpoints} from './plotpoint.handler';

const initialState = {
    all: [],
    showError:false,
    errorMessage: '',
    isFetchingProjects: false,
    isCreatingProject: false,
    showCreateProjectModal: false,
    isUpdatingProject: false,
    isDeletingProject: false,
    isOpeningProject: false,
    isProjectDirty: false,
    openProject: {},
    openProjectChapter: {},
    openProjectScene: {},
    openProjectCharacter: {},
    openProjectRelationship: {},
    openProjectResearch: {},
    openProjectPanel: '',
    showCreateChapterModal: false,
    isCreatingChapter: false,
    isCreatingScene: false,
    createSceneChapterId:'',
    showRenameSceneModal: false,
    renameSceneModalValue: '',
    renameSceneModalChapterId:'',
    renameSceneModalSceneId:'',
    showRenameChapterModal: false,
    renameChapterModalValue: '',
    renameChapterModalChapterId:'',
    showRechapterSceneModal: false,
    rechapterModalValue: '',
    rechapterModalSceneId: '',
    rechapterModalOptions: [],
    isDeletingScene:false,
    showDeleteSceneModal:false,
    deleteSceneModalSceneId:'',
    deleteSceneModalChapterIdId:'',
    deleteSceneModalProjectId:'',
    showDeleteChapterModal:false,
    deleteChapterModalChapterId: '',
    deleteChapterModalProjectId: '',
    isDeletingChapter: false,
    isCreatingCharacter: false,
    showEditCharacterModal: false,
    isCreatingRelationship: false,
    showEditRelationshipModal: false,
    createRelationCharacterId: '',
    showDeleteRelationshipModal:false,
    deleteRelationshipModalRelationshipId: '',
    deleteRelationshipModalCharacterId: '',
    deleteRelationshipModalProjectId: '',
    isDeletingRelationship: false,
    showDeleteCharacterModal:false,
    deleteCharacterModalCharacterId: '',
    deleteCharacterModalProjectId: '',
    isCreatingLocation: false,
    isDeletingLocation: false,
    showDeleteLocationModal:false,
    deleteLocationModalLocationId: '',
    deleteLocationModalProjectId: '',
    openProjectLocation: {},
    showCreateEventModal:false,
    isCreatingEvent: false,
    isDeletingEvent: false,
    showDeleteEventModal:false,
    deleteEventModalLocationId: '',
    deleteEventModalProjectId: '',
    openProjectEvent: {},
    showEditEventModal: false,
    lastSaveDate: new Date().getTime(),
    showExportModal: false,
    projectToExport: {},
    showNewRelationshipModal: false,
    showRenameProjectModal: false,
    renameProjectModalValue: '',
    renameProjectModalProjectId: '',
    isRenamingProject: false,
    renameResearchModalValue: '',
    renameResearchModalResearchId: '',
    showCharacterBreakout: false,
    openBreakoutCharacter: {},
    showResearchNotesBreakout: false,
    openBreakoutResearch: {},
    showLocationBreakout: false,
    openBreakoutLocation: {},
    showImportModal: false,
    showMainSideBar: true,
    showContextSideBar: false,
    openContextPanel: '',
    showProjectViewModal: false,
    showResearchNoteModal: false,
    showEditPlotPointModal: false,
    openProjectPlotPoint: {},
    showIntroModal: false,
    showWordCountExceededModal: false,
    isOffline: false,
    checkGrammar: false,
    keyCount: 0
};

const handlers = {
    ...handleProjects(),
    ...handleChapters(),
    ...handleCharacters(),
    ...handleLocations(),
    ...handleEvents(),
    ...handleResearch(),
    ...handlePlotpoints()
};

export default createReducer(initialState, handlers);
