import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Popup,Card,Breadcrumb,Responsive,Segment,Image,Transition,Dropdown} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import {
    showDeleteCharacterModal,showNewReasearchLinkModal,addResearch
} from '../../../project/actions';
import NotesCard from './NotesCard';
import LinkCard from './LinkCard';
import NewLinkModal from './NewLinkModal';

const ResearchPanel = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;

    const noItemsMessage = (openProject.research.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%', margin: '2em'}}>
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Research</h2>
            </Transition>
        </div>
    ) : null;

    const researchCards = openProject.research.map( research => {

            return research.researchType === 'Notes' ? (<NotesCard key={research._id} research={research} />):
                (<LinkCard key={research._id} research={research} />);
    });

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <NewLinkModal />
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{flexShrink: 0}}>
                <div style={{color:'white'}}>
                <Segment inverted>
                    <Breadcrumb style={{color:'white'}} size='large'>
                        <Breadcrumb.Section>Project</Breadcrumb.Section>
                        <Breadcrumb.Divider style={{ color:'white'}}/>
                        <Breadcrumb.Section active>Research</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>
                </div>
            </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{margin:'1em'}}>
                    <Showable showIf={!props.readOnly}>
                        <Dropdown text='Add Research' icon='flask' labeled button className='icon teal' pointing>
                            <Dropdown.Menu color='teal'>
                                <Dropdown.Item onClick={() => dispatch(addResearch('Notes'))}><Icon name='sticky note'/>Notes</Dropdown.Item>
                                <Dropdown.Item onClick={() => dispatch(showNewReasearchLinkModal())}><Icon name='linkify'/>URL Link</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Showable>
                    <p/>
                    {noItemsMessage}
                    <Card.Group>
                        {researchCards}
                    </Card.Group>
                </div>
            </div>
        </div>        
    );
};

export default ResearchPanel;