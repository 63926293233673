export const noTemplate = (project) => {
    let now = new Date();
    project.acts.push({
        _id: 'NEW_'+now.getTime(),
        tags: [],
        plotpoints: []
    });
    project.outlineTemplate = 'No Template';
    return project;
}


export const herosJourneyTemplate = (project) => {
    let now = new Date().getTime();
    let acts = [];
    let plotpoints = [];
    let act1 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act1, plotpoints, now++, 'The Ordinary World');
    addHJPlotPoint(act1, plotpoints, now++, 'The Call to Adventure');
    addHJPlotPoint(act1, plotpoints, now++, 'Refusal of the Call');
    addHJPlotPoint(act1, plotpoints, now++, 'Meeting the Mentor');
    addHJPlotPoint(act1, plotpoints, now++, 'Crossing the First Threshold');
    acts.push(act1);
    let act2 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act2, plotpoints, now++, 'Tests, Allies, and Enemies');
    addHJPlotPoint(act2, plotpoints, now++, 'Approach to the Inmost Cave');
    addHJPlotPoint(act2, plotpoints, now++, 'The Ordeal');
    addHJPlotPoint(act2, plotpoints, now++, 'The Reward');
    acts.push(act2);
    let act3 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act3, plotpoints, now++, 'The Road Back');
    addHJPlotPoint(act3, plotpoints, now++, 'Resurrection');
    addHJPlotPoint(act3, plotpoints, now++, 'Return with the Elixir');
    acts.push(act3);
    project.acts = acts;
    project.plotpoints = plotpoints;
    project.outlineTemplate = 'Heros Journey';
    return project;

};

const addHJPlotPoint = (act,plotpoints,now,title) => {
    let pp = {
        _id: 'NEW_'+now,
        tags: [],
        synopsis: '',
        isKey: true,
        keyName: title
    };
    plotpoints.push(pp);
    act.plotpoints.push(pp._id);

}

export const storyCircleTemplate = (project) => {
    let now = new Date().getTime();
    let acts = [];
    let plotpoints = [];
    let act1 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act1, plotpoints, now++, 'You');
    addHJPlotPoint(act1, plotpoints, now++, 'Need');
    addHJPlotPoint(act1, plotpoints, now++, 'Go');
    acts.push(act1);
    let act2 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act2, plotpoints, now++, 'Search');
    addHJPlotPoint(act2, plotpoints, now++, 'Find');
    addHJPlotPoint(act2, plotpoints, now++, 'Take');
    acts.push(act2);
    let act3 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act3, plotpoints, now++, 'Return');
    addHJPlotPoint(act3, plotpoints, now++, 'Change');
    acts.push(act3);
    project.acts = acts;
    project.plotpoints = plotpoints;
    project.outlineTemplate = 'Story Circle';
    return project;

};

// Save the cat
export const saveTheCatTemplate = (project) => {
    let now = new Date().getTime();
    let acts = [];
    let plotpoints = [];
    let act1 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act1, plotpoints, now++, 'Opening Image');
    addHJPlotPoint(act1, plotpoints, now++, 'Setup');
    addHJPlotPoint(act1, plotpoints, now++, 'Catalyst');
    addHJPlotPoint(act1, plotpoints, now++, 'Debate');
    acts.push(act1);
    let act2 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act2, plotpoints, now++, 'Break Into 2');
    addHJPlotPoint(act2, plotpoints, now++, 'B Story');
    addHJPlotPoint(act2, plotpoints, now++, 'Fun And Games');
    addHJPlotPoint(act2, plotpoints, now++, 'Midpoint');
    addHJPlotPoint(act2, plotpoints, now++, 'Bad Guys Close In');
    addHJPlotPoint(act2, plotpoints, now++, 'All Is Lost');
    addHJPlotPoint(act2, plotpoints, now++, 'Dark Night Of The Soul');
    acts.push(act2);
    let act3 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act3, plotpoints, now++, 'Break Into 3');
    addHJPlotPoint(act3, plotpoints, now++, 'Finale');
    addHJPlotPoint(act3, plotpoints, now++, 'Final Image');
    acts.push(act3);
    project.acts = acts;
    project.plotpoints = plotpoints;
    project.outlineTemplate = 'Save The Cat';
    return project;

};


// Plot Points by Syd Field
export const paradigmTemplate = (project) => {
    let now = new Date().getTime();
    let acts = [];
    let plotpoints = [];
    let act1 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act1, plotpoints, now++, 'Opening Image');
    addHJPlotPoint(act1, plotpoints, now++, 'Inciting Incident');
    addHJPlotPoint(act1, plotpoints, now++, 'Plot Point 1');
    acts.push(act1);
    let act2 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act2, plotpoints, now++, 'Pinch 1');
    addHJPlotPoint(act2, plotpoints, now++, 'Midpoint');
    addHJPlotPoint(act2, plotpoints, now++, 'Pinch 2');
    addHJPlotPoint(act2, plotpoints, now++, 'Plot Point 2');
    acts.push(act2);
    let act3 = {
        _id: 'NEW_'+now,
        plotpoints: [],
        tags: []
    };
    now++;
    addHJPlotPoint(act3, plotpoints, now++, 'Climax');
    addHJPlotPoint(act3, plotpoints, now++, 'Resolution');
    acts.push(act3);
    project.acts = acts;
    project.plotpoints = plotpoints;
    project.outlineTemplate = 'Paradigm';
    return project;

};
