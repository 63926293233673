import React from 'react';
import ReactDiffViewer,{DiffMethod} from 'react-diff-viewer';
import {Responsive} from 'semantic-ui-react';
import {useSelector} from 'react-redux';

const DiffView = (props) => {
    const oldCode = useSelector(state => state.viewer.oldDiffText);
    const newCode = useSelector(state => state.viewer.newDiffText);

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <div style={{flexShrink: 0}}>
                
            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{marginTop:'1em',marginLeft:'1em'}}>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <ReactDiffViewer 
                            oldValue={oldCode} 
                            newValue={newCode} 
                            splitView={true} 
                            useDarkTheme={true}
                            compareMethod={DiffMethod.WORDS}
                        />
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                        <ReactDiffViewer 
                                oldValue={oldCode} 
                                newValue={newCode} 
                                splitView={false} 
                                hideLineNumbers={true}
                                useDarkTheme={true}
                                compareMethod={DiffMethod.WORDS}
                            />
                    </Responsive>
                </div>
            </div>            
        </div>
    );
};

export default DiffView;