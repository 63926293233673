import PropTypes from 'prop-types';
import React, { useEffect,Component } from 'react';
import {
    Button,Container,Divider,Grid,Header,Icon,Image,List,Menu,Modal,
    Responsive,Segment,Sidebar,Visibility,Table
  } from 'semantic-ui-react';
import {Link} from 'react-router-dom';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
    const isSSR = typeof window === 'undefined'
  
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  }
  
  /* eslint-disable react/no-multi-comp */
  /* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
   * such things.
   */
  const HomepageHeading = ({ mobile,slug }) => (
    <Container text style={{backgroundColor:'rgba(39,39,39,0.5)',paddingBottom:'1em'}}>
        <div style={{ display:'flex',flexDirection:'row', alignItems: 'center',justifyContent:'center'}}>
            {mobile?<img src='/img/cetacean_76x76.png' style={{ maxWidth: '50px'}}/>
            :<img src='/img/cetacean.png' style={{ maxWidth: '100px'}}/>}
        <Header
            as='h1'
            content='Cetacean'
            inverted
            style={{
            fontSize: mobile ? '2em' : '4em',
            fontWeight: 'normal',
            marginBottom: 0,
            marginTop: mobile ? '0em' : '0em',
            }}
        />
      </div>
      <Header
        as='h2'
        content={slug}
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '0.5em',
        }}
      />
    </Container>
  )
  
  HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
  }

  const isActive = (path) => {
      if(window.location.pathname.indexOf(path) > -1) {
          return true;
      }
      return false;
  }
  
  /* Heads up!
   * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
   * It can be more complicated, but you can create really flexible markup.
   */
  class DesktopContainer extends Component {
    state = {}
  
    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })
  
    render() {
      const { children } = this.props;
      const { fixed } = this.state;
      return (
        <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 250, padding: '1em 0em',
              backgroundImage:'url("/img/main_splash.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
               }}
              vertical
            >
              <Menu
                fixed={fixed ? 'top' : null}
                inverted={!fixed}
                pointing={!fixed}
                secondary={!fixed}
                size='large'
              >
                <Container>
                  <Menu.Item as={Link} to='/' active={isActive()}>Home</Menu.Item>
                  <Menu.Item as={Link} to='/blog' active={isActive('/blog')}>Blog</Menu.Item>
                  <Menu.Item as={Link} to='/faq' active={isActive('/faq')}>FAQ</Menu.Item>
                </Container>
              </Menu>
              <HomepageHeading slug={this.props.slug}/>
            </Segment>
          </Visibility>
  
          {children}
        </Responsive>
      )
    }
  }
  
  DesktopContainer.propTypes = {
    children: PropTypes.node,
  }
  
  class MobileContainer extends Component {
    state = {}
  
    handleSidebarHide = () => this.setState({ sidebarOpened: false })
  
    handleToggle = () => this.setState({ sidebarOpened: true })
  
    render() {
      const { children } = this.props
      const { sidebarOpened } = this.state
  
      return (
        <Responsive
          as={Sidebar.Pushable}
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Sidebar
            as={Menu}
            animation='push'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as={Link} to='/'>Home</Menu.Item>
            <Menu.Item as={Link} to='/blog' active={isActive('/blog')}>Blog</Menu.Item>
            <Menu.Item as={Link} to='/faq' active={isActive('/faq')}>FAQ</Menu.Item>
          </Sidebar>
  
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 200, padding: '1em 0em',
              backgroundImage:'url("/img/writing_splash.jpg")',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
              }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile slug={this.props.slug}/>
            </Segment>
  
            {children}
          </Sidebar.Pusher>
        </Responsive>
      )
    }
  }
  
  MobileContainer.propTypes = {
    children: PropTypes.node,
  }
  
  const ResponsiveContainer = ({ children,slug }) => (
    <div>
      <DesktopContainer slug={slug}>{children}</DesktopContainer>
      <MobileContainer slug={slug}>{children}</MobileContainer>
    </div>
  )
  
  ResponsiveContainer.propTypes = {
    children: PropTypes.node,
  }
  
  export default ResponsiveContainer;