import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/wordsApi';

import {
    SEARCH_DICTIONARY_SEND,searchDictionarySuccess,searchDictionaryError,
    SEARCH_THESAURUS_SEND,searchThesaurusSuccess,searchThesaurusError
} from './actions';


function* lookupWord(action) {
    try {
        let response = yield call(() => api.getWord(action.word));
        yield put(searchDictionarySuccess(response));
    }
    catch(err) {
        yield put(searchDictionaryError(err.message));
    }
}

function* lookupSynonym(action) {
    try {
        let response = yield call(() => api.getSynonym(action.word));
        console.log(response)
        yield put(searchThesaurusSuccess(response));
    }
    catch(err) {
        yield put(searchThesaurusError(err.message));
    }
}

export default function* dictionarySagas() {
    yield takeLatest(SEARCH_DICTIONARY_SEND,lookupWord);
    yield takeLatest(SEARCH_THESAURUS_SEND,lookupSynonym);
}