import React from 'react';
import {connect,useDispatch,useSelector} from 'react-redux';
import {Prompt} from 'react-router-dom';
import {Dimmer,Loader,Responsive,Sidebar} from 'semantic-ui-react';
import Fullscreen from 'react-full-screen'

import ProjectSidebar from './ProjectSidebar';
import ProjectPanel from './ProjectPanel';
import ProjectBar from './ProjectBar';
import RenameModal from '../../components/RenameModal';
import RenameChapterModal from './modals/RenameChapterModal';
import RenameResearchModal from './research/RenameResearchModal';
import ErrorModal from '../../components/ErrorModal';
import RechapterSceneModal from './RechapterSceneModal';
import DeleteModal from '../../components/DeleteModal';
import Toolbar from '../header/Toolbar';
import BookSideBar from './sidebars/BookSideBar';
import BasicSideBar from './sidebars/BasicSideBar';
import MobileBookSideBar from './sidebars/MobileBookSideBar';
import CharacterBreakoutWindow from './breakout/CharacterBreakoutWindow';
import ResearchNotesBreakoutWindow from './breakout/ResearchNotesBreakoutWindow';
import DictionaryBreakoutWindow from './breakout/DictionaryBreakoutWindow';
import ThesaurusBreakoutWindow from './breakout/ThesaurusBreakoutWindow';
import LocationBreakoutWindow from './breakout/LocationBreakoutWindow';

import {hideSceneRenameModal,renameLocalScene,
    hideChapterRenameModal,renameLocalChapter,
    hideRechapterSceneModal,rechapterLocalScene,
    hideDeleteSceneModal,deleteScene,removeScene,
    hideDeleteChapterModal,deleteChapter,removeChapter,
    hideDeleteCharacterModal,deleteCharacter,removeCharacter,
    hideDeleteRelationshipModal,deleteRelationship,removeRelationship,
    hideDeleteLocationModal,deleteLocation,removeLocation,
    hideDeleteEventModal,deleteEvent,removeEvent,
    dismissProjectError,
    openChapter,openChaptersPanel,
    removeResearch,hideDeleteResearchModal
    } from '../../project/actions';
import {toggleDistractionFree} from '../../user/actions';

const MobileContainer = (props) => {
    const openProject = useSelector(state => state.projects.openProject);    
    if('Book'===openProject.projectType) {
        return (
            <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyTablet.maxWidth}>
                <Toolbar showProjectMenu={true}>
                            <ProjectPanel useMobile={true} />
                </Toolbar>
          </Responsive>
        );    
    }
    return (
        <Responsive as={Sidebar.Pushable} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Toolbar showProjectMenu={true}>
                <ProjectBar>
                        <ProjectPanel useMobile={true} />
                </ProjectBar>
            </Toolbar>
      </Responsive>
    );
}

function getSidebar(project) {
    switch(project.projectType) {
        case 'Book':
            return (
                <div style={{overflowY:'auto',overflowX:'hidden',height:'calc( 100vh - 54px)',width:'300px'}}>
                    <BookSideBar project={project} />
                </div>
            );
        case 'Basic':
            return (
                <div style={{overflowY:'auto',overflowX:'hidden',height:'calc( 100vh - 54px)',width:'150px'}}>
                    <BasicSideBar project={project} />
                </div>
            );
        default:
            return (
                <div style={{overflowY:'auto',overflowX:'hidden',height:'calc( 100vh - 54px)',width:'300px'}}>
                    <ProjectSidebar project={project} />
                </div>
            );
    }
}

const DesktopContainer = (props) => {
    const dispatch = useDispatch();
    const isDistractionFree = useSelector(state => state.user.isDistractionFree);
    const openProject = useSelector(state => state.projects.openProject);
    return (
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Toolbar>
            <CharacterBreakoutWindow />
            <ResearchNotesBreakoutWindow />
            <DictionaryBreakoutWindow />
            <ThesaurusBreakoutWindow />
            <LocationBreakoutWindow />
            <Fullscreen
                enabled={isDistractionFree}
                onChange={isFull => dispatch(toggleDistractionFree(isFull))}
            >                
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'stretch',marginTop:'4px' }}>        
                    {getSidebar(openProject)}
                    <div style={{overflowY:'auto',height:'calc( 100vh - 54px)',width:'100%',alignSelf:'flex-start'}}>
                        <ProjectPanel useMobile={false}/>
                    </div>
                </div>
            </Fullscreen>
            </Toolbar>
        </Responsive>
    );
};

class ProjectPage extends React.Component {

    componentDidMount() {
        if('Book' === this.props.projects.openProject.projectType) {
            if(this.props.projects.openProject.chapters[0]) {
                this.props.openChapter(this.props.projects.openProject.chapters[0]._id);
            }
            else {
                this.props.openChaptersPanel(this.props.projects.openProject._id);
            }
        }
    }
    
    render() {
        return (
            <div>
                <Dimmer active={this.props.projects.isUpdatingProject}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <Prompt
                    when={this.props.projects.isProjectDirty}
                    message='You have unsaved changes, are you sure you want to leave?'
                />                
                <ErrorModal 
                    show={this.props.projects.showError} 
                    message={this.props.projects.errorMessage}
                    onConfirm={() => this.props.dismissProjectError()}
                />
                <RenameModal 
                    show={this.props.projects.showRenameSceneModal}
                    initialValue={this.props.projects.renameSceneModalValue}
                    onSave={(value) => this.props.renameLocalScene(value)}
                    onCancel={() => this.props.hideSceneRenameModal()}
                />
                <RenameChapterModal />
                <RechapterSceneModal 
                    options={this.props.projects.rechapterModalOptions}
                    show={this.props.projects.showRechapterSceneModal}
                    initialValue={this.props.projects.rechapterModalValue}
                    onSave={(value) => this.props.rechapterLocalScene(value)}
                    onCancel={() => this.props.hideRechapterSceneModal()}
                />
                <RenameResearchModal />                
                <DeleteModal 
                    sceneId={this.props.projects.deleteSceneModalSceneId}
                    chapterId={this.props.projects.deleteSceneModalChapterId}
                    projectId={this.props.projects.deleteSceneModalProjectId}
                    show={this.props.projects.showDeleteSceneModal}                     
                    onCancel={this.props.hideDeleteSceneModal} 
                    onConfirm={(data) => {
                        this.props.removeScene(data.projectId,data.chapterId,data.sceneId);
                        this.props.hideDeleteSceneModal();

                    }}
                />
                <DeleteModal 
                    chapterId={this.props.projects.deleteChapterModalChapterId}
                    projectId={this.props.projects.deleteChapterModalProjectId}
                    show={this.props.projects.showDeleteChapterModal}                     
                    onCancel={this.props.hideDeleteChapterModal} 
                    onConfirm={(data) => {
                        this.props.removeChapter(data.projectId,data.chapterId);
                        this.props.hideDeleteChapterModal();
                    }}
                />
                <DeleteModal 
                    characterId={this.props.projects.deleteCharacterModalCharacterId}
                    projectId={this.props.projects.deleteCharacterModalProjectId}
                    show={this.props.projects.showDeleteCharacterModal}                     
                    onCancel={this.props.hideDeleteCharacterModal} 
                    onConfirm={(data) => {
                        this.props.removeCharacter(data.projectId,data.characterId);
                        this.props.hideDeleteCharacterModal();
                    }}
                />
                <DeleteModal 
                    relationshipId={this.props.projects.deleteRelationshipModalRelationshipId}
                    characterId={this.props.projects.deleteRelationshipModalCharacterId}
                    projectId={this.props.projects.deleteRelationshipModalProjectId}
                    show={this.props.projects.showDeleteRelationshipModal}                     
                    onCancel={this.props.hideDeleteRelationshipModal} 
                    onConfirm={(data) => {
                        this.props.removeRelationship(data.projectId,data.characterId,data.relationshipId);
                        this.props.hideDeleteRelationshipModal();
                    }}
                />
                <DeleteModal 
                    locationId={this.props.projects.deleteLocationModalLocationId}
                    projectId={this.props.projects.deleteLocationModalProjectId}
                    show={this.props.projects.showDeleteLocationModal}                     
                    onCancel={this.props.hideDeleteLocationModal} 
                    onConfirm={(data) => {
                        this.props.removeLocation(data.projectId,data.locationId);
                        this.props.hideDeleteLocationModal();
                    }}
                />
                <DeleteModal 
                    eventId={this.props.projects.deleteEventModalEventId}
                    projectId={this.props.projects.deleteEventModalProjectId}
                    show={this.props.projects.showDeleteEventModal}                     
                    onCancel={this.props.hideDeleteEventModal} 
                    onConfirm={(data) => {
                        this.props.removeEvent(data.projectId,data.eventId);
                        this.props.hideDeleteEventModal();
                    }}
                />
                <DeleteModal 
                    researchId={this.props.projects.deleteResearchModalResearchId}
                    projectId={this.props.projects.deleteResearchModalProjectId}
                    show={this.props.projects.showDeleteResearchModal}                     
                    onCancel={this.props.hideDeleteResearchModal} 
                    onConfirm={(data) => {
                        this.props.removeResearch(data.projectId,data.researchId);
                        this.props.hideDeleteResearchModal();
                    }}
                />

                <DesktopContainer />
                <MobileContainer />
            </div>
        );
    }
}

export default connect( state=>({
    projects: state.projects,
    user: state.user
    }),
    {
        hideSceneRenameModal,renameLocalScene,
        hideChapterRenameModal,renameLocalChapter,
        hideRechapterSceneModal,rechapterLocalScene,
        hideDeleteSceneModal,removeScene,deleteScene,
        hideDeleteChapterModal,deleteChapter,removeChapter,
        hideDeleteCharacterModal,deleteCharacter,removeCharacter,
        hideDeleteRelationshipModal,deleteRelationship,removeRelationship,
        hideDeleteLocationModal,deleteLocation, removeLocation,
        hideDeleteEventModal,deleteEvent,removeEvent,
        dismissProjectError,openChapter,
        removeResearch,hideDeleteResearchModal,openChaptersPanel
    })
    (ProjectPage);