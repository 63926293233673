import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Sidebar} from 'semantic-ui-react';
import ChaptersContext from './writing/ChaptersContext';
import ReadChaptersContext from './read/ReadChaptersContext';
import DictionaryContext from './writing/DictionaryContext';
import ProgressContext from './writing/ProgressContext';
import CharactersContext from './brainstorm/CharactersContext';
import LocationsContext from './brainstorm/LocationsContext';
import ResearchContext from './outline/ResearchContext';
import OutlineContext from './writing/OutlineContext';
import {hideContextSideBar} from '../../project/actions';

const duration = 200;
const sidebarStyle = {
  transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
  entering: { width: 0 },
  entered: { width: '300px' },
  exiting: { width: '300px' },
  exited: { width: 0 }
}
const linkStyle = {
  transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
}

function getContextPanel(panelName) {
    switch(panelName) {
        case 'Chapters': {
            return (<ChaptersContext />);
        }
        case 'ReadChapters': {
            return (<ReadChaptersContext />);            
        }
        case 'EditChapters': {
            return (<ChaptersContext disableMenu={true} />);            
        }
        case 'Dictionary': {
            return (<DictionaryContext />);
        }
        case 'Progress': {
            return (<ProgressContext />);
        }
        case 'Characters': {
            return (<CharactersContext />);
        }
        case 'Locations': {
            return (<LocationsContext />);
        }
        case 'Research': {
            return (<ResearchContext />);
        }
        case 'Outline': {
            return (<OutlineContext />);
        }
        default:
            return (<div></div>);
    }
}

const MobileContextSideBar = (props) => {
    const dispatch = useDispatch();
    const showContext = useSelector(state => state.projects.showContextSideBar);
    const openContextPanel = useSelector(state => state.projects.openContextPanel);
    return(
        <Sidebar animation='overlay' width='wide'
            onHide={() => dispatch(hideContextSideBar())}
            visible={showContext}>
                <div className='context-sidebar-mobile'>
                    {getContextPanel(openContextPanel)}
                </div>
        </Sidebar>
    );

}

export default MobileContextSideBar;