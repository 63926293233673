import React, {useMemo,useState, useCallback} from 'react';
import {createEditor} from 'slate';
import {Slate, Editable, withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import { withRichText, Element, Leaf } from './richTextTools';

  
export const DocumentDisplay = ({content,placeholder,footer,centered}) => {
    const editor = useMemo( () => withRichText(withHistory(withReact(createEditor()))), []);
    const renderElement = useCallback( props => <Element {...props}/>,[]);
    const renderLeaf = useCallback( props => <Leaf {...props}/>,[]);
    const marginBottom = footer ? '10em' : '0em';
    const marginLeft = centered ? 'auto' : '0em';
    const marginRight = centered ? 'auto' : '0em';
    const editorWidth = centered ? '75%' : '100%';

    return (
      <div >
          <Slate editor={editor} value={content}>
            <div >
                    <div style={{
                      background: 'white',
                      marginLeft,
                      marginRight,
                      width: editorWidth,
                      marginBottom: marginBottom,
                      backgroundColor: '#363636',
                      color: 'white',
                      }}>
                  <Editable 
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      placeholder={placeholder}
                      readOnly={true}
                  />
                  </div>
            </div>
            </Slate>
        </div>
    );
}

export default DocumentDisplay;