export const OPEN_DICTIONARY_BREAKOUT = 'OPEN_DICTIONARY_BREAKOUT';
export const CLOSE_DICTIONARY_BREAKOUT = 'CLOSE_DICTIONARY_BREAKOUT';

export const openDictionaryBreakout = (research) => ({ 
    type: OPEN_DICTIONARY_BREAKOUT,
    research
});

export const closeDictionaryBreakout = () => ({ 
    type: CLOSE_DICTIONARY_BREAKOUT
});

export const SEARCH_DICTIONARY_SEND = 'SEARCH_DICTIONARY_SEND';
export const SEARCH_DICTIONARY_SUCCESS = 'SEARCH_DICTIONARY_SUCCESS';
export const SEARCH_DICTIONARY_ERROR = 'SEARCH_DICTIONARY_ERROR';

export const searchDictionary = (word) => ({
    type: SEARCH_DICTIONARY_SEND,
    word
});

export const searchDictionarySuccess = (payload) => ({
    type: SEARCH_DICTIONARY_SUCCESS,
    payload
});

export const searchDictionaryError = (payload) => ({
    type: SEARCH_DICTIONARY_ERROR,
    payload
});


export const OPEN_THESAURUS_BREAKOUT = 'OPEN_THESAURUS_BREAKOUT';
export const CLOSE_THESAURUS_BREAKOUT = 'CLOSE_THESAURUS_BREAKOUT';

export const openThesaurusBreakout = (research) => ({ 
    type: OPEN_THESAURUS_BREAKOUT,
    research
});

export const closeThesaurusBreakout = () => ({ 
    type: CLOSE_THESAURUS_BREAKOUT
});

export const SEARCH_THESAURUS_SEND = 'SEARCH_THESAURUS_SEND';
export const SEARCH_THESAURUS_SUCCESS = 'SEARCH_THESAURUS_SUCCESS';
export const SEARCH_THESAURUS_ERROR = 'SEARCH_THESAURUS_ERROR';

export const searchThesaurus = (word) => ({
    type: SEARCH_THESAURUS_SEND,
    word
});

export const searchThesaurusSuccess = (payload) => ({
    type: SEARCH_THESAURUS_SUCCESS,
    payload
});

export const searchThesaurusError = (payload) => ({
    type: SEARCH_THESAURUS_ERROR,
    payload
});
