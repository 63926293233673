import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Segment,Button,Icon,Popup,Image,Label} from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import Avatar from '../../components/Avatar';
import DeleteModal from '../../components/DeleteModal';
import Showable from '../../components/Showable';
import {likeThought,unlikeThought,openProfileModal,openConversation, closeConversation, 
    openReblogModal,openReadThoughtChapterModal,deleteThought} from '../../feed/actions';
import Conversation from './Conversation';
import WordCountThought from './WordCountThought';
import * as escapeHtml from 'escape-html';
var linkifyHtml = require('linkifyjs/html');

const formatDate = (date,timezone) => {
    if(!date) {
        return '';
    }
    let tzDate = utcToZonedTime(date, timezone).getTime();
    let now = (new Date().getTime() - tzDate) / 1000;
    let days = Math.floor(now / 86400);
    let hours = Math.floor(now / 3600 % 24);
    let minutes = Math.floor(now / 60 % 60);
    let seconds = Math.floor(now % 60);
    if(days>0) return days+'d';
    if(hours>0) return hours+'h';
    if(minutes>0) return minutes+'m';
    return seconds+'s';
}

const parseText = (text) => {
    if(!text) return '';
    return linkifyHtml(escapeHtml(text).replace(/(\r\n|\n|\r)/g,"<br />"));
}

const LikeButton = ({thought}) => {
    const dispatch = useDispatch();
    if(thought.hasLiked) {
        const unlikeButton = <Button color='teal' icon onClick={() => dispatch(unlikeThought(thought.id))}><Icon name='heart'/>{thought.likes>0?thought.likes:null}</Button>;
        return (<Popup trigger={unlikeButton}>Unlike This</Popup>);
    }
    return (<Popup trigger={<Button color='teal' icon onClick={() => dispatch(likeThought(thought.id))}><Icon name='heart outline'/>{thought.likes>0?thought.likes:null}</Button>}>Like This</Popup>);
}

const ChapterLink = ({project,chapter}) => {
    const dispatch = useDispatch();
    if(project && chapter) {
        for(let i=0;i<project.chapters.length;i++) {
            if(project.chapters[i] === chapter._id) {
                return (<Button icon color='orange' onClick={() => dispatch(openReadThoughtChapterModal(chapter))}><Icon name='book'/> {project.name} - Chapter {(i+1)}</Button>
                );
            }
        }
    }
    return [];
}

const ThoughtContent = ({thought}) => {
    const chapterLink = (thought.chapter && thought.project)? <ChapterLink project={thought.project} chapter={thought.chapter} />: [];
    return (
        <div style={{ marginTop:'0.5em'}}>
            {thought.progressName? <WordCountThought name={thought.progressName} wordCount={thought.progressWordCount} wordGoal={thought.progressWordGoal} />:null}
            <div dangerouslySetInnerHTML={{ __html: parseText(thought.text) }} />
            <ChapterLink project={thought.project} chapter={thought.chapter} />
            <Showable showIf={thought.mediaType === 'Image'}>
                <Image src={thought.media} style={{width:'100%', marginTop: '2em',marginBottom:'1em', objectFit: 'cover',borderRadius: '8px',border:'1px solid #ccc'}} />
            </Showable>
            <Showable showIf={thought.mediaType === 'Video'}>
                <div className='ceta-video-wrapper' style={{ marginTop:'2em',marginBottom:'1em'}}>
                    <ReactPlayer url={thought.media} width='100%' height='100%' className='ceta-video'/>
                </div>
            </Showable>
        </div>
    );
}

const Thought = ({ thought,readOnly,isOpen }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [showDeleteModal,setShowDeleteModal] = useState(false);

    const tagList = thought.tags.map( tag => <Label style={{ marginTop:'0.25em'}}key={tag}>{tag}</Label>);
    const commentButton = !readOnly?<Popup trigger={<Button color='teal' onClick={() => dispatch(openConversation(thought.id))} icon><Icon name='comment outline'/>{thought.comments.length>0?thought.comments.length:null}</Button>}>Comments</Popup> : null;
    const reblogButton = !readOnly?<Popup trigger={<Button color='teal' onClick={() => dispatch(openReblogModal(thought))} icon><Icon name='retweet'/></Button>}>Reblog This</Popup> : null;
    const reblogContent = thought.reblog ? (
        <div style={{ margin:'0.5em',padding:'0.5em', borderWidth: '1px',borderColor:'#ccc',borderRadius:'8px',borderStyle:'solid'}}>
            <div style={{ width:'100%'}}>
                <div style={{ display:'inline-block'}}>
                    <Icon name='retweet' />
                </div>
                <div style={{ display:'inline-block'}}>
                    <Avatar thought={thought.reblog} />
                </div>
                <div style={{ display:'inline-block'}}>
                    <div>
                        <span style={{ cursor:'pointer'}} onClick={() => dispatch(openProfileModal(thought.reblog.author))}>
                            <strong>{thought.reblog.author}</strong>
                            {(thought.thoughtType === 'Private')?(<Popup trigger={<Icon name='lock'/>}>Private Thought</Popup>):null}
                        </span>
                    </div>                        
                </div>
                <div style={{ display:'inline-block',float:'right'}}>
                    <span>{formatDate(thought.reblog.createDate, Intl.DateTimeFormat().resolvedOptions().timeZone)}</span>                        
                </div>                    
            </div>
            <ThoughtContent thought={thought.reblog} />
        </div> 
        ): null;

    return (
        <div style={{ width: '100%',marginTop:'0.5em',marginBottom:'0.5em'}}>
            <DeleteModal 
                show={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)} 
                onConfirm={(data) => {
                    dispatch(deleteThought(thought.id));
                    setShowDeleteModal(false);
                }}
            />

            <Segment inverted>
                <div style={{ width:'100%'}}>
                    <div style={{ display:'inline-block'}}>
                        <Avatar thought={thought} />
                    </div>
                    <div style={{ display:'inline-block'}}>
                        <div>
                            <span style={{ cursor:'pointer'}} onClick={() => dispatch(openProfileModal(thought.author))}>
                                <strong>{thought.author}</strong>
                                {(thought.thoughtType === 'Private')?(<Popup trigger={<Icon name='lock'/>}>Private Thought</Popup>):null}
                            </span>
                        </div>                        
                    </div>
                    <div style={{ display:'inline-block',float:'right'}}>
                        <span>{formatDate(thought.createDate, Intl.DateTimeFormat().resolvedOptions().timeZone)}</span>                        
                    </div>                    
                </div>
                <ThoughtContent thought={thought} />
                {reblogContent}
                {tagList}
                <Segment.Inline align='right'>
                    <Showable showIf={((!isOpen) && (!readOnly))}>
                        <Button.Group inverted={true} compact={true}>
                            {commentButton}
                            <Showable showIf={(thought.author !== user.name) && (thought.thoughtType !== 'Private')}>
                                {reblogButton}
                            </Showable>
                            <LikeButton thought={thought} />
                            <Showable showIf={thought.author === user.name}>
                                <Button color='teal' icon onClick={() => setShowDeleteModal(true)}><Icon name='delete'/></Button>
                            </Showable>
                        </Button.Group>
                    </Showable>
                    <Showable showIf={isOpen}>
                        <Button.Group>
                            <Button icon onClick={() => dispatch(closeConversation())}><Icon name='close'/></Button>
                        </Button.Group>
                    </Showable>
                </Segment.Inline>
                <Conversation thought={thought} />
            </Segment>            
        </div>
    );
};

export default Thought;