import React,{createRef,useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Dimmer,Loader,Button,Responsive,Icon} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toolbar from '../header/Toolbar';
import NewThoughtModal from './NewThoughtModal';
import ReblogModal from './ReblogModal';
import ReadChapterModal from './ReadChapterModal';
import Thought from './Thought';
import ProfileModal from './ProfileModal';
import Avatar from '../../components/Avatar';
import ErrorModal from '../../components/ErrorModal';
import {fetchUserThoughts,openNewThoughtModal,dismissFeedError} from '../../feed/actions';

const UserFeed = (props) => {
    const dispatch = useDispatch();
    const userThoughts = useSelector(state => state.feed.userThoughts);    
    const isFetchingThoughts = useSelector(state => state.feed.isFetchingThoughts);
    const userThoughtsHasNext = useSelector(state => state.feed.userThoughtsHasNext);
    const userThoughtsProfile = useSelector(state => state.feed.userThoughtsProfile);
    const feed = useSelector(state => state.feed);

    useEffect( () => {
        dispatch(fetchUserThoughts(0,props.match.params.username));
    },[dispatch,props.match.params.username]);

    const thoughtList = userThoughts.map( thought => <Thought thought={thought} key={thought.id}/>);
    return (
        <Toolbar>
            <NewThoughtModal />
            <ReblogModal />
            <ProfileModal />
            <ReadChapterModal />
            <ErrorModal 
                show={feed.showError} 
                message={feed.errorMessage}
                onConfirm={() => dispatch(dismissFeedError())}
            />
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div style={{}}>
                    <div style={{ width:'200px',overflowX:'hidden',position:'fixed',zIndex:1,paddingTop:'1em' }}>
                            <div style={{ color: 'white', textAlign:'center' }}><h3>{userThoughtsProfile.name}</h3></div>
                            <div style={{ marginBottom:'1em'}}><Avatar profile={userThoughtsProfile} fullsize={true}/></div>
                            <Button.Group fluid vertical={true}>
                            <Button fluid color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/>Compose A Thought</Button>
                            </Button.Group>
                    </div>
                    <div style={{ marginLeft:'200px'}}>
                        <div>
                            <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                                <InfiniteScroll
                                    dataLength={userThoughts.length}
                                    next={() => dispatch(fetchUserThoughts(userThoughts.length,props.match.params.username))}
                                    hasMore={userThoughtsHasNext}
                                    loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                                {thoughtList}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <div>
                    <div>
                        <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                            <InfiniteScroll
                                dataLength={userThoughts.length}
                                next={() => dispatch(fetchUserThoughts(userThoughts.length,props.match.params.username))}
                                hasMore={userThoughtsHasNext}
                                loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                            {thoughtList}
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div style={{ position:'fixed',left:0,bottom:0}}>
                       <Button.Group vertical={true}>
                            <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/></Button>
                            </Button.Group>
 
                    </div>
                </div>
            </Responsive>
        </Toolbar>
    );
};

export default UserFeed;