import React from 'react';
import {useDispatch} from 'react-redux';
import {Container,Button,Grid} from 'semantic-ui-react';
import * as template from './templates';
import {applyTemplate} from '../../../project/actions';

const OutlineGuide = ({project}) => {
    const dispatch = useDispatch();
    return (
        <div>
            <div className='ceta-idea-board'>
                <div className='idea-toolbar'>
                </div>
                <div className='idea-cards'>
                    <Container fluid textAlign='center'>
                        <div style={{ marginBottom:'4em'}}>
                            <h2>Choose an Outline Template</h2>
                        </div>
                        <div>
                            <Grid stackable container verticalAlign='middle'>
                                <Grid.Row>
                                    <Grid.Column width='3'>
                                        <Button color='teal' onClick={() => {
                                            let updatedProject = template.herosJourneyTemplate(project);
                                            dispatch(applyTemplate(updatedProject));
                                        }}>Hero's Journey</Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width='8'>
                                        Joseph Campbell's "Hero with a Thousand Faces". Best for stories where the hero has a transformative arc.
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width='3'>
                                        <Button color='teal' onClick={() => {
                                            let updatedProject = template.saveTheCatTemplate(project);
                                            dispatch(applyTemplate(updatedProject));
                                        }}>Save The Cat</Button>

                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width='8'>
                                        An outline and story structure based on "Save The Cat!" by Blake Snyder and "Save The Cat! Writes A Novel" by Jessica Brody.
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width='3'>
                                        <Button color='teal' onClick={() => {
                                            let updatedProject = template.storyCircleTemplate(project);
                                            dispatch(applyTemplate(updatedProject));
                                        }}>Story Circle</Button>

                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width='8'>
                                        A more modern version of the Hero's Journey by Dan Harmon
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width='3'>
                                        <Button color='teal' onClick={() => {
                                            let updatedProject = template.paradigmTemplate(project);
                                            dispatch(applyTemplate(updatedProject));
                                        }}>Paradigm</Button>

                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width='8'>
                                        The Paradigm method from "Screenplay: The Foundations of Screenwriting" by Syd Field.
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                <Grid.Column width='3'>
                                        <Button color='teal' onClick={() => {
                                            let updatedProject = template.noTemplate(project);
                                            dispatch(applyTemplate(updatedProject));
                                        }}>No Template</Button>
                                    </Grid.Column>
                                    <Grid.Column textAlign='left' width='8'>
                                        I know what I'm doing
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    );
};

export default OutlineGuide;