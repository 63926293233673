import React,{useState,useRef,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Input,Label, Popup} from 'semantic-ui-react';
import {updateLocalResearch,showDeleteResearchModal} from '../../../project/actions';
import UtilityBar from './UtilityBar';

const DisplayCard = ({research,setEditMode}) => {
    const displayName = research.name ? research.name.split('\n').map( (s,i) => <span key={i}>{s}<br/></span>) : 'Click here to edit';

    return (
        <div style={{ width:'100%',height:'100%',paddingTop:'0.5em',paddingBottom:'0.5em',fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"}} onClick={() => setEditMode(true)}>
            {displayName}
        </div>
    );
}

const EditCard = ({research,setEditMode,noteHeight,background,text}) => {
    const dispatch = useDispatch();
    const editRef = useRef(null);
    const [taHeight,setTaHeight] = useState(0);
    useEffect( () => {
        setTaHeight(editRef.current.scrollHeight);
    },[editRef,research.name]);

    return (
        <div style={{ width:'100%',height: '100%',paddingTop:'0.5em',paddingBottom:'0.5em'}}>
            <textarea ref={editRef}
                autoFocus
                style={{ 
                    width:'100%',
                    height: taHeight,
                    border: 'none', 
                    outline: 'none',
                    boxShadow:'none',
                    resize:'none',
                    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                    backgroundColor: background,
                    color: text}} 
                value={research.name} 
                onChange={(ev) => dispatch(updateLocalResearch(research._id,'name',ev.target.value))}                
                onBlur={() => setEditMode(false)}/>
        </div>
    );
}

const getCardClass = (research) => {
    const size = research.size ? research.size : 'regular';
    //return 'ceta-idea-card-'+size;
    return size;
}

const NoteCard = ({research,noteHeight,background,text}) => {
    const [editMode,setEditMode] = useState(false);
    if(editMode) {
        return <EditCard research={research} setEditMode={setEditMode} noteHeight={noteHeight} background={background} text={text}/>
    }
    else {
        return <DisplayCard research={research} setEditMode={setEditMode}/>
    }
}
export default NoteCard;
