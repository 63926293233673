import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {Segment,Button,Icon,Image} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import DocumentDisplay from '../../editor/DocumentDisplay';
import {closeLocationBreakout} from '../../../project/actions';

const LocationBreakoutWindow = (props) => {
    const dispatch = useDispatch();
    const showLocationBreakout = useSelector(state => state.projects.showLocationBreakout);
    const openBreakoutLocation = useSelector(state => state.projects.openBreakoutLocation);

    const sceneryElement = (openBreakoutLocation.scenery) 
        ? JSON.parse(openBreakoutLocation.scenery) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    const denizensElement = (openBreakoutLocation.denizens) 
        ? JSON.parse(openBreakoutLocation.denizens) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
        
    const avatar = openBreakoutLocation.image ? (<Image draggable={false} src={openBreakoutLocation.image}/>) : null;
    return (
        <Showable showIf={showLocationBreakout}>
            <Draggable bounds='parent'>
                <div style={{ position: 'absolute', zIndex: 2,top:0,left:0, width: '250px', height: '50vh', color:'white'}}>
                    <Segment inverted style={{ 
                            backgroundColor:'#141414',
                            height:'100%', 
                            width:'100%',
                            boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                            padding:0}}>
                        <Button style={{ marginTop:'0.5em',marginLeft:'0.5em'}} compact icon size='mini' onClick={() => dispatch(closeLocationBreakout())}><Icon name='close'/></Button>
                        <div style={{ overflowY:'auto',overflowX:'hidden', height:'45vh',padding:'0.5em'}}>
                            {avatar}
                            <div><strong>{openBreakoutLocation.name}</strong></div>
                            <div>{openBreakoutLocation.overview}</div>
                            <div>
                                <label>Scenery:</label>
                                <DocumentDisplay content={sceneryElement} />
                            </div>
                            <div>
                                <label>Denizens:</label>
                                <DocumentDisplay content={denizensElement} />
                            </div>
                        </div>
                    </Segment>
                </div>
            </Draggable>
        </Showable>
    );
}

export default LocationBreakoutWindow;