import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Form} from 'semantic-ui-react';
import {closeShareCheckpointModal, shareCheckpoint} from '../../checkpoint/actions';
import {fetchContacts} from '../../contact/actions';

const ShareModal = (props) => {
    const dispatch = useDispatch();
    const sharedCheckpointId = useSelector(state => state.checkpoints.sharedCheckpointId);
    const showShareModal = useSelector(state => state.checkpoints.showShareModal);
    const isSharingCheckpoint = useSelector(state => state.checkpoints.isSharingCheckpoint);
    const contacts = useSelector(state => state.contacts);
    const [contactId,setContactId] = useState('');
    const [editor,setEditor] = useState(false);

    useEffect( () => {
        dispatch(fetchContacts())
    },[dispatch]);

    const options = contacts.mututal.map( contact => ({
        text: contact.name,
        value: contact._id
    }));

    return (
        <Modal open={showShareModal}>
            <Modal.Header style={{background:'#272727',color:'white'}}>
                Share Checkpoint
            </Modal.Header>
            <Modal.Content style={{background:'#272727',color:'white'}}>
                <Form>
                    <Form.Select 
                        style={{background:'#363636',color:'white'}}
                        placeholder='Select a contact...' 
                        onChange={ (ev,data) => setContactId(data.value)}
                        options={options} />
                    <Form.Checkbox 
                        label={<label style={{color:'white'}}>Allow contact to edit checkpoint</label>}
                        onClick={() => setEditor(!editor)}
                    />
                    <Button color='teal' loading={isSharingCheckpoint}
                        onClick={() => dispatch(shareCheckpoint(sharedCheckpointId,{
                            shareTerms: {
                                projectId: sharedCheckpointId,
                                lendeeId: contactId,
                                isEditor: editor
                        }},props.reloadLendees
                        ))}>Share</Button>
                    <Button onClick={() => {
                        dispatch(closeShareCheckpointModal());
                    }}>Cancel</Button>

                </Form>
            </Modal.Content>
        </Modal>

    );
}

export default ShareModal;