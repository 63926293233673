import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Segment,Image} from 'semantic-ui-react';
import {closeReadMessageModal, deleteMessage} from '../../inbox/actions';
import {utcToZonedTime,format} from 'date-fns-tz';

const ReadMessageModal = (props) => {
    const dispatch = useDispatch();
    const showReadMessageModal = useSelector(state => state.inbox.showReadMessageModal);
    const openMessage = useSelector(state => state.inbox.openMessage);
    const user = useSelector(state => state.user);
    const formattedDate = openMessage.createDate ? format(utcToZonedTime(openMessage.createDate, user.timezone), 'MMM dd, yyyy h:mm:ss a', { timezone:user.timezone }) : '';
    const fromAvatar = openMessage.fromAvatar || '/img/default-persona.png';
    const markupText = openMessage.text ? openMessage.text.split('\n').map( (item,key) => (<div key={key}>{item?item:(<br/>)}</div>)):'';
    return (
        <Modal open={showReadMessageModal} centered={false}>
            <Modal.Content style={{background:'#272727',color:'white'}}>
                <Segment style={{background:'#363636',color:'white'}}>
                    <div>
                        <Image avatar src={fromAvatar} /> {openMessage.fromName}
                    </div>
                    <div>
                        {formattedDate}
                    </div>
                    <div>
                        <strong>{openMessage.subject}</strong>
                    </div>
                </Segment>
                <Segment style={{background:'#363636',color:'white'}}>{markupText}</Segment>
            </Modal.Content>
            <Modal.Actions style={{background:'#272727',color:'white'}}>
                <Button onClick={() => dispatch(closeReadMessageModal())}>Done</Button>
                <Button onClick={() => dispatch(deleteMessage(openMessage._id))}>Delete</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ReadMessageModal;