import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Input,Menu,Tab} from 'semantic-ui-react';
import EmbeddedRichTextEditor from '../../editor/EmbeddedRichTextEditor';
import {updateLocalLocation} from '../../../project/actions';
import UploadedImage from '../../../components/UploadedImage';
import ClosePanelWidget from '../ClosePanelWidget';

const LocationEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const openProjectLocation = useSelector(state => state.projects.openProjectLocation);

    const sceneryElement = (openProjectLocation.scenery) 
        ? JSON.parse(openProjectLocation.scenery) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const denizensElement = (openProjectLocation.denizens) 
        ? JSON.parse(openProjectLocation.denizens) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

        const panes = [
            { menuItem: (
                <Menu.Item key='basic' style={{color:'white',marginLeft:'3em'}}>
                    Basic
                </Menu.Item>
            ), render: () => (
                <div style={{
                    height:'100%',display:'flex',flexDirection:'column',            
                }}>
                    <div style={{flexGrow:1,overflowY:'auto'}}>
    
                        <div style={{margin: '2em'}}>
                            <div style={{display: 'flex',flexDirection: 'row',flexWrap: 'wrap'}}>
                                <div style={{ width: '320px',marginLeft:'auto',marginRight:'auto'}}>
                                    <UploadedImage 
                                        src={openProjectLocation.image}
                                        dim='300px'
                                        stock='/img/ceta-location-default.png'
                                        onDelete={ () => dispatch(updateLocalLocation('image',''))}
                                        onSuccess={ (result) => dispatch(updateLocalLocation('image',result))}
                                    />
                                </div>
                                <div style={{ width: '100%',maxWidth:'400px',marginLeft:'auto',marginRight:'auto'}}>
                                    <p/>
                                    <div style={{ display:'table', width:'100%'}}>
                                        <div style={{ display:'table-row'}}>
                                            <div style={{ display:'table-cell',width:'80px'}}>
                                                <span>Name</span>
                                            </div>
                                            <div style={{ display:'table-cell'}}>
                                                <Input fluid
                                                    placeholder='Name' 
                                                    value={openProjectLocation.name}
                                                    onChange={(ev) => dispatch(updateLocalLocation('name',ev.target.value))}
                                                />
                                            </div>
                                        </div>
                                        <p/>
                                        <div style={{ display:'table-row'}}>
                                            <div style={{ display:'table-cell',width:'80px'}}>
                                                <span>Overview</span>
                                            </div>
                                            <div style={{ display:'table-cell'}}>
                                                <Input fluid
                                                    placeholder='A brief description of the place...' 
                                                    value={openProjectLocation.overview ? openProjectLocation.overview : ''}
                                                    onChange={(ev) => dispatch(updateLocalLocation('overview',ev.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <p/>
                                <EmbeddedRichTextEditor 
                                    title='Scenery'
                                    key='sceneryField'
                                    content={sceneryElement}
                                    onUpdate={(value) => dispatch(updateLocalLocation('scenery',JSON.stringify(value)))}
                                    placeholder='Describe what your location looks like...'
                                />
                                <p/>
                                <EmbeddedRichTextEditor 
                                    title='Denizens'
                                    key='denizensField'
                                    content={denizensElement}
                                    onUpdate={(value) => dispatch(updateLocalLocation('denizens',JSON.stringify(value)))}
                                    placeholder='Describe the people who frequent this location...'
                                />
                        </div>
                    </div>
                </div>
        
            )}
        ];        
    return (
        <div>
            <ClosePanelWidget />
            <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes} style={{paddingLeft:'0.5em'}} key={openProjectLocation._id}/>

        </div>
    );
};

export default LocationEditPanel;