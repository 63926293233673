import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Dropdown, Input,Form, Grid,TextArea} from 'semantic-ui-react';
import Epoch from './Epoch';
import {
    closeEvent,updateLocalEvent
} from '../../../project/actions';


const EditEventModal = (props) => {
    const dispatch = useDispatch();
    const showEditEventModal = useSelector(state => state.projects.showEditEventModal);
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectEvent = useSelector(state => state.projects.openProjectEvent);

    const actOptions = Array.from(Array(7).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const chapterOptions = Array.from(Array(50).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const sceneOptions = Array.from(Array(30).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));
    
    const characterOptions = openProject.characters.map( character => ({
        key: character._id,
        text: character.name,
        value: character._id
    }));

    return (
        <Modal open={showEditEventModal}>
            <Modal.Header style={{ backgroundColor:'#272727',color:'white'}}>
                Edit Event
            </Modal.Header>
            <Modal.Content style={{ backgroundColor:'#272727',color:'white'}}>
                <Form>
                    <Form.Field>
                    <Input 
                            label='Name'
                            placeholder='Name' 
                            value={openProjectEvent.name}
                            onChange={(ev) => dispatch(updateLocalEvent('name',ev.target.value))}
                        />
                    </Form.Field>
                    <p/>
                    <TextArea
                        value={openProjectEvent.description}
                        onChange={(ev) => dispatch(updateLocalEvent('description',ev.target.value))}
                        placeholder='Describe what happened on the event...'
                    />
                    <p/>
                    <Epoch label='Time from day 0' 
                        value={openProjectEvent.epoch} 
                        onChange={(val) => dispatch(updateLocalEvent('epoch',val))}
                        />
                    <p/>
                    Act&nbsp;
                    <Dropdown compact selection 
                        value={openProjectEvent.act} 
                        options={actOptions} 
                        onChange={(ev,dat) => dispatch(updateLocalEvent('act',dat.value))}
                        />
                    &nbsp;&nbsp;
                    Chapter&nbsp;
                    <Dropdown compact selection 
                        value={openProjectEvent.chapter} 
                        options={chapterOptions} 
                        onChange={(ev,dat) => dispatch(updateLocalEvent('chapter',dat.value))}
                        />
                    &nbsp;&nbsp;
                    Scene&nbsp;
                    <Dropdown compact selection 
                        value={openProjectEvent.scene} 
                        options={sceneOptions} 
                        onChange={(ev,dat) => dispatch(updateLocalEvent('scene',dat.value))}
                        />
                    <p/>
                    <Form.Field>
                        <Dropdown options={characterOptions} fluid selection multiple 
                        value={openProjectEvent.characters}
                        onChange={ (ev,dat) => dispatch(updateLocalEvent('characters',dat.value))}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#272727',color:'white'}}>
                <Button onClick={() => {
                        dispatch(closeEvent());
                    }}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default EditEventModal;