import React from 'react';
import {Dropdown} from 'semantic-ui-react';

const TimezoneSelect = ({style,value,onChange}) => {
    if(!value) {
        value = 'UTC';
    }
    const options = [
        { key: 'Pacific/Kiritimati', value:'Pacific/Kiritimati', text:'Pacific/Kiritimati (+14:00hr)'},
        { key: 'Pacific/Enderbury', value:'Pacific/Enderbury', text:'Pacific/Enderbury (+13:00hr)'},
        { key: 'Pacific/Auckland', value:'Pacific/Auckland', text:'Pacific/Auckland (+12:00hr)'},
        { key: 'Pacific/Guadalcanal', value:'Pacific/Guadalcanal', text:'Pacific/Guadalcanal (+11:00hr)'},
        { key: 'Australia/Sydney', value:'Australia/Sydney', text:'Australia/Sydney (+10:00hr)'},
        { key: 'Asia/Tokyo', value:'Asia/Tokyo', text:'Asia/Tokyo (+09:00hr)'},
        { key: 'Asia/Singapore', value:'Asia/Singapore', text:'Asia/Singapore (+08:00hr)'},
        { key: 'Asia/Bangkok', value:'Asia/Bangkok', text:'Asia/Bangkok (+07:00hr)'},
        { key: 'Asia/Dhaka', value:'Asia/Dhaka', text:'Asia/Dhaka (+06:00hr)'},
        { key: 'Asia/Ashkhabad', value:'Asia/Ashkhabad', text:'Asia/Ashkhabad (+05:00hr)'},
        { key: 'Asia/Dubai', value:'Asia/Dubai', text:'Asia/Dubai (+04:00hr)'},
        { key: 'Africa/Nairobi', value:'Africa/Nairobi', text:'Africa/Nairobi (+03:00hr)'},
        { key: 'Africa/Johannesburg', value:'Africa/Johannesburg', text:'Africa/Johannesburg (+02:00hr)'},
        { key: 'Europe/Oslo', value:'Europe/Oslo', text:'Europe/Oslo (+01:00)'},
        { key: 'UTC', value:'UTC', text:'GMT (+00:00)'},
        { key: 'Atlantic/Azores', value:'Atlantic/Azores', text:'Atlantic/Azores (-01:00)'},
        { key: 'America/Noronha', value:'America/Noronha', text:'America/Noronha (-02:00)'},
        { key: 'America/Buenos_Aires', value:'America/Buenos_Aires', text:'America/Buenos_Aires (-03:00)'},
        { key: 'America/Curacao', value:'America/Curacao', text:'America/Curacao (-04:00)'},
        { key: 'America/New_York', value:'America/New_York', text:'America/New_York (-05:00)'},
        { key: 'America/Chicago', value:'America/Chicago', text:'America/Chicago (-06:00)'},
        { key: 'America/Denver', value:'America/Denver', text:'America/Denver (-07:00)'},
        { key: 'America/Los_Angeles', value:'America/Los_Angeles', text:'America/Los_Angeles (-08:00)'},
        { key: 'America/Anchorage', value:'America/Anchorage', text:'America/Anchorage (-09:00)'},
        { key: 'Pacific/Honolulu', value:'Pacific/Honolulu', text:'Pacific/Honolulu (-10:00)'},
        { key: 'Pacific/Pago_Pago', value:'Pacific/Pago_Pago', text:'Pacific/Pago_Pago (-11:00)'}
    ]
    return (
        <Dropdown style={style} selection options={options} value={value} onChange={(ev,data) => onChange(data)}/>
    );
}

export default TimezoneSelect;