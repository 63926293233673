import escapeHtml from 'escape-html'
import { Text } from 'slate'

export const serializeToHtml = nodeArray => {
    let html = '';
    for(let i=0;i<nodeArray.length;i++) {
        html = html + serialize(nodeArray[i]);
    }
    return html;
}

const serialize = node => {
    if (Text.isText(node)) {
        return escapeHtml(node.text)
    }

    const children = node.children.map(n => serialize(n)).join('')

    switch (node.type) {
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
            return `<p>${children}</p>`
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
        default:
            return children
    }
}

export function formatBook(project) {
    let buffer = '';
    project.chapters.forEach( chapter => {
        buffer = buffer + formatBookChapter(chapter);
    });
    return buffer;
}

export function formatBookChapter(chapter) {
    let buffer = '';
    let text = JSON.parse(chapter.text);
    if(text) {
        console.log(text);
        text.forEach( element => {
            switch(element.type) {
                case 'numbered-list':
                    buffer = buffer + formatNumberedList(element);
                    break;
                case 'bulleted-list':
                    buffer = buffer + formatBulletedList(element);
                    break;
                case 'heading-one':
                    buffer = buffer + formatHeading(buffer,element,1);
                    break;
                case 'heading-two':
                    buffer = buffer + formatHeading(buffer,element,2);
                    break;
                default:
                    buffer = buffer + formatText(element);
            }
        })
    }
    return buffer;
}

function formatHeading(element,level) {
    let buffer = '';
    element.children.forEach( child => {        
        let textRun = styleText(child,true);
        if(level === 1) {
            buffer = buffer + '<h1>' + textRun + '</h1>';
        }
        if(level === 2) {
            buffer = buffer + '<h2>' + textRun + '</h2>';
        }        
    });
    return buffer;
}

function formatNumberedList(element) {
    let buffer = '<ol>';
    element.children.forEach( child => {
        if(child.type === 'list-item') {
            let itemBuffer = '<li>';
            child.children.forEach( item => {
                itemBuffer = itemBuffer + styleText(item);        
            });
            itemBuffer = itemBuffer + '</li>';
        }
    });
    return buffer + '</ol>';
}

function formatBulletedList(element) {
    let buffer = '<ul>';
    element.children.forEach( child => {
        if(child.type === 'list-item') {
            let itemBuffer = '<li>';
            child.children.forEach( item => {
                itemBuffer = itemBuffer + styleText(item);        
            });
            itemBuffer = itemBuffer + '</li>';
        }
    });
    return buffer + '</ul>';
}

function formatText(element) {
    let buffer = '<p>';
    element.children.forEach( child => {
        buffer = buffer + styleText(child);
    });
    return buffer + '</p>';
}

function styleText(element,ignoreSize) {    
    let style= '';
    if(element.fontFamily) {
        style = style + 'font-family:'+element.fontFamily;
    }
    if(element.fontSize && !ignoreSize) {
        style = style.length > 0 ? style + ';' : '';
        style = style + 'font-size:'+element.fontSize;
    }
    if(element.underline) {
        style = style.length > 0 ? style + ';' : '';
        style = style + 'text-decoration: underline';
    }
    return '<span style="'+style+'">'+element.text+'</span>';
}