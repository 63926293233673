import React from 'react';
import {useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Segment,Button,Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import showdown from 'showdown';
import DOMPurify from 'dompurify';


const formatDate = (date,timezone) => {
    if(!date) {
        return '';
    }
    return format(utcToZonedTime(date, timezone), 'MM/dd/yy h:mm a', { timezone: timezone });
}


const Post = ({post}) => {
    const user = useSelector(state => state.user);
    const conveter = new showdown.Converter({
        noHeaderId: true,
        simpleLineBreaks: true,
        openLinksInNewWindow: true
    });
    let markedUp = conveter.makeHtml(post.text);
    var clean = DOMPurify.sanitize(markedUp);
    return (
        <Segment>            
            <h1 style={{ margin: 0, padding: 0}}>{post.title}</h1>
            <div style={{ fontSize:'smaller',color:'lighter',marginBottom:'1em'}}>
                {formatDate(post.createDate, user.timezone)}&nbsp;&nbsp;
                <Button as={Link} to={'/blog/'+post._id} size='tiny' compact icon><Icon name='linkify'/></Button>
            </div>
            <div style={{ fontSize: '12pt'}}>
                <div dangerouslySetInnerHTML={{ __html: clean }} />
            </div>
        </Segment>
    )
};

export default Post;