import * as ActionType from './constants';

const updateFeed = (feed,thought) => {
    let newThoughts = [];
    for(let i=0;i<feed.length;i++) {
        if(feed[i].id === thought.id) {
            newThoughts.push(thought);
        }
        else {
            newThoughts.push(feed[i]);
        }
    }
    return newThoughts;
}

const initialState = {
    userThoughts:[],
    userThoughtsUser: '',
    userThoughtsProfile: {},
    userThoughtsHasNext: true,
    publicFeed:[],
    publicFeedHasNext: true,
    publicFeedOffset: 0,
    followedFeed:[],
    followedFeedHasNext: true,
    followedFeedOffset: 0,
    isFetchingThoughts: false,
    isCreatingThought: false,
    isDeletingThought: false,
    showNewThoughtModal: false,
    showProfileModal: false,
    openProfile: {},
    openProfileUsername: '',
    openThought: {},
    following: [],
    followers: [],
    isLoadingFollows: false,
    showReblogModal: false,
    openReblogThought: {},
    showReadChapterModal: false,
    openChapter: {},
    showError: false,
    errorMessage: ''
};

export default (state = initialState, action) => {
    switch(action.type) {
        case ActionType.FETCH_USER_THOUGHTS_SEND:
            return {
                ...state,
                isFetchingThoughts: true,
                userThoughtsUser: action.username,                
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_USER_THOUGHTS_SUCCESS:
            let newuser = state.userThoughts.slice();
            if(action.payload.data.offset === 0) {
                newuser = action.payload.data.thoughts;
            }
            else {
                newuser = newuser.concat(action.payload.data.thoughts);
            }
            return {
                ...state,
                userThoughts: newuser,
                userThoughtsHasNext: action.payload.data.hasNext,
                userThoughtsOffset: action.payload.data.offset,
                userThoughtsProfile: action.payload.data.profile,
                isFetchingThoughts: false
            };
        case ActionType.FETCH_USER_THOUGHTS_ERROR:
            return {
                ...state,
                isFetchingThoughts: false,
                userThoughtsUser: '',
                userThoughtsProfile: {},
                showError: true,
                errorMessage: action.payload
            };
        case ActionType.CREATE_THOUGHT_SEND:
            return {
                ...state,
                isCreatingThought: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.CREATE_THOUGHT_SUCCESS:
            let updatedFeeds = {};
            if(action.payload.data.thought.thoughtType === 'Public') {
                let newThoughtPublicFeed = state.publicFeed.slice();
                newThoughtPublicFeed.unshift(action.payload.data.thought);
                updatedFeeds.publicFeed = newThoughtPublicFeed;
            }
            if(state.userThoughtsUser === action.payload.data.thought.owner) {
                updatedFeeds.userThoughts = state.userThoughts.slice();
                updatedFeeds.userThoughts.unshift(action.payload.data.thought);
            }
            return {
                ...state,
                ...updatedFeeds,
                isCreatingThought: false,
                showNewThoughtModal: false
            };
        case ActionType.CREATE_THOUGHT_ERROR:
            return {
                ...state,
                isCreatingThought: false,
                showNewThoughtModal: false,
                showError: true,
                errorMessage: action.payload
            };
        case ActionType.REBLOG_THOUGHT_SEND:
            return {
                ...state,
                isCreatingThought: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.REBLOG_THOUGHT_SUCCESS:
            let reupdatedFeeds = {};
            if(action.payload.data.thought.thoughtType === 'Public') {
                let newThoughtPublicFeed = state.publicFeed.slice();
                newThoughtPublicFeed.unshift(action.payload.data.thought);
                reupdatedFeeds.publicFeed = newThoughtPublicFeed;  
            }
            return {
                ...state,
                ...reupdatedFeeds,
                isCreatingThought: false,
                showReblogModal: false
            };
        case ActionType.REBLOG_THOUGHT_ERROR:
            return {
                ...state,
                isCreatingThought: false,
                showNewThoughtModal: false,
                showError: true,
                errorMessage: action.payload
            };      
    
        case ActionType.DELETE_THOUGHT_SEND:
            return {
                ...state,
                isDeletingThought: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.DELETE_THOUGHT_SUCCESS:            
            return {
                ...state,
                isDeletingThought: false,
                publicFeed: state.publicFeed.slice().filter( thought => thought.id !== action.thoughtId),
                userThoughts: state.userThoughts.slice().filter( thought => thought.id !== action.thoughtId)
            };
        case ActionType.DELETE_THOUGHT_ERROR:
            return {
                ...state,
                isDeletingThought: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.OPEN_NEW_THOUGHT_MODAL:
            return {
                ...state,
                showNewThoughtModal: true
            };      
        case ActionType.CLOSE_NEW_THOUGHT_MODAL:
            return {
                ...state,
                showNewThoughtModal: false
            };
        case ActionType.UNLIKE_THOUGHT_SEND:
        case ActionType.LIKE_THOUGHT_SEND:
            return {
                ...state,
                isLikingThought: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.UNLIKE_THOUGHT_SUCCESS:
        case ActionType.LIKE_THOUGHT_SUCCESS:
            let newUserThoughts = [];
            for(let i=0;i<state.userThoughts.length;i++) {
                if(state.userThoughts[i].id === action.payload.data.thought.id) {
                    newUserThoughts.push(action.payload.data.thought);
                }
                else {
                    newUserThoughts.push(state.userThoughts[i]);
                }
            }
            let newPublicThoughts = [];
            for(let i=0;i<state.publicFeed.length;i++) {
                if(state.publicFeed[i].id === action.payload.data.thought.id) {
                    newPublicThoughts.push(action.payload.data.thought);
                }
                else {
                    newPublicThoughts.push(state.publicFeed[i]);
                }
            }

            let newFollowedThoughts = [];
            for(let i=0;i<state.followedFeed.length;i++) {
                if(state.followedFeed[i].id === action.payload.data.thought.id) {
                    newFollowedThoughts.push(action.payload.data.thought);
                }
                else {
                    newFollowedThoughts.push(state.followedFeed[i]);
                }
            }

            return {
                ...state,
                userThoughts: newUserThoughts,
                publicFeed: newPublicThoughts,
                followedFeed: newFollowedThoughts,
                isLikingThought: false
            };
        case ActionType.UNLIKE_THOUGHT_ERROR:
        case ActionType.LIKE_THOUGHT_ERROR:
            return {
                ...state,
                isLikingThought: false,
                showError: true,
                errorMessage: action.payload
            };
        case ActionType.OPEN_PROFILE_MODAL:
            return {
                ...state,
                openProfileUsername: action.username,
                showProfileModal: true,
                isFetchingProfile: true
            };      
        case ActionType.OPEN_PROFILE_MODAL_SUCCESS:
            return {
                ...state,
                openProfileUsername: action.username,
                showProfileModal: true,
                openProfile: action.payload.data.profile,
                isFetchingProfile: false
            };      
    
        case ActionType.CLOSE_PROFILE_MODAL:
            return {
                ...state,
                openProfile: {},
                openProfileUsername:'',
                showProfileModal: false
            };
        case ActionType.FETCH_USER_PROFILE_SEND:
            return {
                ...state,
                isFetchingProfile: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                openProfile: action.payload.data.profile,
                isFetchingProfile: false
            };
        case ActionType.FETCH_USER_PROFILE_ERROR:
            return {
                ...state,
                isFetchingProfile: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.FETCH_PUBLIC_FEED_SEND:
            return {
                ...state,
                isFetchingThoughts: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_PUBLIC_FEED_SUCCESS:
            let newpub = state.publicFeed.slice();
            if(action.payload.data.offset === 0) {
                newpub = action.payload.data.thoughts;
            }
            else {
                newpub = newpub.concat(action.payload.data.thoughts);
            }
            return {
                ...state,
                publicFeed: newpub,
                publicFeedHasNext: action.payload.data.hasNext,
                publicFeedOffset: action.payload.data.offset,
                isFetchingThoughts: false
            };
        case ActionType.FETCH_PUBLIC_FEED_ERROR:
            return {
                ...state,
                isFetchingThoughts: false,
                showError: true,
                errorMessage: action.payload
            };      

        case ActionType.FETCH_FOLLOWED_FEED_SEND:
            return {
                ...state,
                isFetchingThoughts: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_FOLLOWED_FEED_SUCCESS:
            let newfollow = state.followedFeed.slice();
            if(action.payload.data.offset === 0) {
                newfollow = action.payload.data.thoughts;
            }
            else {
                newfollow = newfollow.concat(action.payload.data.thoughts);
            }
            
            return {
                ...state,
                followedFeed: newfollow,
                followedFeedHasNext: action.payload.data.hasNext,
                followedFeedOffset: action.payload.data.offset,
                isFetchingThoughts: false
            };
        case ActionType.FETCH_FOLLOWED_FEED_ERROR:
            return {
                ...state,
                isFetchingThoughts: false,
                showError: true,
                errorMessage: action.payload
            };      
    
        case ActionType.FETCH_THOUGHT_SEND:
            return {
                ...state,
                isFetchingThoughts: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_THOUGHT_SUCCESS:
            return {
                ...state,
                openThought: action.payload.data.thought,
                isFetchingThoughts: false
            };
        case ActionType.FETCH_THOUGHT_ERROR:
            return {
                ...state,
                isFetchingThoughts: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.OPEN_CONVERSATION:
            return {
                ...state,
                isFetchingThought: true
            };
        case ActionType.OPEN_CONVERSATION_SUCCESS:
            return {
                ...state,
                openThought: action.payload.data.thought,
                isFetchingThought: false
            };      
        case ActionType.OPEN_CONVERSATION_ERROR:
            return {
                ...state,
                openThought: {},
                showError: true,
                errorMessage: action.payload,
                isFetchingThought: false
            };      
        
        case ActionType.CLOSE_CONVERSATION:
            return {
                ...state,
                openThought: {}
            };
  
        case ActionType.CREATE_COMMENT_SEND:
            return {
                ...state,
                isCreatingComment: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.CREATE_COMMENT_SUCCESS:
            // Update the open Thought
            let ot = {...state.openThought};
            ot.comments.push(action.payload.data.comment);
            // Update any open feeds
            

            return {
                ...state,
                openThought: ot,
                publicFeed: state.publicFeed.slice().map(thought => {
                    if(thought.id === ot.id) {
                        thought.comments.push(action.payload.data.comment);
                     }
                     return thought
                    }),
                userThoughts: state.userThoughts.slice().map(thought => {
                    if(thought.id === ot.id) {
                        thought.comments.push(action.payload.data.comment);
                        }
                        return thought
                    }),    
                isCreatingComment: false,
            };
        case ActionType.CREATE_COMMENT_ERROR:
            return {
                ...state,
                isCreatingComment: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.DISMISS_FEED_ERROR:
            return {
                ...state,
                showError: false,
                errorMessage: ''
            }
        case ActionType.UNFOLLOW_USER_SEND:
        case ActionType.FOLLOW_USER_SEND:
            return {
                ...state,
                followUserUpdating: action.username,
                showError: false,
                errorMessage: ''
            };
        case ActionType.UNFOLLOW_USER_SUCCESS:
        case ActionType.FOLLOW_USER_SUCCESS:
            let openPro = {...state.openProfile};
            if(openPro.name) {
                openPro.isFollowing = !openPro.isFollowing;
            }
            let newFollowing = state.following.map( profile => profile.name===action.payload.data.profile.name ? {...profile,isFollowing:action.payload.data.profile.isFollowing} : profile);
            return {
                ...state,
                followUserUpdating: '',
                following: newFollowing,
                openProfile: openPro
            };
        case ActionType.UNFOLLOW_USER_ERROR:
        case ActionType.FOLLOW_USER_ERROR:
            return {
                ...state,
                followUserUpdating: '',
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.FETCH_FOLLOWING_SEND:
            return {
                ...state,
                isLoadingFollows: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_FOLLOWING_SUCCESS:
            return {
                ...state,
                following: action.payload.data.following,
                isLoadingFollows: false
            };
        case ActionType.FETCH_FOLLOWING_ERROR:
            return {
                ...state,
                isLoadingFollows: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.FETCH_FOLLOWERS_SEND:
            return {
                ...state,
                isLoadingFollows: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_FOLLOWERS_SUCCESS:
            return {
                ...state,
                followers: action.payload.data.followers,
                isLoadingFollows: false
            };
        case ActionType.FETCH_FOLLOWERS_ERROR:
            return {
                ...state,
                isLoadingFollows: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.OPEN_REBLOG_MODAL:
            return {
                ...state,
                showReblogModal: true,
                openReblogThought: action.thought
            };      
        case ActionType.CLOSE_REBLOG_MODAL:
            return {
                ...state,
                showReblogModal: false
            };
        case ActionType.OPEN_READ_THOUGHT_CHAPTER_MODAL:
            return {
                ...state,
                openChapter: action.chapter,
                showReadChapterModal: true,            
            };      
        case ActionType.CLOSE_READ_THOUGHT_CHAPTER_MODAL:
            return {
                ...state,
                showReadChapterModal: false
            };
                  
        default:
            return {...state};
    }
};