import React,{useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Table,Button,Icon,Menu,Tab} from 'semantic-ui-react';
import DeleteModal from '../../components/DeleteModal';
import Toolbar from '../header/Toolbar';
import BlogPost from './BlogPost';
import {fetchPosts,deletePost} from '../../post/actions';

const formatDate = (date,timezone) => {
    if(!date) {
        return '';
    }
    return format(utcToZonedTime(date, timezone), 'MM/dd/yy h:mm:ss a', { timezone: timezone });
}

const BlogPage = (props) => {
    const dispatch = useDispatch();
    const posts = useSelector(state => state.posts.all);
    const admin = useSelector(state => state.user);
    const [showDelete,setShowDelete] = useState(false);
    const [deletePostId,setDeletePostId] = useState('');

    useEffect( () => {
        dispatch(fetchPosts());
    },[dispatch]);
    
    console.log(posts);
    const postList = posts.map( post => (
        <Table.Row key={post._id}>
            <Table.Cell>{post.title}</Table.Cell>
            <Table.Cell>{post.author}</Table.Cell>
            <Table.Cell>{post.summary}</Table.Cell>
            <Table.Cell>{formatDate(post.createDate, admin.timezone)}</Table.Cell>
            <Table.Cell>
                <Button icon size='tiny' onClick={() => {
                    setDeletePostId(post._id);
                    setShowDelete(true);
                }}><Icon name='delete'/></Button>
            </Table.Cell>
        </Table.Row>
    ));

    const panes = [
        {
            menuItem: (
                <Menu.Item key='all' style={{color:'white',marginLeft:'3em'}}>
                    All Posts
                </Menu.Item>
            ), render: () => (
                <Table inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Author</Table.HeaderCell>
                        <Table.HeaderCell>Summary</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {postList}
                </Table.Body>
            </Table>
            )            
        },
        {
            menuItem: (
                <Menu.Item key='new' style={{color:'white',marginLeft:'3em'}}>
                    New Post
                </Menu.Item>
            ), render: () => (
                <BlogPost />
            )
        }
    ];

    return (
        <div>
        <Toolbar/>
        <DeleteModal 
            show={showDelete}                     
            onCancel={() => setShowDelete(false)} 
            onConfirm={(data) => {
                dispatch(deletePost(deletePostId));
                setShowDelete(false);
            }}
        />
        <div style={{margin:'1em'}}>
            <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes} style={{paddingLeft:'0.5em'}} />            
        </div>
    </div>        
    );
};

export default BlogPage;