import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Loader,Dimmer} from 'semantic-ui-react';

//import ProjectListPage from './ProjectListPage';
import Dashboard from './Dashboard';
import LandingPage from './LandingPage';
import Showable from '../../components/Showable';
import ErrorModal from '../../components/ErrorModal';
import {dismissProjectError} from '../../project/actions';
import {dismissViewerError} from '../../viewer/actions';
import {dismissCheckpointError} from '../../checkpoint/actions';
import {dismissContactError} from '../../contact/actions';
import {dismissInboxError} from '../../inbox/actions';

const Main = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const projects = useSelector(state => state.projects);
    const checkpoints = useSelector(state => state.checkpoints);
    const contacts = useSelector(state => state.contacts);
    const inbox = useSelector(state => state.inbox);
    const viewer = useSelector(state => state.viewer);
    return (
        <div>
            <Dimmer active={user.isAuthenticating}>
                <Loader>Logging In</Loader>
            </Dimmer>

            <ErrorModal 
                show={projects.showError} 
                message={projects.errorMessage}
                onConfirm={() => dispatch(dismissProjectError())}
            />
            <ErrorModal 
                show={viewer.showError} 
                message={viewer.errorMessage}
                onConfirm={() => dispatch(dismissViewerError())}
            />
            <ErrorModal 
                show={checkpoints.showError} 
                message={checkpoints.errorMessage}
                onConfirm={() => dispatch(dismissCheckpointError())}
            />
            <ErrorModal 
                show={contacts.showError} 
                message={contacts.errorMessage}
                onConfirm={() => dispatch(dismissContactError())}
            />
            <ErrorModal
                show={inbox.showError} 
                message={inbox.errorMessage}
                onConfirm={() => dispatch(dismissInboxError())}
            />
            <Showable showIf={user.isAuthenticated}>
                <Dashboard {...props} />
            </Showable>
            <Showable showIf={!user.isAuthenticated}>
                <LandingPage {...props} />
            </Showable>
        </div>
    );
}

export default Main;