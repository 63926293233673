import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Input,Grid,Dropdown} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Showable from '../../components/Showable';
import {hideCreateProjectModal,createProject} from '../../project/actions';
import {hasPremium} from '../../services/roles';

const NewBookModal = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const showCreateProjectModal = useSelector(state => state.projects.showCreateProjectModal);
    const isCreatingProject = useSelector(state => state.projects.isCreatingProject);
    const [projectName,setProjectName] = useState('');
    const [coverUrl,setCoverUrl] = useState('');
    const [genre,setGenre] = useState('');
    const [isUrlLink,setIsUrlLink] = useState(false);
    const [linkUrl,setLinkUrl] = useState('');

    const imageUrl = coverUrl ? coverUrl : '/img/stock-cover.png';

    const uploadButton = hasPremium(user) ? <Button color='teal'
    onClick={() => {
        let win = window.cloudinary.openUploadWidget({ 
            cloud_name: 'cetacean-app', 
            upload_preset: 'up_usr_pre',
            multiple: false,
            folder: 'user/'+user.userId,
            tags: [user.userId],
            cropping: true,
            showSkipCropButton: false,
            croppingAspectRatio: 0.625,
            croppingCoordinatesMode: 'custom',
            defaultSource: "local",
            styles: {
                palette: {
                    window: "#141414",
                    windowBorder: "#90A0B3",
                    tabIcon: "#6DFFFF",
                    menuIcons: "#5A616A",
                    textDark: "#000000",
                    textLight: "#FFFFFF",
                    link: "#6DFFFF",
                    action: "#FF620C",
                    inactiveTabIcon: "#888888",
                    error: "#F44235",
                    inProgress: "#0078FF",
                    complete: "#20B832",
                    sourceBg: "#272727"
                },
                fonts: {
                    default: {
                        active: true
                    }
                }
            }            
            },
            (error, result) => {
                //console.log(result);
                if(result.event === 'success') {
                    // Uploaded successfully. Time to update.
                    setCoverUrl(result.info.secure_url);
                }
            });
    }}    
        >Upload A Cover</Button> : 
        <Button as={Link} to='/getpremium' color='orange' onClick={() => dispatch(hideCreateProjectModal())}>Upload Your Own Cover With Premium</Button>;


    return (
        <Modal open={showCreateProjectModal} className='ceta-modal'>
            <Modal.Header>Start a New Book</Modal.Header>
            <Modal.Content>
                <Grid columns={2} stackable>
                    <Grid.Row>
                        <Grid.Column textAlign='center' style={{ borderRight: '1px solid #00cccc'}}>
                            <h3>Optional Cover</h3>
                            <div style={{ paddingBottom: '1em'}}>
                                <img src={imageUrl} width='100' height='160' style={{ objectFit: 'cover',marginLeft:'auto',marginRight:'auto'}}/>
                            </div>
                            <div>                                
                                <Showable showIf={!isUrlLink}>
                                    {uploadButton} <span style={{ paddingLeft: '0.25em',paddingRight: '0.25em'}}>OR</span>
                                    <Button onClick={() => setIsUrlLink(true)} color='teal'>Use an Image Link</Button>
                                </Showable>
                                <Showable showIf={isUrlLink}>
                                    <Input fluid 
                                        action={{ color:'teal',content:'Add Cover',onClick: () => {setCoverUrl(linkUrl);}}} 
                                        placeholder='Enter full URL to image here' value={linkUrl}
                                        onChange={(ev) => setLinkUrl(ev.target.value)}
                                    />
                                </Showable>
                            </div>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>Details</h3>
                            <Input fluid label='Title' value={projectName} onChange={(ev) => setProjectName(ev.target.value)} placeholder='What is the title of your book...'/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => dispatch(hideCreateProjectModal())}>Cancel</Button>
                <Button color='teal' disabled={!projectName} loading={isCreatingProject}
                    onClick={() => dispatch(createProject({project:{name:projectName,projectType:'Book',cover: coverUrl}}))}
                    >Start Writing</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default NewBookModal;