import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Form,Input} from 'semantic-ui-react';
import {closeComposeModal, sendMessage} from '../../inbox/actions';
import {fetchContacts} from '../../contact/actions';

const ComposeMessageModal = (props) => {
    const dispatch = useDispatch();
    const showComposeModal = useSelector(state => state.inbox.showComposeModal);
    const isSendingMessage = useSelector(state => state.inbox.isSendingMessage);
    const contacts = useSelector(state => state.contacts);
    const [text,setText] = useState('');
    const [subject,setSubject] = useState('');
    const [contactId,setContactId] = useState('');

    useEffect( () => {
        dispatch(fetchContacts());
        if(props.contactId) {
            setContactId(props.contactId);
        }
    },[]);

    const options = contacts.mututal.map( contact => ({
        text: contact.name,
        value: contact._id
    }));


    return (
        <Modal open={showComposeModal} className='ceta-modal'>
            <Modal.Header>
                Compose a message
            </Modal.Header>
            <Modal.Content >
                <Form>
                    <Form.Select 
                        style={{background:'#363636',color:'white'}}
                        placeholder='Select a contact...' 
                        onChange={ (ev,data) => setContactId(data.value)}
                        options={options} />
                    <Input className='ceta-field'
                        placeholder='Subject...'
                        inverted
                        fluid
                        value={subject} onChange={(ev) => setSubject(ev.target.value)}/>
                    <p/>
                    <Form.TextArea 
                        style={{background:'#363636',color:'white'}}
                        placeholder='Compose your message here...'
                        rows='10'
                        value={text} 
                        onChange={(ev) => setText(ev.target.value)} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    setText('');
                    setSubject('');
                    dispatch(closeComposeModal());
                }}>Cancel</Button>
                <Button color='teal' loading={isSendingMessage}
                    onClick={() => {
                        dispatch(sendMessage({
                        message: {
                            contactId,
                            text,
                            subject
                        }}));
                        setText('');
                        setSubject('');
                    }}>Send</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ComposeMessageModal;