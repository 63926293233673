import React from 'react';
import {connect} from 'react-redux';
import MobileRichTextEditor from '../editor/MobileRichTextEditor';
import {updateLocalScene,updateProject} from '../../project/actions';

class MobileChapterEditPanel extends React.Component {

    onUpdate = (value) => {
        this.props.updateLocalScene(value);
    }

    onSave = () => {
        this.props.updateProject({project:this.props.projects.openProject});
    }

    render() {
        const textElement = (this.props.projects.openProjectChapter.text) 
            ? JSON.parse(this.props.projects.openProjectChapter.text) 
            : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
        return (
            <MobileRichTextEditor 
                key={this.props.projects.openProjectChapter._id}
                content={textElement}
                onUpdate={this.onUpdate}
                focus={true}
                centered={true}
            />
        );
    }
};

export default connect( state => ({projects: state.projects}),{updateLocalScene,updateProject})(MobileChapterEditPanel);