import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Responsive,Icon} from 'semantic-ui-react';
import RichTextEditor from '../editor/RichTextEditor';
import MobileRichTextEditor from '../editor/MobileRichTextEditor';
import {closeReadThoughtChapterModal} from '../../feed/actions';
import { createPortal } from 'react-dom';

const CetaModal = ({show,onClose,children}) => {
    const content = show && (
        <div className='ceta-reader-overlay'>
            <div className='ceta-reader-modal'>
                <button className='ceta-reader-close' type='button' onClick={onClose}><Icon name='close'/></button>
                <div className='ceta-reader-content'>{children}</div>
            </div>
        </div>
    )
    return createPortal(content, document.body);
}

const ReadChapterModal = (props) => {
    const dispatch = useDispatch();
    const showReadChapterModal = useSelector(state => state.feed.showReadChapterModal);
    const openChapter = useSelector(state => state.feed.openChapter);
    const element = (openChapter.text) 
    ? JSON.parse(openChapter.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    return (
        <CetaModal show={showReadChapterModal} onClose={() => dispatch(closeReadThoughtChapterModal())}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <RichTextEditor 
                    key={openChapter._id}
                    content={element}
                    footer={false}
                    centered={true}
                    readOnly={true}
                />
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <MobileRichTextEditor 
                        key={openChapter._id}
                        content={element}
                        footer={false}
                        centered={true}
                        readOnly={true}
                    />
            </Responsive>

        </CetaModal>
    );
};

export default ReadChapterModal;