import React,{createRef,useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Dimmer,Loader,Button,Responsive,Icon, Modal,Input} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toolbar from '../header/Toolbar';
import NewThoughtModal from './NewThoughtModal';
import ReblogModal from './ReblogModal';
import Thought from './Thought';
import ProfileModal from './ProfileModal';
import ReadChapterModal from './ReadChapterModal';
import ErrorModal from '../../components/ErrorModal';
import {fetchPublicFeed,openNewThoughtModal,dismissFeedError} from '../../feed/actions';
import Showable from '../../components/Showable';

const CurrentUserFeed = (props) => {
    const stickyRef = createRef();
    const dispatch = useDispatch();
    const feed = useSelector(state => state.feed);
    const publicFeed = useSelector(state => state.feed.publicFeed);
    const publicFeedOffset = useSelector(state => state.feed.publicFeedOffset);
    const publicFeedHasNext = useSelector(state => state.feed.publicFeedHasNext);
    const isFetchingThoughts = useSelector(state => state.feed.isFetchingThoughts);
    const openThought = useSelector(state => state.feed.openThought);
    const [tag,setTag] = useState('');
    const [search,setSearch] = useState('');
    const [showSearch,setShowSearch] = useState(false);

    useEffect( () => {
        dispatch(fetchPublicFeed(0,tag));
    },[dispatch,tag]);

    const thoughtList = publicFeed.map( thought => <Thought 
        thought={thought} key={thought.id}
        isOpen={openThought.id === thought.id}
    />);
    return (
        <Toolbar>
            <NewThoughtModal />
            <ReblogModal />
            <ProfileModal />
            <ReadChapterModal />
            <ErrorModal 
                show={feed.showError} 
                message={feed.errorMessage}
                onConfirm={() => dispatch(dismissFeedError())}
            />
            <Modal open={showSearch} className='ceta-modal'>
                <Modal.Content>
                    <Input label={{ color:'teal',content:'Tag'}} fluid placeholder='Enter a tag to search on...' value={search} onChange={(ev) => setSearch(ev.target.value)} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowSearch(false)}>Cancel</Button>
                    <Button onClick={() => {
                        setSearch('');
                        setTag('');
                        setShowSearch(false);
                        }}>Clear</Button>
                    <Button color='teal' onClick={() => {
                        setTag(search);
                        setShowSearch(false);
                    }}>Search</Button>
                </Modal.Actions>
            </Modal>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div>
                    <div style={{ width:'200px',overflowX:'hidden',position:'fixed',zIndex:1,paddingTop:'1em' }}>
                            <Button.Group vertical={true}>
                            <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/>Compose A Thought</Button>
                            <Button icon color='teal' onClick={() => setShowSearch(true)}><Icon name='search'/>Search Tags</Button>
                            </Button.Group>
                    </div>
                    <div style={{ marginLeft:'200px'}}>
                        <div>
                            <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                                <InfiniteScroll
                                    dataLength={publicFeed.length}
                                    next={() => dispatch(fetchPublicFeed(publicFeed.length))}
                                    hasMore={publicFeedHasNext}
                                    loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                                {thoughtList}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <div>
                    <div>
                        <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                            <InfiniteScroll
                                dataLength={publicFeed.length}
                                next={() => dispatch(fetchPublicFeed(publicFeed.length))}
                                hasMore={publicFeedHasNext}
                                loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                            {thoughtList}
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div style={{ position:'fixed',left:0,bottom:0}}>
                       <Button.Group vertical={true}>
                            <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/></Button>
                            <Button icon color='teal' onClick={() => setShowSearch(true)}><Icon name='search'/></Button>
                        </Button.Group>
                    </div>
                </div>
            </Responsive>
        </Toolbar>
    );
};

export default CurrentUserFeed;