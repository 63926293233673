import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Form} from 'semantic-ui-react';
import {updateLocalRelationship} from '../../project/actions';

const RelationshipEditPanel = (props) => {
    const dispatch = useDispatch();
    const openProjectRelationship = useSelector(state => state.projects.openProjectRelationship);
    return (
        <div style={{margin: '2em'}}>
            <Form>
                <Form.Field>
                    <label>Person</label>
                    <input placeholder='The person the character has a relationship with' 
                        value={openProjectRelationship.target}
                        onChange={(ev) => dispatch(updateLocalRelationship('target',ev.target.value))}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Link</label>
                    <input 
                        placeholder='What kind of relationship does the character have with them...' 
                        value={openProjectRelationship.link}
                        onChange={(ev) => dispatch(updateLocalRelationship('link',ev.target.value))}
                    />
                </Form.Field>

            </Form>
        </div>
    );
}

export default RelationshipEditPanel;