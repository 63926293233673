import React,{useState} from 'react';
import {useDispatch} from 'react-redux';
import {Dropdown,Label,Icon} from 'semantic-ui-react';
import {updateLocalPlotPoint} from '../../../project/actions';

const EditPanel = ({id,field,options,value}) => {
    const dispatch = useDispatch();

    const selectOptions = options ? options.map( item => ({
        key: item._id,
        text: item.name,
        value: item._id
    })) : [];

    return (
        <div>
            <Dropdown options={selectOptions} fluid selection multiple 
                value={value?value:[]}
                onChange={ (ev,dat) => dispatch(updateLocalPlotPoint(id,field,dat.value))}
                />
        </div>
    );
}

const DisplayPanel = ({id,field,options,value}) => {
    if(!value || value.length === 0) {
        return (
            <div style={{ color: '#ccc'}}>Add {field}</div>
        );
    }

    const labels = options.filter( opt => value.includes(opt._id))
        .map(opt => <Label color='teal' key={opt._id}>{opt.name}</Label>);
    return (
        <div>
            {labels}
        </div>
    );
}

const SelectorPanel = (props) => {
    
    const [editing,setEditing] = useState(false);

    return (
        <div style={{ display:'flex',flexDirection:'row',marginTop:'0.5em',alignItems:'center'}}>
            <div>
                <Icon color='teal' name={editing ? 'minus circle' : 'plus circle'} onClick={() => setEditing(!editing)} style={{cursor:'pointer'}}/>
            </div>
            <div style={{ width:'100%'}}>
                {editing ? <EditPanel {...props} /> : <DisplayPanel {...props} />}
            </div>
        </div>
    );
}

export default SelectorPanel;