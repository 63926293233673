import {spawn} from 'redux-saga/effects';
import userSagas from './user/sagas';
import projectSagas from './project/sagas';
import adminSagas from './admin/sagas';
import checkpointSagas from './checkpoint/sagas';
import viewerSagas from './viewer/sagas';
import contactSagas from './contact/sagas';
import inboxSagas from './inbox/sagas';
import paymentSagas from './payment/sagas';
import dictionarySagas from './dictionary/sagas';
import postSagas from './post/sagas';
import feedSagas from './feed/sagas';

export default function* sagas() {
  yield spawn(userSagas);
  yield spawn(projectSagas);
  yield spawn(adminSagas);
  yield spawn(checkpointSagas);
  yield spawn(viewerSagas);
  yield spawn(contactSagas);
  yield spawn(inboxSagas);
  yield spawn(paymentSagas);
  yield spawn(dictionarySagas);
  yield spawn(postSagas);
  yield spawn(feedSagas);
}
