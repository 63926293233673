import {Document,Packer,Paragraph,TextRun,AlignmentType,HeadingLevel, Media} from 'docx';
import superagent from 'superagent';
import { saveAs } from 'file-saver';

export async function exportToDocx(project) {
    try {
        const doc = new Document({
            numbering: {
                config: [
                    {
                        reference: "ceta-numbering",
                        levels: [
                            {
                                level: 0,
                                format: "decimall",
                                text: "%1",
                                alignment: AlignmentType.START,
                                style: {
                                    paragraph: {
                                        indent: { left: 720, hanging: 260 },
                                    },
                                },
                            },
                            {
                                level: 1,
                                format: "owerLetter",
                                text: "%2.",
                                alignment: AlignmentType.START,
                                style: {
                                    paragraph: {
                                        indent: { left: 1440, hanging: 980 },
                                    },
                                },
                            },
                            {
                                level: 2,
                                format: "upperLetter",
                                text: "%3)",
                                alignment: AlignmentType.START,
                                style: {
                                    paragraph: {
                                        indent: { left: 2160, hanging: 1700 },
                                    },
                                },
                            },
                            {
                                level: 3,
                                format: "upperRoman",
                                text: "%4)",
                                alignment: AlignmentType.START,
                                style: {
                                    paragraph: {
                                        indent: { left: 2880, hanging: 2420 },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        });
        

        let docContent = [];
        switch(project.projectType) {
            case 'Book':
            case 'Basic':
                formatBook(doc,docContent,project);
                break;
            default:
                formatBookByScene(docContent,project);
        }

        doc.addSection({
            properties: {},
            children: docContent
        });

        let buffer = await Packer.toBuffer(doc);
        let blob = new Blob([buffer], 
            {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        //var blobUrl = URL.createObjectURL(blob);
        //if(window && window.location) {
        //    window.location.replace(blobUrl);
        //}
        let s = project.name;
        let filename = s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        saveAs(blob,filename+'.docx');
    }
    catch(err) {
        console.log(err);
    }
}

function formatBook(doc,docContent,project) {
    project.chapters.forEach( chapter => {
            let text = JSON.parse(chapter.text);
            if(text) {
                text.forEach( element => {
                    switch(element.type) {
                        case 'numbered-list':
                            formatNumberedList(docContent,element);
                            break;
                        case 'bulleted-list':
                            formatBulletedList(docContent,element);
                            break;
                        case 'heading-one':
                            formatHeading(docContent,element,HeadingLevel.HEADING_1);
                            break;
                        case 'heading-two':
                            formatHeading(docContent,element,HeadingLevel.HEADING_2);
                            break;
                        case 'image':
                            formatImage(doc,docContent,element);
                            break;    
                        default:
                            docContent.push(formatText(element));
                    }

                })
            }
    });

}


function formatBookByScene(doc,docContent,project) {
    project.chapters.forEach( chapter => {
        chapter.scenes.forEach( scene => {
            let text = JSON.parse(scene.text);
            if(text) {
                text.forEach( element => {
                    switch(element.type) {
                        case 'numbered-list':
                            formatNumberedList(docContent,element);
                            break;
                        case 'bulleted-list':
                            formatBulletedList(docContent,element);
                            break;
                        case 'heading-one':
                            formatHeading(docContent,element,HeadingLevel.HEADING_1);
                            break;
                        case 'heading-two':
                            formatHeading(docContent,element,HeadingLevel.HEADING_2);
                            break;
                        case 'image':
                            formatImage(doc,docContent,element);
                            break;
                        default:
                            docContent.push(formatText(element));
                    }

                })
            }
        })
    });

}


function readBlobAsAsync(blob) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    })
}

async function formatImage(doc,docContent,element) {
    let buffer = require('buffer');
    let response = await superagent.get(element.url).responseType('blob');
    let dataUrl = await readBlobAsAsync(response.body);
    let data = dataUrl.split(',')[1];
    const image = Media.addImage(doc, buffer.Buffer.from(data,"base64"));
    docContent.push(new Paragraph({
        children: [image]
    }));
}

function formatHeading(docContent,element,level) {
    element.children.forEach( child => {
        let paraChildren = [];
        let textRun = formatTextRun(child,true);
        paraChildren.push(textRun);
        let p = new Paragraph({
            children: paraChildren,
            heading: level
        })
        docContent.push(p);    
    });
}

function formatNumberedList(docContent,element) {    
    element.children.forEach( child => {
        if(child.type === 'list-item') {
            let paraChildren = [];
            child.children.forEach( item => {
                let textRun = formatTextRun(item);
                paraChildren.push(textRun);
                let p = new Paragraph({
                    children: paraChildren,
                    numbering: {
                        reference: "ceta-numbering",
                        level: 0,
                    }
                })
                docContent.push(p);
        
            });
        }
    });
}

function formatBulletedList(docContent,element) {    
    element.children.forEach( child => {
        if(child.type === 'list-item') {
            let paraChildren = [];
            child.children.forEach( item => {
                let textRun = formatTextRun(item);
                paraChildren.push(textRun);
                let p = new Paragraph({
                    children: paraChildren,
                    bullet: {
                        level: 0
                    }
                })
                docContent.push(p);
        
            });
        }
    });
}

function formatText(element) {
    let paraChildren = [];
    element.children.forEach( child => {
        let textRun = formatTextRun(child);
        paraChildren.push(textRun);
    });
    let p = new Paragraph({
        children: paraChildren
    })
    return p;
}

function formatTextRun(element,ignoreSize) {
    let textProps = {text: element.text}
    textProps.bold = element.bold;
    textProps.itallics = element.itallic;
    if(element.fontFamily) {
        textProps.font = {name: element.fontFamily};
    }
    if(element.fontSize && !ignoreSize) {
        textProps.size = (element.fontSize.substring(0,element.fontSize.length-2) * 2);
    }
    if(element.underline) {
        textProps.underline = {style: 'single'};
    }
    let textRun = new TextRun(textProps);

    return textRun;
}