import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Image,Card,Breadcrumb,Responsive,Segment,Popup, Transition} from 'semantic-ui-react';
import Showable from '../../components/Showable';
import {
    showDeleteLocationModal,openLocation,addLocation,openLocationBreakout
} from '../../project/actions';


const LocationsPanel = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const charList = openProject.locations.map( location => {
        const editButton = (
            <Button icon onClick={() => dispatch(openLocation(location._id))}>
                <Icon name='edit'/>
            </Button>            
        );
        const breakoutButton = (<Button icon onClick={() => dispatch(openLocationBreakout(location))}>
            <Icon name='external alternate'/></Button>);
        const deleteButton = (
            <Button icon onClick={() => dispatch(showDeleteLocationModal(openProject._id,location._id))}>
                <Icon name='delete'/>
            </Button>
        );
        const avatar = location.image ? location.image : '/img/ceta-location-default.png';
        return (
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={location._id}>
                <Card key={location._id} style={{backgroundColor:'#363636'}}>
                    <Image src={avatar} wrapped ui={false} onClick={() => dispatch(openLocation(location._id))} style={{cursor: 'pointer'}}/>
                    <Card.Content style={{color:'white'}}>
                        <Card.Header style={{color:'white',paddingTop:'1em'}}><h3>{location.name}</h3></Card.Header>
                        <Card.Meta style={{color: '#cdcdcd'}}>{location.role}</Card.Meta>
                        <Card.Description style={{color: '#cdcdcd'}}>
                            {location.overview ? location.overview : 'No overview'}
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <Showable showIf={!props.readOnly}>
                            <Button.Group>
                                <Popup trigger={editButton}>Edit</Popup>
                                <Popup trigger={breakoutButton}>Breakout Location</Popup>
                                <Popup trigger={deleteButton}>Delete</Popup>
                            </Button.Group>
                        </Showable>
                    </Card.Content>
                </Card>
            </Transition>
    )});

    const noItemsMessage = (openProject.locations.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', margin: '2em'}}>
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Locations</h2>
            </Transition>
        </div>
    ) : null;

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div style={{flexShrink: 0}}>
                    <div style={{color:'white'}}>
                        <Segment inverted>
                            <Breadcrumb style={{color:'white'}} size='large'>
                                <Breadcrumb.Section>Project</Breadcrumb.Section>
                                <Breadcrumb.Divider style={{ color:'white'}}/>
                                <Breadcrumb.Section active>Locations</Breadcrumb.Section>
                            </Breadcrumb>
                        </Segment>
                    </div>
                </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{margin:'1em'}}>
                    <Showable showIf={!props.readOnly}>
                    <Button color='teal' onClick={() => {
                        dispatch(addLocation(openProject._id));
                        //setSession(new Date());
                    }}>
                        <Icon name='globe'/>Create Location
                    </Button>
                    </Showable>
                    <p/>
                    {noItemsMessage}
                    <Card.Group>
                        {charList}
                    </Card.Group>
                </div>
            </div>
        </div>
    );
};

export default LocationsPanel;