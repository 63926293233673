import React, { useState } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Segment,Button,Icon,Input,List,Menu,Header} from 'semantic-ui-react';
import {searchThesaurus,searchDictionary} from '../../../dictionary/actions';
import {
    addChapter,moveChapter,moveChapterUp,moveChapterDown,showChapterRenameModal,showDeleteChapterModal,openChapter,
    removeChapter,hideDeleteChapterModal,hideContextSideBar
} from '../../../project/actions';

const DictionaryContext = (props) => {
    const dispatch = useDispatch();
    const isSearchingDictionary = useSelector(state => state.dictionary.isSearchingDictionary);
    const isSearchingThesaurus = useSelector(state => state.dictionary.isSearchingThesaurus);
    const words = useSelector(state => state.dictionary.words);
    const synonyms = useSelector(state => state.dictionary.synonyms);
    const [word,setWord] = useState('');
    const [tab,setTab] = useState('dictionary');

    const wordList = tab==='dictionary' ? words.map( (word,index) => (
        <List.Item key={index}>
            {index+1}. {word.partOfSpeech}: {word.definition}
        </List.Item>
    )) : 
    synonyms.map( (synonym) => (
        <List.Item key={synonym}>
            {synonym}
        </List.Item>
    ));

    return (
        <div>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item onClick={() => {
                                setTab('dictionary');
                            }}>
                            <Header color={tab==='dictionary'?'teal':'grey'} >Dictionary</Header>
                        </Menu.Item>
                        <Menu.Item onClick={() => {
                                setTab('thesaurus');
                            }}>
                            <Header color={tab!=='dictionary'?'teal':'grey'} >Thesaurus</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                    <div>
                        <Input 
                            placeholder='Look up word' 
                            value={word}
                            fluid
                            onChange={(ev,dat) => setWord(dat.value)}
                            onKeyPress={ (ev) => {
                                if(ev.key === 'Enter' && word !== '') {
                                    if(tab==='dictionary') {
                                        dispatch(searchDictionary(word));
                                    }
                                    else {
                                        dispatch(searchThesaurus(word));
                                    }
                            }}}
                            action={{
                                color: 'teal',
                                loading: isSearchingDictionary || isSearchingThesaurus,
                                icon: 'search',
                                onClick: () => {
                                    if(word !== '') {
                                        if(tab==='dictionary') {
                                            dispatch(searchDictionary(word));
                                        }
                                        else {
                                            dispatch(searchThesaurus(word));
                                        }
                            }}}}                              
                        />
                    </div>                    
                </div>
            </div>            
            <div style={{ height:'calc(100vh - 120px)', overflowY: 'auto',overflowX: 'hidden'}}>
                <List ordered>
                    {wordList}
                </List>                
            </div>
        </div>
    );
}

export default DictionaryContext;