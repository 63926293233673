import React from 'react';
import {Modal,Header,Button,Icon} from 'semantic-ui-react';

const DeleteModal = (props) => {
    return (
        <Modal open={props.show} size='small'>
            <Header icon='warning circle' content='Delete' style={{backgroundColor:'#363636', color:'white'}}/>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
            <p>
                Are you sure you want to delete this?
            </p>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
            <Button onClick={props.onCancel}>
                <Icon name='remove' /> No
            </Button>
            <Button color='teal' onClick={() => props.onConfirm(props)}>
                <Icon name='checkmark' /> Yes
            </Button>
            </Modal.Actions>
        </Modal>        
    );
};

export default DeleteModal;