export function parseDate(dateString) {
    let date = {};
    if(dateString) {
        const parts = dateString.split(',');
        date.century = parseInt(parts[0],10);
        date.year = parseInt(parts[1],10);
        date.month = parseInt(parts[2],10);
        date.day = parseInt(parts[3],10);
        date.hour = parseInt(parts[4],10);
        date.minute = parseInt(parts[5],10);
    }
    return date;
}

export function epochToDate(s,cal) {
    let date = parseDate(s);
    return cal.getMonthName(date.month)?.name+' '+date.day+','+date.century+pad(date.year,2)+'  '+date.hour+':'+pad(date.minute,2);
}


export function getCalendar(name) {
    return getDefaultCalendar();

}

function getDefaultCalendar() {
    const months = [
        { name: 'January', days: 31},
        { name: 'February', days: 29},
        { name: 'March', days: 31},
        { name: 'April', days: 30},
        { name: 'May', days: 31},
        { name: 'June', days: 30},
        { name: 'July', days: 31},
        { name: 'August', days: 31},
        { name: 'September', days: 30},
        { name: 'October', days: 31},
        { name: 'November', days: 30},
        { name: 'December', days: 31},
    ];
    let cal = new Calendar(months,24,60,'bce','ce');
    return cal;
    
}

function pad(num, size) {
    var s = num+'';
    while (s.length < size) s = '0' + s;
    return s;
}    

class Calendar {
    constructor(months,hours,minutes,preEra,postEra) {
        this.state = {
            months,hours,minutes,preEra,postEra
        }
    }

    getMonthName = (num) => {
        return this.state.months[(num===0)?0:num-1];
    }

    getMonthOptions = () => {
        return this.state.months.map( (m,i) => ({
            key: i+1,
            text: m.name,
            value: i+1
        }));
    }

    getDayOptions = (month) => {
        if(month !== undefined) {
            return Array.from(Array(this.state.months[month].days).keys()).map( d =>({
                key: d+1, text: d+1, value: d+1
            }));
        }
        return [];
    }

    getEraOptions = () => {
        return [
            { key:this.state.preEra, text: this.state.preEra, value: this.state.preEra},
            { key:this.state.postEra, text: this.state.postEra, value: this.state.postEra}
        ];
    }

    getCenturyOptions = () => Array.from(Array(50).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt
    }));

    getYearOptions = () => Array.from(Array(99).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt
    }));

    getHourOptions = () => Array.from(Array(this.state.hours).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt
    }));

    getMinuteOptions = () => Array.from(Array(this.state.minutes).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt
    }));

}
