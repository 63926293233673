import React from 'react';
import {connect} from 'react-redux';
import {Message,Dimmer,Loader,Button,Modal,Form} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {fetchMotds,createMotd,deleteMotd} from '../../admin/actions';

class MotdsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newMotdTitle:'',
            newMotdMessage:'',
            newMotdEnabled: false,
            showNewMotdModal: false
        };
    }

    openModal = () => {
        this.setState({
            ...this.state,
            newMotdRecipient:'',
            showNewMotdModal: true
        })
    }
    submitCreateMotd = () => {
        this.props.createMotd({motd: {
            title: this.state.newMotdTitle,
            message: this.state.newMotdMessage,
            enabled: this.state.newMotdEnabled
        }});
        this.setState({...this.state,showNewMotdModal:false})
    }

    submitDelete = (motdId) => {
        this.props.deleteMotd(motdId);
        this.setState({...this.state,showNewMotdModal:false})
    }

    componentDidMount() {
        this.props.fetchMotds();
    }
    render() {

        const motdList = this.props.admin.motds.map( motd => (
            <div key={motd._id}>
                <div style={{ width: '70%',display: 'inline-block'}}>
                    <Message info={motd.enabled}>
                        <Message.Header>{motd.title}</Message.Header>
                        <p>
                            {motd.message}
                        </p>
                    </Message>
                    </div>
                <div style={{width:'30%',display: 'inline-block'}}>
                    <Button.Group>
                        <Button icon='edit' />
                        <Button icon='delete' onClick={() => this.submitDelete(motd._id)}/>
                    </Button.Group>
                    </div>
            </div>
        ));
        return (
            <div>
                <Toolbar/>
                <div style={{margin:'1em'}}>
                    <Dimmer active={this.props.admin.isFetchingMotds}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <Modal open={this.state.showNewMotdModal}>
                        <Modal.Header style={{background:'#363636',color:'white'}}>
                            New Motd
                        </Modal.Header>
                        <Modal.Content style={{background:'#363636',color:'white'}}>
                            <Form>
                                <Form.Field>
                                    <Form.Input 
                                        label='Title'
                                        placeholder='Enter Title...' 
                                        value={this.state.newMotdTitle}
                                        onChange={(ev) => this.setState({...this.state,newMotdTitle:ev.target.value})}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.TextArea
                                        label='Message'
                                        placeholder='Enter Message...' 
                                        value={this.state.newMotdMessage}
                                        onChange={(ev) => this.setState({...this.state,newMotdMessage:ev.target.value})}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Checkbox
                                    label={<label>Enabled</label>} 
                                    onClick={() => this.setState({...this.state,newMotdEnabled:!this.state.newMotdEnabled})}
                                    />
                                </Form.Field>

                                <Button color='teal' onClick={this.submitCreateMotd}>Create</Button>
                                <Button onClick={() => {
                                    this.setState({...this.state,showNewMotdModal:false})
                                }}>Close</Button>
                            </Form>
                        </Modal.Content>
                    </Modal>
                    <Button color='teal' onClick={this.openModal}>New Motd</Button>
                    <div>{motdList}</div>
                </div>
            </div>        
        );
    }
};

export default connect(state=>({
    admin: state.admin
}),{fetchMotds,createMotd,deleteMotd})(MotdsPage);