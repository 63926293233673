import React from 'react';
import {connect} from 'react-redux';
import {Table,Dimmer,Loader,Button,Modal,Form,Input,Icon} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {fetchAdReferrals,createAdReferral,deleteAdReferral} from '../../admin/actions';

class AdReferralsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newCampaign:'',
            newSourceId:'',
            newSourceName:'',
            showModal: false
        };
    }

    openModal = () => {
        this.setState({
            ...this.state,
            newCampaign:'',
            newSourceId:'',
            newSourceName:'',
            showModal: true
        })
    }
    submitCreateReferral = () => {
        this.props.createAdReferral({
            campaign:this.state.newCampaign,
            sourceId:this.state.newSourceId,
            sourceName:this.state.newSourceName,
        });
        this.setState({...this.state,showModal:false})
    }

    submitDelete = (id) => {
        this.props.deleteAdReferral(id);
        this.setState({...this.state,showModal:false})
    }

    componentDidMount() {
        this.props.fetchAdReferrals();
    }
    render() {

        const referralList = this.props.admin.adReferrals.map( referral => (
            <Table.Row key={referral._id}>
                <Table.Cell>
                    {referral.campaign}
                </Table.Cell>
                <Table.Cell>
                    {referral.sourceId}
                </Table.Cell>
                <Table.Cell>
                    {referral.sourceName}
                </Table.Cell>
                <Table.Cell>
                    {referral.hits}
                </Table.Cell>
                <Table.Cell>
                    {referral.converts}
                </Table.Cell>
                <Table.Cell>
                    {referral.subscribers}
                </Table.Cell>
                <Table.Cell>
                    <Button icon onClick={() => this.submitDelete(referral._id)}>
                        <Icon name='delete'/>
                    </Button>
                </Table.Cell>

            </Table.Row>
        ));
        return (
            <div>
                <Toolbar/>
                <div style={{margin:'1em'}}>
                    <Dimmer active={this.props.admin.isFetchingReferrals}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <Modal open={this.state.showModal}>
                        <Modal.Header style={{background:'#363636',color:'white'}}>
                            New Ad Referral
                        </Modal.Header>
                        <Modal.Content style={{background:'#363636',color:'white'}}>
                            <Form>
                                <Form.Field>
                                    <Input 
                                        label='Campaign'
                                        placeholder='Campaign Id String' 
                                        value={this.state.newCampaign}
                                        onChange={(ev) => this.setState({...this.state,newCampaign:ev.target.value})}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input 
                                        label='Source ID'
                                        placeholder='Source Id String' 
                                        value={this.state.newSourceId}
                                        onChange={(ev) => this.setState({...this.state,newSourceId:ev.target.value})}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Input 
                                        label='Source Name'
                                        placeholder='Source Name' 
                                        value={this.state.newSourceName}
                                        onChange={(ev) => this.setState({...this.state,newSourceName:ev.target.value})}
                                    />
                                </Form.Field>

                            </Form>
                        </Modal.Content>
                        <Modal.Actions style={{background:'#363636',color:'white'}}>
                            <Button onClick={() => {
                                this.setState({...this.state,showModal:false})
                            }}>Close</Button>
                            <Button color='teal' onClick={this.submitCreateReferral}>Create</Button>
                        </Modal.Actions>
                    </Modal>
                    <Button color='teal' onClick={this.openModal}>New Ad Referral</Button>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Campaign</Table.HeaderCell>
                                <Table.HeaderCell>Source ID</Table.HeaderCell>
                                <Table.HeaderCell>Source Name</Table.HeaderCell>
                                <Table.HeaderCell>Hits</Table.HeaderCell>
                                <Table.HeaderCell>Converts</Table.HeaderCell>
                                <Table.HeaderCell>Subscribers</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {referralList}
                        </Table.Body>
                    </Table>
                </div>
            </div>        
        );
    }
};

export default connect(state=>({
    admin: state.admin
}),{fetchAdReferrals,createAdReferral,deleteAdReferral})(AdReferralsPage);