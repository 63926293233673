import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Label,Header,Loader,Modal,Menu} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import PinterestBoardCard from '../brainstorm/PinterestBoardCard';
import {getUrlPreview} from '../../../services/api';
import {hideContextSideBar} from '../../../project/actions';

const getImageStyle = (url) => {
    return {
        backgroundImage: 'url('+url+')',
        backgroundSize: 'cover',
        height: '10em'
    };
}

const WebsiteCard = ({research}) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = projects.openProject;
    const [loading,setLoading] = useState(false); 

    const tagList = research.tags ? research.tags.map( tag => <Label key={tag} content={tag} tag color='teal' />) : [];
    
    useEffect( () => {
        if(!research.previewChecked && research.url) {
            setLoading(true);
            getUrlPreview(research.url).then( response => {
                research.preview = response.data.preview;
                setLoading(false);
            });
            research.previewChecked = true;
        }
    },[research.preview]);

    const cardMarquis = research.preview ? (
        
        <div style={getImageStyle(research.preview.images[0])}>
        </div>
    ) : (
        <div style={{textAlign:'center'}}>
            <Showable showIf={!loading}>
                <Icon name='linkify'color='teal' size='huge' style={{marginTop:'0.5em'}}/>
            </Showable>
            <Showable showIf={loading}>
                <Loader active inline />
            </Showable>
        </div>
    );    
    return (
        <div>
            <a href={research.url} target='_blank'>
                {tagList}
            <div>
                <strong>{research.preview ? research.preview.title:''}</strong>
            </div>
            {cardMarquis}
            {research.url}
            </a>
        </div>)
}

const ImageCard = ({research}) => {
    const [show,setShow] = useState(false);
    const trigger = (<img src={research.url} style={{ objectFit: 'contain',width:'100%',height:'100%',cursor:'pointer'}} onClick={() => setShow(true)}/>);
    return (
            <div>
                <Modal trigger={trigger}>
                    <Modal.Content>
                        <img src={research.url} style={{ objectFit: 'contain',width:'100%',height:'100%'}} />
                    </Modal.Content>
                </Modal>
            </div>
    );
}

const IdeaCard = ({research}) => {
    const researchBackgroundColor = useSelector(state => state.projects.openProject.researchBackgroundColor);
    const researchTextColor = useSelector(state => state.projects.openProject.researchTextColor);

    const displayName = research.name.split('\n').map( (s,i) => <span key={i}>{s}<br/></span>);
    const tagList = research.tags ? research.tags.map( tag => <Label key={tag} content={tag} tag color='teal' />) : [];

    return (
        <div className='ceta-idea-shell regular' style={{ 
            backgroundColor: (researchBackgroundColor ? researchBackgroundColor :'#ffff88'),
            color: (researchTextColor ? researchTextColor :'black'),marginTop:'0.5em'
        }}>
            <Showable showIf={research.researchType === 'Image'}>
                <ImageCard research={research} />                
            </Showable>
            <Showable showIf={research.researchType === 'Website'}>
                <WebsiteCard research={research} />                
            </Showable>
            <Showable showIf={research.researchType === 'Pinterest Board'}>
                <PinterestBoardCard research={research} />                
            </Showable>

            <div style={{ width:'100%',height:'100%'}}>
                {displayName}
            </div>
            {tagList}
        </div>
    );
}

const ResearchContext = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const projects = useSelector(state => state.projects);

    const loadPinterestWidgetScript = () => {
        const script = document.createElement('script');
        script.async = true;
        script.dataset.pinBuild = 'doBuild';
        script.src = '//assets.pinterest.com/js/pinit.js';
        document.body.appendChild(script);
    };

    useEffect(() => {
        if(!window.doBuild) {
            loadPinterestWidgetScript();
        }
        else {
            window.doBuild();
        }
    },[]);

    return (
        <div>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item>
                            <Header color='teal'>Ideas</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div style={{ height:'calc(100vh - 70px)', overflowY: 'auto',overflowX: 'hidden'}}>
                {openProject.research.map( (research,index) => <IdeaCard key={research._id} research={research} index={index}/>)}
            </div>
        </div>
    );
}

export default ResearchContext;