import React from 'react';
import {Statistic,Progress} from 'semantic-ui-react';

const WordCountThought = ({name,wordCount,wordGoal}) => {
    const progressPercent = Math.ceil((wordCount / wordGoal) * 100);
    return (
        <div>
            <div style={{ marginBottom: '0.5em'}}><h3>{name}</h3></div>
            <div style={{color:'white',backgroundColor:'#383838'}}>
                <Progress progress color='teal' indicating percent={progressPercent} />
            </div>
            <div style={{ marginBottom: '0.5em'}}>
                <Statistic.Group size='small'>
                    <Statistic color='teal'>
                        <Statistic.Value>{wordCount}</Statistic.Value>
                        <Statistic.Label style={{color:'white'}}>Words</Statistic.Label>
                    </Statistic>
                    <Statistic color='teal'>
                        <Statistic.Value>{wordGoal}</Statistic.Value>
                        <Statistic.Label style={{color:'white'}}>Goal</Statistic.Label>
                    </Statistic>
                </Statistic.Group>
            </div>

        </div>
    );
};

export default WordCountThought;