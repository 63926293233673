import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Form,Button,Modal} from 'semantic-ui-react';
import { sendFeedback,closeFeedbackModal } from '../../user/actions';

const FeedbackModal = (props) => {
    const dispatch = useDispatch();
    const showFeedbackModal = useSelector(state => state.user.showFeedbackModal);
    const [text,setText] = useState('');
    const [problem,setProblem] = useState();
    const options = [
        { text:'My Account', value:'Account'},
        { text:'My Checkpoints', value:'Checkpoints'},
        { text:'My Projects', value:'Projects'},
        { text:'My Subscription', value:'Subscription'},
        { text:'The Text Editor', value:'Text Editor'},
        { text:'Other Issues', value:'Other'},

    ];
    return (
        <Modal open={showFeedbackModal}>
            <Modal.Header style={{backgroundColor:'#272727',color:'white'}}>Feedback and Support</Modal.Header>
            <Modal.Content style={{backgroundColor:'#272727',color:'white'}}>
                <Form>
                    <Form.Select 
                        style={{background:'#363636',color:'white'}}
                        placeholder='What kind of problem...' 
                        onChange={ (ev,data) => setProblem(data.value)}
                        options={options} />

                    <Form.TextArea 
                        style={{background:'#363636',color:'white'}}
                        placeholder='Tell us about your problem...'
                        rows='10'
                        value={text} 
                        onChange={(ev) => setText(ev.target.value)} />
                </Form>                
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#272727',color:'white'}}>
                <Button  onClick={() => dispatch(closeFeedbackModal())}>Cancel</Button>
                <Button  color='teal' onClick={()=> dispatch(sendFeedback(problem,text))}>Send</Button>
            </Modal.Actions>
        </Modal>
    )
};

export default FeedbackModal;