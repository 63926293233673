import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import RichTextEditor from '../editor/RichTextEditor';
import {updateLocalChapter,updateProject} from '../../project/actions';

const ChapterEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);

    const textElement = (projects.openProjectChapter.text) 
    ? JSON.parse(projects.openProjectChapter.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
            <RichTextEditor 
                key={projects.openProjectChapter._id}
                content={textElement}
                onUpdate={ (value) => dispatch(updateLocalChapter(value))}
                focus={true}
                footer={true}
                centered={true}
                ignoreSelectionUpdate={true}
                lastSaveDate={projects.lastSaveDate}
                onSave={() => dispatch(updateProject({project: projects.openProject}))}
            />
    );
}

export default ChapterEditPanel;