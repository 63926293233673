import React from 'react';
import {useSelector} from 'react-redux';
import {Responsive} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import ProjectViewSidebar from './ProjectViewSidebar';
import ManuscriptPanel from './ManuscriptPanel';
import DiffView from './DiffView';
import TimelinePanel from '../project/EventTimelinePanel';
import LocationsPanel from '../project/LocationsPanel';
import CharactersPanel from '../project/CharactersPanel';
import ViewerBar from './ViewerBar';

const ProjectViewer = (props) => {
    const openProject = useSelector(state => state.viewer.openProject);
    const openProjectPanel = useSelector(state => state.viewer.openProjectPanel);
    const mainProject = useSelector(state => state.checkpoints.parentCheckpoint);
    const comp = getViewComponent(openProjectPanel,openProject._id);
    return (
        <div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Toolbar>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'stretch',marginTop:'4px'}}>
                    <div style={{overflowY:'auto',overflowX:'hidden',height:'calc( 100vh - 54px)'}}>
                        <ProjectViewSidebar checkpoint={openProject} projectId={mainProject._id}/>
                    </div>
                    <div style={{overflowY:'auto',height:'calc( 100vh - 54px)',width:'100%'}}> 
                        {comp}
                    </div>
                </div>
            </Toolbar>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <Toolbar>
                <ViewerBar checkpoint={openProject} projectId={mainProject._id}>
                    {comp}
                </ViewerBar>
                </Toolbar>
            </Responsive>
        </div>
    );
}

export default ProjectViewer;

function getViewComponent(view,key) {
    switch(view) {
        case 'Manuscript':
            return (<ManuscriptPanel key={key}/>);
        case 'Diff':
                return (<DiffView key={key}/>);
        case 'Timeline':
                return (<TimelinePanel key={key} readOnly={true}/>);
        case 'LocationsPanel':
                return (<LocationsPanel key={key} readOnly={true}/>);
        case 'CharactersPanel':
                return (<CharactersPanel key={key} readOnly={true}/>);
                            
        default:
            return (<div>View Panel Here</div>);            
    }
}