import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Icon,Modal, Segment, Input, Dropdown} from 'semantic-ui-react';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Link} from 'react-router-dom';
import {readBook,removeResearch,hideDeleteResearchModal,addResearch,showNewReasearchLinkModal} from '../../../project/actions';
import { fetchProjectCheckpoints } from '../../../checkpoint/actions';
import ShareWithUserModal from './ShareWithUserModal';
import DeleteModal from '../../../components/DeleteModal';
import ConfirmModal from '../../../components/ConfirmModal';
import AlertModal from '../../../components/AlertModal';
import {openShareCheckpointModal,unshareCheckpoint,fetchAllShares,
    forkProject,deleteProjectCheckpoint,mergeCheckpoint} from '../../../checkpoint/actions';
import {hasPremium} from '../../../services/roles';


const formatTime = (date,user) => {
    const timeZone = user.timezone;
    const zonedDate = utcToZonedTime(date, timeZone);
    const pattern = 'MMM dd, yyyy h:mm:ss a';
    const output = format(zonedDate, pattern, { timeZone })        
    return output;
}
const RevisionPanel = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isPremium = hasPremium(user);
    const openProject = useSelector(state => state.projects.openProject);
    const checkpoints = useSelector(state => state.checkpoints.checkpoints);
    const isForkingProject = useSelector(state => state.checkpoints.isForkingProject);
    const isProjectDirty = useSelector(state => state.projects.isProjectDirty);
    const readers = useSelector(state => state.checkpoints.shares)
        .filter( share => (openProject.checkpoints.indexOf(share.project._id) > -1) && !share.editor);
    const editors = useSelector(state => state.checkpoints.shares)
        .filter( share => (openProject.checkpoints.indexOf(share.project._id) > -1) && share.editor);
    const [showForkModal,setShowForkModal] = useState(false);
    const [newCheckpointName,setNewCheckpointName] = useState('');
    const [showDelete,setShowDelete] = useState(false);
    const [checkpointToDelete,setCheckpointToDelete] = useState('');
    const [showMergeConfirm,setShowMergeConfirm] = useState(false);
    const [checkpointToMerge,setCheckpointToMerge] = useState('');
    const [showAlert,setShowAlert] = useState(false);

    useEffect( () => {
        dispatch(fetchProjectCheckpoints(openProject._id));
        dispatch(fetchAllShares());
    },[dispatch]);

    
    const checkpointList = checkpoints.map( checkpoint => {
        
        return (
            <div key={checkpoint._id} className='saved-item'>
                <div className='saved-cell'>v{checkpoint.version}</div>
                <div className='saved-cell'>{checkpoint.versionName}</div>
                <div className='saved-cell'>Created {formatTime(checkpoint.updateDate,user)}</div>
                <div className='saved-cell'>
                    <Dropdown text='Action' icon='wrench' labeled button className='icon teal'>
                        <Dropdown.Menu>
                            <Dropdown.Item icon='book' text='Read' onClick={() => {
                                dispatch(readBook(checkpoint._id,openProject._id));
                            }}/>
                            <Dropdown.Item icon='delete' text='Delete' onClick={() => {
                                setCheckpointToDelete(checkpoint._id);
                                setShowDelete(true);
                            }}/>
                            <Dropdown.Item icon='code branch' text='Replace working copy' onClick={() => {
                                if(isProjectDirty) {
                                    setShowAlert(true);
                                    return;
                                }
                                setCheckpointToMerge(checkpoint._id);
                                setShowMergeConfirm(true);
                            }}/>
                            <Dropdown.Item icon='users' text='Send to Readers' onClick={() => dispatch(openShareCheckpointModal(checkpoint._id))}/>
                            <Dropdown.Item icon='user md' text='Send to Editors' onClick={() => dispatch(openShareCheckpointModal(checkpoint._id,true))}/>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        );
    });

    const readerList = readers.map( share => (
        <div key={share._id} className='saved-item'>
            <div className='saved-cell'>{share.lendeeName}</div>
            <div className='saved-cell'>version {share.project.version}</div>
            <div className='saved-cell'>{share.project.versionName}</div>
            <div className='saved-cell'><Button color='teal' onClick={() => dispatch(unshareCheckpoint(share._id))}>Unshare</Button></div>
        </div>
    ));

    const editorList = editors.map( share => (
        <div key={share._id} className='saved-item'>
            <div className='saved-cell'>{share.lendeeName}</div>
            <div className='saved-cell'>version {share.project.version}</div>
            <div className='saved-cell'>{share.project.versionName}</div>            
            <div className='saved-cell'><Button color='teal' onClick={() => dispatch(unshareCheckpoint(share._id))}>Unshare</Button></div>
        </div>
    ));

    const noReaders = readers.length === 0 ? (
        <div className='ceta-revision-empty'>No versions shared with beta readers</div>
    ) : null;

    const noEditors = editors.length === 0 ? (
        <div className='ceta-revision-empty'>
            No versions shared with editors
        </div>
    ) : null;
    
    const newSavePointButton = isPremium ? (<Button color='teal' onClick={() => {
        if(isProjectDirty) {
            setShowAlert(true);
            return;
        }
        setShowForkModal(true);
    }}>Make a new save point</Button>) : (
        <Button color='orange' as={Link} to='/getpremium'>Get Premium to create save points</Button>
    );
    return (
        <div >
            <Modal open={showForkModal} className='ceta-modal'>
                <Modal.Header>
                    New Project Checkpoint
                </Modal.Header>
                <Modal.Content>
                            <Input 
                                fluid
                                label='Name'
                                placeholder='Checkpoint Name' 
                                value={newCheckpointName}
                                onChange={(ev) => setNewCheckpointName(ev.target.value)}
                            />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowForkModal(false)}>Close</Button>
                    <Button color='teal' 
                        onClick={() => {
                            dispatch(forkProject(
                                openProject._id,
                                { 
                                    fork: {
                                        id:openProject._id,
                                        name: newCheckpointName
                                    }
                                }));
                            setShowForkModal(false);
                            setNewCheckpointName('');
                        }}
                        loading={isForkingProject}
                        disabled={newCheckpointName === ''}
                        >Create</Button>                        
                </Modal.Actions>
            </Modal>
            <DeleteModal 
                    show={showDelete} 
                    onCancel={() => setShowDelete(false)} 
                    onConfirm={ () => {
                        dispatch(deleteProjectCheckpoint(openProject._id,checkpointToDelete));
                        setShowDelete(false);
                    }}/>
            <ConfirmModal 
                show={showMergeConfirm} 
                title='Merge Checkpoint'
                message='Are you sure you want to merge this checkpoint into the main project?'
                onCancel={() => setShowMergeConfirm(false)} 
                onConfirm={ () => {
                    dispatch(mergeCheckpoint(openProject._id,checkpointToMerge));
                    setShowMergeConfirm(false);
                }}/>
            <AlertModal 
                show={showAlert} 
                title='Unsaved Project'
                message='Your current project is unsaved. Save it first before proceeding.'
                onCancel={() => setShowAlert(false)} 
                />
            <ShareWithUserModal />

            <div className='ceta-revision-section'>
                <div className='ceta-revision-header'>Current Working Version</div>
                <div className='ceta-revision-main'>
                    <div className='heading'>{openProject.name}</div>
                    <div>Version {openProject.version}</div>
                    <div>Last Updated {formatTime(openProject.updateDate,user)}</div>
                    <div>
                        <Button.Group>
                            {newSavePointButton}
                        </Button.Group>
                    </div>
                </div>
            </div>
            <div className='ceta-revision-section'>
                <div className='ceta-revision-header'>Saved Versions</div>
                <div className='ceta-revision-saved'>
                {checkpointList}
                </div>
            </div>

            <div className='ceta-revision-section'>
                <div className='ceta-revision-header'>Sent to Beta Readers</div>
                <div className='ceta-revision-saved'>
                    {readerList}
                    {noReaders}
                </div>
            </div>
            <div className='ceta-revision-section'>
                <div className='ceta-revision-header'>Sent to Editors</div>
                <div className='ceta-revision-saved'>
                    {editorList}
                    {noEditors}
                </div>
            </div>
        </div>
    );
}

export default RevisionPanel;