import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';
import * as ActionType from './constants';
import {
    fetchPosts,fetchPostsSuccess,fetchPostsError,
    createPostSuccess,createPostError,
    deletePostSuccess,deletePostError
    } from './actions';

function* getPosts(action) {
    try {
        let response = yield call(() => api.fetchPosts());
        if(response.success) {
            yield put(fetchPostsSuccess(response));
        }
        else {
            yield put(fetchPostsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchPostsError(err.message));
    }
}

function* createPost(action) {
    try {
        let response = yield call(() => api.createPost(action.payload));
        if(response.success) {
            yield put(createPostSuccess(response));
            yield put(fetchPosts());
        }
        else {
            yield put(createPostError(response.message));
        }
    }
    catch(err) {
        yield put(createPostError(err.message));
    }
}

function* deletePost(action) {
    try {
        let response = yield call(() => api.deletePost(action.postId));
        if(response.success) {
            yield put(deletePostSuccess(response));
            yield put(fetchPosts());
        }
        else {
            yield put(deletePostSuccess(response.message));
        }
    }
    catch(err) {
        yield put(deletePostSuccess(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(ActionType.FETCH_POSTS_SEND,getPosts);
    yield takeLatest(ActionType.CREATE_POST_SEND,createPost);
    yield takeLatest(ActionType.DELETE_POST_SEND,deletePost);
}