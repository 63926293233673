import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,TextArea} from 'semantic-ui-react';
import Avatar from '../../components/Avatar';
import Thought from './Thought';
import {closeReblogModal,reblogThought} from '../../feed/actions';

const ReblogModal = (props) => {
    const dispatch = useDispatch();
    const showReblogModal = useSelector(state => state.feed.showReblogModal);
    const openReblogThought = useSelector(state => state.feed.openReblogThought);
    const isCreatingThought = useSelector(state => state.feed.isCreatingThought);
    const [text,setText] = useState('');

    return (
        <Modal open={showReblogModal} className='ceta-modal'>
            <Modal.Header>
                <Avatar /> <span>Reblog A Thought</span>
            </Modal.Header>
            <Modal.Content>
                    <TextArea style={{ 
                        width: '100%',marginBottom:'1em',resize:'none',
                        border: 'none',outline: 'none',boxShadow:'none',
                        padding:'0.5em'
                        }} 
                        rows={7} value={text} autoFocus={true}
                        placeholder='Add additional thought here...'
                        onChange={ (ev) => setText(ev.target.value)}/>
                    <Thought thought={openReblogThought} readOnly={true}/>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => {
                    dispatch(closeReblogModal());
                    setText('');
                }}>Cancel</Button>
                <Button color='teal' loading={isCreatingThought} onClick={() => {
                    dispatch(reblogThought(openReblogThought.id,{
                        text
                    }));
                    setText('');
                    }}>Reblog</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ReblogModal;