export const FETCH_CODES_SEND = 'FETCH_CODES_SEND';
export const FETCH_CODES_SUCCESS = 'FETCH_CODES_SUCCESS';
export const FETCH_CODES_ERROR = 'FETCH_CODES_ERROR';

export const fetchCodes = payload => ({
    type: FETCH_CODES_SEND,
    payload
});

export const fetchCodesSuccess = payload => {
    return {
    type: FETCH_CODES_SUCCESS,
    payload
}};

export const fetchCodesError = payload => ({
    type: FETCH_CODES_ERROR,
    payload
});

export const CREATE_CODE_SEND = 'CREATE_CODE_SEND';
export const CREATE_CODE_SUCCESS = 'CREATE_CODE_SUCCESS';
export const CREATE_CODE_ERROR = 'CREATE_CODE_ERROR';

export const createCode = payload => ({
    type: CREATE_CODE_SEND,
    payload
});

export const createCodeSuccess = payload => {
    return {
    type: CREATE_CODE_SUCCESS,
    payload
}};

export const createCodeError = payload => ({
    type: CREATE_CODE_ERROR,
    payload
});

export const DELETE_CODE_SEND = 'DELETE_CODE_SEND';
export const DELETE_CODE_SUCCESS = 'DELETE_CODE_SUCCESS';
export const DELETE_CODE_ERROR = 'DELETE_CODE_ERROR';

export const deleteCode = payload => ({
    type: DELETE_CODE_SEND,
    payload
});

export const deleteCodeSuccess = payload => {
    return {
    type: DELETE_CODE_SUCCESS,
    payload
}};

export const deleteCodeError = payload => ({
    type: DELETE_CODE_ERROR,
    payload
});

export const SEND_CODE_SEND = 'SEND_CODE_SEND';
export const SEND_CODE_SUCCESS = 'SEND_CODE_SUCCESS';
export const SEND_CODE_ERROR = 'SEND_CODE_ERROR';

export const sendCode = codeId => ({
    type: SEND_CODE_SEND,
    codeId
});

export const sendCodeSuccess = payload => {
    return {
    type: SEND_CODE_SUCCESS,
    payload
}};

export const sendCodeError = payload => ({
    type: SEND_CODE_ERROR,
    payload
});


// MOTD

export const FETCH_MOTDS_SEND = 'FETCH_MOTDS_SEND';
export const FETCH_MOTDS_SUCCESS = 'FETCH_MOTDS_SUCCESS';
export const FETCH_MOTDS_ERROR = 'FETCH_MOTDS_ERROR';

export const fetchMotds = payload => ({
    type: FETCH_MOTDS_SEND,
    payload
});

export const fetchMotdsSuccess = payload => {
    return {
    type: FETCH_MOTDS_SUCCESS,
    payload
}};

export const fetchMotdsError = payload => ({
    type: FETCH_MOTDS_ERROR,
    payload
});

export const FETCH_MOTD_SEND = 'FETCH_MOTD_SEND';
export const FETCH_MOTD_SUCCESS = 'FETCH_MOTD_SUCCESS';
export const FETCH_MOTD_ERROR = 'FETCH_MOTD_ERROR';

export const fetchMotd = payload => ({
    type: FETCH_MOTD_SEND,
    payload
});

export const fetchMotdSuccess = payload => {
    return {
    type: FETCH_MOTD_SUCCESS,
    payload
}};

export const fetchMotdError = payload => ({
    type: FETCH_MOTD_ERROR,
    payload
});

export const CREATE_MOTD_SEND = 'CREATE_MOTD_SEND';
export const CREATE_MOTD_SUCCESS = 'CREATE_MOTD_SUCCESS';
export const CREATE_MOTD_ERROR = 'CREATE_MOTD_ERROR';

export const createMotd = payload => ({
    type: CREATE_MOTD_SEND,
    payload
});

export const createMotdSuccess = payload => {
    return {
    type: CREATE_MOTD_SUCCESS,
    payload
}};

export const createMotdError = payload => ({
    type: CREATE_MOTD_ERROR,
    payload
});

export const UPDATE_MOTD_SEND = 'UPDATE_MOTD_SEND';
export const UPDATE_MOTD_SUCCESS = 'UPDATE_MOTD_SUCCESS';
export const UPDATE_MOTD_ERROR = 'UPDATE_MOTD_ERROR';

export const updateMotd = payload => ({
    type: UPDATE_MOTD_SEND,
    payload
});

export const updateMotdSuccess = payload => {
    return {
    type: UPDATE_MOTD_SUCCESS,
    payload
}};

export const updateMotdError = payload => ({
    type: UPDATE_MOTD_ERROR,
    payload
});

export const DELETE_MOTD_SEND = 'DELETE_MOTD_SEND';
export const DELETE_MOTD_SUCCESS = 'DELETE_MOTD_SUCCESS';
export const DELETE_MOTD_ERROR = 'DELETE_MOTD_ERROR';

export const deleteMotd = payload => ({
    type: DELETE_MOTD_SEND,
    payload
});

export const deleteMotdSuccess = payload => {
    return {
    type: DELETE_MOTD_SUCCESS,
    payload
}};

export const deleteMotdError = payload => ({
    type: DELETE_MOTD_ERROR,
    payload
});

export const DISMISS_MOTD = 'DISMISS_MOTD'
export const dismissMotd = () => ({
    type: DISMISS_MOTD
});


export const SEARCH_USERS_SEND = 'SEARCH_USERS_SEND';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_ERROR = 'FETCH_MOTD_ERROR';

export const searchUsers = (searchEmail,searchRole,searchLastLogin) => ({
    type: SEARCH_USERS_SEND,
    searchEmail,
    searchRole,
    searchLastLogin
});

export const searchUsersSuccess = payload => {
    return {
    type: SEARCH_USERS_SUCCESS,
    payload
}};

export const searchUsersError = payload => ({
    type: SEARCH_USERS_ERROR,
    payload
});

export const ADMIN_UPDATE_USER_SEND = 'ADMIN_UPDATE_USER_SEND';
export const ADMIN_UPDATE_USER_SUCCESS = 'ADMIN_UPDATE_USER_SUCCESS';
export const ADMIN_UPDATE_USER_ERROR = 'ADMIN_UPDATE_USER_ERROR';

export const adminUpdateUser = payload => ({
    type: ADMIN_UPDATE_USER_SEND,
    payload
});

export const adminUpdateUserSuccess = payload => {
    return {
    type: ADMIN_UPDATE_USER_SUCCESS,
    payload
}};

export const adminUpdateUserError = payload => ({
    type: ADMIN_UPDATE_USER_ERROR,
    payload
});


export const FETCH_ACTIVE_USERS_SEND = 'FETCH_ACTIVE_USERS_SEND';
export const FETCH_ACTIVE_USERS_SUCCESS = 'FETCH_ACTIVE_USERS_SUCCESS';
export const FETCH_ACTIVE_USERS_ERROR = 'FETCH_ACTIVE_USERS_ERROR';

export const fetchActiveUsers = payload => ({
    type: FETCH_ACTIVE_USERS_SEND,
    payload
});

export const fetchActiveUsersSuccess = payload => {
    return {
    type: FETCH_ACTIVE_USERS_SUCCESS,
    payload
}};

export const fetchActiveUsersError = payload => ({
    type: FETCH_ACTIVE_USERS_ERROR,
    payload
});


export const FETCH_AD_REFERRALS_SEND = 'FETCH_AD_REFERRALS_SEND';
export const FETCH_AD_REFERRALS_SUCCESS = 'FETCH_AD_REFERRALS_SUCCESS';
export const FETCH_AD_REFERRALS_ERROR = 'FETCH_AD_REFERRALS_ERROR';

export const fetchAdReferrals = payload => ({
    type: FETCH_AD_REFERRALS_SEND,
    payload
});

export const fetchAdReferralsSuccess = payload => {
    return {
    type: FETCH_AD_REFERRALS_SUCCESS,
    payload
}};

export const fetchAdReferralsError = payload => ({
    type: FETCH_AD_REFERRALS_ERROR,
    payload
});


export const CREATE_AD_REFERRAL_SEND = 'CREATE_AD_REFERRAL_SEND';
export const CREATE_AD_REFERRAL_SUCCESS = 'CREATE_AD_REFERRAL_SUCCESS';
export const CREATE_AD_REFERRAL_ERROR = 'CREATE_AD_REFERRAL_ERROR';

export const createAdReferral = payload => ({
    type: CREATE_AD_REFERRAL_SEND,
    payload
});

export const createAdReferralSuccess = payload => {
    return {
    type: CREATE_AD_REFERRAL_SUCCESS,
    payload
}};

export const createAdReferralError = payload => ({
    type: CREATE_AD_REFERRAL_ERROR,
    payload
});

export const DELETE_AD_REFERRAL_SEND = 'DELETE_AD_REFERRAL_SEND';
export const DELETE_AD_REFERRAL_SUCCESS = 'DELETE_AD_REFERRAL_SUCCESS';
export const DELETE_AD_REFERRAL_ERROR = 'DELETE_AD_REFERRAL_ERROR';

export const deleteAdReferral = payload => ({
    type: DELETE_AD_REFERRAL_SEND,
    payload
});

export const deleteAdReferralSuccess = payload => {
    return {
    type: DELETE_AD_REFERRAL_SUCCESS,
    payload
}};

export const deleteAdReferralError = payload => ({
    type: DELETE_AD_REFERRAL_ERROR,
    payload
});

export const FETCH_ANALYTICS_SEND = 'FETCH_ANALYTICS_SEND';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';

export const fetchAnalytics = () => ({
    type: FETCH_ANALYTICS_SEND
});

export const fetchAnalyticsSuccess = payload => {
    return {
    type: FETCH_ANALYTICS_SUCCESS,
    payload
}};

export const fetchAnalyticsError = payload => ({
    type: FETCH_ANALYTICS_ERROR,
    payload
});

export const FETCH_PROJECTS_REPORT_SEND = 'FETCH_PROJECTS_REPORT_SEND';
export const FETCH_PROJECTS_REPORT_SUCCESS = 'FETCH_PROJECTS_REPORT_SUCCESS';
export const FETCH_PROJECTS_REPORT_ERROR = 'FETCH_PROJECTS_REPORT_ERROR';

export const fetchProjectsReport = () => ({
    type: FETCH_PROJECTS_REPORT_SEND
});

export const fetchProjectsReportSuccess = payload => {
    return {
    type: FETCH_PROJECTS_REPORT_SUCCESS,
    payload
}};

export const fetchProjectsReportError = payload => ({
    type: FETCH_PROJECTS_REPORT_ERROR,
    payload
});
