import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Dropdown, Input,Form, Icon,TextArea} from 'semantic-ui-react';
import DateModal from './DateModal';
import {getCalendar,epochToDate} from '../../../util/calendar';
import {
    addEvent,hideCreateEventModal
} from '../../../project/actions';


const NewEventModal = (props) => {
    const dispatch = useDispatch();
    const showCreateEventModal = useSelector(state => state.projects.showCreateEventModal);
    const openProject = useSelector(state => state.projects.openProject);
    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [epoch,setEpoch] = useState('0,0,0,0,0,0');
    const [chapter,setChapter] = useState(1);
    const [scene,setScene] = useState(1);
    const [act,setAct] = useState(1);
    const [characters,setCharacters] = useState([]);
    const [plotpoint,setPlotpoint] = useState('');
    const [showDateModal,setShowDateModal] = useState(false);

    const actOptions = Array.from(Array(7).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const chapterOptions = Array.from(Array(50).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const sceneOptions = Array.from(Array(30).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const characterOptions = openProject.characters.map( character => ({
        key: character._id,
        text: character.name,
        value: character._id
    }));

    const plotpointOptions = openProject.plotpoints.map( plotpoint => ({
        key: plotpoint._id,
        text: plotpoint.synopsis,
        value: plotpoint._id
    }));

    const formattedDate = epochToDate(epoch,getCalendar());

    return (
        <Modal open={showCreateEventModal} onMount={() => {
            setName('');
            setDescription('');
            setEpoch('0,0,0,0,0,0');
            setAct(1);
            setChapter(1);
            setScene(1);
        }}>
            <Modal.Header style={{ backgroundColor:'#272727',color:'white'}}>
                New Event
            </Modal.Header>
            <Modal.Content style={{ backgroundColor:'#272727',color:'white'}}>
                <Form>
                    <Form.Field>
                    <Input 
                            label='Name'
                            placeholder='Name' 
                            value={name}
                            onChange={(ev) => setName(ev.target.value)}
                        />
                    </Form.Field>
                    <p/>
                    <TextArea
                        value={description}
                        onChange={(ev) => setDescription(ev.target.value)}
                        placeholder='Describe what happened on the event...'
                    />
                    <p/>
                    <DateModal 
                        open={showDateModal}
                        onClose={() => setShowDateModal(false)}
                        onSave={(val) => {
                            setEpoch(val);
                            setShowDateModal(false);
                        }}
                        dateString={epoch}
                        calendar={getCalendar()}
                    />

                    <Button icon onClick={() => setShowDateModal(true)}>Date<Icon name='calendar'/></Button>
                    &nbsp;&nbsp;<strong>{formattedDate}</strong><p/>

                    <div style={{ display: 'table',width:'100%'}}>
                        <div style={{ display: 'table-row'}}>
                            <div style={{ display: 'table-cell', width: '80px',verticalAlign:'middle'}}>
                                <strong>Plotpoint</strong>
                            </div>
                            <div style={{ display: 'table-cell'}}>
                                <Dropdown options={plotpointOptions} fluid selection 
                                    value={plotpoint}
                                    onChange={ (ev,dat) => setPlotpoint(dat.value)}
                                    />
                            </div>
                        </div>
                    </div>

                    <p/>
                    <Form.Field>
                        <Dropdown options={characterOptions} fluid selection multiple 
                        value={characters}
                        onChange={ (ev,dat) => setCharacters(dat.value)}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#272727',color:'white'}}>
                <Button onClick={() => dispatch(hideCreateEventModal())}>Cancel</Button>
                <Button color='teal' disabled={(name==='')} onClick={ 
                    () => {
                        dispatch(addEvent(openProject._id,{
                            event: {
                                name,
                                description,
                                epoch,
                                act,
                                chapter,
                                scene,
                                characters,
                                plotpoint
                            }}));
                        dispatch(hideCreateEventModal());
                }}>Create</Button>

            </Modal.Actions>
        </Modal>
    );
}

export default NewEventModal;