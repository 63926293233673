import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Popup,Card,Breadcrumb,Responsive,Segment,Image,Transition} from 'semantic-ui-react';
import Showable from '../../components/Showable';
import {
    showDeleteCharacterModal,openCharacter,addCharacter,openCharacterBreakout
} from '../../project/actions';

const CharactersPanel = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;

    const charList = openProject.characters.map( character => {
        const editButton = (<Button icon onClick={() => dispatch(openCharacter(character._id))}>
            <Icon name='edit'/></Button>);
        const breakoutButton = (<Button icon onClick={() => dispatch(openCharacterBreakout(character))}>
            <Icon name='external alternate'/></Button>);
        const deleteButton = (<Button icon onClick={() => dispatch(showDeleteCharacterModal(openProject._id,character._id))}>
            <Icon name='delete'/></Button>);
        const avatar = character.image ? character.image : '/img/ceta-persona-default.png';

        return (
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={character._id}>
                <Card  style={{backgroundColor:'#363636'}}>                    
                    <Image src={avatar} wrapped ui={false} onClick={() => dispatch(openCharacter(character._id))} style={{cursor: 'pointer'}}/>
                    <Card.Content style={{color:'white'}}>
                        <Card.Header style={{color:'white',paddingTop:'1em'}}><h3>{character.name}</h3></Card.Header>
                        <Card.Meta style={{color: '#cdcdcd'}}>{character.role}</Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                        <Showable showIf={!props.readOnly}>
                            <Button.Group>
                                <Popup trigger={editButton}>Edit</Popup>
                                <Popup trigger={breakoutButton}>Breakout Window</Popup>
                                <Popup trigger={deleteButton}>Delete</Popup>
                            </Button.Group>
                        </Showable>
                    </Card.Content>
                </Card>
            </Transition>
    )});

    const noItemsMessage = (openProject.characters.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center',margin: '2em'}}>
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Characters</h2>
            </Transition>
        </div>
    ) : null;

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{flexShrink: 0}}>
                <div style={{color:'white'}}>
                <Segment inverted>
                    <Breadcrumb style={{color:'white'}} size='large'>
                        <Breadcrumb.Section>Project</Breadcrumb.Section>
                        <Breadcrumb.Divider style={{ color:'white'}}/>
                        <Breadcrumb.Section active>Characters</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>
                </div>
            </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{margin:'1em'}}>
                    <Showable showIf={!props.readOnly}>
                    <Button color='teal' onClick={() => {
                        dispatch(addCharacter(openProject._id));
                        //setSession(new Date());
                    }}>
                        <Icon name='user'/> Create Character
                    </Button>
                    </Showable>
                    <p/>
                    {noItemsMessage}
                    <Card.Group>
                        {charList}
                    </Card.Group>
                </div>
            </div>
        </div>
    );
};

export default CharactersPanel;