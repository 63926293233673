import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Form,Menu,Tab,Segment,Breadcrumb,Responsive,Grid,Dropdown} from 'semantic-ui-react';
import EmbeddedRichTextEditor from '../editor/EmbeddedRichTextEditor';
import RelationshipsPanel from './RelationshipsPanel';
import {updateLocalCharacter} from '../../project/actions';
import UploadedImage from '../../components/UploadedImage';
import MotivePanel from './MotivePanel';

function unNull(val) {
    return val ? val : undefined;
}

const CharacterEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const openProjectCharacter = useSelector(state => state.projects.openProjectCharacter);

    const descElement = (openProjectCharacter.description) 
        ? JSON.parse(openProjectCharacter.description) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const valuesElement = (openProjectCharacter.values) 
        ? JSON.parse(openProjectCharacter.values) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const demeanorElement = (openProjectCharacter.demeanor) 
        ? JSON.parse(openProjectCharacter.demeanor) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const needsElement = (openProjectCharacter.needs) 
        ? JSON.parse(openProjectCharacter.needs) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const fearsElement = (openProjectCharacter.fears) 
        ? JSON.parse(openProjectCharacter.fears) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    const roleOptions = [
        { key: 'None', text:'None', value:'None'},
        { key: 'Protagonist', text:'Protagonist', value:'Protagonist'},
        { key: 'Antagonist', text:'Antagonist', value:'Antagonist'},
        { key: 'Deuteragonist', text:'Deuteragonist', value:'Deuteragonist'},
        { key: 'Love Interest', text:'Love Interest', value:'Love Interest'},
        { key: 'Mentor', text:'Mentor', value:'Mentor'},
        { key: 'Confidant', text:'Confidant', value:'Confidant'},
        { key: 'Foil', text:'Foil', value:'Foil'},
        { key: 'Trickster', text:'Trickster', value:'Trickster'},
        { key: 'Tertiary', text:'Tertiary', value:'Tertiary'}
    ];
    const archetypeOptions = [
        { key: 'None', text:'None', value:'None'},
        { key: 'The Hero', text:'The Hero', value:'The Hero'},
        { key: 'The Lover', text:'The Lover', value:'The Lover'},
        { key: 'The Jester', text:'The Jester', value:'The Jester'},
        { key: 'The Everyman', text:'The Everyman', value:'The Everyman'},
        { key: 'The Caregiver', text:'The Caregiver', value:'The Caregiver'},
        { key: 'The Ruler', text:'The Ruler', value:'The Ruler'},
        { key: 'The Artist', text:'The Artist', value:'The Artist'},
        { key: 'The Innocent', text:'The Innocent', value:'The Innocent'},
        { key: 'The Sage', text:'The Sage', value:'The Sage'},
        { key: 'The Explorer', text:'The Explorer', value:'The Explorer'},
        { key: 'The Outlaw', text:'The Outlaw', value:'The Outlaw'},
        { key: 'The Magician', text:'The Magician', value:'The Magician'}
    ];
    
    const panes = [
        { menuItem: (
            <Menu.Item key='basic' style={{color:'white'}}>
                Basic
            </Menu.Item>
        ), render: () => (
            <div style={{ marginBottom:'3em'}}>
                <Grid columns={2} stackable={true} style={{marginBottom:'1em'}}>
                    <Grid.Row>
                        <Grid.Column>
                            <UploadedImage 
                                src={openProjectCharacter.image}
                                dim='300px'
                                stock='/img/ceta-persona-default.png'
                                onDelete={ () => dispatch(updateLocalCharacter('image',''))}
                                onSuccess={ (result) => dispatch(updateLocalCharacter('image',result))}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label style={{color:'white'}}>Name</label>
                                <input 
                                    placeholder='Name' 
                                    value={unNull(openProjectCharacter.name)}
                                    onChange={(ev) => dispatch(updateLocalCharacter('name',ev.target.value))}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{color:'white'}}>Role</label>
                                <Dropdown selection fluid
                                    value={unNull(openProjectCharacter.role)}
                                    options={roleOptions} 
                                    onChange={(ev,dat) => dispatch(updateLocalCharacter('role',dat.value))}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{color:'white'}}>Archetype</label>
                                <Dropdown selection fluid
                                    value={unNull(openProjectCharacter.archetype)}
                                    options={archetypeOptions} 
                                    onChange={(ev,dat) => dispatch(updateLocalCharacter('archetype',dat.value))}
                                />

                            </Form.Field>

                        </Grid.Column>

                    </Grid.Row>
                </Grid>
                <Form.Field>
                    <label style={{color:'white'}}>Physical Description</label>
                    <EmbeddedRichTextEditor 
                        key='descriptionField'
                        content={descElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('description',JSON.stringify(value)))}
                        placeholder='Describe what your character looks like...'
                    />
                </Form.Field>
            </div>
        )},
        { menuItem: (
            <Menu.Item key='personality' style={{color:'white'}}>
                Personality
            </Menu.Item>
        ), render: () => (
            <div>
                <Form.Field>
                    <label style={{color:'white'}}>Traits</label>
                <Segment inverted>
                <Grid style={{marginBottom:'1em'}}>
                    <Grid.Row>
                        <Grid.Column style={{color:'white'}} width={2} verticalAlign='middle'>
                            <strong>Likable</strong>
                        </Grid.Column>
                        <Grid.Column style={{color:'white'}}width={12} verticalAlign='middle'>
                            <strong>0</strong>&nbsp;&nbsp;
                            <input type='range' 
                                value={openProjectCharacter.likable} 
                                onChange={(ev) => dispatch(updateLocalCharacter('likable',ev.target.value))}
                                step='1' min={0} max={10} style={{ width: '50%'}}/>&nbsp;
                            <strong>10</strong>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{color:'white'}} width={2} verticalAlign='middle'>
                            <strong>Proactive</strong>
                        </Grid.Column>
                        <Grid.Column style={{color:'white'}}width={12} verticalAlign='middle'>
                            <strong>0</strong>&nbsp;&nbsp;
                                <input type='range' 
                                    value={openProjectCharacter.proactive} 
                                    onChange={(ev) => dispatch(updateLocalCharacter('proactive',ev.target.value))}
                                    step='1' min={0} max={10} style={{ width: '50%'}}/>&nbsp;
                            <strong>10</strong>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column style={{color:'white'}} width={2} verticalAlign='middle'>
                            <strong>Competent</strong>
                        </Grid.Column>
                        <Grid.Column style={{color:'white'}}width={12} verticalAlign='middle'>
                            <strong>0</strong>&nbsp;&nbsp;
                                <input type='range' 
                                    value={openProjectCharacter.competent} 
                                    onChange={(ev) => dispatch(updateLocalCharacter('competent',ev.target.value))}
                                    step='1' min={0} max={10} style={{ width: '50%'}}/>&nbsp;
                            <strong>10</strong>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                </Segment>
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Motives</label>
                    <MotivePanel value={openProjectCharacter.motives} onChange={(val) => dispatch(updateLocalCharacter('motives',val))}/>
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Demeanor</label>
                    <EmbeddedRichTextEditor 
                        key='demeanorField'
                        content={demeanorElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('demeanor',JSON.stringify(value)))}
                        placeholder='Describe the personality and persona the character presents to others...'
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Values</label>
                    <EmbeddedRichTextEditor 
                        key='valuesField'
                        content={valuesElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('values',JSON.stringify(value)))}
                        placeholder='What does the character value as important in others or themself...'
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Fears</label>
                    <EmbeddedRichTextEditor 
                        key='fearsField'
                        content={fearsElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('fears',JSON.stringify(value)))}
                        placeholder='What does the character fear...'
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Needs</label>
                    <EmbeddedRichTextEditor 
                        key='needsField'
                        content={needsElement}
                        onUpdate={(value) => dispatch(updateLocalCharacter('needs',JSON.stringify(value)))}
                        placeholder='What does the character feel they need and what they actually need...'
                    />
                </Form.Field>
            </div>
        )},
        { menuItem: (
            <Menu.Item key='relationships' style={{color:'white'}}>
                Relationships
            </Menu.Item>
        ), render: () => (
            <RelationshipsPanel />
        )}
    ];
    return (
        <div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div style={{
                    height:'100%',display:'flex',flexDirection:'column',            
                    }}>
                    <div style={{flexShrink: 0}}>
                        <div style={{color:'white'}}>
                            <Segment inverted>
                                <Breadcrumb style={{color:'white'}} size='large'>
                                    <Breadcrumb.Section>Project</Breadcrumb.Section>
                                    <Breadcrumb.Divider style={{ color:'white'}}/>
                                    <Breadcrumb.Section >Characters</Breadcrumb.Section>
                                    <Breadcrumb.Divider style={{ color:'white'}}/>
                                    <Breadcrumb.Section active>{openProjectCharacter.name}</Breadcrumb.Section>

                                </Breadcrumb>
                            </Segment>
                        </div>
                    </div>
                    <div style={{flexGrow:1,overflowY:'auto'}}>
                        <div style={{marginTop: '1em',marginLeft:'2em',marginRight:'2em'}}>
                            <Form>
                                <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes}/>
                            </Form>
                        </div>
                    </div>
                </div>
            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <div style={{ margin: '0.5em' }}>
                    <Form>
                        <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes}/>
                    </Form>
                </div>
            </Responsive>
        </div>
    );
};

export default CharacterEditPanel;