import {
    DISMISS_VIEWER_ERROR,
    OPEN_VIEW,OPEN_VIEW_SUCCESS,OPEN_VIEW_ERROR,
    OPEN_DIFF_VIEW,OPEN_DIFF_VIEW_SUCCESS,OPEN_DIFF_VIEW_ERROR,
    OPEN_TIMELINE, OPEN_LOCATIONS_PANEL, OPEN_CHARACTERS_PANEL
} from './actions';
import {convertToPlainText} from '../util/exportToPlainText';

const initialState = {
    isOpeningProject: false,
    showError:false,
    errorMessage: '',
    openMode: 'owner',
    openProject: {},
    openProjectChapter: {},
    openProjectScene: {},
    openProjectCharacter: {},
    openProjectRelationship: {},
    openProjectPanel: 'Manuscript',
    openProjectLocation: {},
    openProjectEvent: {},
    isOpeningDiffs: false,
    oldDiffText: '',
    newDiffText: '',
};

export default (state = initialState, action) => {
switch(action.type) {

    case DISMISS_VIEWER_ERROR:
        return {
            ...state,
            showError: false,
            errorMessage: ''
        }
    case OPEN_VIEW:
        return {
            ...state,
            isOpeningProject:true,
            openMode: action.mode
        };
    case OPEN_VIEW_SUCCESS:
        let openChapter = action.payload.data.project.chapters[0];
        let openScene = openChapter.scenes[0];
        return {
            ...state,
            isOpeningProject:false,
            openProjectPanel: 'Manuscript',
            openProject: action.payload.data.project,
            openProjectChapter: openChapter,
            openProjectScene: openScene,
            openProjectCharacter: {},
            openProjectRelationship: {}
        };
    case OPEN_VIEW_ERROR:
        return {
            ...state,
            isOpeningProject: false,
            showError: true,
            errorMessage: action.payload
        };
    case OPEN_DIFF_VIEW:
        return {
            ...state,
            openProjectPanel: 'Diff',
            isOpeningDiffs: true,
            openDiffError: false,
            openDiffErrorMessage: ''
                    
        };
    case OPEN_DIFF_VIEW_SUCCESS:
        let oldText = convertToPlainText(action.payload.data.project);
        let newText = convertToPlainText(state.openProject);
        return {
            ...state,
            oldDiffText: oldText,
            newDiffText: newText,                    
            isOpeningDiffs: false,
            openDiffError: false,
            openDiffErrorMessage: ''
        };
    case OPEN_DIFF_VIEW_ERROR:
        return {
            ...state,
            isOpeningProject: false,
            showError: true,
            errorMessage: action.payload
        };
    case OPEN_TIMELINE:
            return {
                ...state,
                openProjectPanel: 'Timeline'
            }
    case OPEN_LOCATIONS_PANEL:
            return {
                ...state,
                openProjectPanel: 'LocationsPanel'
            }        
    case OPEN_CHARACTERS_PANEL:
            return {
                ...state,
                openProjectPanel: 'CharactersPanel'
            }
                          
    default:
        return {...state};
}
};