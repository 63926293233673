import React from 'react';
import {Modal,Button,Input} from 'semantic-ui-react';

class EditorLinkModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: 'https://',
        };
    }
    
    handleChange = (event) => {
        this.setState({ text: event.target.value });
    };
    
    handleSubmit = () => {
        this.props.onSubmit(this.state.text);    
    };
    
    render() {
        const { show, onDismiss } = this.props;
        
        return (
            <Modal open={show} className='ceta-modal' style={{ zIndex: 9001}}>
                    <Modal.Header>Enter link URL</Modal.Header>
                    <Modal.Content>
                        <Input fluid value={this.state.text} onChange={this.handleChange} />
                    </Modal.Content>
                  
                    <Modal.Actions>
                        <Button onClick={onDismiss}>Cancel</Button>
                        <Button color='teal' onClick={this.handleSubmit}>Save</Button>
                    </Modal.Actions>
            </Modal>        
        );
    }
}
export default EditorLinkModal;