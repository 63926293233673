import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';
import history from "../history";

import {
        FORK_PROJECT_SEND,
        forkProjectSuccess,forkProjectError,
        FETCH_PROJECT_CHECKPOINTS_SEND,
        fetchProjectCheckpointsSuccess, fetchProjectCheckpointsError, fetchProjectCheckpoints,
        DELETE_PROJECT_CHECKPOINT_SEND,
        deleteProjectCheckpointSuccess,deleteProjectCheckpointError,
        SHARE_CHECKPOINT_SEND,
        shareCheckpointSuccess,shareCheckpointError,
        FETCH_SHARED_CHECKPOINTS_SEND,
        fetchSharedCheckpointsSuccess, fetchSharedCheckpointsError, fetchSharedCheckpoints,
        MERGE_CHECKPOINT_SEND,
        mergeCheckpointSuccess,mergeCheckpointError,
        FETCH_LENT_CHECKPOINTS_SEND,
        fetchLentCheckpointsSuccess, fetchLentCheckpointsError, fetchLentCheckpoints,
        OPEN_CHECKPOINT_SHARING,
        openCheckpointSharingSuccess,openCheckpointSharingError,
        UNSHARE_CHECKPOINT_SEND,
        unshareCheckpointSuccess,unshareCheckpointError,openCheckpointSharing,
        FETCH_ALL_SHARES_SEND, fetchAllSharesSuccess, fetchAllSharesError, fetchAllShares

    } from './actions';

function* fork(action) {
    try {
        let response = yield call(() => api.forkProject(action.payload));
        if(response.success) {
            yield put(forkProjectSuccess(response));
            yield put(fetchProjectCheckpoints(action.projectId));
        }
        else {
            yield put(forkProjectError(response.message));
        }
    }
    catch(err) {
        yield put(forkProjectError(err.message));
    }
}

function* getProjectCheckpoints(action) {
    try {
        let response = yield call(() => api.fetchProjectCheckpoints(action.payload));
        if(response.success) {
            yield put(fetchProjectCheckpointsSuccess(response));
        }
        else {
            yield put(fetchProjectCheckpointsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchProjectCheckpointsError(err.message));
    }

}

function* deleteProjectCheckpoint(action) {
    try {
        let response = yield call(() => api.deleteProjectCheckpoint(action.projectId,action.checkpointId));
        if(response.success) {
            yield put(deleteProjectCheckpointSuccess(response));
            yield put(fetchProjectCheckpoints(action.projectId));
        }
        else {
            yield put(deleteProjectCheckpointError(response.message));
        }
    }
    catch(err) {
        yield put(deleteProjectCheckpointError(err.message));
    }
}

function* share(action) {
    try {
        let response = yield call(() => api.shareCheckpoint(action.checkpointId,action.payload));
        if(response.success) {
            yield put(shareCheckpointSuccess(response));
            if(action.reloadLendees) {
                yield put(openCheckpointSharing(action.payload));
            }
            else {
                yield put(fetchSharedCheckpoints(action.projectId));
                yield put(fetchAllShares());
            }
        }
        else {
            yield put(shareCheckpointError(response.message));
        }
    }
    catch(err) {
        yield put(shareCheckpointError(err.message));
    }
}

function* getSharedCheckpoints(action) {
    try {
        let response = yield call(() => api.fetchSharedProjects(action.payload));
        if(response.success) {
            yield put(fetchSharedCheckpointsSuccess(response));
        }
        else {
            yield put(fetchSharedCheckpointsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchSharedCheckpointsError(err.message));
    }
}

function* merge(action) {
    try {
        let response = yield call(() => api.mergeCheckpoint(action.projectId,action.checkpointId));
        if(response.success) {
            yield put(mergeCheckpointSuccess(response));
            yield put(fetchProjectCheckpoints(action.projectId));
        }
        else {
            yield put(mergeCheckpointError(response.message));
        }
    }
    catch(err) {
        yield put(mergeCheckpointError(err.message));
    }
}

function* getLentCheckpoints(action) {
    try {
        let response = yield call(() => api.fetchLentProjects(action.payload));
        if(response.success) {
            yield put(fetchLentCheckpointsSuccess(response));
        }
        else {
            yield put(fetchLentCheckpointsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchLentCheckpointsError(err.message));
    }
}

function* getCheckpointLendees(action) {
    try {
        let response = yield call(() => api.fetchProjectLendees(action.checkpoint._id));
        if(response.success) {
            yield put(openCheckpointSharingSuccess(response));
            yield call(() => history.push("/shared"));
        }
        else {
            yield put(openCheckpointSharingError(response.message));
        }
    }
    catch(err) {
        yield put(openCheckpointSharingError(err.message));
    }
}

function* unshare(action) {
    try {
        let response = yield call(() => api.unshareCheckpoint(action.shareId));
        if(response.success) {
            yield put(unshareCheckpointSuccess(response));
            yield put(fetchAllShares());
        }
        else {
            yield put(unshareCheckpointError(response.message));
        }
    }
    catch(err) {
        yield put(unshareCheckpointError(err.message));
    }
}

function* getAllShares(action) {
    try {
        let response = yield call(() => api.fetchAllShares(action.payload));
        if(response.success) {
            yield put(fetchAllSharesSuccess(response));
        }
        else {
            yield put(fetchAllSharesError(response.message));
        }
    }
    catch(err) {
        yield put(fetchAllSharesError(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(FORK_PROJECT_SEND,fork);
    yield takeLatest(FETCH_PROJECT_CHECKPOINTS_SEND,getProjectCheckpoints);
    yield takeLatest(DELETE_PROJECT_CHECKPOINT_SEND,deleteProjectCheckpoint);
    yield takeLatest(SHARE_CHECKPOINT_SEND,share);
    yield takeLatest(FETCH_SHARED_CHECKPOINTS_SEND,getSharedCheckpoints);
    yield takeLatest(MERGE_CHECKPOINT_SEND,merge);
    yield takeLatest(FETCH_LENT_CHECKPOINTS_SEND,getLentCheckpoints);
    yield takeLatest(OPEN_CHECKPOINT_SHARING,getCheckpointLendees);
    yield takeLatest(UNSHARE_CHECKPOINT_SEND,unshare);
    yield takeLatest(FETCH_ALL_SHARES_SEND, getAllShares);
}