import React from 'react';
import {connect} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import {Button, Card, Dimmer,Loader,Icon,Popup,Message,Transition} from 'semantic-ui-react';
import {
    openProject, fetchProjects, createProject,showCreateProjectModal,
    hideCreateProjectModal,deleteProject, showExportModal,showProjectRenameModal,openImportModal,
    readBook,editBook
} from '../../../project/actions';
import {fetchMotd,dismissMotd} from '../../../admin/actions';
import {openView} from '../../../viewer/actions';
import {fetchSharedCheckpoints,fetchLentCheckpoints,openCheckpointSharing} from '../../../checkpoint/actions';
import Toolbar from '../../header/Toolbar';
import {maxWidth} from '../../../util/stringUtils';
import {hasPremium} from '../../../services/roles';

class SharedFromListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newProjectName: 'Untitled',
            showDelete: false
        }
    }

    componentDidMount() {
        this.props.fetchSharedCheckpoints();
        this.props.fetchMotd();
    }


    formatTime = (date) => {
        const timeZone = this.props.user.timezone;
        const zonedDate = utcToZonedTime(date, timeZone);
        const pattern = 'MMM dd, yyyy h:mm:ss a';
        const output = format(zonedDate, pattern, { timeZone })        
        return output;
    }

    render() {
        const motd = (this.props.admin.showMotd && this.props.admin.motd) ? (
            <Message info onDismiss={this.props.dismissMotd} style={{ backgroundColor: '#383838',color:'#00ffff'}}>
                <Message.Header style={{color:'#0ff'}}>{this.props.admin.motd.title}</Message.Header>
                <p>
                    {this.props.admin.motd.message}
                </p>
            </Message>
        ) : null;


        const noLent = (this.props.checkpoints.lent.length === 0) ? (
            <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%', margin: '2em'}}>
                <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
                <h1 style={{ color: 'white' }}>You Have No Projects Shared With Others</h1>
                </Transition>
            </div>
            ) : null;

            const noShared = (this.props.checkpoints.shared.length === 0) ? (
                <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%', margin: '2em'}}>
                    <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
                    <h1 style={{ color: 'white' }}>No Projects Shared With You</h1>
                    </Transition>
                </div>
                ) : null;
    
            const shared = this.props.checkpoints.shared.map( share => {
                const project = share.project;
                const openButton = (
                    <Button content='Open' icon='file' onClick={() => {
                        if(share.editor) {
                            this.props.editBook(project._id);
                        }
                        else {
                            this.props.readBook(project._id);
                        }
                    }} />
                );
                return (
                    <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={project._id}>
                    <Card style={{backgroundColor:'#363636'}}>
                        <Card.Content>
                            <Card.Header style={{color:'white'}}>{maxWidth(project.name,30)}</Card.Header>
                            <div style={{width:'100%', textAlign:'center'}} draggable={true} key={project._id} onDragStart={(ev) => ev.dataTransfer.setData("projectId", project._id)}>
                                <Icon name='share square'color='teal' size='massive'/>
                            </div>
                            <Card.Meta style={{color: '#cdcdcd'}}>
                                <span>Chapters: {project.chapters.length}</span><br/>
                                <span>Last Updated: {this.formatTime(project.updateDate)}</span><br/>
                            </Card.Meta>
                        </Card.Content>
                        <Card.Content extra>
                            <div style={{width:'100%',textAlign:'center'}}>
                            <Popup trigger={openButton}>View Project</Popup>
                            </div>
                        </Card.Content>
                    </Card>
                    </Transition>
            )});
    
    
        return (
            <div>
                <Toolbar>
                <div style={{marginLeft:'1em',marginRight:'1em',marginBottom:'1em'}}>
                    <Dimmer active={this.props.projects.isFetchingProjects}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <p/>
                    {motd}
                    <div>
                        {noShared}
                        <Card.Group>
                            {shared}
                        </Card.Group>
                    </div>                    
                </div>
            </Toolbar>
            </div>
        );
    }
}

export default connect(state=>({
    user: state.user,
    projects: state.projects,
    checkpoints: state.checkpoints,
    admin: state.admin
}),
{
    openProject,fetchProjects,createProject,showCreateProjectModal,
    hideCreateProjectModal,deleteProject,fetchSharedCheckpoints,
    openView,fetchMotd,dismissMotd,fetchLentCheckpoints,openCheckpointSharing,
    showExportModal,showProjectRenameModal,openImportModal,readBook,editBook
})(SharedFromListPage);