import React, { useCallback,useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Grid,Popup,Header,Responsive,Dropdown,Menu} from 'semantic-ui-react';
import DeleteModal from '../../../components/DeleteModal';
import RenameChapterModal from '../../project/modals/RenameChapterModal';
import {
    addChapter,moveChapter,moveChapterUp,moveChapterDown,showChapterRenameModal,showDeleteChapterModal,openChapter,
    removeChapter,hideDeleteChapterModal,hideContextSideBar
} from '../../../project/actions';
import {convertElementToPlainText} from '../../../util/exportToPlainText';

const BookCard = ({ id, chapter, index, moveCard }) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: 'card',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'card', id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let preview = convertElementToPlainText(chapter.text);
    if(preview.length > 150) {
        preview = preview.slice(0,150);
    }
    return (
      <div ref={ref} style={{ opacity:1 }}>
          <div className='ceta-chapter-card' onClick={() => dispatch(openChapter(chapter._id))}>
              <div className='ceta-chapter-header'>
                <span style={{ fontSize: 'larger'}}><strong>Chapter {index+1}</strong>: {chapter.name}</span>
              </div>
              <div className='preview'>
                {preview}
              </div>
          </div>
      </div>
    )
  }


const ChaptersContext = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const projects = useSelector(state => state.projects);

    const renderCard = (chapter,index) => {
        return (
            <BookCard 
                key={chapter._id} 
                index={index}
                id={chapter._id}
                chapter={chapter}
                moveCard={moveCard}
            />
        );
    };

    const moveCard = useCallback(
        (dragIndex,hoverIndex) => {
            dispatch(moveChapter(dragIndex,hoverIndex));
        }
    );

    return (
        <div>
            <DeleteModal 
                chapterId={projects.deleteChapterModalChapterId}
                projectId={projects.deleteChapterModalProjectId}
                show={projects.showDeleteChapterModal}                     
                onCancel={() => dispatch(hideDeleteChapterModal())} 
                onConfirm={(data) => {
                    dispatch(removeChapter(data.projectId,data.chapterId));
                    dispatch(hideDeleteChapterModal());
                }}
            />
            <RenameChapterModal />
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item>
                            <Header color='teal'>Chapters</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div style={{ height:'calc(100vh - 70px)', overflowY: 'auto',overflowX: 'hidden'}}>
                {openProject.chapters.map( (chapter,index) => renderCard(chapter,index))}
            </div>
        </div>
    );
}

export default ChaptersContext;