import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Avatar from '../../components/Avatar';
import {closeProfileModal,followUser,unfollowUser} from '../../feed/actions';
import {fetchContacts,requestContact,approveContact} from '../../contact/actions';
import Showable from '../../components/Showable';

const ContactButton = ({username}) => {
    const dispatch = useDispatch();
    const mututal = useSelector(state => state.contacts.mututal);
    const requested = useSelector(state => state.contacts.requested);
    const pending = useSelector(state => state.contacts.pending);

    let inviteButton = <Button color='teal' onClick={() => {
        dispatch(requestContact({
            contact: {
                requestType: 'name',
                name: username
            }
        }));        
    }}>Invite to be a contact</Button>;
    mututal.filter( contact => contact.name === username)
    .forEach( contact => {
        inviteButton = <Button disabled={true}>{contact.name} is already a contact</Button>;
    });
    requested.filter( contact => contact.name === username)
    .forEach( contact => {
        inviteButton = <Button disabled={true}>Waiting for {contact.name} approval</Button>;
    });
    pending.filter( contact => contact.name === username)
    .forEach( contact => {
        inviteButton = <Button color='orange' onClick={() => {
            dispatch(approveContact(contact._id));            
        }}>Approve {contact.name} as a contact</Button>;
    });
    
    useEffect( () => {
        dispatch(fetchContacts());
    },[]);
    return inviteButton;
};

const ProfileModal = (props) => {
    const dispatch = useDispatch();
    const showProfileModal = useSelector(state => state.feed.showProfileModal);
    const openProfile = useSelector(state => state.feed.openProfile);
    const user = useSelector(state => state.user);

    let followButton = openProfile.isFollowing?
        (<Button color='teal' onClick={() => dispatch(unfollowUser(openProfile.name))}>Unfollow</Button>):
        (<Button onClick={() => dispatch(followUser(openProfile.name))}>Follow</Button>);
    
    return (
        <Modal open={showProfileModal} className='ceta-modal'>
            <Modal.Content>
                <div style={{ display:'grid',gridTemplateColumns:'20% auto'}}>
                    <div>
                        <Avatar user={openProfile} fullsize={true} />
                    </div>
                    <div style={{ paddingLeft: '1em',textAlign:'center'}}>
                        <h2>{openProfile.name}</h2>
                        <Showable showIf={openProfile.name !== user.name}>
                            <div>{followButton}</div>
                        </Showable>
                        <div style={{ marginTop:'1em'}}><Button onClick={() => dispatch(closeProfileModal())} as={Link} to={'/thoughts/user/'+openProfile.name}>Thoughts</Button></div>
                        <Showable showIf={openProfile.name !== user.name}>
                        <div style={{ marginTop:'1em'}}><ContactButton username={openProfile.name} /></div>
                        </Showable>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => dispatch(closeProfileModal())}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ProfileModal;