import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';

import {
    FETCH_INBOX_SEND,fetchInboxSuccess, fetchInboxError,
    FETCH_MESSAGES_SEND,fetchMessagesSuccess, fetchMessagesError,
    SEND_MESSAGE_SEND,sendMessageSuccess, sendMessageError,fetchMessages,
    DELETE_MESSAGE_SEND,deleteMessageSuccess, deleteMessageError,
    MARK_READ_SEND,markReadSuccess,
    FETCH_UNREAD_SEND,fetchUnreadSuccess, fetchUnreadError,
    BROADCAST_MESSAGE_SEND,broadcastMessageSuccess,broadcastMessageError
} from './actions';

function* sendMessage(action) {
    try {
        let response = yield call(() => api.sendMessage(action.payload));
        if(response.success) {
            yield put(sendMessageSuccess(response));
            yield put(fetchMessages());
        }
        else {
            yield put(sendMessageError(response.message));
        }
    }
    catch(err) {
        yield put(sendMessageError(err.message));
    }

}
    
function* deleteMessage(action) {
    try {
        let response = yield call(() => api.deleteMessage(action.payload));
        if(response.success) {
            yield put(deleteMessageSuccess(response));
            yield put(fetchMessages());
        }
        else {
            yield put(deleteMessageError(response.message));
        }
    }
    catch(err) {
        yield put(deleteMessageError(err.message));
    }

}

function* getMessages(action) {
    try {
        let response = yield call(() => api.fetchMessages(action.payload));
        if(response.success) {
            yield put(fetchMessagesSuccess(response));
        }
        else {
            yield put(fetchMessagesError(response.message));
        }
    }
    catch(err) {
        yield put(fetchMessagesError(err.message));
    }

}

function* getInbox(action) {
    try {
        let response = yield call(() => api.fetchInbox(action.payload));
        if(response.success) {
            yield put(fetchInboxSuccess(response));
        }
        else {
            yield put(fetchInboxError(response.message));
        }
    }
    catch(err) {
        yield put(fetchInboxError(err.message));
    }

}

function* readMessage(action) {
    try {
        let response = yield call( () => api.readMessage(action.payload._id))
        if(response.success) {
            yield put(markReadSuccess(response));
        }
    }
    catch(err) {
        console.log(err);
    }
}

function* getUnread(action) {
    try {
        let response = yield call(() => api.fetchUnread(action.payload));
        if(response.success) {
            yield put(fetchUnreadSuccess(response));
        }
        else {
            yield put(fetchUnreadError(response.message));
        }
    }
    catch(err) {
        yield put(fetchUnreadError(err.message));
    }

}

function* castMessage(action) {
    try {
        let response = yield call(() => api.broadcastMessage(action.payload));
        if(response.success) {
            yield put(broadcastMessageSuccess(response));
        }
        else {
            yield put(broadcastMessageError(response.message));
        }
    }
    catch(err) {
        yield put(broadcastMessageError(err.message));
    }

}

export default function* projectSagas() {
    yield takeLatest(FETCH_INBOX_SEND,getInbox);
    yield takeLatest(FETCH_MESSAGES_SEND,getMessages);
    yield takeLatest(SEND_MESSAGE_SEND,sendMessage);
    yield takeLatest(DELETE_MESSAGE_SEND,deleteMessage);
    yield takeLatest(MARK_READ_SEND, readMessage);
    yield takeLatest(FETCH_UNREAD_SEND,getUnread);
    yield takeLatest(BROADCAST_MESSAGE_SEND, castMessage);
}