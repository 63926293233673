import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Input,Label, Popup} from 'semantic-ui-react';
import {updateLocalResearch,showDeleteResearchModal,resizeResearch} from '../../../project/actions';

const UtilityBar = ({research}) => {
    const dispatch = useDispatch();
    const openProjectId = useSelector(state => state.projects.openProject._id);    
    const [newTag,setNewTag] = useState('');

    const tagList = research.tags ? research.tags.map( tag => <Label key={tag} content={tag} tag color='teal' removeIcon='delete' onRemove={() => {
        let newTags = research.tags.filter(t => t !== tag);
        dispatch(updateLocalResearch(research._id,'tags',newTags));
    }}/>) : [];
    const sizeIcon = research.size === 'large' ? 'window minimize' : 'window maximize';
    return (
        <div>
            <div style={{ width: '100%'}}>
            <Popup trigger={<Icon name='tag'/>} on='click'>
                <Input icon='tag' iconPosition='left' placeholder='Add tag...' value={newTag} 
                onKeyPress={(ev) => {
                    if(ev.key === 'Enter') {
                        let newTags = [newTag];
                        newTags = newTags.concat(research.tags);
                        dispatch(updateLocalResearch(research._id,'tags',newTags));
                        setNewTag('');
                    }
                }} onChange={(ev) => {
                    setNewTag(ev.target.value)
                }}/>
            </Popup>
            <Icon style={{ float:'right'}} name='delete' onClick={() => dispatch(showDeleteResearchModal(openProjectId,research._id))}/>
            <Icon style={{ float:'right'}} name={sizeIcon} onClick={() => dispatch(resizeResearch(research._id, research.size === 'large'?'regular':'large'))}/>
            </div>
        </div>
    );
}

export default UtilityBar;