import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';

import {
        FETCH_CONTACTS_SEND,
        fetchContactsSuccess, fetchContactsError,
        REQUEST_CONTACT_SEND,
        requestContactSuccess, requestContactError,fetchContacts,
        DELETE_CONTACT_SEND,
        deleteContactSuccess, deleteContactError,
        APPROVE_CONTACT_SEND,
        approveContactSuccess, approveContactError
    } from './actions';

function* requestContact(action) {
    try {
        let response = yield call(() => api.requestContact(action.payload));
        if(response.success) {
            yield put(requestContactSuccess(response));
            yield put(fetchContacts());
        }
        else {
            yield put(requestContactError(response.message));
        }
    }
    catch(err) {
        yield put(requestContactError(err.message));
    }

}
    
function* deleteContact(action) {
    try {
        let response = yield call(() => api.deleteContact(action.payload));
        if(response.success) {
            yield put(deleteContactSuccess(response));
            yield put(fetchContacts());
        }
        else {
            yield put(deleteContactError(response.message));
        }
    }
    catch(err) {
        yield put(deleteContactError(err.message));
    }

}

function* getContacts(action) {
    try {
        let response = yield call(() => api.fetchContacts(action.payload));
        if(response.success) {
            yield put(fetchContactsSuccess(response));
        }
        else {
            yield put(fetchContactsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchContactsError(err.message));
    }

}

function* approveContact(action) {
    try {
        let response = yield call(() => api.approveContact(action.contactId));
        if(response.success) {
            yield put(approveContactSuccess(response));
            yield put(fetchContacts());
        }
        else {
            yield put(approveContactError(response.message));
        }
    }
    catch(err) {
        yield put(approveContactError(err.message));
    }

}

export default function* projectSagas() {
    yield takeLatest(FETCH_CONTACTS_SEND,getContacts);
    yield takeLatest(REQUEST_CONTACT_SEND,requestContact);
    yield takeLatest(DELETE_CONTACT_SEND,deleteContact);
    yield takeLatest(APPROVE_CONTACT_SEND,approveContact);
}