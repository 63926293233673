import React,{useState,useRef,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Transition,Input} from 'semantic-ui-react';
import {utcToZonedTime} from 'date-fns-tz';
import Avatar from '../../components/Avatar';
import {createComment} from '../../feed/actions';

const formatDate = (date,timezone) => {
    if(!date) {
        return '';
    }
    let tzDate = utcToZonedTime(date, timezone).getTime();
    let now = (new Date().getTime() - tzDate) / 1000;
    let days = Math.floor(now / 86400);
    let hours = Math.floor(now / 3600 % 24);
    let minutes = Math.floor(now / 60 % 60);
    let seconds = Math.floor(now % 60);
    if(days>0) return days+'d';
    if(hours>0) return hours+'h';
    if(minutes>0) return minutes+'m';
    return seconds+'s';
}

const parseText = (text) => {
    if(!text) return '';
    return text.replace('\n','</br>');
}

const ConvoPanel = ({thought}) => {
    const ref = useRef(null);
    const [text,setText] = useState('');
    const dispatch = useDispatch();
    const openThought = useSelector(state => state.feed.openThought);
    const isCreatingComment = useSelector(state => state.feed.isCreatingComment);

    useEffect( () => {
        if(ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    },[openThought]);

    const isOpen = (openThought.id === thought.id);
    const comments = isOpen? openThought.comments.map( comment => (
        <div key={comment.id} style={{ paddingTop: '0.5em'}}>
            <Avatar thought={comment} /> <strong>{comment.author}</strong> {parseText(comment.text)}
        </div>
    )): [];
    return (
            <div>
                <div ref={ref} style={{ height: '20em',overflowY:'auto',marginTop:'1em',marginBottom:'1em'}}>
                    {comments}
                </div>
                <Input fluid action={{
                    color:'teal',content:'Send',loading: isCreatingComment, onClick: () => {
                        dispatch(createComment(thought.id,{ text }));
                        setText('');
                        ref.current.scrollTop = ref.current.scrollHeight;
                    }
                }} value={text} onChange={(ev) => setText(ev.target.value)} onKeyDown={(ev) => {if(ev.key === 'Enter') {
                    dispatch(createComment(thought.id,{ text }));
                    setText('');
                    ref.current.scrollTop = ref.current.scrollHeight;
                }}}/>
            </div>
    );
};

const Conversation = ({thought}) => {
    const openThought = useSelector(state => state.feed.openThought);

    const isOpen = (openThought.id === thought.id);
    return isOpen ? (<ConvoPanel thought={thought} />):null;
};

export default Conversation;