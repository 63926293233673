import {combineReducers} from 'redux';
import { connectRouter } from 'connected-react-router'
import {reducer as form} from 'redux-form';
import userReducer from './user/reducer';
import projectReducer from './project/reducer';
import adminReducer from './admin/reducer';
import checkpointReducer from './checkpoint/reducer';
import viewerReducer from './viewer/reducer';
import contactReducer from './contact/reducer';
import inboxReducer from './inbox/reducer';
import paymentReducer from './payment/reducer';
import dictionaryReducer from './dictionary/reducer';
import postReducer from './post/reducer';
import feedReducer from './feed/reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    form,
    user: userReducer,
    projects: projectReducer,
    admin: adminReducer,
    checkpoints: checkpointReducer,
    viewer: viewerReducer,
    contacts: contactReducer,
    inbox: inboxReducer,
    payment: paymentReducer,
    dictionary: dictionaryReducer,
    posts: postReducer,
    feed: feedReducer
});