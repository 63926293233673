import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Label,Popup,Header,Loader,List,Menu} from 'semantic-ui-react';
import {
    addCharacter,showDeleteCharacterModal,openCharacter,
    removeCharacter,hideDeleteCharacterModal,hideContextSideBar
} from '../../../project/actions';

const PlotPointCard = ({plotpoint}) => {
    const synopsis = plotpoint.synopsis.split('\n').map( (s,i) => <span key={i}>{s}<br/></span>);
    const tagList = plotpoint.tags ? plotpoint.tags.map( tag => <Label key={tag} content={tag} tag color='teal' />) : [];
    return (
        <List.Item>
            <List.Content>
                <div>
                    {synopsis}
                </div>
                {tagList}
            </List.Content>
        </List.Item>
    );
}


const OutlineContext = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const projects = useSelector(state => state.projects);

    return (
        <div>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item>
                            <Header color='teal'>Outline</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div style={{ height:'calc(100vh - 70px)', overflowY: 'auto',overflowX: 'hidden'}}>
                {openProject.acts.map( (act,index) => {
                    const plotpointList = openProject.plotpoints
                        .filter( pp => act.plotpoints.indexOf(pp._id) > -1)
                        .map( (pp,i) => <PlotPointCard key={pp._id} plotpoint={pp} index={i}/>);
                    return (
                        <div key={act._id} style={{ marginBottom: '1em'}}>
                            <div className='ceta-outline-context-act'>
                                Act {index+1}   
                            </div>
                            <List bulleted>
                                {plotpointList}
                            </List>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default OutlineContext;