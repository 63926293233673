import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Form,Segment,Breadcrumb,Responsive,Grid} from 'semantic-ui-react';
import EmbeddedRichTextEditor from '../editor/EmbeddedRichTextEditor';
import {updateLocalLocation} from '../../project/actions';
import UploadedImage from '../../components/UploadedImage';

const LocationEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const openProjectLocation = useSelector(state => state.projects.openProjectLocation);

    const sceneryElement = (openProjectLocation.scenery) 
        ? JSON.parse(openProjectLocation.scenery) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const denizensElement = (openProjectLocation.denizens) 
        ? JSON.parse(openProjectLocation.denizens) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
        <div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{
                height:'100%',display:'flex',flexDirection:'column',            
            }}>
                <div style={{flexShrink: 0}}>
                    <div style={{color:'white'}}>
                        <Segment inverted>
                            <Breadcrumb style={{color:'white'}} size='large'>
                                <Breadcrumb.Section>Project</Breadcrumb.Section>
                                <Breadcrumb.Divider style={{ color:'white'}}/>
                                <Breadcrumb.Section >Locations</Breadcrumb.Section>
                                <Breadcrumb.Divider style={{ color:'white'}}/>
                                <Breadcrumb.Section active>{openProjectLocation.name}</Breadcrumb.Section>
                            </Breadcrumb>
                        </Segment>
                    </div>
                </div>
                <div style={{flexGrow:1,overflowY:'auto'}}>

                    <div style={{margin: '2em'}}>
                        <Form>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <UploadedImage 
                                        src={openProjectLocation.image}
                                        dim='300px'
                                        stock='/img/ceta-location-default.png'
                                        onDelete={ () => dispatch(updateLocalLocation('image',''))}
                                        onSuccess={ (result) => dispatch(updateLocalLocation('image',result))}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                <Form.Field>
                                    <label style={{color:'white'}}>Name</label>
                                    <input 
                                        placeholder='Name' 
                                        value={openProjectLocation.name}
                                        onChange={(ev) => dispatch(updateLocalLocation('name',ev.target.value))}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label style={{color:'white'}}>Overview</label>
                                    <input 
                                        placeholder='A brief description of the place...' 
                                        value={openProjectLocation.overview ? openProjectLocation.overview : ''}
                                        onChange={(ev) => dispatch(updateLocalLocation('overview',ev.target.value))}
                                    />
                                </Form.Field>
                                </Grid.Column>
                            </Grid.Row>

                        </Grid>

                            <Form.Field>
                                <label style={{color:'white'}}>Scenery</label>
                                <EmbeddedRichTextEditor 
                                    content={sceneryElement}
                                    onUpdate={(value) => dispatch(updateLocalLocation('scenery',JSON.stringify(value)))}
                                    placeholder='Describe what your location looks like...'
                                />
                            </Form.Field>
                            <Form.Field>
                                <label style={{color:'white'}}>Denizens</label>
                                <EmbeddedRichTextEditor 
                                    content={denizensElement}
                                    onUpdate={(value) => dispatch(updateLocalLocation('denizens',JSON.stringify(value)))}
                                    placeholder='Describe the people who frequent this location...'
                                />
                            </Form.Field>
                        </Form>
                    </div>
                </div>
            </div>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <div style={{ margin: '0.5em' }}>
                <Form>
                    <Form.Field>
                        <label style={{color:'white'}}>Name</label>
                        <input 
                            placeholder='Name' 
                            value={openProjectLocation.name}
                            onChange={(ev) => dispatch(updateLocalLocation('name',ev.target.value))}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label style={{color:'white'}}>Scenery</label>
                        <EmbeddedRichTextEditor 
                            content={sceneryElement}
                            onUpdate={(value) => dispatch(updateLocalLocation('scenery',JSON.stringify(value)))}
                            placeholder='Describe what your location looks like...'
                        />
                    </Form.Field>
                    <Form.Field>
                        <label style={{color:'white'}}>Denizens</label>
                        <EmbeddedRichTextEditor 
                            content={denizensElement}
                            onUpdate={(value) => dispatch(updateLocalLocation('denizens',JSON.stringify(value)))}
                            placeholder='Describe the people who frequent this location...'
                        />
                    </Form.Field>

                </Form>
            </div>
        </Responsive>

        </div>
    );
};

export default LocationEditPanel;