export const DISMISS_VIEWER_ERROR = 'DISMISS_VIEWER_ERROR';

export const dismissViewerError = () => ({
    type: DISMISS_VIEWER_ERROR
});

export const OPEN_VIEW = 'OPEN_VIEW';
export const OPEN_VIEW_SUCCESS = 'OPEN_VIEW_SUCCESS';
export const OPEN_VIEW_ERROR = 'OPEN_VIEW_ERROR';
export const CLOSE_VIEW = 'CLOSE_VIEW';

export const openView = (projectId,mode) => ({
    type: OPEN_VIEW,
    projectId,
    mode
});

export const openViewSuccess = (payload) => ({
    type: OPEN_VIEW_SUCCESS,
    payload
});

export const openViewError = (payload) => ({
    type: OPEN_VIEW_ERROR,
    payload
});

export const closeView = () => ({
    type: CLOSE_VIEW
});


export const OPEN_DIFF_VIEW = 'OPEN_DIFF_VIEW';
export const OPEN_DIFF_VIEW_SUCCESS = 'OPEN_DIFF_VIEW_SUCCESS';
export const OPEN_DIFF_VIEW_ERROR = 'OPEN_DIFF_VIEW_ERROR';
export const CLOSE_DIFF_VIEW = 'CLOSE_DIFF_VIEW';

export const openDiffView = (projectId) => ({
    type: OPEN_DIFF_VIEW,
    projectId
});

export const openDiffViewSuccess = (payload) => ({
    type: OPEN_DIFF_VIEW_SUCCESS,
    payload
});

export const openDiffViewError = (payload) => ({
    type: OPEN_DIFF_VIEW_ERROR,
    payload
});

export const closeDiffView = () => ({
    type: CLOSE_DIFF_VIEW
});


export const OPEN_TIMELINE = 'OPEN_TIMELINE';

export const openTimeline = () => ({
    type: OPEN_TIMELINE
});

export const OPEN_LOCATIONS_PANEL = 'OPEN_LOCATIONS_PANEL';
export const openLocationsPanel = () => ({
    type: OPEN_LOCATIONS_PANEL
});

export const OPEN_CHARACTERS_PANEL = 'OPEN_CHARACTERS_PANEL';
export const openCharactersPanel = () => ({
    type: OPEN_CHARACTERS_PANEL
});
