import PropTypes from 'prop-types'
import React, { Component,useEffect } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react';
import Post from './Post';
import ResponsiveContainer from '../main/ResponsiveContainer';
import {fetchPosts} from '../../post/actions';

const filterPost = (current,target) => {
    if(!target) {
        // no post id passed so let it all through
        return true;
    }
    if(target === current) {
        return true;
    }
    return false;
}

const PublicBlogPage = (props) => {
    const {postId} = useParams();
    const dispatch = useDispatch();
    const posts = useSelector(state => state.posts.all);
    const isFetchingPosts = useSelector(state => state.posts.isFetchingPosts);

    useEffect( () => {dispatch(fetchPosts())},[dispatch]);

    const postList = posts.filter((post => filterPost(post._id,postId)))
        .map( post => <Post key={post._id} post={post}/>);
    return (
        <ResponsiveContainer slug='News and Articles'>
            <Container text>
            <Segment loading={isFetchingPosts} inverted style={{ padding: '1em'}}>
                {postList}
            </Segment>
            </Container>

            <Segment inverted vertical style={{ padding: '5em 0em' }}>
            <Container>
                <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={3}>
                    <Header inverted as='h4' content='Help' />
                    <List link inverted>
                        <List.Item as='a'>FAQ</List.Item>
                        <List.Item as='a' href="mailto:support@cetacean.app">Contact Us</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                    <Header inverted as='h4' content='Company' />
                    <List link inverted>
                        <List.Item as='a' href="https://www.bluewizardlabs.com">About Us</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                    <Header as='h4' inverted>
                        Follow us on social media
                    </Header>
                    <p>
                        <Icon color='teal' size='big' name='twitter square' link onClick={() => window.open('http://www.twitter.com/CetaceanApp')}/>
                    </p>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
            </Segment>
        </ResponsiveContainer>
    );
}

export default PublicBlogPage;