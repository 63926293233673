import {
        AUTHENTICATION_SEND,AUTHENTICATION_SUCCESS,AUTHENTICATION_ERROR,AUTHENTICATION_CANCEL,
        RE_AUTHENTICATION_SEND, UNVERIFIED_ACCOUNT,
        REGISTRATION_SEND,REGISTRATION_SUCCESS,REGISTRATION_ERROR,
        PASSWORD_UPDATE_SEND,PASSWORD_UPDATE_SUCCESS,PASSWORD_UPDATE_ERROR,
        UNAUTHENTICATE,
        PASSWORD_RESET_SEND,PASSWORD_RESET_SUCCESS,PASSWORD_RESET_ERROR,
        USERNAME_CHECK_SEND,USERNAME_CHECK_SUCCESS,USERNAME_CHECK_ERROR,
        DISTRACTION_FREE_TOGGLE,LOG_LINK_MARKER,
        UPDATE_PREFS_SEND,UPDATE_PREFS_SUCCESS,UPDATE_PREFS_ERROR,
        OPEN_FEEDBACK_MODAL,CLOSE_FEEDBACK_MODAL,
        SEND_FEEDBACK_SEND,SEND_FEEDBACK_SUCCESS,SEND_FEEDBACK_ERROR,
        RE_VERIFY_SEND,RE_VERIFY_SUCCESS,RE_VERIFY_ERROR
    } from './actions';

import * as FeedActionType from '../feed/constants';

import { CONFIRM_PAYMENT_SUCCESS,SUBSCRIBE_PREMIUM_SUCCESS,UNSUBSCRIBE_PREMIUM_SUCCESS } from '../payment/actions';

const initialState = {
    isAuthenticated: false,
    token: undefined,
    userId: '',
    email: '',
    name: '',
    timezone: 'UTC',
    roles: [],
    stripeSubscriptionStatus: '',
    stripeSubscriptionEnd: 0,
    stripeSubscriptionCanceled: false,
    avatar: '',
    penName: '',
    following: [],
    showError: false,
    errorMessage: '',
    isAuthenticating: false,
    authenticationError: false,
    authenticationErrorMessage: '',
    isUnverified: false,
    isRegistering: false,
    registrationError: false,
    registrationErrorMessage: '',
    hasRegistered: false,
    isUpdatingPassword: false,
    updatePaswordError: false,
    updatePasswordMessage: '',
    isResettingPassword: false,
    resetError: false,
    resetErrorMessage: '',
    hasPasswordReset: false,
    usernameStatus: 'unknown',
    isCheckingUsername: false,
    usernameCheckError: false,
    usernameCheckErrorMessage: '',
    isDistractionFree: false,
    isUpdatingPreferences: false,
    linkMarker: {}
};

export default (state = initialState, action) => {
    switch(action.type) {
        case AUTHENTICATION_SEND:
            return {
                ...state,
                isAuthenticating: true,
                isUnverified: false,
                authenticationError: false,
                authenticationErrorMessage: ''
            };
        case AUTHENTICATION_SUCCESS:
            return {
                ...state,
                userId: action.payload.data.user.id,
                email: action.payload.data.user.email,
                name: action.payload.data.user.name,
                token: action.payload.data.user.token,
                roles: action.payload.data.user.roles,
                timezone: action.payload.data.user.timezone,
                penName: action.payload.data.user.penName,
                stripeSubscriptionStatus: action.payload.data.user.stripeSubscriptionStatus,
                stripeSubscriptionEnd: action.payload.data.user.stripeSubscriptionEnd,
                stripeSubscriptionCanceled: action.payload.data.user.stripeSubscriptionCanceled,
                avatar: action.payload.data.user.avatar,
                following: action.payload.data.user.following,
                isAuthenticated: true,
                isAuthenticating: false
            };
        case AUTHENTICATION_ERROR:
            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: false,
                authenticationError: true,
                authenticationErrorMessage: action.payload
            }
        case UNVERIFIED_ACCOUNT:
            return {
                ...state,
                isAuthenticating: false,
                isUnverified: true,
                isAuthenticated: false,
                authenticationError: true,
                authenticationErrorMessage: action.payload
            }
        case RE_VERIFY_SEND:
            return {
                ...state,
                hasVerifyBeenSent: false,
                isReverifying: true,
                isUnverified: false,
                reverifyError: false,
                reverifyErrorMessage: ''
            }
        case RE_VERIFY_SUCCESS:
            return {
                ...state,
                hasVerifyBeenSent:true,
                isReverifying: false,
                isUnverified: false,
                reverifyError: false,
                reverifyErrorMessage: ''
            }
        case RE_VERIFY_ERROR:
            return {
                ...state,
                isReverifying: false,
                isUnverified: false,
                reverifyError: true,
                reverifyErrorMessage: action.payload
            }
        
        case AUTHENTICATION_CANCEL:
            return {
                ...state,
                isAuthenticating: false
            }
        case RE_AUTHENTICATION_SEND:
            return {
                ...state,
                isAuthenticating: true
            }
        case UNAUTHENTICATE:
            return {
                ...state,
                ...initialState
            }
        case REGISTRATION_SEND:
            return {
                ...state,
                isRegistering: true,
                registrationError: false,
                registrationErrorMessage: ''
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                isRegistering: false,
                hasRegistered: true
            };
        case REGISTRATION_ERROR:
                return {
                    ...state,
                    isRegistering: false,
                    registrationError: true,
                    registrationErrorMessage: action.payload
                }
        case PASSWORD_UPDATE_SEND:
            return {
                ...state,
                isUpdatingPassword: true
            }
        case PASSWORD_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdatingPassword: false
            }
        case PASSWORD_UPDATE_ERROR:
            return {
                ...state,
                isUpdatingPassword:false,
                updatePaswordError: true,
                updatePasswordMessage: action.payload
            }
        case PASSWORD_RESET_SEND:
            return {
                ...state,
                isResettingPassword: true,
                resetError: false,
                resetErrorMessage: '',
                hasPasswordReset: false
            
            };
        case PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                isResettingPassword: false,
                resetError: false,
                resetErrorMessage: '',
                hasPasswordReset: true            
            };
        case PASSWORD_RESET_ERROR:
            return {
                ...state,
                isResettingPassword: false,
                resetError: true,
                resetErrorMessage: action.payload,
                hasPasswordReset: false            
            }
        case USERNAME_CHECK_SEND:
            return {
                ...state,
                usernameStatus: 'unknown',
                isCheckingUsername: true,
                usernameCheckError: false,
                usernameCheckErrorMessage: ''            
            };
        case USERNAME_CHECK_SUCCESS:
            return {
                ...state,
                usernameStatus: 'available',
                isCheckingUsername: false,
                usernameCheckError: false,
                usernameCheckErrorMessage: ''
            };
        case USERNAME_CHECK_ERROR:
            return {
                ...state,
                usernameStatus: 'unavailable',
                isCheckingUsername: false,
                usernameCheckError: false,
                usernameCheckErrorMessage: ''
            }
        case DISTRACTION_FREE_TOGGLE:
            return {
                ...state,
                isDistractionFree: action.mode
            }
        case UPDATE_PREFS_SEND:
            return {
                ...state,
                isUpdatingPreferences: true
            }
        case UPDATE_PREFS_SUCCESS:
            return {
                ...state,
                isUpdatingPreferences: false,
                timezone: action.payload.data.user.timezone,
                avatar: action.payload.data.user.avatar,
                penName: action.payload.data.user.penName
            }
        case UPDATE_PREFS_ERROR:
            return {
                ...state,
                isUpdatingPreferences:false,
                showError: true,
                errorMessage: action.payload
            }
        case UNSUBSCRIBE_PREMIUM_SUCCESS:
        case SUBSCRIBE_PREMIUM_SUCCESS:
        case CONFIRM_PAYMENT_SUCCESS:
            return {
                ...state,
                roles: action.payload.data.user.roles,
                stripeSubscriptionStatus: action.payload.data.user.stripeSubscriptionStatus,
                stripeSubscriptionEnd: action.payload.data.user.stripeSubscriptionEnd,
                stripeSubscriptionCanceled: action.payload.data.user.stripeSubscriptionCanceled              
            }
  
        case OPEN_FEEDBACK_MODAL:
            return {
                ...state,
                showFeedbackModal: true,
            }
        
        case CLOSE_FEEDBACK_MODAL:
            return {
                ...state,
                showFeedbackModal: false,
            }
            
        case SEND_FEEDBACK_SEND:
            return {
                ...state,
                isSendingFeedback: true
            }
        case SEND_FEEDBACK_SUCCESS:
            return {
                ...state,
                isSendingFeedback: false,
                showFeedbackModal: false
            }
        case SEND_FEEDBACK_ERROR:
            return {
                ...state,
                isSendingFeedback:false,
                showError: true,
                errorMessage: action.payload
            }
        case LOG_LINK_MARKER:
            return {
                ...state,
                linkMarker: action.payload
            }
        case FeedActionType.FOLLOW_USER_SUCCESS:
        case FeedActionType.UNFOLLOW_USER_SUCCESS: {
            return {
                ...state,
                following: action.payload.data.user.following
            }
        }
        default:
            return {...state};
    }
};