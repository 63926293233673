import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import RichTextEditor from '../../editor/RichTextEditor';
import {updateLocalChapter,updateProject} from '../../../project/actions';

const ChapterEditPanel = ({useMobile}) =>  {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);

    const textElement = (projects.openProjectChapter.text) 
    ? JSON.parse(projects.openProjectChapter.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
            <RichTextEditor 
                key={projects.openProjectChapter._id+projects.keyCount}
                content={textElement}
                onUpdate={ (value) => dispatch(updateLocalChapter(value))}
                placeholder='Do not be afraid of a blank page...'
                focus={true}
                footer={true}
                centered={true}
                ignoreSelectionUpdate={true}
                lastSaveDate={projects.lastSaveDate}
                onSave={() => dispatch(updateProject({project: projects.openProject}))}
                useMobile={useMobile}
                checkGrammar={projects.checkGrammar}
                spacing={projects.openProject.spacing}
                autoIndent={projects.openProject.autoIndent}
                backgroundColor={projects.openProject.backgroundColor}
                textColor={projects.openProject.textColor}
            />
    );
}

export default ChapterEditPanel;