import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Modal,Form,Segment} from 'semantic-ui-react';
import {
    showDeleteRelationshipModal,
    openRelationship,updateLocalRelationship,closeRelationship,
    showNewRelationshipModal
} from '../../../project/actions';
import Graph from 'react-graph-vis';
import NewRelationshipModal from './NewRelationshipModal';

const EditRelationshipModal = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectRelationship = useSelector(state => state.projects.openProjectRelationship);
    const showEditRelationshipModal = useSelector(state => state.projects.showEditRelationshipModal);

    let targetChar = {};
    let subjectChar = {};
    if(openProjectRelationship) {
        openProject.characters.forEach(character => {
            if(character._id === openProjectRelationship.subject) {
                subjectChar = character;
            }
            else if (character._id === openProjectRelationship.target) {
                targetChar = character;
            }
        });
    }
    let currentStatus = (openProjectRelationship && openProjectRelationship.status) ? openProjectRelationship.status[0].status : '';
    return(
        <Modal open={showEditRelationshipModal} centered={false}>
            <Modal.Header style={{background:'#363636',color:'white'}}>
                Edit Relationship
            </Modal.Header>
            <Modal.Content style={{background:'#363636',color:'white'}}>
                <Form>
                    <h2>{subjectChar.name}</h2>
                    <Form.Field>
                        <Form.Input 
                            placeholder='Status' 
                            value={currentStatus}
                            onChange={(ev) => dispatch(updateLocalRelationship('status',ev.target.value))}
                        />
                    </Form.Field>
                    <h2>{targetChar.name}</h2>
                </Form>                
            </Modal.Content>
            <Modal.Actions style={{background:'#363636',color:'white'}}>
                <Button onClick={() => dispatch(closeRelationship())}>Close</Button>
                <Button onClick={() => dispatch(showDeleteRelationshipModal(openProject._id,openProjectRelationship._id))}>Delete</Button>
            </Modal.Actions>
        </Modal>

    );
}

function getCharacterName(characters,id) {
    for(let i=0;i<characters.length;i++) {
        if(characters[i]._id === id) {
            return characters[i].name;
        }
    }
}

const RelationshipsPanel = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectCharacter = useSelector(state => state.projects.openProjectCharacter);
    const charRelationships = openProject.relationships.filter(rel => rel.subject === openProjectCharacter._id);

    let nodes = charRelationships.map(rel => ({ id: rel.target, label: getCharacterName(openProject.characters,rel.target)}));
    nodes.push({ id: openProjectCharacter._id, label: openProjectCharacter.name, x: '30vh',y:'30vh'});

    let edges = [];
    let nodesToCheck = [];
    for(let i=0; i<openProject.relationships.length; i++) {
        let rel = openProject.relationships[i];
        if(openProjectCharacter._id === rel.subject) {
            edges.push({
                id: rel._id,
                from: openProjectCharacter._id,
                to: rel.target,
                label: rel.status[0].status
            });
        }
        else if (openProjectCharacter._id === rel.target) {
            edges.push({
                id: rel._id,
                from: rel.subject,
                to: openProjectCharacter._id,
                label: rel.status[0].status
            });
            nodesToCheck.push(rel.subject);
        }
    }
    nodesToCheck.filter(nodeId => {
        let found = false;
        nodes.forEach( node => {
            if(node.id === nodeId) {
                found = true;
            }
        })
        return !found;
    })
        .forEach(nodeId => nodes.push({ id: nodeId, label: getCharacterName(openProject.characters,nodeId)}));

    const graph = {
        nodes: nodes,
        edges: edges
      };
      const options = {
        layout: {
          hierarchical: false
        },
        nodes: {
            color: '#00fcfc',
            shape: 'box'
        },
        edges: {
          color: "#ccc",
          width: 3,
          smooth: {
              enabled: true,
              type: 'curvedCW',
              roundness: 0.25
          },
          font: {
              color: '#000',
              face: 'arial',
              background: '#ccc',
              strokeWidth: 0
          },
          length: 200
        }
      };
      
      const events = {
        select: function(event) {
          var { nodes, edges } = event;
          if( nodes.indexOf(openProjectCharacter._id) < 0) {
            if( edges.length > 0) {
                dispatch(openRelationship(edges[0]));
            }
            }
        }
      };

    return (
        <div>
            <div>
                <NewRelationshipModal />
                <EditRelationshipModal />
                <Button color='teal' onClick={() => {
                    dispatch(showNewRelationshipModal());
                    //setSession(new Date());
                }}>
                    <Icon name='users'/> Create Relationship
                </Button>
            </div>
                <div style={{padding:'1em'}}>
                    <Segment inverted>
                    <Graph graph={graph} options={options} events={events} style={{height:'60vh'}} />
                    </Segment>
                </div>
            </div>
    );
};

export default RelationshipsPanel;