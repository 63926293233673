import React,{createRef,useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Responsive,Icon} from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Toolbar from '../header/Toolbar';
import NewThoughtModal from './NewThoughtModal';
import ReblogModal from './ReblogModal';
import ReadChapterModal from './ReadChapterModal';
import Thought from './Thought';
import ProfileModal from './ProfileModal';
import ErrorModal from '../../components/ErrorModal';
import {fetchFollowedFeed,openNewThoughtModal,dismissFeedError} from '../../feed/actions';

const FollowedFeed = (props) => {
    const stickyRef = createRef();
    const dispatch = useDispatch();
    const feed = useSelector(state => state.feed);
    const followedFeed = useSelector(state => state.feed.followedFeed);
    const followedFeedOffset = useSelector(state => state.feed.followedFeedOffset);
    const followedFeedHasNext = useSelector(state => state.feed.followedFeedHasNext);
    const isFetchingThoughts = useSelector(state => state.feed.isFetchingThoughts);
    const openThought = useSelector(state => state.feed.openThought);
    const [search,setSearch] = useState('');

    useEffect( () => {
        dispatch(fetchFollowedFeed(0));
    },[dispatch]);

    const thoughtList = followedFeed.map( thought => <Thought 
        thought={thought} key={thought.id}
        isOpen={openThought.id === thought.id}
    />);
    return (
        <Toolbar>
            <NewThoughtModal />
            <ReblogModal />
            <ProfileModal />
            <ReadChapterModal />
            <ErrorModal 
                show={feed.showError} 
                message={feed.errorMessage}
                onConfirm={() => dispatch(dismissFeedError())}
            />
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                <div style={{}}>
                    <div style={{ width:'200px',overflowX:'hidden',position:'fixed',zIndex:1,paddingTop:'1em' }}>
                            <Button.Group vertical={true}>
                            <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/>Compose A Thought</Button>
                            </Button.Group>
                    </div>
                    <div style={{ marginLeft:'200px'}}>
                        <div>
                            <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                                <InfiniteScroll
                                    dataLength={followedFeed.length}
                                    next={() => dispatch(fetchFollowedFeed(followedFeed.length))}
                                    hasMore={followedFeedHasNext}
                                    loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                                {thoughtList}
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

            </Responsive>
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                <div>
                    <div>
                        <div style={{ maxWidth: '40em',marginLeft:'auto',marginRight:'auto'}}>
                            <InfiniteScroll
                                dataLength={followedFeed.length}
                                next={() => dispatch(fetchFollowedFeed(followedFeed.length))}
                                hasMore={followedFeedHasNext}
                                loader={<div style={{ color:'white'}}><h4>Loading...</h4></div>}>
                            {thoughtList}
                            </InfiniteScroll>
                        </div>
                    </div>
                    <div style={{ position:'fixed',left:0,bottom:0}}>
                       <Button.Group vertical={true}>
                            <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='plus'/></Button>
                            <Button icon color='teal'><Icon name='search'/></Button>
                            </Button.Group>
 
                    </div>
                </div>
            </Responsive>
        </Toolbar>
    );
};

export default FollowedFeed;