import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import history from './history';
import { routerMiddleware } from 'connected-react-router';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import CacheBuster from './CacheBuster';
import App from './App';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import createRootReducer from './reducers'
import sagas from './sagas';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import * as Sentry from '@sentry/browser';
import CONFIG from './config';

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

/*eslint-disable */
const composeSetup =
    process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
/*eslint-enable */

// allows redux devtools to watch sagas
const store = createStore(
    createRootReducer(history),
    composeSetup(applyMiddleware(sagaMiddleware, routerMiddleware(history))) 
);

sagaMiddleware.run(sagas);

const stripePromise = loadStripe(CONFIG.stripePubKey);

if(CONFIG.enableSentryLogging) {
    Sentry.init({dsn: "https://ee541f05a12d46dfaab8607faa71b752@o393722.ingest.sentry.io/5243140"});
}

ReactDOM.render(
    <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
        return (
            <Provider store={store}>
                <Elements stripe={stripePromise}>
                    <DndProvider backend={Backend}>
                        <App history={history}/>
                    </DndProvider>
                </Elements>
            </Provider>
            );
        }}
      </CacheBuster>
    ,
    document.getElementById('root')
);

