import {
    OPEN_DICTIONARY_BREAKOUT,CLOSE_DICTIONARY_BREAKOUT,
    SEARCH_DICTIONARY_SEND,SEARCH_DICTIONARY_SUCCESS,SEARCH_DICTIONARY_ERROR,
    OPEN_THESAURUS_BREAKOUT,CLOSE_THESAURUS_BREAKOUT,
    SEARCH_THESAURUS_SEND,SEARCH_THESAURUS_SUCCESS,SEARCH_THESAURUS_ERROR
} from './actions';

const initialState = {
    words: [],
    synonyms: [],
    showError:false,
    errorMessage: '',
    showDictionaryBreakout: false,
    isSearchingDictionary: false,
    showThesaurusBreakout: false,
    isSearchingThesaurus: false
};

export default (state = initialState, action) => {
switch(action.type) {

    case OPEN_DICTIONARY_BREAKOUT:
        return {
            ...state,
            showDictionaryBreakout: true            
        }
    case CLOSE_DICTIONARY_BREAKOUT:
        return {
            ...state,
            showDictionaryBreakout: false
        }
    case SEARCH_DICTIONARY_SEND:
        return {
            ...state,
            isSearchingDictionary: true
        }
    case SEARCH_DICTIONARY_SUCCESS:
        return {
            ...state,
            words: action.payload,
            isSearchingDictionary: false
        }            
    case SEARCH_DICTIONARY_ERROR:
        return {
            ...state,
            isSearchingDictionary: false,
            showError: true,
            errorMessage: action.payload
        }            

    case OPEN_THESAURUS_BREAKOUT:
        return {
            ...state,
            showThesaurusBreakout: true            
        }
    case CLOSE_THESAURUS_BREAKOUT:
        return {
            ...state,
            showThesaurusBreakout: false
        }
    case SEARCH_THESAURUS_SEND:
        return {
            ...state,
            isSearchingThesaurus: true
        }
    case SEARCH_THESAURUS_SUCCESS:
        return {
            ...state,
            synonyms: action.payload.synonyms,
            isSearchingThesaurus: false
        }            
    case SEARCH_THESAURUS_ERROR:
        return {
            ...state,
            isSearchingThesaurus: false,
            showError: true,
            errorMessage: action.payload
        }            
            
    default:
        return {...state};
}
};