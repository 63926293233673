import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {Segment,Button,Icon,Image} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import DocumentDisplay from '../../editor/DocumentDisplay';
import {closeResearchNotesBreakout} from '../../../project/actions';

const ResearchNotesBreakoutWindow = (props) => {
    const dispatch = useDispatch();
    const showResearchNotesBreakout = useSelector(state => state.projects.showResearchNotesBreakout);
    const openBreakoutResearch = useSelector(state => state.projects.openBreakoutResearch);

    const textElement = (openBreakoutResearch.text) 
        ? JSON.parse(openBreakoutResearch.text) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    return (
        <Showable showIf={showResearchNotesBreakout}>
            <Draggable bounds='parent'>
                <div style={{ position: 'absolute', zIndex: 2,top:0,left:0, width: '250px', height: '50vh', color:'white'}}>
                    <Segment inverted style={{ 
                            backgroundColor:'#141414',
                            height:'100%', 
                            width:'100%',
                            boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                            padding:0}}>
                        <Button style={{ marginTop:'0.5em',marginLeft:'0.5em'}} compact icon size='mini' onClick={() => dispatch(closeResearchNotesBreakout())}><Icon name='close'/></Button>
                        <div style={{ overflowY:'auto',overflowX:'hidden', height:'45vh',padding:'0.5em'}}>
                                <DocumentDisplay content={textElement} />
                        </div>
                    </Segment>
                </div>
            </Draggable>
        </Showable>
    );
}

export default ResearchNotesBreakoutWindow;