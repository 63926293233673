import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { Transition } from 'react-transition-group'
import {Dimmer,Loader,Menu,Icon,Popup,Sidebar} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {toggleDistractionFree} from '../../user/actions';
import {
    hideMobileMainSideBar,openChaptersSideBar, updateProject,
    showProjectViewModal,openDictionarySideBar, openProgressSideBar,
    openIdeaBoard,openCharactersSideBar, openLocationsSideBar, openOutline,
    openResearchSideBar, openOutlineSideBar
} from '../../project/actions';

const duration = 200;
const sidebarStyle = {
  transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
  entering: { width: 0 },
  entered: { width: '200px' },
  exiting: { width: '200px' },
  exited: { width: 0 }
}
const linkStyle = {
  transition: `opacity ${duration}ms`
}
const linkTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
}

const getViewMenuItem = (dispatch,projectView) => {
    let name = 'Writing';
    let icon = 'pencil';
    if(projectView === 'Brainstorm') {
        name = 'Brainstorm';
        icon = 'idea';
    }
    else if (projectView === 'Outline') {
        name = 'Outline';
        icon = 'list';
    }
    else if (projectView === 'Revision') {
        name = 'Revision';
        icon = 'sync alternate';
    }
    return (<Menu.Item onClick={() => dispatch(showProjectViewModal())}>View: {name}<Icon name={icon}/></Menu.Item>)
}

const getMainSidebarItems = (dispatch,projectView) => {
    switch (projectView) {
        case 'Brainstorm': {
            return [
                (<Menu.Item key='Ideas' onClick={() => dispatch(openIdeaBoard())}>Idea Board<Icon name='sticky note'/></Menu.Item>),
                (<Menu.Item key='Characters' onClick={() => dispatch(openCharactersSideBar())} >Characters<Icon name='users'/></Menu.Item>),
                (<Menu.Item key='Places' onClick={() => dispatch(openLocationsSideBar())} >Locations<Icon name='map'/></Menu.Item>)
            ]
        }
        case 'Outline': {
            return [
                (<Menu.Item key='Outline' onClick={() => dispatch(openOutline())}>Outline<Icon name='list'/></Menu.Item>),
                (<Menu.Item key='Ideas' onClick={() => dispatch(openResearchSideBar())} >Ideas<Icon name='idea'/></Menu.Item>),
                (<Menu.Item key='Characters' onClick={() => dispatch(openCharactersSideBar())} >Characters<Icon name='users'/></Menu.Item>),
                (<Menu.Item key='Places' onClick={() => dispatch(openLocationsSideBar())} >Locations<Icon name='map'/></Menu.Item>)
            ]
        }
        case 'Revision': {
            return [
            ]
        }
        case 'Read': {
            return [
                (<Menu.Item key='Chapters' onClick={() => dispatch(openChaptersSideBar())} >Chapters<Icon name='book'/></Menu.Item>)
            ];
        }
        default: {
            return [
                (<Menu.Item key='Progress' onClick={() => dispatch(openProgressSideBar())}>Progress<Icon name='chart line'/></Menu.Item>),
                (<Menu.Item key='Chapters' onClick={() => dispatch(openChaptersSideBar())} >Chapters<Icon name='book'/></Menu.Item>),
                (<Menu.Item key='Dictionary' onClick={() => dispatch(openDictionarySideBar())}>Dictionary<Icon name='keyboard'/></Menu.Item>),
                (<Menu.Item key='Outline' onClick={() => dispatch(openOutlineSideBar())}>Outline<Icon name='list'/></Menu.Item>),
            ];
        }
    }
}

const MobileMainSideBar = (props) => {
    const dispatch = useDispatch();
    const showMain = useSelector(state => state.projects.showMainSideBar);
    const openProject = useSelector(state => state.projects.openProject);
    const isProjectDirty = useSelector(state => state.projects.isProjectDirty);
    const isOffline = useSelector(state => state.projects.isOffline);
    const saveClass= isProjectDirty ? 'ceta-unsaved' : '';
    const offlineMessage = isOffline ? 
        <Popup trigger={<Menu.Header className='ceta-offline-message'>OFFLINE MODE</Menu.Header>}>The network connection to the servers is currently down. Continue working and your changes will be saved locally and sync'd with the servers once the connection is good.</Popup>
        : null;

    return(

        <Sidebar as={Menu} animation='overlay' inverted
            onHide={() => dispatch(hideMobileMainSideBar())}
            vertical visible={showMain}>
            {offlineMessage}
            <Menu.Item as={Link} to='/'>Return to Projects<Icon name='reply'/></Menu.Item>
            <Menu.Item 
                onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(hideMobileMainSideBar());
                    dispatch(updateProject({project: openProject}));
                }}
                >Save<Icon className={saveClass} name='cloud upload'/>
            </Menu.Item>
            {getViewMenuItem(dispatch,openProject.view)}
            {getMainSidebarItems(dispatch,openProject.view)}
    </Sidebar>
    );
}

export default MobileMainSideBar;