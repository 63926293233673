import React,{useState,useEffect} from 'react';
import {Button, Modal,Dropdown} from 'semantic-ui-react';

const RechapterSceneModal = (props) => {    
    const [value,setValue] = useState('');
    useEffect( () => {
        setValue(props.initialValue);
    }, [props.initialValue]);

    return (
        <Modal open={props.show}>
            <Modal.Header>Enter new Name</Modal.Header>
            <Modal.Content>
                <Dropdown 
                    value={value} 
                    selection 
                    options={props.options}
                    onChange={(ev,data) => setValue(data.value) }
                />

            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => props.onSave(value)}>Save</Button>
                <Button negative onClick={props.onCancel}>Cancel</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default RechapterSceneModal;