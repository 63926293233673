import React from 'react';
import {connect} from 'react-redux';
import {Container, Form, Button, Menu, Tab, Message,Image,Dimmer,Icon} from 'semantic-ui-react';

import Toolbar from '../header/Toolbar';
import TimezoneSelect from '../../components/TimezoneSelect';
import UploadedImage from '../../components/UploadedImage';
import ManageSubscription from './ManageSubscription';
import {sendPasswordUpdaate,updatePreferences} from '../../user/actions';

class Settings extends React.Component {

    constructor() {
        super();
        this.state = {
            password1: '',
            password2: '',
            timezone: '',
            penName: ''
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            timezone: this.props.user.timezone,
            penName: this.props.user.penName
        })
    }

    updatePassword1 = (event) => {
        this.setState({
            ...this.state, 
            password1: event.target.value
        });

    }
    updatePassword2 = (event) => {
        this.setState({
            ...this.state, 
            password2: event.target.value
        });
    }

    submitPasswordUpdate = () => {
        if( (this.state.password1 !== '') && (this.state.password1 === this.state.password2)) {
            this.props.sendPasswordUpdaate({user: {password:this.state.password1}});
            this.setState({
                ...this.state,
                password1: '',
                password2: '',
                timezone: '',
            });
        }
    }

    updateTimezone = (tz) => {
        this.setState({...this.state, timezone: tz});
        this.props.updatePreferences({
            user: { 
                timezone: tz,
                avatar: this.props.user.avatar,
                penName: this.props.user.penName,
            }
        });
    }

    updateAvatar = (url) => {
        this.props.updatePreferences({
            user: { 
                timezone: this.state.timezone,
                penName: this.props.user.penName,
                avatar: url
            }
        });
    }   

    updatePenName = (text) => {
        this.props.updatePreferences({
            user: { 
                timezone: this.state.timezone,
                avatar: this.props.user.avatar,
                penName: text
            }
        });
    }

    render() {
        const panes = [
            { menuItem: (
                <Menu.Item key='Preferences' style={{color:'white'}}>
                    Preferences
                </Menu.Item>
            ), render: () => (
                <div>
                    <UploadedImage 
                        src={this.props.user.avatar}
                        onDelete={ () => this.updateAvatar('')}
                        onSuccess={ (result) => this.updateAvatar(result)}
                    />
                    <p/>
                    <Form>
                        <Form.Field>
                            <label style={{color:'white'}}>Pen Name</label>
                            <Form.Input 
                                style={{ width: '300px'}} 
                                value={this.props.user.penName} 
                                onChange={ (ev) => this.updatePenName(ev.target.value)} 
                                />
                        </Form.Field>
                        <p/>
                        <Form.Field>
                            <label style={{color:'white'}}>Timezone</label>
                            <TimezoneSelect 
                                style={{ width: '300px'}}
                                value={this.state.timezone} 
                                onChange={ (data) => this.updateTimezone(data.value)}
                            />
                        </Form.Field>
                    </Form>
                </div>
            )},
            { menuItem: (
                <Menu.Item key='Password' style={{color:'white'}}>
                    Password
                </Menu.Item>
            ), render: () => (
                <div>
                    <Form 
                        error={(this.state.password1 !== this.state.password2)}
                        loading={this.props.user.isUpdatingPassword}>
                        <label>Change Password</label>
                        <p/>
                            <Form.Input 
                                type='password'
                                placeholder="Enter new password" 
                                onChange={this.updatePassword1}
                                value={this.state.password1}
                                />
                            <Form.Input 
                                type='password'
                                placeholder="Confirm new password" 
                                onChange={this.updatePassword2}
                                value={this.state.password2}
                                />
                            <Message
                                error
                                header='Invalid Password'
                                content='The new passwords do not match'
                                />
                        <Button color='teal' onClick={this.submitPasswordUpdate}>Update Password</Button>
                    </Form>
                </div>
            )},
            { menuItem: (
                <Menu.Item key='Subscription' style={{color:'white'}}>
                    Subscription
                </Menu.Item>
            ), render: () => (
                <ManageSubscription />
            )}            
        ];
    
        return (
            <div>
                <Toolbar/>
                <Container style={{color:'white'}}>
                    <Tab menu={{secondary:true,pointing:true,color:'teal'}} panes={panes}/>                    
                </Container>
            </div>
        );
    }
}

export default connect(
    state => ({user: state.user}),
    {sendPasswordUpdaate,updatePreferences}
)(Settings);