import React from 'react';
import {connect} from 'react-redux';
import {sendPasswordReset} from '../../user/actions';
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Segment, Dimmer, Loader, Message, Header } from 'semantic-ui-react';


class PasswordReset extends React.Component {

    constructor(props) {
        super(props);
        this.state = { email: ''}
    }

    sendReset = () => {
        this.props.sendPasswordReset({login: {email:this.state.email}});
    }
    
    render() {
        if(this.props.user.isResettingPassword && (!this.props.user.hasPasswordReset )) {
            return (
            <Dimmer active>
                <Loader />
            </Dimmer>            
            );
        }

        return (
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                <Header inverted as='h1'>C e t a c e a n</Header>
                <Form inverted size='large' error={this.props.user.resetError}>
                    <Segment style={{backgroundColor:'#272727',color:'white'}}>
                    <Message info>
                        Enter your email address below to receive a password reset link.
                    </Message>
                    <Form.Input fluid 
                        icon='user'
                        iconPosition='left'
                        placeholder='E-mail address' 
                        onChange={ (event) => this.setState({...this.state,email: event.target.value})}
                        />
                    <Message
                        error
                        header='Unable To Send Reset Link'
                        content={this.props.user.resetErrorMessage}
                    />
                    <Message
                        hidden={!this.props.user.hasPasswordReset}
                    >
                        <Message.Header>Password Reset Sent</Message.Header>
                        <p>Check your email and follow the link.</p>
                    </Message>

                    <Button color='teal' fluid size='large' onClick={this.sendReset}>
                        Reset Password
                    </Button>
                    <Link style={{color:'#00b5ad'}} to="/register">Register</Link> &nbsp;|&nbsp;
                    <Link style={{color:'#00b5ad'}} to="/login">Log In</Link>
                    </Segment>
                </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect( 
    state => ({user: state.user}),
    {sendPasswordReset})(PasswordReset);
