import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Menu} from 'semantic-ui-react';
import Showable from '../../components/Showable';
import {
    openView,openDiffView,openTimeline,
    openLocationsPanel,openCharactersPanel
} from '../../viewer/actions';

const ProjectViewSidebar = ({projectId,checkpoint}) => {
    const dispatch = useDispatch();
    const [active,setActive] = useState('chapters');
    const openMode = useSelector(state => state.viewer.openMode);
    const ownerOptions = (openMode === 'owner');
    return (
        <div style={{
            height:'100%',
            display:'flex',
            flexDirection:'column'
        }}>
            <div style={{flexShrink: 0}}>
            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <Menu vertical inverted style={{width:'100%'}}>
                    <Menu.Item 
                        active={active === 'chapters'}
                        onClick={() => {
                            setActive('chapters');
                            dispatch(openView(checkpoint._id,openMode));
                            }}>
                        Chapters
                    </Menu.Item>
                    
                    <Showable showIf={ownerOptions}>
                    <Menu.Item 
                        active={active === 'diff'}
                        onClick={() => {
                            setActive('diff');
                            dispatch(openDiffView(projectId));
                            }}>
                        Changes
                    </Menu.Item>
                    <Menu.Item 
                        active={active === 'characters'}
                        onClick={() => {
                            setActive('characters');
                            dispatch(openCharactersPanel(checkpoint._id));
                            }}>
                        Characters
                    </Menu.Item>
                    <Menu.Item 
                        active={active === 'locations'}
                        onClick={() => {
                            setActive('locations');
                            dispatch(openLocationsPanel(checkpoint._id));
                            }}>
                        Locations
                    </Menu.Item>

                    <Menu.Item 
                        active={active === 'history'}
                        onClick={() => {
                            setActive('history');
                            dispatch(openTimeline(checkpoint._id));
                            }}>
                        Timeline
                    </Menu.Item>
                    </Showable>                    
                </Menu>

            </div>            
        </div>
    );
}
export default ProjectViewSidebar;