import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Dropdown, Input,Form, Icon,TextArea} from 'semantic-ui-react';
import DateModal from './DateModal';
import {getCalendar,epochToDate} from '../../../util/calendar';
import {
    closeEvent,updateLocalEvent, showContextSideBar
} from '../../../project/actions';


const EditEventModal = (props) => {
    const dispatch = useDispatch();
    const showEditEventModal = useSelector(state => state.projects.showEditEventModal);
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectEvent = useSelector(state => state.projects.openProjectEvent);
    const [showTest,setShowTest] = useState(false);

    const actOptions = Array.from(Array(7).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const chapterOptions = Array.from(Array(50).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));

    const sceneOptions = Array.from(Array(30).keys()).map( opt => ({
        key: opt+1, text: opt+1, value: opt+1
    }));
    
    const characterOptions = openProject.characters.map( character => ({
        key: character._id,
        text: character.name,
        value: character._id
    }));

    const plotpointOptions = openProject.plotpoints.map( plotpoint => ({
        key: plotpoint._id,
        text: plotpoint.synopsis,
        value: plotpoint._id
    }));

    const formattedDate = epochToDate(openProjectEvent.epoch,getCalendar());

    return (
        <Modal open={showEditEventModal}>
            <Modal.Header style={{ backgroundColor:'#272727',color:'white'}}>
                Edit Event
            </Modal.Header>
            <Modal.Content style={{ backgroundColor:'#272727',color:'white'}}>
                <Form>
                    <Form.Field>
                    <Input 
                            label='Name'
                            placeholder='Name' 
                            value={openProjectEvent.name}
                            onChange={(ev) => dispatch(updateLocalEvent('name',ev.target.value))}
                        />
                    </Form.Field>
                    <p/>
                    <TextArea
                        value={openProjectEvent.description}
                        onChange={(ev) => dispatch(updateLocalEvent('description',ev.target.value))}
                        placeholder='Describe what happened on the event...'
                    />
                    <p/>
                    <Button icon onClick={() => setShowTest(true)}>Date<Icon name='calendar'/></Button>
                    &nbsp;&nbsp;<strong>{formattedDate}</strong><p/>

                    <div style={{ display: 'table',width:'100%'}}>
                        <div style={{ display: 'table-row'}}>
                            <div style={{ display: 'table-cell', width: '80px',verticalAlign:'middle'}}>
                                <strong>Plotpoint</strong>
                            </div>
                            <div style={{ display: 'table-cell'}}>
                                <Dropdown options={plotpointOptions} fluid selection 
                                    value={openProjectEvent.plotpoint}
                                    onChange={ (ev,dat) => dispatch(updateLocalEvent('plotpoint',dat.value))}
                                    />
                            </div>
                        </div>
                    </div>
                    <p/>
                    <div style={{ display: 'table',width:'100%'}}>
                        <div style={{ display: 'table-row'}}>
                            <div style={{ display: 'table-cell', width: '80px',verticalAlign:'middle'}}>
                                <strong>Characters</strong>
                            </div>
                            <div style={{ display: 'table-cell'}}>
                                <Dropdown options={characterOptions} fluid selection multiple 
                                    value={openProjectEvent.characters}
                                    onChange={ (ev,dat) => dispatch(updateLocalEvent('characters',dat.value))}
                                    />
                            </div>
                        </div>
                    </div>
                </Form>
                <DateModal 
                    open={showTest}
                    onClose={() => setShowTest(false)}
                    onSave={(val) => {
                        dispatch(updateLocalEvent('epoch',val))
                        setShowTest(false);
                    }}
                    dateString={openProjectEvent.epoch}
                    calendar={getCalendar()}
                />
            </Modal.Content>
            <Modal.Actions style={{ backgroundColor:'#272727',color:'white'}}>
                <Button onClick={() => {
                        dispatch(closeEvent());
                    }}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default EditEventModal;