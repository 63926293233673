import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';

import {
        FETCH_CODES_SEND,fetchCodesSuccess, fetchCodesError,
        CREATE_CODE_SEND,createCodeSuccess, createCodeError,fetchCodes,
        DELETE_CODE_SEND,deleteCodeSuccess, deleteCodeError,
        SEND_CODE_SEND, sendCodeSuccess, sendCodeError,
        FETCH_MOTDS_SEND,fetchMotdsSuccess, fetchMotdsError,
        FETCH_MOTD_SEND,fetchMotdSuccess, fetchMotdError,
        CREATE_MOTD_SEND,createMotdSuccess, createMotdError,fetchMotds,
        UPDATE_MOTD_SEND,updateMotdSuccess, updateMotdError,
        DELETE_MOTD_SEND,deleteMotdSuccess, deleteMotdError,
        SEARCH_USERS_SEND,searchUsersSuccess, searchUsersError,
        ADMIN_UPDATE_USER_SEND,adminUpdateUserSuccess,adminUpdateUserError,
        FETCH_ACTIVE_USERS_SEND,fetchActiveUsersSuccess,fetchActiveUsersError,
        FETCH_AD_REFERRALS_SEND,fetchAdReferralsSuccess,fetchAdReferralsError,
        CREATE_AD_REFERRAL_SEND,createAdReferralSuccess, createAdReferralError,fetchAdReferrals,
        DELETE_AD_REFERRAL_SEND,deleteAdReferralSuccess, deleteAdReferralError,
        FETCH_ANALYTICS_SEND,fetchAnalyticsSuccess, fetchAnalyticsError,
        FETCH_PROJECTS_REPORT_SEND,fetchProjectsReportSuccess, fetchProjectsReportError
    } from './actions';

function* createCode(action) {
    try {
        let response = yield call(() => api.createCode(action.payload));
        if(response.success) {
            yield put(createCodeSuccess(response));
            yield put(fetchCodes());
        }
        else {
            yield put(createCodeError(response.message));
        }
    }
    catch(err) {
        yield put(createCodeError(err.message));
    }

}
    
function* deleteCode(action) {
    try {
        let response = yield call(() => api.deleteCode(action.payload));
        if(response.success) {
            yield put(deleteCodeSuccess(response));
            yield put(fetchCodes());
        }
        else {
            yield put(deleteCodeError(response.message));
        }
    }
    catch(err) {
        yield put(deleteCodeError(err.message));
    }
}

function* getCodes(action) {
    try {
        let response = yield call(() => api.fetchCodes(action.payload));
        if(response.success) {
            yield put(fetchCodesSuccess(response));
        }
        else {
            yield put(fetchCodesError(response.message));
        }
    }
    catch(err) {
        yield put(fetchCodesError(err.message));
    }

}

function* sendCode(action) {
    try {
        let response = yield call(() => api.sendCode(action.codeId));
        if(response.success) {
            yield put(sendCodeSuccess(response));
            yield put(fetchCodes());
        }
        else {
            yield put(sendCodeError(response.message));
        }
    }
    catch(err) {
        yield put(sendCodeError(err.message));
    }

}


function* createMotd(action) {
    try {
        let response = yield call(() => api.createMotd(action.payload));
        if(response.success) {
            yield put(createMotdSuccess(response));
            yield put(fetchMotds());
        }
        else {
            yield put(createMotdError(response.message));
        }
    }
    catch(err) {
        yield put(createMotdError(err.message));
    }
}

function* updateMotd(action) {
    try {
        let response = yield call(() => api.updateMotd(action.payload));
        if(response.success) {
            yield put(updateMotdSuccess(response));
            yield put(fetchMotds());
        }
        else {
            yield put(updateMotdError(response.message));
        }
    }
    catch(err) {
        yield put(updateMotdError(err.message));
    }
}

function* deleteMotd(action) {
    try {
        let response = yield call(() => api.deleteMotd(action.payload));
        if(response.success) {
            yield put(deleteMotdSuccess(response));
            yield put(fetchMotds());
        }
        else {
            yield put(deleteMotdError(response.message));
        }
    }
    catch(err) {
        yield put(deleteMotdError(err.message));
    }
}

function* getMotds(action) {
    try {
        let response = yield call(() => api.fetchMotds(action.payload));
        if(response.success) {
            yield put(fetchMotdsSuccess(response));
        }
        else {
            yield put(fetchMotdsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchMotdsError(err.message));
    }
}

function* getMotd(action) {
    try {
        let response = yield call(() => api.fetchMotd(action.payload));
        if(response.success) {
            yield put(fetchMotdSuccess(response));
        }
        else {
            yield put(fetchMotdError(response.message));
        }
    }
    catch(err) {
        yield put(fetchMotdError(err.message));
    }
}

function* searchUsers(action) {
    try {
        let response = yield call(() => api.searchUsers(action.searchEmail,action.searchRole,action.searchLastLogin));
        if(response.success) {
            yield put(searchUsersSuccess(response));
        }
        else {
            yield put(searchUsersError(response.message));
        }
    }
    catch(err) {
        yield put(searchUsersError(err.message));
    }
}

function* adminUpdateUser(action) {
    try {
        let response = yield call(() => api.adminUpdateUser(action.payload));
        if(response.success) {
            yield put(adminUpdateUserSuccess(response));
        }
        else {
            yield put(adminUpdateUserError(response.message));
        }
    }
    catch(err) {
        yield put(adminUpdateUserError(err.message));
    }
}

function* getActiveUsers(action) {
    try {
        let response = yield call(() => api.fetchActiveUsers());
        if(response.success) {
            yield put(fetchActiveUsersSuccess(response));
        }
        else {
            yield put(fetchActiveUsersError(response.message));
        }
    }
    catch(err) {
        yield put(fetchActiveUsersError(err.message));
    }
}

function* getAdReferrals(action) {
    try {
        let response = yield call(() => api.fetchAdReferrals());
        if(response.success) {
            yield put(fetchAdReferralsSuccess(response));
        }
        else {
            yield put(fetchAdReferralsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchAdReferralsError(err.message));
    }
}

function* createAdReferral(action) {
    try {
        let response = yield call(() => api.createAdReferral(action.payload));
        if(response.success) {
            yield put(createAdReferralSuccess(response));
            yield put(fetchAdReferrals());
        }
        else {
            yield put(createAdReferralError(response.message));
        }
    }
    catch(err) {
        yield put(createAdReferralError(err.message));
    }

}

function* deleteAdReferral(action) {
    try {
        let response = yield call(() => api.deleteAdReferral(action.payload));
        if(response.success) {
            yield put(deleteAdReferralSuccess(response));
            yield put(fetchAdReferrals());
        }
        else {
            yield put(deleteAdReferralError(response.message));
        }
    }
    catch(err) {
        yield put(deleteAdReferralError(err.message));
    }
}

function* getAnalytics(action) {
    try {
        let response = yield call(() => api.fetchAnalytics());
        if(response.success) {
            yield put(fetchAnalyticsSuccess(response));
        }
        else {
            yield put(fetchAnalyticsError(response.message));
        }
    }
    catch(err) {
        yield put(fetchAnalyticsError(err.message));
    }
}

function* getProjectsReport(action) {
    try {
        let response = yield call(() => api.fetchProjectsReport());
        if(response.success) {
            yield put(fetchProjectsReportSuccess(response));
        }
        else {
            yield put(fetchProjectsReportError(response.message));
        }
    }
    catch(err) {
        yield put(fetchProjectsReportError(err.message));
    }
}

export default function* projectSagas() {
    yield takeLatest(FETCH_CODES_SEND,getCodes);
    yield takeLatest(CREATE_CODE_SEND,createCode);
    yield takeLatest(DELETE_CODE_SEND,deleteCode);
    yield takeLatest(FETCH_MOTDS_SEND,getMotds);
    yield takeLatest(SEND_CODE_SEND,sendCode);
    yield takeLatest(FETCH_MOTD_SEND,getMotd);
    yield takeLatest(CREATE_MOTD_SEND,createMotd);
    yield takeLatest(UPDATE_MOTD_SEND,updateMotd);
    yield takeLatest(DELETE_MOTD_SEND,deleteMotd);
    yield takeLatest(SEARCH_USERS_SEND,searchUsers);
    yield takeLatest(ADMIN_UPDATE_USER_SEND,adminUpdateUser);
    yield takeLatest(FETCH_ACTIVE_USERS_SEND,getActiveUsers);
    yield takeLatest(FETCH_AD_REFERRALS_SEND,getAdReferrals);
    yield takeLatest(CREATE_AD_REFERRAL_SEND,createAdReferral);
    yield takeLatest(DELETE_AD_REFERRAL_SEND,deleteAdReferral);
    yield takeLatest(FETCH_ANALYTICS_SEND, getAnalytics);
    yield takeLatest(FETCH_PROJECTS_REPORT_SEND, getProjectsReport);

}