import React,{useState} from 'react';
import {connect,useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Menu,Dropdown,Responsive,Sidebar,Icon,Visibility,Image,Label} from 'semantic-ui-react';
import {isAdmin,hasPremium} from '../../services/roles';
import {unauthenticate} from '../../user/actions';
import Showable from '../../components/Showable';
import FeedbackModal from '../feedback/FeedbackModal';
import NewProjectModal from '../project/modals/NewProjectModal';
import NewBookModal from '../book/NewBookModal';
import ImportModal from '../project/modals/ImportModal';
import {openFeedbackModal} from '../../user/actions';
import {fetchUnread} from '../../inbox/actions';

import {
    createChapter,updateProject,
    openChaptersPanel,openChapter,
    openTimeline,openCharactersPanel,openLocationsPanel,openResearchPanel,
    showCreateProjectModal,openImportModal
} from '../../project/actions';


const MobileToolbar = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [sidebarOpened,setSidebarOpened] = useState(false);
    const [projectsSidebarOpened, setProjectsSidebarOpened] = useState(false);
    const [collabSidebarOpened, setCollabSidebarOpened] = useState(false);
    const [settingsSidebarOpened, setSettingsSidebarOpened] = useState(false);

    const [chapterSidebarOpened,setChapterSidebarOpened] = useState(false);
    const [active,setActive] = useState('');
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectChapter = useSelector(state => state.projects.openProjectChapter);
    const openProjectSharedEdit = useSelector( state => state.projects.openProjectSharedEdit);
    const allProjects = useSelector(state => state.projects.all);

    const chapterList = openProject.chapters ? openProject.chapters.map( (chapter,index) => {
        const titleStyle = (openProjectChapter._id === chapter._id) ? {fontWeight:'bold'} : {};
        return (
        <Menu.Item key={chapter._id} style={titleStyle} onClick={() => {
            dispatch(openChapter(chapter._id));
            setChapterSidebarOpened(false);
        }}>
            Chapter {index+1}
        </Menu.Item>
        )
    }) : null;

    const newProjectMenuItem = ((allProjects.length === 0) || (hasPremium(user))) ? (
        <Menu.Item 
            content='New Project'
            icon='plus circle'
            onClick={() => dispatch(showCreateProjectModal())} />
    ) : (
        <Menu.Item 
            as={Link}
            to='/getpremium'
            ><Icon name='plus circle'/>&nbsp;New Project</Menu.Item>
    );

    const importProjectMenuItem = ((allProjects.length === 0) || (hasPremium(user))) ? (
        <Menu.Item 
            content='Import Project'
            icon='upload'
            color='teal' 
            onClick={() => dispatch(openImportModal())} />
    ) : (
        <Menu.Item 
            as={Link}
            to='/getpremium'
            icon
            ><Icon name='upload'/>&nbsp;Import Project</Menu.Item>
    );    

    const getPremiumItem = !hasPremium(user) ? (
        <Menu.Item name="Get Premium" as={Link} to='/getpremium'/>
    ) : null;

    return (
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSidebarOpened(false)}
                vertical
                visible={sidebarOpened}
            >                
                {getPremiumItem}
                <Menu.Item name="Projects" 
                    onClick={() => setProjectsSidebarOpened(true)}/>
                <Menu.Item name="Community" 
                    onClick={() => setCollabSidebarOpened(true)}/>
                <Menu.Item name="Settings" 
                    disabled={!hasPremium(user)} 
                    onClick={() => setSettingsSidebarOpened(true)}/>
                <Showable showIf={props.showProjectMenu}>
                    <Menu.Item onClick={() => {
                        setSidebarOpened(false);
                        dispatch(updateProject({project: openProject}))}
                        }><Icon name='cloud upload'/> Save to cloud</Menu.Item>
                    <Menu.Item
                        active={active === 'Chapters'}
                        onClick={() => {
                            setActive('Chapters');
                            setChapterSidebarOpened(true);
                            setSidebarOpened(false);
                            }}><Icon name='book'/>Chapters</Menu.Item>
                    <Menu.Item 
                        active={active === 'Research'}
                        disabled={openProjectSharedEdit}
                        onClick={() => {
                            setActive('Research');
                            dispatch(openResearchPanel,
                                showCreateProjectModal(openProject._id));
                            setSidebarOpened(false);
                            }}><Icon name='flask'/>Research
                    </Menu.Item>
                    <Menu.Item 
                        active={active === 'Characters'}
                        disabled={openProjectSharedEdit}
                        onClick={() => {
                            setActive('Characters');
                            dispatch(openCharactersPanel(openProject._id));
                            setSidebarOpened(false);
                            }}><Icon name='users'/>Characters
                    </Menu.Item>
                    <Menu.Item
                        active={active === 'Locations'}
                        disabled={openProjectSharedEdit}
                        onClick={() => {
                            setActive('Locations');
                            dispatch(openLocationsPanel(openProject._id));
                            setSidebarOpened(false);
                            }}
                        ><Icon name='globe'/> Locations</Menu.Item>
                    <Menu.Item
                        active={active === 'Timeline'}
                        disabled={openProjectSharedEdit}
                        onClick={() => {
                            setActive('Timeline');
                            dispatch(openTimeline(openProject._id));
                            setSidebarOpened(false);
                            }}
                    ><Icon name='calendar'/> Timeline</Menu.Item>

                </Showable>
            </Sidebar>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setProjectsSidebarOpened(false)}
                vertical
                visible={projectsSidebarOpened}>
                    {newProjectMenuItem}
                    <Dropdown.Item as={Link} to='/'>My Projects</Dropdown.Item>
                    <Dropdown.Item as={Link} to='/projects/others'>Other's Projects</Dropdown.Item>
                    {importProjectMenuItem}
            </Sidebar>

            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setCollabSidebarOpened(false)}
                vertical
                visible={collabSidebarOpened}>
                        <Dropdown.Item as={Link} to='/thoughts'>Timeline</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/thoughts/public'>Search</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/thoughts/user'>My Thoughts</Dropdown.Item>                        
                        <Dropdown.Item as={Link} to='/following'>Following</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/followers'>Followers</Dropdown.Item> 
                        <Dropdown.Item as={Link} to='/contacts'>Contacts</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/inbox'>Messages</Dropdown.Item>
                </Sidebar>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSettingsSidebarOpened(false)}
                vertical
                visible={settingsSidebarOpened}>
                    <Dropdown.Item as={Link} to='/settings'>Settings</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(openFeedbackModal())}>Support</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(unauthenticate())}>Sign Out</Dropdown.Item>
            </Sidebar>

            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setChapterSidebarOpened(false)}
                vertical
                visible={chapterSidebarOpened}
            >
                <Menu.Item disabled={openProjectSharedEdit}
                    onClick={() => {dispatch(createChapter(openProject._id))}}>
                        <Icon name='plus circle'/>Add Chapter</Menu.Item>
                <Menu.Item disabled={openProjectSharedEdit} 
                    onClick={() => {dispatch(openChaptersPanel(openProject._id))}}>
                        <Icon name='setting'/>Manage Chapters</Menu.Item>
                {chapterList}
            </Sidebar>

            <div style={{ margin:0}}>
              <Menu inverted pointing secondary size='large' style={{backgroundColor:'black'}}>
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item>
                    <strong>C e t a c e a n</strong>
                </Menu.Item>
              </Menu>
              </div>
            <div style={{ minHeight: '100vh'}}>
                {props.children}
            </div>
        </Responsive>
    );
};

const DesktopToolbar = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const unread = useSelector(state => state.inbox.unread);
    const pendingRequests = useSelector(state => state.contacts.pendingRequests);
    const allProjects = useSelector(state => state.projects.all);
    const [fixed,setFixed] = useState(false);

    const getPremiumItem = !hasPremium(user) ? (
        <Menu.Item name="Get Premium" as={Link} to='/getpremium'/>
    ) : null;
    const avatar = user.avatar ? user.avatar : '/img/default-persona.png';

    const newProjectMenuItem = ((allProjects.length === 0) || (hasPremium(user))) ? (
        <Menu.Item 
            content='New Project'
            icon='plus circle'
            onClick={() => dispatch(showCreateProjectModal())} />
    ) : (
        <Menu.Item 
            as={Link}
            to='/getpremium'
            ><Icon name='plus circle'/>&nbsp;New Project</Menu.Item>
    );

    const importProjectMenuItem = ((allProjects.length === 0) || (hasPremium(user))) ? (
        <Menu.Item 
            content='Import Project'
            icon='upload'
            color='teal' 
            onClick={() => dispatch(openImportModal())} />
    ) : (
        <Menu.Item 
            as={Link}
            to='/getpremium'
            icon
            ><Icon name='upload'/>&nbsp;Import Project</Menu.Item>
    );    
    return (
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onTopPassed={() => setFixed(true)}
          onTopPassedReverse={() => setFixed(false)}
        >
            <div>
        <div style={{
            background:'black',
            color:'white',
            width: '100%',
            height: '50px',
            lineHeight: '50px',
            display: 'inline-block'
            }}>
            <Menu inverted fixed={fixed ? 'top' : null} style={{backgroundColor:'black'}}>
                <Menu.Item header style={{paddingLeft:'1em',paddingRight:'1em',fontWeight:'bold'}}>C e t a c e a n</Menu.Item>
                {getPremiumItem}
                <Menu.Item name="Projects">
                        <Dropdown text="Projects" pointing>
                            <Dropdown.Menu>
                                <Dropdown.Header />
                                {newProjectMenuItem}
                                <Dropdown.Item as={Link} to='/'><Icon name='folder'/>My Projects</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/projects/others'><Icon name='reply'/>Other's Projects</Dropdown.Item>
                                {importProjectMenuItem}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                <Menu.Item name="Community">
                    <Showable showIf={(unread > 0)}>
                        <Label color='orange' horizontal>
                            {unread}
                        </Label>
                    </Showable>
                    <Showable showIf={(pendingRequests > 0)}>
                        <Label color='teal' horizontal>
                            {pendingRequests}
                        </Label>
                    </Showable>
                    <Dropdown text="Community (BETA)" pointing>
                        <Dropdown.Menu>
                            <Dropdown.Header />
                            <Dropdown.Item as={Link} to='/thoughts'><Icon name='comments'/>Timeline</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/thoughts/public'><Icon name='search'/>Search</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/thoughts/user'><Icon name='comment'/>My Thoughts</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/following'><Icon name='star'/> Following</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/followers'><Icon name='heart'/> Followers</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/contacts'><Icon name='users'/>
                                <Showable showIf={(pendingRequests > 0)}>
                                    <Label color='teal' horizontal>
                                        {pendingRequests}
                                    </Label>
                                </Showable>
                                Contacts</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/inbox'><Icon name='mail'/> 
                                <Showable showIf={(unread > 0)}>
                                    <Label color='orange' horizontal>
                                        {unread}
                                    </Label>
                                </Showable>
                                Messages</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
                <Menu.Item name="Latest News">
                    <Dropdown text="Latest News" pointing>
                        <Dropdown.Menu>
                            <Dropdown.Header />
                            <Dropdown.Item as={Link} to='/blog'><Icon name='keyboard'/>Blog</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/faq'><Icon name='info circle'/>FAQ</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
                <Showable showIf={isAdmin(user)}>
                    <Menu.Item name="Admin">
                        <Dropdown text="Admin" pointing>
                            <Dropdown.Menu>
                                <Dropdown.Header />
                                <Dropdown.Item as={Link} to='/admin/codes'>Codes</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/motd'>MotD</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/broadcast'>Broadcast</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/blog'>Blog</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/users'>Users</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/analytics'>Analytics</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/referrals/ad'>Ad Referrals</Dropdown.Item>
                                <Dropdown.Item as={Link} to='/admin/activity'>Activity Monitor</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Showable>
                <Menu.Item name={user.name} position='right'>
                    <Dropdown text={user.name} pointing direction='left' icon={(<Image style={{ marginLeft:'0.25em'}} src={avatar} avatar />)}>
                        <Dropdown.Menu>
                            <Dropdown.Header />
                            <Dropdown.Item as={Link} to='/settings'><Icon name='settings'/> Settings</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(openFeedbackModal())}><Icon name='help'/>Support</Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item onClick={() => dispatch(unauthenticate())}><Icon name='log out'/> Sign Out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
            </Menu>
        </div>
            {props.children}
        </div>
        </Visibility>
        </Responsive>
    );
}

class Toolbar extends React.Component {
    componentDidMount() {
        this.props.fetchUnread();
        this.timer = setInterval(()=> this.props.fetchUnread(), 30000);
      }
      
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null; // here...
    }
    render() {
        return (
            <div>
                <FeedbackModal />
                <NewBookModal/>
                <ImportModal />
                <MobileToolbar {...this.props}>
                    {this.props.children}
                </MobileToolbar>
                <DesktopToolbar>
                    {this.props.children}
                </DesktopToolbar>
            </div>
        );
    }
}

export default connect(
    state => ({unread: state.inbox.unread}),
    {fetchUnread}
)(Toolbar);