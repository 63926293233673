import * as actionType from './constants';

export const dismissProjectError = () => ({
    type: actionType.DISMISS_PROJECT_ERROR
});

export const showCreateProjectModal = () => ({
    type: actionType.SHOW_CREATE_PROJECT_MODAL
});

export const hideCreateProjectModal = () => ({
    type: actionType.HIDE_CREATE_PROJECT_MODAL
});

export const openProject = (projectId,sharedEdit) => ({
    type: actionType.OPEN_PROJECT,
    payload:projectId,
    sharedEdit
});

export const openProjectSuccess = (payload,isDirty) => ({
    type: actionType.OPEN_PROJECT_SUCCESS,
    payload,
    isDirty
});

export const openProjectError = (payload) => ({
    type: actionType.OPEN_PROJECT_ERROR,
    payload
});

export const closeProject = () => ({
    type: actionType.CLOSE_PROJECT
});

export const readProject = (projectId) => ({
    type: actionType.READ_PROJECT_SEND,
    payload:projectId
});

export const readProjectSuccess = (payload) => ({
    type: actionType.READ_PROJECT_SUCCESS,
    payload
});

export const readProjectError = (payload) => ({
    type: actionType.READ_PROJECT_ERROR,
    payload
});


export const createProject = payload => ({
    type: actionType.CREATE_PROJECT_SEND,
    payload
});

export const createProjectSuccess = payload => {
    return {
    type: actionType.CREATE_PROJECT_SUCCESS,
    payload
}};

export const createProjectError = payload => ({
    type: actionType.CREATE_PROJECT_ERROR,
    payload
});

export const fetchProjects = payload => ({
    type: actionType.FETCH_PROJECTS_SEND,
    payload
});

export const fetchProjectsSuccess = payload => {
    return {
    type: actionType.FETCH_PROJECTS_SUCCESS,
    payload
}};

export const fetchProjectsError = payload => ({
    type: actionType.FETCH_PROJECTS_ERROR,
    payload
});

export const deleteProject = payload => ({
    type: actionType.DELETE_PROJECT_SEND,
    payload
});

export const deleteProjectSuccess = payload => {
    return {
    type: actionType.DELETE_PROJECT_SUCCESS,
    payload
}};

export const deleteProjectError = payload => ({
    type: actionType.DELETE_PROJECT_ERROR,
    payload
});

export const updateProject = payload => ({
    type: actionType.UPDATE_PROJECT_SEND,
    payload
});

export const updateProjectSuccess = payload => {
    return {
    type: actionType.UPDATE_PROJECT_SUCCESS,
    payload
}};

export const updateProjectError = payload => ({
    type: actionType.UPDATE_PROJECT_ERROR,
    payload
});

export const autoSaveProject = payload => ({
    type: actionType.AUTO_SAVE_PROJECT_SEND,
    payload
});

export const autoSaveProjectSuccess = payload => {
    return {
    type: actionType.AUTO_SAVE_PROJECT_SUCCESS,
    payload
}};

export const autoSaveProjectError = payload => ({
    type: actionType.AUTO_SAVE_PROJECT_ERROR,
    payload
});

export const offlineMode = () => ({
    type: actionType.OFFLINE_MODE
});

export const updateLocalScene = (value) => ({
    type: actionType.UPDATE_LOCAL_SCENE,
    payload: value
});

export const showCreateChapterModal = () => ({
    type: actionType.SHOW_CREATE_CHAPTER_MODAL
});

export const hideCreateChapterModal = () => ({
    type: actionType.HIDE_CREATE_CHAPTER_MODAL
});

export const addChapter = (projectId,payload) => ({
    type: actionType.ADD_CHAPTER,
    projectId,
    payload
});

export const createChapter = (projectId,payload) => ({
    type: actionType.CREATE_CHAPTER_SEND,
    projectId,
    payload
});

export const createChapterSuccess = payload => {
    return {
    type: actionType.CREATE_CHAPTER_SUCCESS,
    payload
}};

export const createChapterError = payload => ({
    type: actionType.CREATE_CHAPTER_ERROR,
    payload
});

export const openChapter = (chapterId) => ({
    type: actionType.OPEN_CHAPTER,
    chapterId
});

export const updateLocalChapter = (value) => ({
    type: actionType.UPDATE_LOCAL_CHAPTER,
    payload: value
});

export const openChaptersPanel = () => ({
    type: actionType.OPEN_CHAPTERS_PANEL
});

export const addScene = (projectId,chapterId,payload) => ({
    type: actionType.ADD_SCENE,
    projectId,
    chapterId,
    payload
});

export const createScene = (projectId,chapterId,payload) => ({
    type: actionType.CREATE_SCENE_SEND,
    projectId,
    chapterId,
    payload
});

export const createSceneSuccess = payload => {
    return {
    type: actionType.CREATE_SCENE_SUCCESS,
    payload
}};

export const createSceneError = payload => ({
    type: actionType.CREATE_SCENE_ERROR,
    payload
});

export const openScene = (chapterId,sceneId) => ({
    type: actionType.OPEN_SCENE,
    chapterId,
    sceneId
});

export const showSceneRenameModal = (chapterId,sceneId,initialValue) => ({
    type: actionType.SHOW_SCENE_RENAME_MODAL,
    chapterId,
    sceneId,
    initialValue
});

export const hideSceneRenameModal = () => ({
    type: actionType.HIDE_SCENE_RENAME_MODAL
});

export const renameLocalScene = (value) => ({
    type: actionType.RENAME_LOCAL_SCENE,
    value
});

export const showChapterRenameModal = (chapterId,initialValue) => ({
    type: actionType.SHOW_CHAPTER_RENAME_MODAL,
    chapterId,
    initialValue
});

export const hideChapterRenameModal = () => ({
    type: actionType.HIDE_CHAPTER_RENAME_MODAL
});

export const renameLocalChapter = (value) => ({
    type: actionType.RENAME_LOCAL_CHAPTER,
    value
});

export const moveSceneUp = (chapterId,sceneId) => ({
    type: actionType.MOVE_SCENE_UP,
    chapterId,
    sceneId
});
export const moveSceneDown = (chapterId,sceneId) => ({
    type: actionType.MOVE_SCENE_DOWN,
    chapterId,
    sceneId
});
export const moveChapterUp = (chapterId) => ({
    type: actionType.MOVE_CHAPTER_UP,
    chapterId
});
export const moveChapterDown = (chapterId) => ({
    type: actionType.MOVE_CHAPTER_DOWN,
    chapterId
});
export const moveChapter = (sourceIndex,destinationIndex,chapterId) => ({
    type: actionType.MOVE_CHAPTER,
    sourceIndex,
    destinationIndex,
    chapterId
});

export const showRechapterSceneModal = (sceneId,initialValue) => ({
    type: actionType.SHOW_RECHAPTER_SCENE_MODAL,
    sceneId,
    initialValue
});

export const hideRechapterSceneModal = () => ({
    type: actionType.HIDE_RECHAPTER_SCENE_MODAL
});

export const rechapterLocalScene = (value) => ({
    type: actionType.RECHAPTER_LOCAL_SCENE,
    value
});

export const showDeleteSceneModal = (projectId,chapterId,sceneId) => ({
    type: actionType.SHOW_DELETE_SCENE_MODAL,
    sceneId,
    chapterId,
    projectId
});

export const hideDeleteSceneModal = () => ({
    type: actionType.HIDE_DELETE_SCENE_MODAL
});

export const removeScene = (projectId,chapterId,sceneId) => ({
    type: actionType.REMOVE_SCENE,
    projectId,
    chapterId,
    sceneId
});

export const deleteScene = (projectId,chapterId,sceneId) => ({
    type: actionType.DELETE_SCENE_SEND,
    projectId,
    chapterId,
    sceneId
});

export const deleteSceneSuccess = payload => {
    return {
    type: actionType.DELETE_SCENE_SUCCESS,
    payload
}};

export const deleteSceneError = payload => ({
    type: actionType.DELETE_SCENE_ERROR,
    payload
});


export const showDeleteChapterModal = (projectId,chapterId) => ({
    type: actionType.SHOW_DELETE_CHAPTER_MODAL,
    chapterId,
    projectId
});

export const hideDeleteChapterModal = () => ({
    type: actionType.HIDE_DELETE_CHAPTER_MODAL
});

export const removeChapter = (projectId,chapterId) => ({
    type: actionType.REMOVE_CHAPTER,
    projectId,
    chapterId
});

export const deleteChapter = (projectId,chapterId) => ({
    type: actionType.DELETE_CHAPTER_SEND,
    projectId,
    chapterId
});

export const deleteChapterSuccess = payload => {
    return {
    type: actionType.DELETE_CHAPTER_SUCCESS,
    payload
}};

export const deleteChapterError = payload => ({
    type: actionType.DELETE_CHAPTER_ERROR,
    payload
});

export const addCharacter = (projectId,payload) => ({
    type: actionType.ADD_CHARACTER,
    projectId,
    payload
});

export const createCharacter = (projectId,payload) => ({
    type: actionType.CREATE_CHARACTER_SEND,
    projectId,
    payload
});

export const createCharacterSuccess = payload => {
    return {
    type: actionType.CREATE_CHARACTER_SUCCESS,
    payload
}};

export const createCharacterError = payload => ({
    type: actionType.CREATE_CHARACTER_ERROR,
    payload
});

export const showDeleteRelationshipModal = (projectId,relationshipId) => ({
    type: actionType.SHOW_DELETE_RELATIONSHIP_MODAL,
    relationshipId,
    projectId
});

export const hideDeleteRelationshipModal = () => ({
    type: actionType.HIDE_DELETE_RELATIONSHIP_MODAL
});

export const removeRelationship = (projectId,relationshipId) => ({
    type: actionType.REMOVE_RELATIONSHIP,
    projectId,
    relationshipId
});

export const deleteRelationship = (projectId,characterId,relationshipId) => ({
    type: actionType.DELETE_RELATIONSHIP_SEND,
    projectId,
    characterId,
    relationshipId
});

export const deleteRelationshipSuccess = payload => {
    return {
    type: actionType.DELETE_RELATIONSHIP_SUCCESS,
    payload
}};

export const deleteRelationshipError = payload => ({
    type: actionType.DELETE_RELATIONSHIP_ERROR,
    payload
});

export const openRelationship = (relationshipId) => ({
    type: actionType.OPEN_RELATIONSHIP,
    relationshipId
});

export const closeRelationship = () => ({
    type: actionType.CLOSE_RELATIONSHIP
});

export const addRelationship = (payload) => ({
    type: actionType.ADD_RELATIONSHIP,
    payload
});

export const showNewRelationshipModal = () => ({
    type: actionType.SHOW_NEW_RELATIONSHIP_MODAL
})
export const hideNewRelationshipModal = () => ({
    type: actionType.HIDE_NEW_RELATIONSHIP_MODAL
})

export const createRelationship = (projectId,characterId,payload) => ({
    type: actionType.CREATE_RELATIONSHIP_SEND,
    projectId,
    characterId,
    payload
});

export const createRelationshipSuccess = payload => {
    return {
    type: actionType.CREATE_RELATIONSHIP_SUCCESS,
    payload
}};

export const createRelationshipError = payload => ({
    type: actionType.CREATE_RELATIONSHIP_ERROR,
    payload
});

export const openCharactersPanel = () => ({
    type: actionType.OPEN_CHARACTERS_PANEL
});

export const openCharacter = (characterId) => ({
    type: actionType.OPEN_CHARACTER,
    characterId
});

export const closeCharacter = () => ({
    type: actionType.CLOSE_CHARACTER
});

export const updateLocalCharacter = (field,value) => ({
    type: actionType.UPDATE_LOCAL_CHARACTER,
    field,
    value
});

export const updateLocalRelationship = (field,value) => ({
    type: actionType.UPDATE_LOCAL_RELATIONSHIP,
    field,
    value 
});

export const showDeleteCharacterModal = (projectId,characterId) => ({
    type: actionType.SHOW_DELETE_CHARACTER_MODAL,
    characterId,
    projectId
});

export const hideDeleteCharacterModal = () => ({
    type: actionType.HIDE_DELETE_CHARACTER_MODAL
});

export const removeCharacter = (projectId,characterId) => ({
    type: actionType.REMOVE_CHARACTER,
    projectId,
    characterId
});

export const deleteCharacter = (projectId,characterId) => ({
    type: actionType.DELETE_CHARACTER_SEND,
    projectId,
    characterId
});

export const deleteCharacterSuccess = payload => {
    return {
    type: actionType.DELETE_CHARACTER_SUCCESS,
    payload
}};

export const deleteCharacterError = payload => ({
    type: actionType.DELETE_CHARACTER_ERROR,
    payload
});

export const addLocation = (projectId,payload) => ({
    type: actionType.ADD_LOCATION,
    projectId,
    payload
});

export const createLocation = (projectId,payload) => ({
    type: actionType.CREATE_LOCATION_SEND,
    projectId,
    payload
});

export const createLocationSuccess = payload => {
    return {
    type: actionType.CREATE_LOCATION_SUCCESS,
    payload
}};

export const createLocationError = payload => ({
    type: actionType.CREATE_LOCATION_ERROR,
    payload
});

export const showDeleteLocationModal = (projectId,locationId) => ({
    type: actionType.SHOW_DELETE_LOCATION_MODAL,
    locationId,
    projectId
});

export const hideDeleteLocationModal = () => ({
    type: actionType.HIDE_DELETE_LOCATION_MODAL
});

export const removeLocation = (projectId,locationId) => ({
    type: actionType.REMOVE_LOCATION,
    projectId,
    locationId
});

export const deleteLocation = (projectId,locationId) => ({
    type: actionType.DELETE_LOCATION_SEND,
    projectId,
    locationId
});

export const deleteLocationSuccess = payload => {
    return {
    type: actionType.DELETE_LOCATION_SUCCESS,
    payload
}};

export const deleteLocationError = payload => ({
    type: actionType.DELETE_LOCATION_ERROR,
    payload
});

export const openLocationsPanel = () => ({
    type: actionType.OPEN_LOCATIONS_PANEL
});

export const openLocation = (locationId) => ({
    type: actionType.OPEN_LOCATION,
    locationId
});

export const closeLocation = () => ({
    type: actionType.CLOSE_LOCATION
});

export const updateLocalLocation = (field,value) => ({
    type: actionType.UPDATE_LOCAL_LOCATION,
    field,
    value
});

export const showCreateEventModal = () => ({
    type: actionType.SHOW_CREATE_EVENT_MODAL
});

export const hideCreateEventModal = () => ({
    type: actionType.HIDE_CREATE_EVENT_MODAL
});

export const addEvent = (projectId,payload) => ({
    type: actionType.ADD_EVENT,
    projectId,
    payload
});

export const createEvent = (projectId,payload) => ({
    type: actionType.CREATE_EVENT_SEND,
    projectId,
    payload
});

export const createEventSuccess = payload => {
    return {
    type: actionType.CREATE_EVENT_SUCCESS,
    payload
}};

export const createEventError = payload => ({
    type: actionType.CREATE_EVENT_ERROR,
    payload
});

export const showDeleteEventModal = (projectId,eventId) => ({
    type: actionType.SHOW_DELETE_EVENT_MODAL,
    eventId,
    projectId
});

export const hideDeleteEventModal = () => ({
    type: actionType.HIDE_DELETE_EVENT_MODAL
});

export const removeEvent = (projectId,eventId) => ({
    type: actionType.REMOVE_EVENT,
    projectId,
    eventId
});

export const deleteEvent = (projectId,eventId) => ({
    type: actionType.DELETE_EVENT_SEND,
    projectId,
    eventId
});

export const deleteEventSuccess = payload => {
    return {
    type: actionType.DELETE_EVENT_SUCCESS,
    payload
}};

export const deleteEventError = payload => ({
    type: actionType.DELETE_EVENT_ERROR,
    payload
});

export const openEvent = (eventId) => ({
    type: actionType.OPEN_EVENT,
    eventId
});

export const closeEvent = () => ({
    type: actionType.CLOSE_EVENT
});

export const updateLocalEvent = (field,value) => ({
    type: actionType.UPDATE_LOCAL_EVENT,
    field,
    value
});

export const openTimeline = () => ({
    type: actionType.OPEN_TIMELINE
});

export const moveEventEarlier = (eventId) => ({
    type: actionType.MOVE_EVENT_EARLIER,
    eventId
});

export const moveEventLater = (eventId) => ({
    type: actionType.MOVE_EVENT_LATER,
    eventId
});

export const openProgressPanel = () => ({
    type: actionType.OPEN_PROGRESS_PANEL
});

export const setWordCountGoal = (value) => ({
    type: actionType.SET_WORD_COUNT_GOAL,
    value
});

export const showExportModal = (projectId) => ({
    type: actionType.SHOW_EXPORT_MODAL,
    projectId
});

export const hideExportModal = () => ({
    type: actionType.HIDE_EXPORT_MODAL
});

export const fetchProject = projectId => ({
    type: actionType.FETCH_PROJECT_SEND,
    projectId
});

export const fetchProjectSuccess = payload => {
    return {
    type: actionType.FETCH_PROJECT_SUCCESS,
    payload
}};

export const fetchProjectError = payload => ({
    type: actionType.FETCH_PROJECT_ERROR,
    payload
});

export const showProjectRenameModal = (projectId,initialValue) => ({
    type: actionType.SHOW_PROJECT_RENAME_MODAL,
    projectId,
    initialValue
});

export const hideProjectRenameModal = () => ({
    type: actionType.HIDE_PROJECT_RENAME_MODAL
});

export const renameProject = (payload) => ({
    type: actionType.RENAME_PROJECT_SEND,
    payload
});

export const renameProjectSuccess = (payload) => ({
    type: actionType.RENAME_PROJECT_SUCCESS,
    payload
});

export const renameProjectError = (payload) => ({
    type: actionType.RENAME_PROJECT_ERROR,
    payload
});

export const openResearchPanel = () => ({
    type: actionType.OPEN_RESEARCH_PANEL
});

export const addResearch = (projectType,url) => ({
    type: actionType.ADD_RESEARCH,
    projectType,
    url
});

export const openResearchNotes = (researchId) => ({
    type: actionType.OPEN_RESEARCH_NOTES,
    researchId
});

export const updateLocalResearchNotes = (value) => ({
    type: actionType.UPDATE_LOCAL_RESEARCH_NOTES,
    payload: value
});

export const showDeleteResearchModal = (projectId,researchId) => ({
    type: actionType.SHOW_DELETE_RESEARCH_MODAL,
    researchId,
    projectId
});

export const hideDeleteResearchModal = () => ({
    type: actionType.HIDE_DELETE_RESEARCH_MODAL
});

export const removeResearch = (projectId,researchId) => ({
    type: actionType.REMOVE_RESEARCH,
    projectId,
    researchId
});

export const showResearchRenameModal = (researchId,initialValue) => ({
    type: actionType.SHOW_RESEARCH_RENAME_MODAL,
    researchId,
    initialValue
});

export const hideResearchRenameModal = () => ({
    type: actionType.HIDE_RESEARCH_RENAME_MODAL
});

export const renameLocalResearch = (value) => ({
    type: actionType.RENAME_LOCAL_RESEARCH,
    value
});


export const showNewReasearchLinkModal = (linkType) => ({
    type: actionType.SHOW_NEW_RESEARCH_LINK_MODAL,
    linkType
});

export const hideNewResearchLinkModal = () => ({
    type: actionType.HIDE_NEW_RESEARCH_LINK_MODAL
});

export const openCharacterBreakout = (character) => ({ 
    type: actionType.OPEN_CHARACTER_BREAKOUT,
    character
});

export const closeCharacterBreakout = () => ({ 
    type: actionType.CLOSE_CHARACTER_BREAKOUT
});

export const openResearchNotesBreakout = (research) => ({ 
    type: actionType.OPEN_RESEARCH_NOTES_BREAKOUT,
    research
});

export const closeResearchNotesBreakout = () => ({ 
    type: actionType.CLOSE_RESEARCH_NOTES_BREAKOUT
});

export const openLocationBreakout = (location) => ({ 
    type: actionType.OPEN_LOCATION_BREAKOUT,
    location
});

export const closeLocationBreakout = () => ({ 
    type: actionType.CLOSE_LOCATION_BREAKOUT
});

export const openImportModal = () => ({ 
    type: actionType.OPEN_IMPORT_MODAL
});

export const closeImportModal = () => ({ 
    type: actionType.CLOSE_IMPORT_MODAL
});

export const importDoc = (formData) => ({
    type: actionType.IMPORT_DOC_SEND,
    formData
});

export const importDocSuccess = payload => {
    return {
    type: actionType.IMPORT_DOC_SUCCESS,
    payload
}};

export const importDocError = payload => ({
    type: actionType.IMPORT_DOC_ERROR,
    payload
});

export const showMainSideBar = () => ({
    type: actionType.SHOW_MAIN_SIDEBAR
});

export const hideMainSideBar = () => ({
    type: actionType.HIDE_MAIN_SIDEBAR
});

export const hideMobileMainSideBar = () => ({
    type: actionType.HIDE_MOBILE_MAIN_SIDEBAR
});

export const showContextSideBar = () => ({
    type: actionType.SHOW_CONTEXT_SIDEBAR
});

export const hideContextSideBar = () => ({
    type: actionType.HIDE_CONTEXT_SIDEBAR
});

export const openChaptersSideBar = () => ({
    type: actionType.OPEN_CHAPTERS_SIDEBAR
});

export const openReadChaptersSideBar = () => ({
    type: actionType.OPEN_READ_CHAPTERS_SIDEBAR
});

export const openEditChaptersSideBar = () => ({
    type: actionType.OPEN_EDIT_CHAPTERS_SIDEBAR
});

export const openDictionarySideBar = () => ({
    type: actionType.OPEN_DICTIONARY_SIDEBAR
});

export const openProgressSideBar = () => ({
    type: actionType.OPEN_PROGRESS_SIDEBAR
});

export const showProjectViewModal = () => ({
    type: actionType.SHOW_PROJECT_VIEW_MODAL
});

export const hideProjectViewModal = () => ({
    type: actionType.HIDE_PROJECT_VIEW_MODAL
});

export const updateLocalProjectView = (view) => ({
    type: actionType.UPDATE_LOCAL_PROJECT_VIEW,
    view
});

export const openIdeaBoard = () => ({
    type: actionType.OPEN_IDEA_BOARD
});

export const openResearchNoteModal = (research) => ({
    type: actionType.OPEN_RESEARCH_NOTE_MODAL,
    research
});

export const closeResearchNoteModal = () => ({
    type: actionType.CLOSE_RESEARCH_NOTE_MODAL
});

export const updateLocalResearch = (researchId,field,value) => ({
    type: actionType.UPDATE_LOCAL_RESEARCH,
    researchId,
    field,
    value
});

export const openCharactersSideBar = () => ({
    type: actionType.OPEN_CHARACTERS_SIDEBAR
});

export const openLocationsSideBar = () => ({
    type: actionType.OPEN_LOCATIONS_SIDEBAR
});

export const openOutline = () => ({
    type: actionType.OPEN_OUTLINE
});

export const addPlotPoint = (actId) => ({
    type: actionType.ADD_PLOTPOINT,
    actId
});

export const removePlotPoint = (actId,plotpointId) => ({
    type: actionType.REMOVE_PLOTPOINT,
    actId,
    plotpointId
});

export const applyTemplate = (project) => ({
    type: actionType.APPLY_TEMPLATE,
    project
});

export const showEditPlotPointModal = (plotpoint) => ({
    type: actionType.SHOW_EDIT_PLOTPOINT_MODAL,
    plotpoint
});

export const hideEditPlotPointModal = () => ({
    type: actionType.HIDE_EDIT_PLOTPOINT_MODAL
});

export const updateLocalPlotPoint = (plotpointId,field,value) => ({
    type: actionType.UPDATE_LOCAL_PLOTPOINT,
    plotpointId,
    field,
    value
});

export const movePlotPoint = (sourceIndex,sourceActIndex,destinationIndex,destinationActIndex,plotpointId) => ({
    type: actionType.MOVE_PLOTPOINT,
    sourceIndex,
    sourceActIndex,
    destinationIndex,
    destinationActIndex,
    plotpointId
});

export const showDeletePlotPointModal = (actId,plotpointId) => ({
    type: actionType.SHOW_DELETE_PLOTPOINT_MODAL,
    plotpointId,
    actId
});

export const hideDeletePlotPointModal = () => ({
    type: actionType.HIDE_DELETE_PLOTPOINT_MODAL
});

export const addAct = () => ({
    type: actionType.ADD_ACT
});

export const resetOutline = () => ({
    type: actionType.RESET_OUTLINE
});

export const openResearchSideBar = () => ({
    type: actionType.OPEN_RESEARCH_SIDEBAR
});

export const openOutlineSideBar = () => ({
    type: actionType.OPEN_OUTLINE_SIDEBAR
});

export const openRevision = () => ({
    type: actionType.OPEN_REVISION
});

export const readBook = (projectId,previousProjectId) => ({
    type: actionType.READ_BOOK,
    payload:projectId,
    previousProjectId
});

export const readBookSuccess = (payload,isDirty) => ({
    type: actionType.READ_BOOK_SUCCESS,
    payload,
    isDirty
});

export const readBookError = (payload) => ({
    type: actionType.READ_BOOK_ERROR,
    payload
});

export const openProjectDiff = (projectId) => ({
    type: actionType.OPEN_PROJECT_DIFF,
    projectId
});

export const openProjectDiffSuccess = (payload) => ({
    type: actionType.OPEN_PROJECT_DIFF_SUCCESS,
    payload
});

export const openProjectDiffError = (payload) => ({
    type: actionType.OPEN_PROJECT_DIFF_ERROR,
    payload
});

export const hideIntroModal = () => ({
    type: actionType.HIDE_INTRO_MODAL
});

export const editBook = (projectId,previousProjectId) => ({
    type: actionType.EDIT_BOOK,
    payload:projectId,
    previousProjectId
});

export const editBookSuccess = (payload,isDirty) => ({
    type: actionType.EDIT_BOOK_SUCCESS,
    payload,
    isDirty
});

export const editBookError = (payload) => ({
    type: actionType.EDIT_BOOK_ERROR,
    payload
});

export const exceededWordCountError = () => ({
    type: actionType.EXCEEDED_WORDCOUNT_ERROR
})

export const dismissWordCountError = () => ({
    type: actionType.DISMISS_WORDCOUNT_ERROR
});

export const toggleGrammarCheck = () => ({
    type: actionType.TOGGLE_GRAMMAR_CHECK
});

export const showProjectViewDefault = (currentView) => ({
    type: actionType.SHOW_PROJECT_VIEW_DEFAULT,
    currentView
});

export const setLineSpacing = (spacing) => ({
    type: actionType.SET_LINE_SPACING,
    spacing
});

export const openEditorSettingsSideBar = () => ({ type: actionType.OPEN_EDITOR_SETTINGS_SIDEBAR });
export const openBrainstormSettingsSideBar = () => ({ type: actionType.OPEN_BRAINSTORM_SETTINGS_SIDEBAR });

export const toggleAutoIndent = () => ({
    type: actionType.TOGGLE_AUTO_INDENT
});

export const setEditorPallet = (backgroundColor,textColor) => ({
    type: actionType.SET_EDITOR_PALLETE,
    backgroundColor,
    textColor
});
export const setResearchPallet = (backgroundColor,textColor) => ({
    type: actionType.SET_RESEARCH_PALLETE,
    backgroundColor,
    textColor
});

export const moveResearch = (sourceIndex,destinationIndex,researchId) => ({
    type: actionType.MOVE_RESEARCH,
    sourceIndex,
    destinationIndex,
    researchId
});

export const resizeResearch = (researchId,size) => ({
    type: actionType.RESIZE_RESEARCH,
    researchId,
    size
});

export const exportEpub = (projectId,projectName) => ({ type: actionType.EXPORT_EPUB_SEND, projectId,projectName});
export const exportEpubSuccess = (payload) => ({ type: actionType.EXPORT_EPUB_SUCCESS, payload});
export const exportEpubError = (payload) => ({ type: actionType.EXPORT_EPUB_ERROR, payload});

export const exportPdf = (projectId,projectName) => ({ type: actionType.EXPORT_PDF_SEND, projectId,projectName});
export const exportPdfSuccess = (payload) => ({ type: actionType.EXPORT_PDF_SUCCESS, payload});
export const exportPdfError = (payload) => ({ type: actionType.EXPORT_PDF_ERROR, payload});

export const createProjectSequel = projectId => ({ type: actionType.CREATE_SEQUEL_SEND, projectId});
export const createProjectSequelSuccess = payload => ({ type: actionType.CREATE_SEQUEL_SUCCESS, payload });
export const createProjectSequelError = payload => ({ type: actionType.CREATE_SEQUEL_ERROR, payload });
