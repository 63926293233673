import React from 'react';
import {useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Button,Modal,Form,Input,Progress,Statistic,Segment,Breadcrumb,Icon} from 'semantic-ui-react';
import {XYPlot,VerticalBarSeries,HorizontalGridLines,XAxis,YAxis,makeWidthFlexible} from 'react-vis';
import {setWordCountGoal} from '../../project/actions';
import {countWordsInProject} from '../../util/wordCount';
import '../../react-vis.css';

const TimelinePanel = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const [showModal, setShowModal] = useState(false);
    const [newGoal,setNewGoal] = useState();
    useEffect( () => { setNewGoal(openProject.wordGoal)} ,[openProject.wordGoal])

    const daysMap = new Map();
    openProject.progress.forEach( progress => {
        // Format date to month/day/year
        const dateName = format(new Date(progress.createDate), 'MM/dd/yy');
        let currentVal = daysMap.get(dateName);
        if(currentVal) {
            if(currentVal < progress.wordCountTotal);
            daysMap.set(dateName,progress.wordCountTotal);
        }
        else {
            daysMap.set(dateName, progress.wordCountTotal);
        }
    });

    let currentWordCount = openProject.wordCount;
    if(projects.isProjectDirty) {
        // Need to include unsaved text
        currentWordCount = countWordsInProject(openProject);
        daysMap.set(format(new Date(), 'MM/dd/yy'),currentWordCount);

    }

    const data = [];
    daysMap.forEach( (value,key,map) => {
        data.push({
            x: key,
            y: value
        });
    });
    const progressPercent = Math.ceil((currentWordCount / openProject.wordGoal) * 100);
    const FlexibleXYPlot = makeWidthFlexible(XYPlot);
    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column'            
        }}>
            <div style={{flexShrink: 0}}>
                <Segment inverted>
                    <Breadcrumb style={{color:'white'}} size='large'>
                        <Breadcrumb.Section>Project</Breadcrumb.Section>
                        <Breadcrumb.Divider style={{ color:'white'}}/>
                        <Breadcrumb.Section active>Progress</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>
            </div>
            <div style={{flexGrow:1,overflowX:'auto'}}>                
                <div style={{margin:'1em',
                    color:'white',
                    
                    padding:'1em'
                }}>
                <Modal open={showModal}>
                    <Modal.Content style={{backgroundColor:'#272727', color:'white'}}>
                        <Form>
                            <Form.Field>
                                <Input 
                                    label='Final Word Count Goal'
                                    value={newGoal}
                                    onChange={(ev) => setNewGoal(ev.target.value)}
                                />
                            </Form.Field>
                        </Form>
                        <p/>
                        <Button color='teal' onClick={() => {
                            dispatch(setWordCountGoal(newGoal));
                            setShowModal(false);
                        }}>Save</Button>
                        <Button onClick={() => setShowModal(false)}>Cancel</Button>
                    </Modal.Content>
                </Modal>

                    <Segment inverted>                    
                    <Statistic.Group>
                        <Statistic color='teal'>
                            <Statistic.Value>{currentWordCount}</Statistic.Value>
                            <Statistic.Label style={{color:'white'}}>Words</Statistic.Label>
                        </Statistic>
                        <Statistic color='teal'>
                            <Statistic.Value>{openProject.wordGoal}</Statistic.Value>
                            <Statistic.Label style={{color:'white'}}>Goal</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                    <p/>
                    <div style={{color:'white',backgroundColor:'#383838'}}>
                        <Progress progress color='teal' indicating percent={progressPercent} />
                    </div>
                    <Button color='teal' onClick={() => setShowModal(true)}><Icon name='chart line'/>Set Goal</Button>
                    <p/>

                    <FlexibleXYPlot height={300} xType="ordinal">
                        <HorizontalGridLines />
                        <XAxis  />
                        <YAxis />                        
                        <VerticalBarSeries data={data} />
                    </FlexibleXYPlot> 
                    </Segment>                   
                </div>
            </div>
        </div>
    );
};

export default TimelinePanel;