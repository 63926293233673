import React from 'react';
import {useSelector} from 'react-redux';
import Toolbar from '../header/Toolbar';
import ManuscriptPanel from './ManuscriptPanel';

const SharedCheckpointViewer = (props) => {
    const openProject = useSelector(state => state.viewer.openProject);
    return (
        <div>
            <Toolbar>
                <ManuscriptPanel key={openProject._id}/>
            </Toolbar>
        </div>
    );
}

export default SharedCheckpointViewer;

