import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Statistic,Button,Icon,Input,Modal,Menu,Header,Progress,Table} from 'semantic-ui-react';
import NewThoughtModal from '../../feed/NewThoughtModal';
import {
    setWordCountGoal,hideContextSideBar
} from '../../../project/actions';
import {openNewThoughtModal} from '../../../feed/actions';
import {countWordsInProject} from '../../../util/wordCount';

const ProgressContext = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const [showModal, setShowModal] = useState(false);
    const [newGoal,setNewGoal] = useState();

    useEffect( () => { setNewGoal(openProject.wordGoal)} ,[openProject.wordGoal])

    const daysMap = new Map();
    openProject.progress.forEach( progress => {
        // Format date to month/day/year
        const dateName = format(new Date(progress.createDate), 'MM/dd/yy');
        let currentVal = daysMap.get(dateName);
        if(currentVal) {
            if(currentVal < progress.wordCountTotal);
            daysMap.set(dateName,progress.wordCountTotal);
        }
        else {
            daysMap.set(dateName, progress.wordCountTotal);
        }
    });

    let currentWordCount = openProject.wordCount;
    if(projects.isProjectDirty) {
        // Need to include unsaved text
        //currentWordCount = countWordsInProject(openProject);
        //daysMap.set(format(new Date(), 'MM/dd/yy'),currentWordCount);

    }
    const progressPercent = Math.ceil((currentWordCount / openProject.wordGoal) * 100);
    const data = [];
    let previousDay = 0;
    daysMap.forEach( (value,key,map) => {
        let dailyCount = value - previousDay;
        if(dailyCount !== 0) {
            data.push(<Table.Row key={key}>
                <Table.Cell>{key}</Table.Cell>
            <Table.Cell>{dailyCount}</Table.Cell>
            </Table.Row>);
        }
        previousDay = value;
    });
    data.reverse();
    return (
        <div>
            <NewThoughtModal showWordCount={true} wordCountProject={openProject} />
            <Modal open={showModal} className='ceta-modal'>
                <Modal.Content style={{backgroundColor:'#272727', color:'white'}}>
                    <Input 
                        fluid
                        type='number'
                        min={0}
                        step={1}
                        label='Final Word Count Goal'
                        value={newGoal}
                        onChange={(ev) => {

                            setNewGoal(ev.target.value);
                        }}
                    />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button color='teal' onClick={() => {
                        dispatch(setWordCountGoal(newGoal));
                        setShowModal(false);
                    }}>Save</Button>
                </Modal.Actions> 
            </Modal>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item >
                            <Header color='teal'>Progress</Header>
                        </Menu.Item>
                        <Menu.Item>
                            <Icon color='teal' name='chart line' size='large'
                                onClick={() => setShowModal(true)}
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>            
            <div style={{ height:'calc(100vh - 50px)', overflowY: 'auto',overflowX: 'hidden'}}>
                <div style={{ margin: '0.5em'}}>
                    <div style={{color:'white',backgroundColor:'#383838'}}>
                        <Progress progress color='teal' indicating percent={progressPercent} />
                    </div>
                    <div>
                        <Statistic.Group size='small'>
                            <Statistic color='teal'>
                                <Statistic.Value>{currentWordCount}</Statistic.Value>
                                <Statistic.Label style={{color:'white'}}>Words</Statistic.Label>
                            </Statistic>
                            <Statistic color='teal'>
                                <Statistic.Value>{openProject.wordGoal}</Statistic.Value>
                                <Statistic.Label style={{color:'white'}}>Goal</Statistic.Label>
                            </Statistic>
                        </Statistic.Group>
                    </div>
                    <div>
                        <Button color='teal' icon onClick={() => dispatch(openNewThoughtModal())}><Icon name='bullhorn'/>Share Word Count</Button>
                    </div>
                    <div style={{ marginTop: '2em'}}>
                        <Table inverted>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Day</Table.HeaderCell>
                                    <Table.HeaderCell>Words</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProgressContext;