import React from 'react';
import {connect} from 'react-redux';
import {Table,Dimmer,Loader,Button,Modal,Form,Input,Icon, Dropdown} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {fetchCodes,createCode,deleteCode,sendCode} from '../../admin/actions';

class CodesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            newCodeRecipient:'',
            newCodeRole:'',
            showNewCodeModal: false
        };
    }

    openModal = () => {
        this.setState({
            ...this.state,
            newCodeRecipient:'',
            showNewCodeModal: true
        })
    }
    submitCreateCode = () => {
        this.props.createCode({recipient:this.state.newCodeRecipient,codeType:this.state.newCodeRole});
        this.setState({...this.state,showNewCodeModal:false})
    }

    submitDelete = (codeId) => {
        this.props.deleteCode(codeId);
        this.setState({...this.state,showNewCodeModal:false})
    }

    componentDidMount() {
        this.props.fetchCodes();
    }
    render() {

        const codeTypes = [
            { key: 'Basic', text: 'Basic', value: 'Basic'},
            { key: 'Beta', text: 'Beta', value: 'Beta'},
            { key: 'Demo', text: 'Demo', value: 'Demo'}
        ];

        const codeList = this.props.admin.codes.map( code => (
            <Table.Row key={code._id}>
                <Table.Cell>
                    {code.recipient}
                </Table.Cell>
                <Table.Cell>
                    {code.key}
                </Table.Cell>
                <Table.Cell>
                    {code.role}
                </Table.Cell>
                <Table.Cell>
                    {code.createDate}
                </Table.Cell>
                <Table.Cell>
                    {code.redeemedDate}
                </Table.Cell>
                <Table.Cell>
                    <Button color={code.sent?'teal':null} disabled={code.redeemed} loading={this.props.admin.isSendingCode} icon onClick={() => this.props.sendCode(code._id)}>
                        <Icon name='mail'/>
                    </Button>
                    <Button icon onClick={() => this.submitDelete(code._id)}>
                        <Icon name='delete'/>
                    </Button>
                </Table.Cell>

            </Table.Row>
        ));
        return (
            <div>
                <Toolbar/>
                <div style={{margin:'1em'}}>
                    <Dimmer active={this.props.admin.isFetchingCodes}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <Modal open={this.state.showNewCodeModal}>
                        <Modal.Header style={{background:'#363636',color:'white'}}>
                            New Code
                        </Modal.Header>
                        <Modal.Content style={{background:'#363636',color:'white'}}>
                            <Form>
                                <Form.Field>
                                    <Input 
                                        label='Email'
                                        placeholder='Recipient Email' 
                                        value={this.state.newCodeRecipient}
                                        onChange={(ev) => this.setState({...this.state,newCodeRecipient:ev.target.value})}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Dropdown options={codeTypes} placeholder='Select Role' selection
                                        onChange={ (ev,data) => this.setState({...this.state,newCodeRole: data.value})}
                                    />
                                </Form.Field>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions style={{background:'#363636',color:'white'}}>
                            <Button onClick={() => {
                                this.setState({...this.state,showNewCodeModal:false})
                            }}>Close</Button>
                            <Button color='teal' onClick={this.submitCreateCode}>Create</Button>
                        </Modal.Actions>
                    </Modal>
                    <Button color='teal' onClick={this.openModal}>New Code</Button>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Recipient</Table.HeaderCell>
                                <Table.HeaderCell>Code</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Created</Table.HeaderCell>
                                <Table.HeaderCell>Redeemed</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {codeList}
                        </Table.Body>
                    </Table>
                </div>
            </div>        
        );
    }
};

export default connect(state=>({
    admin: state.admin
}),{fetchCodes,createCode,deleteCode,sendCode})(CodesPage);