import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';
import * as storage from '../services/storage';
import history from "../history";

import {
        AUTHENTICATION_SEND,
        authenticationSuccess,authenticationError,authenticationCancel,unverifiedAccount,
        RE_AUTHENTICATION_SEND,
        RE_VERIFY_SEND, reverifySuccess,reverifyError,
        REGISTRATION_SEND,
        registrationSuccess, registrationError,
        PASSWORD_UPDATE_SEND,
        passwordUpdateSuccess, passwordUpdateError, UNAUTHENTICATE,
        PASSWORD_RESET_SEND,
        passwordResetSuccess,passwordResetError,
        USERNAME_CHECK_SEND,
        usernameCheckSuccess,usernameCheckError,
        UPDATE_PREFS_SEND,
        preferencesUpdateSuccess,preferencesUpdateError,
        SEND_FEEDBACK_SEND, sendFeedbackSuccess, sendFeedbackError,
        LOG_LINK_MARKER
    } from './actions';

function* authenticate(action) {
    try {
        let response = yield call(() => api.authenticate(action.payload));
        if(response.success) {
            yield call(() => storage.saveToken(response.data.user.token));
            yield put(authenticationSuccess(response));
            yield call(() => history.push("/"));
        }
        else {
            yield put(authenticationError(response.message));
        }
    }
    catch(err) {
        if(err.status && (err.status === 403 || err.status === 422)) {
            yield put(authenticationError('Invallid Username/Password'));
        }
        else if(err.status && (err.status === 406 )) {
            yield put(unverifiedAccount('Email has not been verified'));
        }
        else if(err.status && (err.status === 409 )) {
            yield put(authenticationError('Account has been disabled'));
        }
        else {
            yield put(authenticationError(err.message));
        }
    }
}

function* reauthenticate(action) {
    try {
        let response = yield call(() => api.fetchUser());
        if(response.success) {
            yield put(authenticationSuccess(response));
            //yield call(() => history.push("/"));
        }
        else {
            yield call(() => storage.clearToken());
            yield put(authenticationError(response.message));
        }
    }
    catch(err) {
        if(err.status === 401) {
            // JWT probably expired. Remove it and send to login
            yield call(() => storage.clearToken());
            yield call(() => history.push("/login"));
            yield put(authenticationCancel());
        }
        else {
            yield call(() => history.push("/outage"));
            //yield call(() => storage.clearToken());
            yield put(authenticationError(err.message));
        }
    }
}

function* register(action) {
    try {
        let response = yield call(() => api.register(action.payload));        
        if(response.success) {
            yield put(registrationSuccess());
        }
        else {
            yield put(registrationError(response.message));
        }
    }
    catch(err) {
        if(err.status && (err.status === 403 )) {
            yield put(registrationError('Missing a valid email, username, or password'));
        }
        else if(err.status && (err.status === 422)) {
            yield put(registrationError('Registration code invalid'));
        }
        else {
            yield put(registrationError(err.message));
        }
    }
}

function* updatePassword(action) {
    try {
        let response = yield call(() => api.updatePassword(action.payload));
        
        if(response.success) {
            yield put(passwordUpdateSuccess(response));
        }
        else {
            yield put(passwordUpdateError(response.message));
        }
    }
    catch(err) {
        yield put(passwordUpdateError(err.message));
    }
}

function* signout(action) {
    yield call(() => storage.clearToken());
}

function* reverify(action) {
    try {
        let response = yield call(() => api.reverify(action.payload));        
        if(response.success) {
            yield put(reverifySuccess());
        }
        else {
            yield put(reverifyError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        if(err.status && (err.status === 406)) {
            yield put(reverifyError('Unknown email'));
        }
        else {
            yield put(reverifyError(err.message));
        }        

    }
}

function* resetPassword(action) {
    try {
        let response = yield call(() => api.resetPassword(action.payload));        
        if(response.success) {
            yield put(passwordResetSuccess());
        }
        else {
            yield put(passwordResetError(response.message));
        }
    }
    catch(err) {
        console.log(err);
        if(err.status && (err.status === 409)) {
            yield put(passwordResetError('User already exists'));
        }
        else {
            yield put(passwordResetError(err.message));
        }        
    }
}

function* updatePreferences(action) {
    try {
        let response = yield call(() => api.updatePreferences(action.payload));
        if(response.success) {
            yield put(preferencesUpdateSuccess(response));
        }
        else {
            yield put(preferencesUpdateError(response.message));
        }
    }
    catch(err) {
        yield put(preferencesUpdateError(err.message));
    }
}

function* checkUsername(action) {
    try {
        let response = yield call(() => api.checkUsername(action.payload));        
        if(response.success) {
            yield put(usernameCheckSuccess());
        }
        else {
            yield put(usernameCheckError(response.message));
        }
    }
    catch(err) {
        yield put(usernameCheckError(err.message));
    }
}

function* sendFeedback(action) {
    try {
        let response = yield call(() => api.sendFeedback({ feedback: { problem: action.problem, text: action.text}}));        
        if(response.success) {
            yield put(sendFeedbackSuccess());
        }
        else {
            yield put(sendFeedbackError(response.message));
        }
    }
    catch(err) {
        yield put(sendFeedbackError(err.message));
    }
}

function* logLinkMarker(action) {
    try {
        let response = yield call(() => api.logReferralMetric(action.payload));        
    }
    catch(err) {
        console.log(err.message);
    }
}

export default function* userSagas() {
    yield takeLatest(AUTHENTICATION_SEND,authenticate);
    yield takeLatest(REGISTRATION_SEND,register);
    yield takeLatest(RE_AUTHENTICATION_SEND, reauthenticate);
    yield takeLatest(PASSWORD_UPDATE_SEND, updatePassword);
    yield takeLatest(UNAUTHENTICATE, signout);
    yield takeLatest(PASSWORD_RESET_SEND, resetPassword);
    yield takeLatest(USERNAME_CHECK_SEND, checkUsername);
    yield takeLatest(UPDATE_PREFS_SEND, updatePreferences);
    yield takeLatest(SEND_FEEDBACK_SEND, sendFeedback);
    yield takeLatest(LOG_LINK_MARKER, logLinkMarker);
    yield takeLatest(RE_VERIFY_SEND, reverify);
}