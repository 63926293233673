import React, {useMemo,useState, useCallback} from 'react';
import {createEditor} from 'slate';
import {Slate, Editable, withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import isHotkey from 'is-hotkey';
import { 
  withRichText, Element, Leaf, Toolbar, MarkButton, BlockButton, MarkDropdown
} from './richTextTools';

  
export const EmbeddedRichTextEditor = ({content,onUpdate,placeholder,focus,footer,centered,readOnly,title}) => {
    const editor = useMemo( () => withRichText(withHistory(withReact(createEditor()))), []);
    const [value, setValue] = useState(content);
    const renderElement = useCallback( props => <Element {...props}/>,[]);
    const renderLeaf = useCallback( props => <Leaf {...props}/>,[]);
    const marginBottom = footer ? '10em' : '0em';
    const marginLeft = centered ? 'auto' : '0em';
    const marginRight = centered ? 'auto' : '0em';
    const editorWidth = centered ? '75%' : '100%';

    const toolbar = !readOnly ? (
      <Toolbar embedded={true}>
      <MarkButton format="bold" icon="bold" tooltip='Bold' compact={true}/>
      <MarkButton format="italic" icon="italic" tooltip='Italic' compact={true}/>
      <MarkButton format="underline" icon="underline" tooltip='Underline' compact={true}/>
      <MarkButton format="strikethrough" icon="strikethrough" tooltip='Strikethrough' compact={true}/>
      <BlockButton format="heading-one" text="H1" compact={true}/>
      <BlockButton format="heading-two" text="H2" compact={true}/>
      <BlockButton format="numbered-list" icon="list ol" compact={true}/>
      <BlockButton format="bulleted-list" icon="list ul" compact={true}/>
      <MarkDropdown name='Font' type='fontFamily' tooltip='Font' compact={true} options={[
          {label:'Arial',value:'Arial'},
          {label:'Arial Black',value:'Arial Black'},
          {label:'Bookman',value:'Bookman'},
          {label:'Candara',value:'Candara'},
          {label:'Courier New',value:'Courier New'},
          {label:'Times New Roman',value:'Times New Roman'},
          {label:'Verdana',value:'Verdana'},
      ]} />
      <MarkDropdown name='Size' type='fontSize' tooltip='Font Size' compact={true} options={[
          {label:'8',value:'8pt'},
          {label:'9',value:'9pt'},
          {label:'10',value:'10pt'},
          {label:'11',value:'11pt'},
          {label:'12',value:'12pt'},
          {label:'14',value:'14pt'},
          {label:'16',value:'16pt'},
          {label:'18',value:'18pt'},
      ]} />
    </Toolbar>

    ) : null;
    return (
        <div>
            <div style={{ 
                fontSize:'12px',fontWeight:700,fontFamily: 'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif',
                boxSizing: 'border-box',backgroundColor: '#00b5ad',
                margin: 0,
                lineHeight: '12px',
                paddingTop:'9px',
                paddingBottom:'9px',
                paddingLeft:'12px',
                paddingRight:'12px',
                borderRadius: '.21428571rem .21428571rem 0 0'}}>{title}</div>
      <div style={{ backgroundColor:'#373737'}}>
          <Slate editor={editor} value={value} onChange={value => {
                  setValue(value);

                  if(onUpdate) {
                      onUpdate(value);
                  }
          }}>
              {toolbar}
            <div >
                    <div style={{
                      background: 'white',
                      marginLeft,
                      marginRight,
                      width: editorWidth,
                      marginBottom: marginBottom,
                      backgroundColor: '#363636',
                      color: 'white',
                      padding: '1em'
                      }}>
                  <Editable 
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      placeholder={placeholder}
                      readOnly={readOnly}
                      spellCheck='true'
                      autoFocus={focus}
                      onKeyDown={ event => {
                          if(isHotkey('mod+z',event)) {
                              event.preventDefault();
                              editor.undo();
                          }
                          if(isHotkey('mod+y',event)) {
                              event.preventDefault();
                              editor.redo();
                          }
                      }}
                  />
                  </div>
            </div>
            </Slate>
        </div>
        </div>
    );
}

export default EmbeddedRichTextEditor;