import {
    FETCH_CODES_SEND,FETCH_CODES_SUCCESS,FETCH_CODES_ERROR,
    CREATE_CODE_SEND,CREATE_CODE_SUCCESS,CREATE_CODE_ERROR,
    DELETE_CODE_SEND,DELETE_CODE_SUCCESS,DELETE_CODE_ERROR,
    SEND_CODE_SEND,SEND_CODE_SUCCESS,SEND_CODE_ERROR,
    FETCH_MOTDS_SEND,FETCH_MOTDS_SUCCESS,FETCH_MOTDS_ERROR,
    FETCH_MOTD_SEND,FETCH_MOTD_SUCCESS,FETCH_MOTD_ERROR,
    CREATE_MOTD_SEND,CREATE_MOTD_SUCCESS,CREATE_MOTD_ERROR,
    UPDATE_MOTD_SEND,UPDATE_MOTD_SUCCESS,UPDATE_MOTD_ERROR,
    DELETE_MOTD_SEND,DELETE_MOTD_SUCCESS,DELETE_MOTD_ERROR,
    DISMISS_MOTD,
    SEARCH_USERS_SEND,SEARCH_USERS_SUCCESS,SEARCH_USERS_ERROR,
    ADMIN_UPDATE_USER_SEND,ADMIN_UPDATE_USER_SUCCESS,ADMIN_UPDATE_USER_ERROR,
    FETCH_ACTIVE_USERS_SEND,FETCH_ACTIVE_USERS_SUCCESS,FETCH_ACTIVE_USERS_ERROR,
    FETCH_AD_REFERRALS_SEND,FETCH_AD_REFERRALS_SUCCESS,FETCH_AD_REFERRALS_ERROR,
    CREATE_AD_REFERRAL_SEND,CREATE_AD_REFERRAL_SUCCESS,CREATE_AD_REFERRAL_ERROR,
    DELETE_AD_REFERRAL_SEND,DELETE_AD_REFERRAL_SUCCESS,DELETE_AD_REFERRAL_ERROR,
    FETCH_ANALYTICS_SEND,FETCH_ANALYTICS_SUCCESS,FETCH_ANALYTICS_ERROR,
    FETCH_PROJECTS_REPORT_SUCCESS,FETCH_PROJECTS_REPORT_ERROR

} from './actions';

const initialState = {
    codes:[],
    motds:[],
    activeUsers:[],
    adReferrals: [],
    analytics: {},
    projectsReport: [],
    isFetchingCodes: false,
    fetchCodesError: false,
    fetchCodesErrorMessage: '',
    isCreatingCode: false,
    createCodeError: false,
    createCodeErrorMessage: '',
    isDeletingCode: false,
    deleteCodeError: false,
    deleteCodeErrorMessage: '',
    isFetchingMotds: false,
    fetchMotdsError: false,
    fetchMotdsErrorMessage: '',
    isFetchingMotd: false,
    fetchMotdError: false,
    fetchMotdErrorMessage: '',
    isCreatingMotd: false,
    createMotdError: false,
    createMotdErrorMessage: '',
    isUpdatingMotd: false,
    updateMotdError: false,
    updateMotdErrorMessage: '',
    isDeletingMotd: false,
    deleteMotdError: false,
    deleteMotdErrorMessage: '',
    showMotd: true,
    isSearchingUsers: false,
    searchUsersError: false,
    searchUsersErrorMessage: '',
    searchUsersResults: [],
    isUpdatingUser: false,
    adminUpdateUserError: false,
    adminUpdateUserErrorMessage: '',
    isSendingCode: false,
    isFetchingAdReferrals: false
};

export default (state = initialState, action) => {
switch(action.type) {
    case FETCH_CODES_SEND:
        return {
            ...state,
            isFetchingCodes: true,
            fetchCodesError: false,
            fetchCodesErrorMessage: ''
        };
    case FETCH_CODES_SUCCESS:
        return {
            ...state,
            codes: action.payload.data.codes,
            isFetchingCodes: false
        };
    case FETCH_CODES_ERROR:
        return {
            ...state,
            isFetchingCodes: false,
            fetchCodesError: true,
            fetchCodesErrorMessage: action.payload
        };
    case CREATE_CODE_SEND:
        return {
            ...state,
            isCreatingCode: true,
            createCodeError: false,
            createCodeErrorMessage: ''
        };
    case CREATE_CODE_SUCCESS:
        return {
            ...state,
            isCreatingCode: false,
            showCreateCodeModal: false
        };
    case CREATE_CODE_ERROR:
        return {
            ...state,
            isCreatingCode: false,
            createCodeError: true,
            createCodeErrorMessage: action.payload
        };  
    case DELETE_CODE_SEND:
        return {
            ...state,
            isDeletingCode: true,
            deleteCodeError: false,
            deleteCodeErrorMessage: ''
        };
    case DELETE_CODE_SUCCESS:
        return {
            ...state,
            isDeletingCode: false
        };
    case DELETE_CODE_ERROR:
        return {
            ...state,
            isDeletingCode: false,
            deleteCodeError: true,
            deleteCodeErrorMessage: action.payload
        };
    case SEND_CODE_SEND:
        return {
            ...state,
            isSendingCode: true
        };
    case SEND_CODE_SUCCESS:
        return {
            ...state,
            isSendingCode: false
        };
    case SEND_CODE_ERROR:
        return {
            ...state,
            isSendingCode: false,
            showError: true,
            errorMessage: action.payload
        };

        case FETCH_MOTDS_SEND:
            return {
                ...state,
                isFetchingMotds: true,
                fetchMotdsError: false,
                fetchMotdsErrorMessage: ''
            };
        case FETCH_MOTDS_SUCCESS:
            return {
                ...state,
                motds: action.payload.data.motds,
                isFetchingMotds: false
            };
        case FETCH_MOTDS_ERROR:
            return {
                ...state,
                isFetchingMotds: false,
                fetchMotdsError: true,
                fetchMotdsErrorMessage: action.payload
            };
        case FETCH_MOTD_SEND:
            return {
                ...state,
                isFetchingMotd: true,
                fetchMotdError: false,
                fetchMotdErrorMessage: ''
            };
        case FETCH_MOTD_SUCCESS:
            return {
                ...state,
                motd: action.payload.data.motd,
                isFetchingMotd: false
            };
        case FETCH_MOTD_ERROR:
            return {
                ...state,
                isFetchingMotd: false,
                fetchMotdError: true,
                fetchMotdErrorMessage: action.payload
            };
    
        case CREATE_MOTD_SEND:
            return {
                ...state,
                isCreatingMotd: true,
                createMotdError: false,
                createMotdErrorMessage: ''
            };
        case CREATE_MOTD_SUCCESS:
            return {
                ...state,
                isCreatingMotd: false,
                showCreateMotdModal: false
            };
        case CREATE_MOTD_ERROR:
            return {
                ...state,
                isCreatingMotd: false,
                createMotdError: true,
                createMotdErrorMessage: action.payload
            };
        case UPDATE_MOTD_SEND:
            return {
                ...state,
                isUpdatingMotd: true,
                updateMotdError: false,
                updateMotdErrorMessage: ''
            };
        case UPDATE_MOTD_SUCCESS:
            return {
                ...state,
                isUpdatingMotd: false,
                showUpdateMotdModal: false
            };
        case UPDATE_MOTD_ERROR:
            return {
                ...state,
                isUpdatingMotd: false,
                updateMotdError: true,
                updateMotdErrorMessage: action.payload
            };
    
        case DELETE_MOTD_SEND:
            return {
                ...state,
                isDeletingMotd: true,
                deleteMotdError: false,
                deleteMotdErrorMessage: ''
            };
        case DELETE_MOTD_SUCCESS:
            return {
                ...state,
                isDeletingMotd: false
            };
        case DELETE_MOTD_ERROR:
            return {
                ...state,
                isDeletingMotd: false,
                deleteMotdError: true,
                deleteMotdErrorMessage: action.payload
            };
        case DISMISS_MOTD:
            return {
                ...state,
                showMotd: false
            };
        case SEARCH_USERS_SEND:
            return {
                ...state,
                isSearchingUsers: true,
                searchUsersError: false,
                searchUsersErrorMessage: '',
                searchUsersResults: []
            };
        case SEARCH_USERS_SUCCESS:
            return {
                ...state,
                searchUsersResults: action.payload.data.users,
                isSearchingUsers: false
            };
        case SEARCH_USERS_ERROR:
            return {
                ...state,
                isSearchingUsers: false,
                searchUsersError: true,
                searchUsersErrorMessage: action.payload
            };

        case ADMIN_UPDATE_USER_SEND:
            return {
                ...state,
                isUpdatingUser: true,
                adminUpdateUserError: false,
                adminUpdateUserErrorMessage: ''
            };
        case ADMIN_UPDATE_USER_SUCCESS:
            let newResults = state.searchUsersResults.slice();
            newResults = newResults.map( user => {
                return (user._id===action.payload.data.user._id) ? action.payload.data.user : user;
            });
            return {
                ...state,
                isUpdatingUser: false,
                searchUsersResults: newResults
            };
        case ADMIN_UPDATE_USER_ERROR:
            return {
                ...state,
                isUpdatingUser: false,
                adminUpdateUserError: true,
                adminUpdateUserErrorMessage: action.payload
            };
        case FETCH_ACTIVE_USERS_SEND:
            return {
                ...state,
                isActiveUsers: true
            };
        case FETCH_ACTIVE_USERS_SUCCESS:
            return {
                ...state,
                activeUsers: action.payload.data.results,
                isActiveUsers: false
            };
        case FETCH_ACTIVE_USERS_ERROR:
            return {
                ...state,
                isActiveUsers: false,
                fetchMotdsError: true,
                fetchMotdsErrorMessage: action.payload
            };
        case FETCH_AD_REFERRALS_SEND:
            return {
                ...state,
                isFetchingAdReferrals: true
            };
        case FETCH_AD_REFERRALS_SUCCESS:
            return {
                ...state,
                adReferrals: action.payload.data.referrals,
                isFetchingAdReferrals: false
            };
        case FETCH_AD_REFERRALS_ERROR:
            return {
                ...state,
                isFetchingAdReferrals: false,
                showError: true,
                errorMessage: action.payload
            };
        case CREATE_AD_REFERRAL_SUCCESS:
            return {
                ...state,
                isCreatingAdReferral: false,
                showCreateAdReferralModal: false
            };
        case CREATE_AD_REFERRAL_ERROR:
            return {
                ...state,
                isCreatingAdReferral: false,
                showError: true,
                errorMessage: action.payload
            };    
        case DELETE_AD_REFERRAL_SEND:
            return {
                ...state,
                isDeletingAdReferral: true,
                deleteAdReferralError: false,
                deleteAdReferralErrorMessage: ''
            };
        case DELETE_AD_REFERRAL_SUCCESS:
            return {
                ...state,
                isDeletingAdReferral: false
            };
        case DELETE_AD_REFERRAL_ERROR:
            return {
                ...state,
                isDeletingAdReferral: false,
                showError: true,
                errorMessage: action.payload
            };

        case FETCH_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: action.payload.data.analytics,
            };
        case FETCH_ANALYTICS_ERROR:
            return {
                ...state,
                showError: true,
                errorMessage: action.payload
            };
        case FETCH_PROJECTS_REPORT_SUCCESS:
            return {
                ...state,
                projectsReport: action.payload.data.report.data,
            };
        case FETCH_PROJECTS_REPORT_ERROR:
            return {
                ...state,
                showError: true,
                errorMessage: action.payload
            };
        
    default:
        return {...state};
}
};