import React from 'react';
import {Segment,Grid,Header,List,Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Toolbar from '../header/Toolbar';

const GetPremium = (props) => {
    return (
        <div>
            <Toolbar>
                <div>
                <Segment style={{ padding: '2em 2em',backgroundColor:'white' }} vertical id='Features'>
                <Segment inverted color='teal' textAlign='center'>
                    <Header as='h1' style={{fontSize: '4em'}}>Get Cetacean Premium</Header>
                </Segment>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                <Grid.Column width={8}>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    Unlock All Cetacean Features
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                        In addition to the free editor and worldbuilding tools, Cetacean Premium offers a range
                        of collaboration tools as well as relaxed project restrictions.
                    </p>
                    <Header as='h3' style={{ fontSize: '2em' }}>
                    $4.99 A Month
                    </Header>
                    <p style={{ fontSize: '1.33em' }}>
                        Cetacean Premium is a recurring monthly subscription service. For $4.99 (USD) a month, you
                        unlock the full version of the application.
                    </p>
                </Grid.Column>
                <Grid.Column floated='right' width={6}>
                    <List size='big'>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                                No word count limits
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                                Unlimited number of projects
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                                Upload images
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                                Save checkpoint versions of projects
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                            Collaborate with others
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                            View editorial changes
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                            Share your story with beta readers
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='checkmark' color='teal'/>
                            <List.Content>
                            Priority Customer Service Support
                            </List.Content>
                        </List.Item>
                    </List>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column textAlign='center'>
                    <Button as={Link} to='/getpremium/payment' color='teal' size='large'>
                        Subscribe To Cetacean Premium
                        </Button>
                </Grid.Column>
                </Grid.Row>
            </Grid>
            </Segment>

                </div>
            </Toolbar>
        </div>
    );
}

export default GetPremium;