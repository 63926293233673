import {
    DISMISS_INBOX_ERROR,
    FETCH_INBOX_SEND,FETCH_INBOX_SUCCESS,FETCH_INBOX_ERROR,
    FETCH_MESSAGES_SEND,FETCH_MESSAGES_SUCCESS,FETCH_MESSAGES_ERROR,
    SEND_MESSAGE_SEND,SEND_MESSAGE_SUCCESS,SEND_MESSAGE_ERROR,
    DELETE_MESSAGE_SEND,DELETE_MESSAGE_SUCCESS,DELETE_MESSAGE_ERROR,
    OPEN_COMPOSE_MODAL,CLOSE_COMPOSE_MODAL,
    OPEN_READ_MESSAGE_MODAL,CLOSE_READ_MESSAGE_MODAL,
    MARK_READ_SEND,MARK_READ_SUCCESS,MARK_READ_ERROR,
    FETCH_UNREAD_SEND,FETCH_UNREAD_SUCCESS,FETCH_UNREAD_ERROR,
    BROADCAST_MESSAGE_SEND,BROADCAST_MESSAGE_SUCCESS,BROADCAST_MESSAGE_ERROR
} from './actions';

const initialState = {
    inbox: {},
    messages: [],
    unread: 0,
    showError: false,
    errorMessage: '',
    isFetchingInbox: false,
    fetchInboxError: false,
    fetchInboxErrorMessage: '',
    isFetchingMessages: false,
    fetchMessagesError: false,
    fetchMessagesErrorMessage: '',
    isSendingMessage: false,
    sendMessageError: false,
    sendMessageErrorMessage: '',
    isDeletingMessage: false,
    deleteMessageError: false,
    deleteMessageErrorMessage: '',
    showComposeModal: false,
    showReadModal: false,
    readingMessage: {},
    isBroadcastingMessage: false,
    showReadMessageModal: false,
    openMessage: {}
};

export default (state = initialState, action) => {
switch(action.type) {
    case DISMISS_INBOX_ERROR:
        return {
            ...state,
            showError: false,
            errorMessage: ''
        }
    case FETCH_INBOX_SEND:
        return {
            ...state,
            isFetchingInbox: true,
            fetchInboxError: false,
            fetchInboxErrorMessage: ''
        };
    case FETCH_INBOX_SUCCESS:
        return {
            ...state,
            inbox: action.payload.data.inbox,
            isFetchingInbox: false
        };
    case FETCH_INBOX_ERROR:
        return {
            ...state,
            isFetchingInbox: false,
            showError: true,
            errorMessage: action.payload
        };

    case FETCH_MESSAGES_SEND:
        return {
            ...state,
            isFetchingMessages: true,
            fetchMessagesError: false,
            fetchMessagesErrorMessage: ''
        };
    case FETCH_MESSAGES_SUCCESS:
        return {
            ...state,
            messages: action.payload.data.messages,
            isFetchingMessages: false,
            unread: action.payload.data.messages.filter(msg => !msg.read).length
        };
    case FETCH_MESSAGES_ERROR:
        return {
            ...state,
            isFetchingMessages: false,
            showError: true,
            errorMessage: action.payload
        };

    case SEND_MESSAGE_SEND:
        return {
            ...state,
            isSendingMessage: true,
            sendMessageError: false,
            sendMessageErrorMessage: ''
        };
    case SEND_MESSAGE_SUCCESS:
        return {
            ...state,
            isSendingMessage: false,
            showComposeModal: false
        };
    case SEND_MESSAGE_ERROR:
        return {
            ...state,
            isSendingMessage: false,
            showError: true,
            errorMessage: action.payload
        };  
    case DELETE_MESSAGE_SEND:
        return {
            ...state,
            isDeletingMessage: true,
            deleteMessageError: false,
            deleteMessageErrorMessage: ''
        };
    case DELETE_MESSAGE_SUCCESS:
        return {
            ...state,
            isDeletingMessage: false,
            showReadMessageModal: false,
            openMessage: {}
        
        };
    case DELETE_MESSAGE_ERROR:
        return {
            ...state,
            isDeletingMessage: false,
            showError: true,
            errorMessage: action.payload
        };
    case OPEN_COMPOSE_MODAL:
        return {
            ...state,
            showComposeModal: true
        }
    case CLOSE_COMPOSE_MODAL:
        return {
            ...state,
            showComposeModal: false
        }
    case OPEN_READ_MESSAGE_MODAL:
        return {
            ...state,
            showReadMessageModal: true,
            openMessage: action.message
        }
    case CLOSE_READ_MESSAGE_MODAL:
        return {
            ...state,
            showReadMessageModal: false,
            openMessage: {}
        }
    
    case MARK_READ_SUCCESS:
        let newUnread = 0;
        let newMessages = state.messages.map( msg => {
            if(msg._id === action.payload.data.message._id) {
                return action.payload.data.message;
            }
            return msg;
        });
        newUnread = state.unread -1;
        return {
            ...state,
            messages: newMessages,
            unread: newUnread
        }
    case FETCH_UNREAD_SUCCESS:
        return {
            ...state,
            unread: action.payload.data.messages
        };

    case BROADCAST_MESSAGE_SEND:
        return {
            ...state,
            isBroadcastingMessage: true,
            sendMessageError: false,
            sendMessageErrorMessage: ''
        };
    case BROADCAST_MESSAGE_SUCCESS:
        return {
            ...state,
            isBroadcastingMessage: false,
            showComposeModal: false
        };
    case BROADCAST_MESSAGE_ERROR:
        return {
            ...state,
            isBroadcastingMessage: false,
            showError: true,
            errorMessage: action.payload
        };  
    
    default:
        return {...state};
}
};