import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import ChapterEditPanel from '../writing/ChapterEditPanel';
import {openChapter,openChaptersPanel,openIdeaBoard,openOutline,openRevision} from '../../../project/actions';

const EditBookPanel = (props) => {
    const dispatch = useDispatch();
    const projects = useSelector( state => state.projects);
    const projectView = useSelector( state => state.projects.openProject.view);

    useEffect( () => {
        switch(projectView) {
            default: {
                if('Book' === projects.openProject.projectType) {
                    if(projects.openProject.chapters[0]) {
                        dispatch(openChapter(projects.openProject.chapters[0]._id));
                    }
                    else {                        
                        dispatch(openChaptersPanel(projects.openProject._id));
                    }
                }        
            }
        }
    },[dispatch,projectView]);

    switch(projects.openProjectPanel) { 
        case 'Chapter':
            return (
                <ChapterEditPanel key={projects.openProject._id}/>
            );
        default:
            return (
                <div>
                    Project Panel Here
                </div>
            );            
    }
}

export default EditBookPanel;