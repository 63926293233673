export const AUTHENTICATION_SEND = 'AUTHENTICATION_SEND';
export const AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

export const sendAuthentication = payload => ({
    type: AUTHENTICATION_SEND,
    payload
});

export const authenticationSuccess = payload => { 
    return {
    type: AUTHENTICATION_SUCCESS,
    payload
}};

export const authenticationError = payload => ({
    type: AUTHENTICATION_ERROR,
    payload
});

export const UNVERIFIED_ACCOUNT = 'UNVERIFIED_ACCOUNT';
export const unverifiedAccount = payload => ({ type: UNVERIFIED_ACCOUNT, payload });
export const RE_VERIFY_SEND = 'RE_VERIFY_SEND';
export const reverify = email => ({ type: RE_VERIFY_SEND, payload: { registration: {email}} });
export const RE_VERIFY_SUCCESS = 'RE_VERIFY_SUCCESS';
export const reverifySuccess = payload => ({ type: RE_VERIFY_SUCCESS, payload });
export const RE_VERIFY_ERROR = 'RE_VERIFY_ERROR';
export const reverifyError = payload => ({ type: RE_VERIFY_ERROR, payload });

export const AUTHENTICATION_CANCEL = 'AUTHENTICATION_CANCEL';
export const authenticationCancel = () => ({
    type: AUTHENTICATION_CANCEL
});

export const RE_AUTHENTICATION_SEND = 'RE_AUTHENTICATION_SEND';
export const sendReauthentication = () => ({
    type: RE_AUTHENTICATION_SEND
});

export const REGISTRATION_SEND = 'REGISTRATION_SEND';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';

export const sendRegistration = payload => ({
    type: REGISTRATION_SEND,
    payload
});

export const registrationSuccess = payload => {
    return {
    type: REGISTRATION_SUCCESS,
    payload
}};

export const registrationError = payload => ({
    type: REGISTRATION_ERROR,
    payload
});

export const PASSWORD_UPDATE_SEND = 'PASSWORD_UPDATE_SEND';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR';

export const sendPasswordUpdaate = payload => ({
    type: PASSWORD_UPDATE_SEND,
    payload
});

export const passwordUpdateSuccess = payload => {
    return {
    type: PASSWORD_UPDATE_SUCCESS,
    payload
}};

export const passwordUpdateError = payload => ({
    type: PASSWORD_UPDATE_ERROR,
    payload
});

export const UNAUTHENTICATE = 'UNAUTHENTICATE';

export const unauthenticate = () => ({
    type: UNAUTHENTICATE
});

export const PASSWORD_RESET_SEND = 'PASSWORD_RESET_SEND';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const sendPasswordReset = payload => ({
    type: PASSWORD_RESET_SEND,
    payload
});

export const passwordResetSuccess = payload => { 
    return {
    type: PASSWORD_RESET_SUCCESS,
    payload
}};

export const passwordResetError = payload => ({
    type: PASSWORD_RESET_ERROR,
    payload
});

export const USERNAME_CHECK_SEND = 'USERNAME_CHECK_SEND';
export const USERNAME_CHECK_SUCCESS = 'USERNAME_CHECK_SUCCESS';
export const USERNAME_CHECK_ERROR = 'USERNAME_CHECK_ERROR';

export const checkUsername = payload => ({
    type: USERNAME_CHECK_SEND,
    payload
});

export const usernameCheckSuccess = payload => { 
    return {
    type: USERNAME_CHECK_SUCCESS,
    payload
}};

export const usernameCheckError = payload => ({
    type: USERNAME_CHECK_ERROR,
    payload
});

export const DISTRACTION_FREE_TOGGLE = 'DISTRACTION_FREE_TOGGLE';

export const toggleDistractionFree = (mode) => ({
    type: DISTRACTION_FREE_TOGGLE,
    mode
});


export const UPDATE_PREFS_SEND = 'UPDATE_PREFS_SEND';
export const UPDATE_PREFS_SUCCESS = 'UPDATE_PREFS_SUCCESS';
export const UPDATE_PREFS_ERROR = 'UPDATE_PREFS_ERROR';

export const updatePreferences = payload => ({
    type: UPDATE_PREFS_SEND,
    payload
});

export const preferencesUpdateSuccess = payload => {
    return {
    type: UPDATE_PREFS_SUCCESS,
    payload: payload
}};

export const preferencesUpdateError = payload => ({
    type: UPDATE_PREFS_ERROR,
    payload
});

export const SEND_FEEDBACK_SEND = 'SEND_FEEDBACK_SEND';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_ERROR = 'SEND_FEEDBACK_ERROR';

export const sendFeedback = (problem,text) => ({
    type: SEND_FEEDBACK_SEND,
    problem,
    text
});

export const sendFeedbackSuccess = payload => {
    return {
    type: SEND_FEEDBACK_SUCCESS,
    payload
}};

export const sendFeedbackError = payload => ({
    type: SEND_FEEDBACK_ERROR,
    payload
});

export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL';
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL';

export const openFeedbackModal = () => ({
    type: OPEN_FEEDBACK_MODAL
});

export const closeFeedbackModal = payload => {
    return {
    type: CLOSE_FEEDBACK_MODAL,
    payload
}};

export const LOG_LINK_MARKER = 'LOG_LINK_MARKER';
export const logLinkMarker = payload => {
    return {
    type: LOG_LINK_MARKER,
    payload
}};