import * as ActionType from './constants';

export const fetchPosts = () => ({ type: ActionType.FETCH_POSTS_SEND });
export const fetchPostsSuccess = (payload) => ({ type: ActionType.FETCH_POSTS_SUCCESS,payload });
export const fetchPostsError = (payload) => ({ type: ActionType.FETCH_POSTS_ERROR,payload });

export const createPost = (payload) => ({ type: ActionType.CREATE_POST_SEND, payload });
export const createPostSuccess = (payload) => ({ type: ActionType.CREATE_POST_SUCCESS, payload });
export const createPostError = (payload) => ({ type: ActionType.CREATE_POST_ERROR, payload });

export const deletePost = (postId) => ({ type: ActionType.DELETE_POST_SEND, postId });
export const deletePostSuccess = (payload) => ({ type: ActionType.DELETE_POST_SUCCESS, payload });
export const deletePostError = (payload) => ({ type: ActionType.DELETE_POST_ERROR, payload });