import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Accordion,Menu,Dropdown,Button,Icon,Popup} from 'semantic-ui-react';
import {exportToDocx} from '../../util/exportToDocx';
import {exportToPlainText} from '../../util/exportToPlainText';
import {
    addChapter,addScene,openScene,updateProject,
    showSceneRenameModal,showChapterRenameModal,
    moveSceneUp,moveSceneDown,moveChapterUp,moveChapterDown,
    showRechapterSceneModal, showDeleteSceneModal, showDeleteChapterModal,
    openTimeline,openCharactersPanel,openLocationsPanel,
    openProgressPanel
} from '../../project/actions';
import {toggleDistractionFree} from '../../user/actions';

const SceneList = ({projectId,chapterId,scenes}) => {
    const dispatch = useDispatch();
    const currentScene = useSelector(state => state.projects.openProjectScene);
    const sceneList = scenes.map( (scene,index) => {
        const bgStyle= (currentScene && (currentScene._id === scene._id)) ? {fontWeight:'bold',color:'white'} : {};
        return (
        <Dropdown key={scene._id} item text={scene.name} style={bgStyle}>
            <Dropdown.Menu direction='left'>
                <Dropdown.Item icon='edit' text='Edit Scene' onClick={() => dispatch(openScene(chapterId,scene._id))}/>
                <Dropdown.Item icon='pencil square' text='Rename' onClick={() => dispatch(showSceneRenameModal(chapterId,scene._id,scene.name))}/>
                <Dropdown.Item icon='level up' text='Move Up' onClick={() => dispatch(moveSceneUp(chapterId,scene._id))}/>
                <Dropdown.Item icon='level down' text='Move Down' onClick={() => dispatch(moveSceneDown(chapterId,scene._id))}/>
                <Dropdown.Item icon='move' text='Move To Chapter' onClick={() => dispatch(showRechapterSceneModal(scene._id,chapterId))}/>
                <Dropdown.Item icon='delete' text='Delete Scene' onClick={() => dispatch(showDeleteSceneModal(projectId,chapterId,scene._id))}/>
            </Dropdown.Menu>
        </Dropdown>
        )
        });
    return (
        <div>
            {sceneList}
        </div>

    );
}

const ChapterHeader= ({project,chapter}) => {
    const dispatch = useDispatch();
    return (
        <Dropdown item text='Chapter' icon='cog'>
            <Dropdown.Menu direction='left'>
                <Dropdown.Item icon='file' text='Add Scene' onClick={() => dispatch(addScene(project._id,chapter._id))} />
                <Dropdown.Item icon='pencil square' text='Rename' onClick={() => dispatch(showChapterRenameModal(chapter._id,chapter.name))}/>
                <Dropdown.Item icon='level up' text='Move Up' onClick={() => dispatch(moveChapterUp(chapter._id))}/>
                <Dropdown.Item icon='level down' text='Move Down' onClick={() => dispatch(moveChapterDown(chapter._id))}/>
                <Dropdown.Item icon='delete' text='Delete Chapter' onClick={() => dispatch(showDeleteChapterModal(project._id,chapter._id))}/>
            </Dropdown.Menu>            
        </Dropdown>
    );
}

const getChapterMenu = (project,chapter) => {
    return (
        <div>
            <ChapterHeader project={project} chapter={chapter}/>
            <SceneList projectId={project._id} chapterId={chapter._id} scenes={chapter.scenes}/>
        </div>
    );
}

const ChapterPanel = (props) => {
    const dispatch = useDispatch();
    const currentChapter = useSelector(state => state.projects.openProjectChapter);
    const [activeChapterIndex,setActiveChapterIndex] = useState(0);
    const chapterList = props.project.chapters.map( (chapter,index) => {
        const titleStyle = (currentChapter._id === chapter._id) ? {fontWeight:'bold'} : {};
        return (
        <Menu.Item key={chapter._id}>
            <Accordion.Title 
                style={titleStyle}
                active={activeChapterIndex === index}
                content={chapter.name}
                onClick={() => setActiveChapterIndex(index)}
            />
            <Accordion.Content active={activeChapterIndex === index} content={getChapterMenu(props.project,chapter)} />
        </Menu.Item>
    )
});

    return (
        <div>
            <Button icon onClick={() => dispatch(addChapter(props.project._id))}>
                <Icon name='plus circle'/>
                &nbsp;New Chapter
            </Button>
            <Accordion as={Menu} vertical inverted>            
            {chapterList}
        </Accordion>

        </div>
    );
}


const ProjectSidebar = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const isProjectDirty = useSelector( state => state.projects.isProjectDirty);
    const isDistractionFree = useSelector( state => state.user.isDistractionFree);
    const [activeIndex,setActiveIndex] = useState(true);
    const [active,setActive] = useState('');
    const chapterContent = (<ChapterPanel project={props.project}/>);
    const uploadStyle = isProjectDirty ? {color:'teal'} : {};
    return (
        <div style={{
            height:'100%',
            display:'flex',
            flexDirection:'column'
        }}>
            <div style={{flexShrink: 0}}>
            <Popup trigger={
                    <Button {...uploadStyle} icon='cloud upload' 
                        disabled={!isProjectDirty}
                        onMouseDown={ (ev) => ev.preventDefault()}
                        onClick={(ev) => {
                            ev.preventDefault();
                            dispatch(updateProject({project: openProject}));                            
                        }}
                    />
                } content='Save project to cloud' />
            <Popup trigger={
                    <Button icon='expand' 
                        onMouseDown={ (ev) => ev.preventDefault()}
                        onClick={() => dispatch(toggleDistractionFree(!isDistractionFree))}
                    />
                } content='Distraction free mode' />
            <Dropdown icon='save' button>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => exportToDocx(props.project)}>Export To Word Doc</Dropdown.Item>
                    <Dropdown.Item onClick={() => exportToPlainText(props.project)}>Export To Plain Text</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <Accordion as={Menu} vertical inverted style={{width:'100%'}}>
                    <Menu.Item>
                        <Accordion.Title 
                            active={activeIndex === true}
                            content='Chapters'
                            onClick={() => setActiveIndex(!activeIndex)}
                        />
                        <Accordion.Content active={activeIndex === true} content={chapterContent} />
                    </Menu.Item>
                </Accordion>
                <Menu vertical inverted style={{width:'100%'}}>
                    <Menu.Item 
                        active={active === 'progress'}
                        onClick={() => {
                            setActive('progress');
                            dispatch(openProgressPanel(props.project._id));
                            }}>
                        Progress
                    </Menu.Item>

                    <Menu.Item 
                        active={active === 'characters'}
                        onClick={() => {
                            setActive('characters');
                            dispatch(openCharactersPanel(props.project._id));
                            }}>
                        Characters
                    </Menu.Item>
                    <Menu.Item 
                        active={active === 'locations'}
                        onClick={() => {
                            setActive('locations');
                            dispatch(openLocationsPanel(props.project._id));
                            }}>
                        Locations
                    </Menu.Item>

                    <Menu.Item 
                        active={active === 'history'}
                        onClick={() => {
                            setActive('history');
                            dispatch(openTimeline(props.project._id));
                            }}>
                        Timeline
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
};

export default ProjectSidebar;