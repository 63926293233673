
export function convertElementToPlainText(elementText) {
    let buffer = '';
    if(elementText) {             
        let text = JSON.parse(elementText);       
        text.forEach( element => {
            switch(element.type) {
                case 'numbered-list':
                    buffer = buffer + parseList(element);
                    break;
                case 'bulleted-list':
                    buffer = buffer + parseList(element);
                    break;
                case 'heading-one':
                    buffer = buffer + parseHeading(element);
                    break;
                case 'heading-two':
                    buffer = buffer + parseHeading(element);
                    break;
                default:
                    buffer = buffer + parseText(element);
            }
        })
    }
    return buffer;
}

export function convertSceneToPlainText(scene) {
    let buffer = '';
    let text = JSON.parse(scene.text);
    if(text) {                    
        text.forEach( element => {
            switch(element.type) {
                case 'numbered-list':
                    buffer = buffer + parseList(element);
                    break;
                case 'bulleted-list':
                    buffer = buffer + parseList(element);
                    break;
                case 'heading-one':
                    buffer = buffer + parseHeading(element);
                    break;
                case 'heading-two':
                    buffer = buffer + parseHeading(element);
                    break;
                default:
                    buffer = buffer + parseText(element);
            }

        })
    }
    return buffer;
}

export function convertToPlainText(project) {
    if('Book' === project.projectType) {
        return convertBookToPlainText(project);
    }
        return convertBookByScenesToPlainText(project);
}

export function convertBookToPlainText(project) {
    let buffer = '';

    project.chapters.forEach( chapter => {
        buffer = buffer + convertElementToPlainText(chapter.text);            
    });
    return buffer;
}

export function convertBookByScenesToPlainText(project) {
    let buffer = '';

    project.chapters.forEach( chapter => {
        chapter.scenes.forEach( scene => {
            let text = JSON.parse(scene.text);
            if(text) {                    
                text.forEach( element => {
                    switch(element.type) {
                        case 'numbered-list':
                            buffer = buffer + parseList(element);
                            break;
                        case 'bulleted-list':
                            buffer = buffer + parseList(element);
                            break;
                        case 'heading-one':
                            buffer = buffer + parseHeading(element);
                            break;
                        case 'heading-two':
                            buffer = buffer + parseHeading(element);
                            break;
                        default:
                            buffer = buffer + parseText(element);
                    }

                })
            }
            
        });
    });
    return buffer;
}

export async function exportToPlainText(project) {
    try {
        let buffer = convertToPlainText(project);
        let blob = new Blob([buffer], 
            {type: 'text/plain'});
        var blobUrl = URL.createObjectURL(blob);
        if(document) {
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = "manuscript.txt";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);            
        }
    }
    catch(err) {
        console.log(err);
    }
}

function parseHeading(element) {
    let buffer = '';
    element.children.forEach( child => {
        buffer = buffer +child.text+'\n';
    });
    return buffer;
}

function parseList(element) { 
    let buffer = '';
    element.children.forEach( child => {
        if(child.type === 'list-item') {
            child.children.forEach( item => {
                buffer = buffer +item.text+'\n';
            });
        }
    });
    return buffer;
}

function parseText(element) {
    let s = '';
    element.children.forEach( child => {
        s = s + child.text;
    });
    s = s + '\n';
    return s;
}