import superagent from 'superagent';

const ENDPOINTS = {
    GET_WORD: 'https://wordsapiv1.p.rapidapi.com/words/',
}

export const getWord = (word) => 
    superagent.get(ENDPOINTS.GET_WORD+word)
        .set('x-rapidapi-host','wordsapiv1.p.rapidapi.com')
        .set('x-rapidapi-key','053bf78819msh48221c3c18219bdp1b4173jsn65218886ede8')
        .set('useQueryString',true)
        .then(response => response.body.results);

export const getSynonym = (word) => 
    superagent.get(ENDPOINTS.GET_WORD+word+'/synonyms')
        .set('x-rapidapi-host','wordsapiv1.p.rapidapi.com')
        .set('x-rapidapi-key','053bf78819msh48221c3c18219bdp1b4173jsn65218886ede8')
        .set('useQueryString',true)
        .then(response => response.body);
    