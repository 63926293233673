import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Input,Label, Popup} from 'semantic-ui-react';
import {updateLocalResearch,showDeleteResearchModal,resizeResearch} from '../../../project/actions';

const TagBar = ({research}) => {
    const dispatch = useDispatch();    

    const tagList = research.tags ? research.tags.map( tag => <Label key={tag} content={tag} tag color='teal' removeIcon='delete' onRemove={() => {
        let newTags = research.tags.filter(t => t !== tag);
        dispatch(updateLocalResearch(research._id,'tags',newTags));
    }}/>) : [];
    return (
        <div style={{ width: '100%'}}>
            {tagList}
        </div>
    );
}

export default TagBar;