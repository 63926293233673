import PropTypes from 'prop-types'
import React, { useEffect,Component } from 'react'
import {useDispatch} from 'react-redux';
import {
  Button,Container,Divider,Grid,Header,Icon,Image,List,Menu,Modal,
  Responsive,Segment,Sidebar,Visibility,Table
} from 'semantic-ui-react';
import CookieConsent from "react-cookie-consent";
import {Link} from 'react-router-dom';
import queryString from 'query-string'
import {logLinkMarker} from '../../user/actions';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text style={{backgroundColor:'rgba(39,39,39,0.5)',paddingBottom:'1em'}}>
    <Header
      as='h1'
      content='Cetacean'
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
      }}
    />
    <Header
      as='h2'
      content='An online writing tool for authors'
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '0.5em',
      }}
    />
    <Button as={Link} to='/register' color='teal' size='huge'>
      Brainstorm. Outline. Write. Revise
      <Icon name='right arrow' />
    </Button>
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 500, padding: '1em 0em',
            backgroundImage:'url("/img/main_splash.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
             }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item as='a' href='#Features'>Features</Menu.Item>
                <Menu.Item as='a' href='#Pricing'>Pricing</Menu.Item>
                <Menu.Item as={Link} to='/blog'>Blog</Menu.Item>
                <Menu.Item as={Link} to='/faq'>FAQ</Menu.Item>
                <Menu.Item position='right'>
                  <Button as={Link} to ="/login" inverted={!fixed}>
                    Log in
                  </Button>
                  <Button as={Link} to ="/register" inverted={!fixed} color={fixed?'teal':null} style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Responsive
        as={Sidebar.Pushable}
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}
      >
        <Sidebar
          as={Menu}
          animation='push'
          inverted
          onHide={this.handleSidebarHide}
          vertical
          visible={sidebarOpened}
        >
          <Menu.Item as='a' active>
            Home
          </Menu.Item>
          <Menu.Item as='a' href='#Features'>Features</Menu.Item>
          <Menu.Item as='a' href='#Pricing'>Pricing</Menu.Item>
          <Menu.Item as={Link} to='/blog'>Blog</Menu.Item>
          <Menu.Item as={Link} to='/faq'>FAQ</Menu.Item>
          <Menu.Item as={Link} to='/login'>Log in</Menu.Item>
          <Menu.Item as={Link} to='/register'>Sign Up</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher dimmed={sidebarOpened}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em',
            backgroundImage:'url("/img/writing_splash.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
            }}
            vertical
          >
            <Container>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={this.handleToggle}>
                  <Icon name='sidebar' />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button as={Link} to='/login' inverted>
                    Log in
                  </Button>
                  <Button as={Link} to='/register' inverted style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item>
              </Menu>
            </Container>
            <HomepageHeading mobile />
          </Segment>

          {children}
        </Sidebar.Pusher>
      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const LandingPage = (props) => {
  const dispatch = useDispatch();
  useEffect( () => {
      if(props.location) {
        const values = queryString.parse(props.location.search)
        if(values) {
            dispatch(logLinkMarker(values))
        }
      }
  },[dispatch]);

  return (
  <ResponsiveContainer>
  <CookieConsent
    buttonStyle={{ backgroundColor:'#00b5ad', color: 'white'}}
  >
      I consent to the &nbsp;
      <a style={{color:'#00b5ad'}} href='/privacy.html' target='_blank'>Privacy Policy</a>&nbsp;
      and&nbsp;<a style={{color:'#00b5ad'}} href='/tos.html' target='_blank'>Terms of Service</a>.
  </CookieConsent>    
    <Segment style={{ padding: '4em 0em',backgroundColor:'white' }} vertical id='Features'>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              We Give Writers The Tools To Build Their World
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Creating a story is more than just putting words in a text editor. Cetacean allows authors
              to build and manage characters, locations, and events.
            </p>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Backup Your Stories Online
            </Header>
            <p style={{ fontSize: '1.33em' }}>
                Instead of worrying about losing the manuscripts and notes on your hard drive or USB, 
                Cetacean allows writers to back up instantly to the cloud.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <List size='big'>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Import your existing manuscript
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Cloud-based project storage
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Idea board for notes, links, and images
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Outline plot points
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Pre-made outline templates
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                        Drag &amp; Drop chapters to organize
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                      Character and Location profiles
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                    Story event timeline
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                    Collaborate with other writers
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                    View editorial changes
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                    Get feedback from beta-readers
                    </List.Content>
                </List.Item>
                <List.Item>
                    <List.Icon name='checkmark' color='teal'/>
                    <List.Content>
                    Export to multiple document formats
                    </List.Content>
                </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign='center'>
              <Button as={Link} to='/register' color='teal' size='large'>
                  Start Writing Now
                </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em',backgroundColor:'white' }} vertical id='Features'>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Brainstorm
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Use the idea board to create the thoughts and inspirations for your book. Build your characters' roles, traits, and relationships. Track notes, pictures, and research links to construct the foundation of your story.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Modal centered={true} style={{cursor:'pointer'}} size='large' trigger={<Image size='large' src='/img/brainstorm_screen_thumb.jpg' />}>
              <Modal.Content>
                <Image fluid src='/img/brainstorm_screen.jpg' />
              </Modal.Content>
            </Modal>            
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column floated='left' width={6}>
            <Modal centered={true} size='large' trigger={<Image style={{cursor:'pointer'}} size='large' src='/img/outline_screen_thumb.jpg' />}>
              <Modal.Content>
                <Image fluid src='/img/outline_screen.jpg' />
              </Modal.Content>
            </Modal>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Outline
            </Header>
            <p style={{ fontSize: '1.33em' }}>
                Using the notes from your idea board, create the outline for your book. Use one of the pre-built templates to speed up the process.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Write
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Dark themed to reduce eye strain, Cetacean's rich text editor is designed not to distract you from
              getting your writing done. Drag and drop chapters, reference your outline, and track your daily progress in real-time.
            </p>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Modal centered={true} style={{cursor:'pointer'}} size='large' trigger={<Image size='large' src='/img/writing_screen_thumb.jpg' />}>
              <Modal.Content>
                <Image fluid src='/img/writing_screen.jpg' />
              </Modal.Content>
            </Modal>            
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column floated='left' width={6}>
            <Modal centered={true} size='large' trigger={<Image style={{cursor:'pointer'}} size='large' src='/img/revision_screen_thumb.jpg' />}>
              <Modal.Content>
                <Image fluid src='/img/revision_screen.jpg' />
              </Modal.Content>
            </Modal>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Revise
            </Header>
            <p style={{ fontSize: '1.33em' }}>
                Save checkpoints of your manuscript and share them with beta-readers for feedback. You can send versions of your manuscript to editors and review their changes and notes.
            </p>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column textAlign='center'>
              <Button as={Link} to='/register' color='teal' size='large'>
                  Start Writing Now
                </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment style={{ padding: '8em 0em',backgroundColor:'white'}} vertical id='Pricing'>
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          Pricing
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Cetacean is <strong>free</strong> for our basic features. This is not a trial and you can
          keep using our free option for as long as you like. For an increase in limits
          and access to full features, we offer a <strong>premium</strong> version for a subscription of <strong>$4.99</strong> a month.
          If you need to stop your subscription you will still be able to download your writing projects. Your
          writing belongs to you.
        </p>
        <Button as={Link} to='/register' color='teal' size='large'>
        Start Writing Now
        </Button>

        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          Pricing Plan Features
        </Divider>
        <Table celled unstackable compact={true}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Feature</Table.HeaderCell>
                    <Table.HeaderCell>Free</Table.HeaderCell>
                    <Table.HeaderCell>Premium</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Idea Board</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Character Profiles</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Location Profiles</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Outline Tool</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Projects</Table.Cell>
                    <Table.Cell>One 50k word project</Table.Cell>
                    <Table.Cell>Unlimited*</Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Cloud Backup</Table.Cell>
                    <Table.Cell>Limited to one version</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Upload Images</Table.Cell>
                    <Table.Cell>Remote URLs Only</Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Project Collaboration</Table.Cell>
                    <Table.Cell><Icon name='close' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Share With Beta Readers</Table.Cell>
                    <Table.Cell><Icon name='close' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>View Editorial Changes</Table.Cell>
                    <Table.Cell><Icon name='close' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Priority Customer Support</Table.Cell>
                    <Table.Cell><Icon name='close' /></Table.Cell>
                    <Table.Cell><Icon name='checkmark' /></Table.Cell>
                </Table.Row>

            </Table.Body>
        </Table>
        <span style={{fontSize:'0.75em'}}>
        * Cetacean reserves the right to limit or place restrictions if we feel this is being abused or used
        for malicious purposes.
        </span>
      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Help' />
              <List link inverted>
                <List.Item as={Link} to='/faq' target='_blank'>FAQ</List.Item>
                <List.Item as='a' href="mailto:support@cetacean.app">Contact Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Company' />
              <List link inverted>
                <List.Item as='a' href="https://www.bluewizardlabs.com">About Us</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={7}>
              <Header as='h4' inverted>
                Follow us on social media
              </Header>
              <p>
                <Icon color='teal' size='big' name='twitter square' link onClick={() => window.open('http://www.twitter.com/CetaceanApp')}/>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
  );
}

export default LandingPage