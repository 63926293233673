import * as ActionType from './constants';

export const fetchUserThoughts = (offset,username) => ({ type: ActionType.FETCH_USER_THOUGHTS_SEND, offset, username });
export const fetchUserThoughtsSuccess = (payload) => ({ type: ActionType.FETCH_USER_THOUGHTS_SUCCESS, payload });
export const fetchUserThoughtsError = (payload) => ({ type: ActionType.FETCH_USER_THOUGHTS_ERROR, payload });

export const createThought = (thought) => ({ type: ActionType.CREATE_THOUGHT_SEND, thought });
export const createThoughtSuccess = (payload) => ({ type: ActionType.CREATE_THOUGHT_SUCCESS, payload });
export const createThoughtError = (payload) => ({ type: ActionType.CREATE_THOUGHT_ERROR, payload });

export const reblogThought = (thoughtId,thought) => ({ type: ActionType.REBLOG_THOUGHT_SEND, thoughtId, thought });
export const reblogThoughtSuccess = (payload) => ({ type: ActionType.REBLOG_THOUGHT_SUCCESS, payload });
export const reblogThoughtError = (payload) => ({ type: ActionType.REBLOG_THOUGHT_ERROR, payload });

export const deleteThought = (thoughtId) => ({ type: ActionType.DELETE_THOUGHT_SEND,thoughtId });
export const deleteThoughtSuccess = (thoughtId) => ({ type: ActionType.DELETE_THOUGHT_SUCCESS, thoughtId });
export const deleteThoughtError = (payload) => ({ type: ActionType.DELETE_THOUGHT_ERROR, payload });

export const openNewThoughtModal = () => ({ type: ActionType.OPEN_NEW_THOUGHT_MODAL });
export const closeNewThoughtModal = () => ({ type: ActionType.CLOSE_NEW_THOUGHT_MODAL });

export const likeThought = (thought) => ({ type: ActionType.LIKE_THOUGHT_SEND, thought });
export const likeThoughtSuccess = (payload) => ({ type: ActionType.LIKE_THOUGHT_SUCCESS, payload });
export const likeThoughtError = (payload) => ({ type: ActionType.LIKE_THOUGHT_ERROR, payload });

export const unlikeThought = (thought) => ({ type: ActionType.UNLIKE_THOUGHT_SEND, thought });
export const unlikeThoughtSuccess = (payload) => ({ type: ActionType.UNLIKE_THOUGHT_SUCCESS, payload });
export const unlikeThoughtError = (payload) => ({ type: ActionType.UNLIKE_THOUGHT_ERROR, payload });

export const openProfileModal = (username) => ({ type: ActionType.OPEN_PROFILE_MODAL, username });
export const openProfileModalSuccess = (payload) => ({ type: ActionType.OPEN_PROFILE_MODAL_SUCCESS, payload });
export const openProfileModalError = (payload) => ({ type: ActionType.OPEN_PROFILE_MODAL_ERROR, payload });
export const closeProfileModal = () => ({ type: ActionType.CLOSE_PROFILE_MODAL });

export const fetchUserProfile = (username) => ({ type: ActionType.FETCH_USER_PROFILE_SEND,username });
export const fetchUserProfileSuccess = (payload) => ({ type: ActionType.FETCH_USER_PROFILE_SUCCESS, payload });
export const fetchUserProfileError = (payload) => ({ type: ActionType.FETCH_USER_PROFILE_ERROR, payload });

export const fetchFollowedFeed = (offset) => ({ type: ActionType.FETCH_FOLLOWED_FEED_SEND,offset });
export const fetchFollowedFeedSuccess = (payload) => ({ type: ActionType.FETCH_FOLLOWED_FEED_SUCCESS, payload });
export const fetchFollowedFeedError = (payload) => ({ type: ActionType.FETCH_FOLLOWED_FEED_ERROR, payload });

export const fetchPublicFeed = (offset,tag) => ({ type: ActionType.FETCH_PUBLIC_FEED_SEND,offset,tag });
export const fetchPublicFeedSuccess = (payload) => ({ type: ActionType.FETCH_PUBLIC_FEED_SUCCESS, payload });
export const fetchPublicFeedError = (payload) => ({ type: ActionType.FETCH_PUBLIC_FEED_ERROR, payload });

export const fetchThought = (thoughtId) => ({ type: ActionType.FETCH_THOUGHT_SEND,thoughtId });
export const fetchThoughtSuccess = (payload) => ({ type: ActionType.FETCH_THOUGHT_SUCCESS, payload });
export const fetchThoughtError = (payload) => ({ type: ActionType.FETCH_THOUGHT_ERROR, payload });

export const openConversation = (thoughtId) => ({ type: ActionType.OPEN_CONVERSATION, thoughtId });
export const openConversationSuccess = (payload) => ({ type: ActionType.OPEN_CONVERSATION_SUCCESS, payload });
export const openConversationError = (payload) => ({ type: ActionType.OPEN_CONVERSATION_ERROR, payload });
export const closeConversation = () => ({ type: ActionType.CLOSE_CONVERSATION });

export const createComment = (thoughtId,comment) => ({ type: ActionType.CREATE_COMMENT_SEND, thoughtId,comment });
export const createCommentSuccess = (payload) => ({ type: ActionType.CREATE_COMMENT_SUCCESS, payload });
export const createCommentError = (payload) => ({ type: ActionType.CREATE_COMMENT_ERROR, payload });

export const dismissFeedError = () => ({ type: ActionType.DISMISS_FEED_ERROR });

export const followUser = (username) => ({ type: ActionType.FOLLOW_USER_SEND, username });
export const followUserSuccess = (payload) => ({ type: ActionType.FOLLOW_USER_SUCCESS, payload });
export const followUserError = (payload) => ({ type: ActionType.FOLLOW_USER_ERROR, payload });

export const unfollowUser = (username) => ({ type: ActionType.UNFOLLOW_USER_SEND, username });
export const unfollowUserSuccess = (payload) => ({ type: ActionType.UNFOLLOW_USER_SUCCESS, payload });
export const unfollowUserError = (payload) => ({ type: ActionType.UNFOLLOW_USER_ERROR, payload });

export const fetchFollowing = () => ({ type: ActionType.FETCH_FOLLOWING_SEND });
export const fetchFollowingSuccess = (payload) => ({ type: ActionType.FETCH_FOLLOWING_SUCCESS, payload });
export const fetchFollowingError = (payload) => ({ type: ActionType.FETCH_FOLLOWING_ERROR, payload });

export const fetchFollowers = () => ({ type: ActionType.FETCH_FOLLOWERS_SEND });
export const fetchFollowersSuccess = (payload) => ({ type: ActionType.FETCH_FOLLOWERS_SUCCESS, payload });
export const fetchFollowersError = (payload) => ({ type: ActionType.FETCH_FOLLOWERS_ERROR, payload });

export const openReblogModal = (thought) => ({ type: ActionType.OPEN_REBLOG_MODAL,thought });
export const closeReblogModal = () => ({ type: ActionType.CLOSE_REBLOG_MODAL });

export const openReadThoughtChapterModal = (chapter) => ({ type: ActionType.OPEN_READ_THOUGHT_CHAPTER_MODAL,chapter });
export const closeReadThoughtChapterModal = () => ({ type: ActionType.CLOSE_READ_THOUGHT_CHAPTER_MODAL });
