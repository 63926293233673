import PropTypes from 'prop-types';

const Showable = props => {
    if(props.showIf) {
        return props.children;
    }
    return null;
};

Showable.propTypes = {
    showIf: PropTypes.bool
};

export default Showable;