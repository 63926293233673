import * as ActionTypes from './constants';

export const handleCharacters = () => {
    return {
    [ActionTypes.ADD_CHARACTER]: (state,action) => {
        let addCharacterProject = {...state.openProject};
        let characterDate = new Date();
        let addedCharacter = {
            _id: 'NEW_'+characterDate.getTime(),
            name: 'New Character',
            relationships: [],
            motives: []
        };
        addCharacterProject.characters.push(addedCharacter);
        return {
            ...state,
            openProject: addCharacterProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_CHARACTER_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingCharacter: true,
            createCharacterError: false,
            createCharacterErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_CHARACTER_SUCCESS]: (state,action) => {
        let characterAddedProject = {...state.openProject};
        characterAddedProject.characters.push(action.payload.data.character);
        return {
            ...state,
            isCreatingCharacter: false,
            openProject: characterAddedProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_CHARACTER_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingCharacter: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.ADD_RELATIONSHIP]: (state,action) => {
        let addRelProject = {...state.openProject};

        let relDate = new Date();
        let addedRel = {
            _id: 'NEW_'+relDate.getTime(),
            subject: action.payload.subject,
            status: [{status: action.payload.status}],
            target: action.payload.target
        };
        addRelProject.relationships.push(addedRel);

        return {
            ...state,
            openProject: addRelProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_NEW_RELATIONSHIP_MODAL]: (state,action) => {
        return {
            ...state,
            showNewRelationshipModal: true
        };
    },
    [ActionTypes.HIDE_NEW_RELATIONSHIP_MODAL]: (state,action) => {
        return {
            ...state,
            showNewRelationshipModal: false
        };
    },
    [ActionTypes.CREATE_RELATIONSHIP_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingRelationship: true,
            createRelationshipError: false,
            createRelationshipErrorMessage: '',
            createRelationCharacterId: action.characterId
        };
    },
    [ActionTypes.CREATE_RELATIONSHIP_SUCCESS]: (state,action) => {
        let relationAddedProject = {...state.openProject};
        for(let i=0;i<relationAddedProject.characters.length;i++) {
            if(relationAddedProject.characters[i]._id === state.createRelationCharacterId) {
                relationAddedProject.characters[i].relationships.push(action.payload.data.relationship);
            }
        }
        return {
            ...state,
            isCreatingRelationship: false,
            openProject: relationAddedProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_RELATIONSHIP_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingRelationship: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_CHARACTERS_PANEL]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'CharactersPanel'
        };
    },        
    [ActionTypes.OPEN_CHARACTER]: (state,action) => {
        let newOpenCharacter = state.openProject.characters.filter(character => character._id === action.characterId)[0];
        return {
            ...state,
            openProjectCharacter: newOpenCharacter,
            openProjectPanel: 'Character'
        };
    },
    [ActionTypes.CLOSE_CHARACTER]: (state,action) => {
        return {
            ...state,
            showEditCharacterModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_CHARACTER]: (state,action) => {
        let updateLocalCharacterProject = {...state.openProject};
        let updatedCharacter = {...state.openProjectCharacter};        
        for(let i=0;i<updateLocalCharacterProject.characters.length;i++) {
            let character = updateLocalCharacterProject.characters[i];
            if(character._id === state.openProjectCharacter._id) {
                character[action.field] = action.value;
                updatedCharacter[action.field] = action.value;
            }
        }
        return {
            ...state,
            openProject: updateLocalCharacterProject,
            openProjectCharacter: updatedCharacter,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_RELATIONSHIP]: (state,action) => {
        let openRelationship = state.openProject.relationships.filter(relationship => relationship._id === action.relationshipId)[0];
        return {
            ...state,
            openProjectRelationship: openRelationship,
            showEditRelationshipModal: true
        };
    },
    [ActionTypes.CLOSE_RELATIONSHIP]: (state,action) => {
        return {
            ...state,
            showEditRelationshipModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_RELATIONSHIP]: (state,action) => {
        let updatedRelationshipProject = {...state.openProject};
        let updatedRelationship = {...state.openProjectRelationship};
        for(let j=0;j< updatedRelationshipProject.relationships.length;j++) {
            let relationship = updatedRelationshipProject.relationships[j];
            if(relationship._id === state.openProjectRelationship._id) {
                if(action.field === 'status') {
                    updatedRelationship.status[0].status = action.value;
                }
                else {
                    relationship[action.field] = action.value;
                    updatedRelationship[action.field] = action.value;
                }
            }
        }
        return {
            ...state,
            openProject: updatedRelationshipProject,
            openProjectRelationship: updatedRelationship,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_DELETE_RELATIONSHIP_MODAL]: (state,action) => {
        return {
            ...state,
            showEditRelationshipModal: false,
            showDeleteRelationshipModal:true,
            deleteRelationshipModalRelationshipId: action.relationshipId,
            deleteRelationshipModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_RELATIONSHIP_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteRelationshipModal:false
        };
    },
    [ActionTypes.REMOVE_RELATIONSHIP]: (state,action) => {
        let remRelationshipOpenProject = {...state.openProject};
        let remCharacterRelationshipIndex = remRelationshipOpenProject.relationships.findIndex( relationship => {
            return (relationship._id === state.deleteRelationshipModalRelationshipId);
        });

        if(!state.deleteRelationshipModalRelationshipId.startsWith('NEW_')) {
            if(!remRelationshipOpenProject.deletedRelationships) {
                remRelationshipOpenProject.deletedRelationships = [];
            }
            remRelationshipOpenProject.deletedRelationships.push(state.deleteRelationshipModalRelationshipId);
        }
        remRelationshipOpenProject.relationships.splice(remCharacterRelationshipIndex,1);
        let remRelationshipOptional = {};
        if(state.openProjectRelationship._id === state.deleteRelationshipModalRelationshipId) {
            remRelationshipOptional.openProjectRelationship = remRelationshipOpenProject.relationships[0];
        }
        return {
            ...state,
            ...remRelationshipOptional,
            isDeletingRelationship: false,
            openProject: remRelationshipOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_RELATIONSHIP_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingRelationship: true,
            deleteRelationshipError: false,
            deleteRelationshipErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_RELATIONSHIP_SUCCESS]: (state,action) => {
        let delRelationshipOpenProject = {...state.openProject};
        let delrelationshipChapterIndex = delRelationshipOpenProject.characters.findIndex( character => {
            return (character._id === state.deleteRelationshipModalCharacterId);
        });
        let delRelationshipCharacter = delRelationshipOpenProject.characters[delrelationshipChapterIndex];
        let delCharacterRelationshipIndex = delRelationshipCharacter.relationships.findIndex( relationship => {
            return (relationship._id === state.deleteRelationshipModalRelationshipId);
        });

        delRelationshipCharacter.relationships.splice(delCharacterRelationshipIndex,1);
        let delRelationshipOptional = {};
        if(state.openProjectRelationship._id === state.deleteRelationshipModalRelationshipId) {
            delRelationshipOptional.openProjectRelationship = delRelationshipOpenProject.characters[0].relationships[0];
        }
        return {
            ...state,
            ...delRelationshipOptional,
            isDeletingRelationship: false,
            openProject: delRelationshipOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_RELATIONSHIP_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingRelationship: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_DELETE_CHARACTER_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteCharacterModal:true,
            deleteCharacterModalCharacterId: action.characterId,
            deleteCharacterModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_CHARACTER_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteCharacterModal:false
        };
    },
    [ActionTypes.REMOVE_CHARACTER]: (state,action) => {
        let remCharOpenProject = {...state.openProject};
        let remCharIndex = remCharOpenProject.characters.findIndex( character => {
            return (character._id === state.deleteCharacterModalChapterId);
        });
        if(!state.deleteCharacterModalCharacterId.startsWith('NEW_')) {
            if(!remCharOpenProject.deletedCharacters) {
                remCharOpenProject.deletedCharacters = [];
            }
            remCharOpenProject.deletedCharacters.push(state.deleteCharacterModalChapterId);
        }
        remCharOpenProject.characters.splice(remCharIndex,1);
        let remCharOptional = {};
        if(state.openProjectCharacter._id === state.deleteCharacterModalCharacterId) {
            remCharOptional.openProjectCharacter = {};
            remCharOptional.openProjectRelationship = {};
            switch(remCharOpenProject.view) {
                case 'Brainstorm':
                    remCharOptional.openProjectPanel = 'IdeaBoard';
                    break;
                case 'Outline':
                    remCharOptional.openProjectPanel = 'Outline';
                    break;
                default:
                    remCharOptional.openProjectPanel = '';
            }
        }
        return {
            ...state,
            ...remCharOptional,
            isDeletingChapter: false,
            openProject: remCharOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_CHARACTER_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingCharacter: true,
            deleteCharacterError: false,
            deleteCharacterErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_CHARACTER_SUCCESS]: (state,action) => {
        let delCharOpenProject = {...state.openProject};
        let delCharIndex = delCharOpenProject.characters.findIndex( character => {
            return (character._id === state.deleteCharacterModalChapterId);
        });
        
        delCharOpenProject.characters.splice(delCharIndex,1);
        let delCharOptional = {};
        if(state.openProjectCharacter._id === state.deleteCharacterModalCharacterId) {
            delCharOptional.openProjectCharacter = delCharOpenProject.characters[0];
            delCharOptional.openProjectRelationship = delCharOpenProject.characters[0].relationships[0];
        }
        return {
            ...state,
            ...delCharOptional,
            isDeletingChapter: false,
            openProject: delCharOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_CHARACTER_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingCharacter: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_CHARACTER_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showCharacterBreakout: true,
            openBreakoutCharacter: action.character
        };
    },
    [ActionTypes.CLOSE_CHARACTER_BREAKOUT]: (state,action) => {
        return {
            ...state,
            showCharacterBreakout: false,
            openBreakoutCharacter: {}
        };
    },
    [ActionTypes.OPEN_CHARACTERS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Characters'
        };
    }
}
};
