import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import RichTextEditor from '../../editor/RichTextEditor';
import {updateLocalResearchNotes,updateProject} from '../../../project/actions';

const EditResearchNotesPanel = (props) =>  {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);

    const textElement = (projects.openProjectResearch.text) 
    ? JSON.parse(projects.openProjectResearch.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
            <RichTextEditor 
                key={projects.openProjectResearch._id}
                content={textElement}
                onUpdate={ (value) => dispatch(updateLocalResearchNotes(value))}
                focus={true}
                footer={true}
                centered={true}
                ignoreSelectionUpdate={true}
                lastSaveDate={projects.lastSaveDate}
                onSave={() => dispatch(updateProject({project: projects.openProject}))}
            />
    );
}

export default EditResearchNotesPanel;