import React,{useState,useEffect} from 'react';
import {Dropdown,Button,Icon,Label} from 'semantic-ui-react';

const calculate = (years,months,days,hours,minutes) => {
    return minutes + (hours * 60) + (days * 60 * 24) + (months * 60 * 24 * 30) + (years * 60 * 24 * 30 * 12);
}

const Epoch = (props) => {
    const [editMode,setEditMode] = useState(false);
    const [centuries,setCenturies] = useState(0);
    const [years,setYears] = useState(0);
    const [months,setMonths] = useState(0);
    const [days,setDays] = useState(0);
    const [hours,setHours] = useState(0);
    const [minutes,setMinutes] = useState(0);

    useEffect( () => {
        if(props.value) {
            const vals = props.value.split(',');
            setCenturies(vals[0]);
            setYears(vals[1]);
            setMonths(vals[2]);
            setDays(vals[3]);
            setHours(vals[4]);
            setMinutes(vals[5]);
        }
    },[]);

    function pad(num, size) {
        var s = num+'';
        while (s.length < size) s = '0' + s;
        return s;
    }    
    const format = (cent,yr,mon,dy,hr,min) => {
        return cent+','+yr+','+mon+','+dy+','+hr+','+min;
    }

    const tag = props.label ? (<Label size='large'>{props.label}</Label>):null;

    const centuryOptions = Array.from(Array(50).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    const yearOptions = Array.from(Array(99).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    const monthOptions = Array.from(Array(12).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    const dayOptions = Array.from(Array(31).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    const hourOptions = Array.from(Array(24).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    const minuteOptions = Array.from(Array(60).keys()).map( opt => ({
        key: opt, text: pad(opt,2), value: opt+''
    }));

    if(editMode) {
        return (
            <div>
                {tag}
                MM/DD/CCYY&nbsp;&nbsp;
                <Dropdown compact selection value={months} options={monthOptions} onChange={(ev,dat) => {
                    setMonths(dat.value);
                    props.onChange && props.onChange(format(
                        centuries,years,dat.value,days,hours,minutes
                    ));
                    }}/>
                &nbsp;/&nbsp;
                <Dropdown compact selection value={days} options={dayOptions} onChange={(ev,dat) => {
                    setDays(dat.value);
                    props.onChange && props.onChange(format(
                        centuries,years,months,dat.value,hours,minutes
                    ));
                    }}/>
                &nbsp;/&nbsp;
                <Dropdown compact selection value={centuries} options={centuryOptions} onChange={(ev,dat) => {
                    setCenturies(dat.value);
                    props.onChange && props.onChange(format(
                        dat.value,years,months,days,hours,minutes
                    ));
                    }}/>
                <Dropdown compact selection value={years} options={yearOptions} onChange={(ev,dat) => {
                    setYears(dat.value);
                    props.onChange && props.onChange(format(
                        centuries,dat.value,months,days,hours,minutes
                    ));
                    }}/>
                &nbsp;
                &nbsp;HH:mm&nbsp;&nbsp;
                <Dropdown compact selection value={hours} options={hourOptions} onChange={(ev,dat) => {
                    setHours(dat.value);
                    props.onChange && props.onChange(format(
                        centuries,years,months,days,dat.value,minutes
                    ));
                    }}/>
                &nbsp;:&nbsp;
                <Dropdown compact selection value={minutes} options={minuteOptions} onChange={(ev,dat) => {
                    setMinutes(dat.value);
                    props.onChange && props.onChange(format(
                        centuries,years,months,days,hours,dat.value
                    ));
                    }}/>
                &nbsp;
                <Button icon size='mini' onClick={() => setEditMode(false)}><Icon name='cancel'/></Button>
            </div>
        );
    }

    return(
        <div>
            {tag}&nbsp;&nbsp;{pad(months,2)}/{pad(days,2)}/{pad(centuries,2)}{pad(years,2)}&nbsp;{pad(hours,2)}:{ pad(minutes,2)}
            &nbsp;&nbsp;
            <Button icon size='mini' onClick={() => setEditMode(true)}><Icon name='edit'/></Button>
        </div>
    ); 
}

export default Epoch;