import React from 'react';
import ReactDiffViewer,{DiffMethod} from 'react-diff-viewer';
import {useSelector} from 'react-redux';

const TextDiffPanel = (props) => {
    const oldCode = useSelector(state => state.projects.oldDiffText);
    const newCode = useSelector(state => state.projects.newDiffText);

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <div style={{flexShrink: 0}}>
                
            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{marginTop:'1em',marginLeft:'1em'}}>
                        <ReactDiffViewer 
                            oldValue={oldCode} 
                            newValue={newCode} 
                            splitView={true} 
                            useDarkTheme={true}
                            compareMethod={DiffMethod.WORDS}
                        />
                </div>
            </div>            
        </div>
    );
};

export default TextDiffPanel;