import {convertSceneToPlainText,convertElementToPlainText} from './exportToPlainText';

export const MAX_BASIC_WORDCOUNT = 50000;

export function countWordsInProject(project) {
    switch(project.projectType) {
        case 'Book':
            return countWordsInChapteredProject(project);
        case 'Basic':
            return countWordsInChapteredProject(project);
        default:
            return countWordsInBookByScenesProject(project);
    }
}

export function countWordsInChapteredProject(project) {
    let count = 0;
    for(let i=0;i<project.chapters.length;i++) {
        const chapter = project.chapters[i];
        if(chapter) {
            count = count + wordCount(convertElementToPlainText(chapter.text));
        }
    }
    return count;
}

export function countWordsInBookByScenesProject(project) {
    let count = 0;
    for(let i=0;i<project.chapters.length;i++) {
        const chapter = project.chapters[i];
        if(chapter) {
            count = count + countWordsInChapter(chapter);
        }
    }
    return count;
}

export function countWordsInChapter(chapter) {
    let count = 0;
    for(let i=0;i<chapter.scenes.length;i++){
        const scene = chapter.scenes[i];
        if(scene) {
            count = count + countWordsInScene(scene);    
        }
    }
    return count;
}

export function countWordsInScene(scene) {
    if(scene.text) {
        return wordCount(convertSceneToPlainText(scene));
    }
    return 0;
}

function wordCount(s) {
    return s.split(' ')
    .filter(function(n) { return n !== '' })
    .length;    
}