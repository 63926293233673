import React from 'react';
import {useSelector} from 'react-redux';
import {Image} from 'semantic-ui-react';

const Avatar = ({thought,fullsize,user,profile}) => {
    let userAvatar = useSelector(state => state.user.avatar);
    if(thought) {
        userAvatar = thought.avatar;
    }
    if(user) {
        userAvatar = user.avatar;
    }
    if(profile) {
        userAvatar = profile.avatar;
    }
    const imageToUse = userAvatar ? userAvatar : '/img/default-persona.png';
    let style={ maxWidth:'100%',height:'auto' };
    if(fullsize) {
        style.width = '100%';
        style.borderRadius = '8px';
    }
    return (
        <Image style={style} avatar={!fullsize} src={imageToUse} />
    );
};

export default Avatar;