import React,{useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Link} from 'react-router-dom'
import {Button, Icon, Modal,Input,Dimmer,Loader,Responsive,Segment,Image} from 'semantic-ui-react';
import {format} from 'date-fns';
import ComposeMessageModal from '../inbox/ComposeMessageModal';
import {fetchContacts,requestContact, deleteContact, approveContact} from '../../contact/actions';
import {openComposeModal} from '../../inbox/actions';
import {hasPremium} from '../../services/roles';

const ContactsPanel = (props) => {
    const dispatch = useDispatch();
    const contacts = useSelector(state => state.contacts);
    const user = useSelector(state => state.user);
    const [showDelete,setShowDelete] = useState(false);
    const [contactToDelete, setContactToDelete] = useState();
    const [inviteName,setInviteName] = useState('');
    const [inviteEmail,setInviteEmail] = useState('');
    const [showInvite,setShowInvite] = useState(false);
    const [contactToMessage,setContactToMessage] = useState();

    useEffect( () => {
        dispatch(fetchContacts())
    },[dispatch]);

    const pendingList = contacts.pending.map( contact => {
        const avatar = contact.avatar || '/img/default-persona.png';
        return (
            <Segment inverted color='orange' key={contact._id}>
                <div style={{display:'block',whiteSpace:'nowrap'}}>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Image avatar src={avatar} />
                        <span style={{color:'white',fontWeight:'bold'}}>{contact.name}</span>
                        <span style={{marginLeft:'1em',color:'white'}}>Requesting to be a contact</span>
                        <Button color='teal' floated='right'
                                onClick={() => dispatch(approveContact(contact._id))}
                            >Approve</Button>
                            <Button floated='right' onClick={() => {
                                setContactToDelete(contact._id);
                                setShowDelete(true);
                            }}>Deny</Button>
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                        <div style={{color:'white',fontWeight:'bold'}}>
                            <Image avatar src='/img/default-persona.png' />{contact.name}
                        </div>
                        <div style={{color:'white'}}>Requesting to be a contact</div>
                        <Button color='teal'
                                onClick={() => dispatch(approveContact(contact._id))}
                            >Approve</Button>
                        <Button onClick={() => {
                            setContactToDelete(contact._id);
                            setShowDelete(true);
                        }}>Deny</Button>
                    </Responsive>
                </div>
            </Segment>
        );
    });

    const requestedList = contacts.requested.map( contact => {
        const avatar = contact.avatar || '/img/default-persona.png';
        return (
            <Segment inverted color='grey' key={contact._id}>
                <div style={{display:'block',whiteSpace:'nowrap'}}>
                    <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                        <Image avatar src={avatar} />
                        <span style={{color:'white',fontWeight:'bold'}}>{contact.name}</span>
                        <span style={{marginLeft:'1em',color:'white'}}>Waiting for approval</span>
                        <Button floated='right' icon='delete' onClick={() => {
                            setContactToDelete(contact._id);
                            setShowDelete(true);
                        }}/>
                    </Responsive>
                    <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                        <div style={{color:'white',fontWeight:'bold'}}>
                            <Image avatar src='/img/default-persona.png' />{contact.name}
                        </div>
                        <div style={{color:'white'}}>Waiting for approval</div>
                        <Button icon='delete' onClick={() => {
                            setContactToDelete(contact._id);
                            setShowDelete(true);
                        }}/>
                    </Responsive>
                </div>
            </Segment>
        );
    });

    const mututalList = contacts.mututal.map( contact => {
        const avatar = contact.avatar || '/img/default-persona.png';
        const messageButton = hasPremium(user) ? (
            <Button floated='right' color='teal' icon='edit'  content='Message'
                onClick={() => {
                    setContactToMessage(contact._id);
                    dispatch(openComposeModal());
                }}
            />
        ) : (
            <Button floated='right' color='teal' icon='edit'  content='Message' as={Link} to='/getpremium' />

        );
        return (
        <Segment inverted key={contact._id}>
            <div style={{display:'block',whiteSpace:'nowrap'}}>
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Image avatar src={avatar} />
                    <span style={{color:'white',fontWeight:'bold'}}>{contact.name}</span>
                    <span style={{color:'white',marginLeft:'1em'}}>
                        Added: {format(new Date(contact.createDate),'MMM dd, yyyy')}
                    </span>
                    <Button floated='right' icon='delete' onClick={() => {
                        setContactToDelete(contact._id);
                        setShowDelete(true);
                    }}/>
                    {messageButton}
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>                
                    <div style={{color:'white',fontWeight:'bold'}}>
                        <Image avatar src='/img/default-persona.png' />
                        {contact.name}
                    </div>
                    <div style={{color:'white'}}>
                        Added: {format(new Date(contact.createDate),'MMM dd, yyyy')}
                    </div>
                    <div>
                        {messageButton}
                    <Button icon='delete' onClick={() => {
                        setContactToDelete(contact._id);
                        setShowDelete(true);
                    }}/>
                    </div>
                </Responsive>
            </div>
        </Segment>
    )});

    const noContacts = ( (contacts.mututal.length === 0) && (contacts.pending.length === 0) && (contacts.requested.length === 0)) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%', margin: '2em'}}>
            <h1 style={{ color: 'white' }}>You Have No Contacts</h1>
        </div>

    ) : null;

    const addContactButton = hasPremium(user) ? (
        <Button icon color='teal' onClick={() => setShowInvite(true)}><Icon name='plus circle'/> Add Contact</Button>        
    ) : (
        <Button icon color='teal' as={Link} to='/getpremium'><Icon name='plus circle'/> Add Contact</Button>
    );

    return (
        <div style={{ width: '100%'}}>
        <div style={{margin:'1em'}}>
            <Dimmer active={contacts.isFetchingProjects}>
                <Loader>Loading</Loader>
            </Dimmer>
            <ComposeMessageModal contactId={contactToMessage}/>
            <Modal open={showInvite}>
                <Modal.Header style={{backgroundColor:'#363636',color:'white'}}>Invite a new contact</Modal.Header>
                <Modal.Content style={{backgroundColor:'#363636',color:'white'}}>
                        <Input 
                            fluid 
                            placeholder='Existing username'
                            value={inviteName}
                            onChange={ (event) => setInviteName(event.target.value)}
                            action={{
                                color:'teal',
                                labelPosition:'right',
                                icon:'user',
                                content:'Invite User',
                                onClick: () => {
                                    dispatch(requestContact({
                                        contact: {
                                            requestType: 'name',
                                            name: inviteName
                                        }
                                    }));
                                    setShowInvite(false);
                                },
                                loading:contacts.isRequestingContact
                            }}
                        />
                        <p/>
                        <Input 
                            fluid 
                            placeholder="Email an invite to user"
                            value={inviteEmail}
                            onChange={ (event) => setInviteEmail(event.target.value)}
                            action={{
                                color:'teal',
                                labelPosition:'right',
                                icon:'mail',
                                content:'Email Invite',
                                onClick: () => {
                                    dispatch(requestContact({
                                        contact: {
                                            requestType: 'email',
                                            email: inviteEmail
                                        }
                                    }));
                                    setShowInvite(false);
                                },
                                loading:contacts.isRequestingContact
                            }}

                        />
                </Modal.Content>
                <Modal.Actions style={{backgroundColor:'#363636',color:'white'}}>
                    <Button onClick={() => setShowInvite(false)}>Cancel</Button>
                </Modal.Actions>
            </Modal>
            <Modal open={showDelete}>
                <Modal.Header style={{backgroundColor:'#363636',color:'white'}}>Confirm Delete</Modal.Header>
                <Modal.Content style={{backgroundColor:'#363636',color:'white'}}>
                Are you sure you wish to remove {contactToDelete?contactToDelete.name:''} as a contact?
                </Modal.Content>
                <Modal.Actions style={{backgroundColor:'#363636',color:'white'}}>
                    <Button onClick={() => setShowDelete(false)}>Cancel</Button>
                    <Button color='teal' onClick={() => {
                        dispatch(deleteContact(contactToDelete));
                        setShowDelete(false);
                    }}>Delete</Button>
                </Modal.Actions>
            </Modal>
          <div>
                {addContactButton}
            </div>
            <p/>
            {noContacts}
            <Segment.Group>
                {pendingList}
            </Segment.Group>
            <Segment.Group>
                {requestedList}
            </Segment.Group>
            <Segment.Group>
                {mututalList}
            </Segment.Group>
        </div>
        </div>
    );

};

export default ContactsPanel;