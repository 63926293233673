import React,{useState} from 'react';
import {Button,Input,List,Icon, Modal,Segment} from 'semantic-ui-react';

const MotivePanel = (props) => {
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [modalValue,setModalValue] = useState('');
    const [modalIndex,setModalIndex] = useState(0);

    const motiveList = props.value.map( (motive,index) => {
        
        return (
            <List.Item key={'motive'+index} onClick={() => {
                setModalValue('');
                setModalIndex(index);
                setShowEditModal(true);
            }}>
                <List.Content style={{color:'white'}}>{motive}</List.Content>
            </List.Item>
        )});
    return (
        <Segment inverted>
            <List divided inverted relaxed verticalAlign='middle' selection>
                {motiveList}
            </List>
            <Button onClick={() => setShowCreateModal(true)}>Add a character motive</Button>

            <Modal open={showEditModal}>
                    <Modal.Header style={{ backgroundColor: '#272727',color:'white' }}>Edit Character Motive</Modal.Header>
                    <Modal.Content style={{ backgroundColor: '#272727',color:'white' }}>
                        <Input fluid value={props.value[modalIndex]} onChange={(ev) => {
                                let newVal = props.value.slice();
                                newVal[modalIndex] = ev.target.value;
                                props.onChange(newVal);
                            }} 
                            placeholder='A one line description of a character motive...'
                        />
                    </Modal.Content>
                    <Modal.Actions style={{ backgroundColor: '#272727',color:'white' }}>
                    <Button onClick={() => {
                            let newVal = props.value.slice();
                            newVal.splice(modalIndex,1);
                            props.onChange(newVal);
                            setShowEditModal(false);
                            setModalValue('');
                        }} >Delete</Button>

                        <Button onClick={() => {
                            setShowEditModal(false);
                            setModalValue('');
                        }}>Done</Button>
                    </Modal.Actions>
                </Modal>

            <Modal open={showCreateModal}>
                <Modal.Header style={{ backgroundColor: '#272727',color:'white' }}>Add Character Motive</Modal.Header>
                <Modal.Content style={{ backgroundColor: '#272727',color:'white' }}>
                    <Input fluid value={modalValue} onChange={(ev) => setModalValue(ev.target.value)} 
                        placeholder='A one line description of a character motive...'
                    />
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: '#272727',color:'white' }}>
                    <Button onClick={() => {
                        setShowCreateModal(false);
                        setModalValue('');
                    }}>Cancel</Button>
                    <Button color='teal' onClick={() => {
                        let newVal = props.value.slice();
                        newVal.push(modalValue);
                        props.onChange(newVal);
                        setShowCreateModal(false);
                        setModalValue('');
                    }}>Save</Button>
                </Modal.Actions>
            </Modal>
        </Segment>
    );
};

export default MotivePanel;