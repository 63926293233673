import * as ActionTypes from './constants';

export const handleEvents = () => {
    return {

    [ActionTypes.SHOW_CREATE_EVENT_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateEventModal:true
        };
    },
    [ActionTypes.HIDE_CREATE_EVENT_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateEventModal:false
        };
    },
    [ActionTypes.ADD_EVENT]: (state,action) => {
        let addEvProject = {...state.openProject};
        let evDate = new Date();
        let addedEv = {...action.payload.event};
        addedEv._id = 'NEW_'+evDate.getTime();        
        let evReorderedEvents = addEvProject.events.slice();
        evReorderedEvents.push(addedEv);
        evReorderedEvents.sort( (a,b) => (a.epoch > b.epoch) ? 1 : -1);
        addEvProject.events = evReorderedEvents;

        return {
            ...state,
            openProject: addEvProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_EVENT_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingEvent: true,
            createEventError: false,
            createEventErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_EVENT_SUCCESS]: (state,action) => {
        let eventAddedProject = {...state.openProject};
        eventAddedProject.events.push(action.payload.data.event);
        return {
            ...state,
            isCreatingEvent: false,
            openProject: eventAddedProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.CREATE_EVENT_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingEvent: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_DELETE_EVENT_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteEventModal:true,
            deleteEventModalEventId: action.eventId,
            deleteEventModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_DELETE_EVENT_MODAL]: (state,action) => {
        return {
            ...state,
            showDeleteEventModal:false
        };
    },
    [ActionTypes.REMOVE_EVENT]: (state,action) => {
        let remEvOpenProject = {...state.openProject};
        let remEvIndex = remEvOpenProject.events.findIndex( event => {
            return (event._id === state.deleteEventModalEventId);
        });
        
        if(!state.deleteEventModalEventId.startsWith('NEW_')) {
            if(!remEvOpenProject.deletedEvents) {
                remEvOpenProject.deletedEvents = [];
            }
            remEvOpenProject.deletedEvents.push(state.deleteEventModalEventId);
        }
        remEvOpenProject.events.splice(remEvIndex,1);
        return {
            ...state,
            isDeletingEvent: false,
            openProject: remEvOpenProject,
            openProjectEvent: {},
            showEditEventModal: false,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_EVENT_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingEvent: true,
            deleteEventError: false,
            deleteEventErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_EVENT_SUCCESS]: (state,action) => {
        let delEvOpenProject = {...state.openProject};
        let delEvIndex = delEvOpenProject.events.findIndex( event => {
            return (event._id === state.deleteEventModalEventId);
        });
        
        delEvOpenProject.events.splice(delEvIndex,1);
        let delEvOptional = {};
        if(state.openProjectEvent._id === state.deleteEventModalEventId) {
            delEvOptional.openProjectEvent = delEvOpenProject.events[0];
        }
        return {
            ...state,
            ...delEvOptional,
            isDeletingEvent: false,
            openProject: delEvOpenProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.DELETE_EVENT_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingEvent: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_EVENT]: (state,action) => {
        let newOpenEvent = state.openProject.events.filter(event => event._id === action.eventId)[0];
        return {
            ...state,
            openProjectEvent: newOpenEvent,
            showEditEventModal: true,
        };
    },
    [ActionTypes.CLOSE_EVENT]: (state,action) => {
        return {
            ...state,
            showEditEventModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_EVENT]: (state,action) => {
        let updateLocalEventProject = {...state.openProject};
        let updatedEvent = {...state.openProjectEvent};
        for(let i=0;i<updateLocalEventProject.events.length;i++) {
            let event = updateLocalEventProject.events[i];
            if(event._id === state.openProjectEvent._id) {
                event[action.field] = action.value;
                updatedEvent[action.field] = action.value;
            }
        }
        let updatedAndSortedEvents = updateLocalEventProject.events.slice();
        updatedAndSortedEvents.sort( (a,b) => (a.epoch > b.epoch) ? 1 : -1);
        updateLocalEventProject.events = updatedAndSortedEvents;
        return {
            ...state,
            openProject: updateLocalEventProject,
            openProjectEvent: updatedEvent,
            isProjectDirty: true
        };
    },
    [ActionTypes.OPEN_TIMELINE]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'Timeline'
        };
    },
    [ActionTypes.MOVE_EVENT_EARLIER]: (state,action) => {
        let moveEventUpProject = {...state.openProject};
        let eventUpIndex = moveEventUpProject.events.findIndex( (event) => {
            return (event._id === action.eventId);
        });
        if(eventUpIndex >= 1) {
            let movedEvent = moveEventUpProject.events.splice(eventUpIndex,1)[0];
            moveEventUpProject.events.splice(eventUpIndex-1,0,movedEvent);
        }
        return {
            ...state,
            openProject: moveEventUpProject
        };
    },
    [ActionTypes.MOVE_EVENT_LATER]: (state,action) => {
        let moveEventDownProject = {...state.openProject};
        let eventDownIndex = moveEventDownProject.events.findIndex( (event) => {
            return (event._id === action.eventId);
        });
        if(eventDownIndex < moveEventDownProject.events.length) {
            let movedEvent = moveEventDownProject.events.splice(eventDownIndex,1)[0];
            moveEventDownProject.events.splice(eventDownIndex+1,0,movedEvent);
        }
        return {
            ...state,
            openProject: moveEventDownProject
        };
    }
}
};
