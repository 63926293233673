import React,{useEffect,createRef} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Dimmer,Loader,Menu,Icon,Sticky,Ref,Transition} from 'semantic-ui-react';
import {Prompt} from 'react-router-dom';
import Fullscreen from 'react-full-screen';

import ReadSideBar from './ReadSideBar';
import ContextSideBar from '../ContextSideBar';
import ReadBookPanel from './ReadBookPanel';
import ErrorModal from '../../../components/ErrorModal';

import {toggleDistractionFree} from '../../../user/actions';
import { showMainSideBar,dismissProjectError} from '../../../project/actions';


const ReadBookPage = (props) => {
    const dispatch = useDispatch();
    const showMain = useSelector(state => state.projects.showMainSideBar);
    const showContext = useSelector(state => state.projects.showContextSideBar);
    const projects = useSelector(state => state.projects);
    const isDistractionFree = useSelector(state => state.user.isDistractionFree);

    let contextRef = createRef();
    const contentOffset = 0 + (showMain ? 200 : 0) + (showContext ? 300 : 0);
    const contentWidth = 'calc(100vw - '+contentOffset+'px)';

    return (
        <Ref innerRef={contextRef}>
        <div className='ceta-book main'>
            <Dimmer active={projects.isUpdatingProject}>
                <Loader>Loading</Loader>
            </Dimmer>
            <Prompt
                when={projects.isProjectDirty}
                message='You have unsaved changes, are you sure you want to leave?'
            />                
            <ErrorModal 
                show={projects.showError} 
                message={projects.errorMessage}
                onConfirm={() => dispatch(dismissProjectError())}
            />

            <Fullscreen
                enabled={isDistractionFree}
                onChange={isFull => dispatch(toggleDistractionFree(isFull))}
            >                
                <Sticky context={contextRef}>
                    <Transition visible={!showMain}>
                        <div style={{ position: 'absolute', top:'10px',left:'5px',zIndex:100}}>
                            <Icon onClick={() => dispatch(showMainSideBar())} name='bars' size='big'/>
                        </div>
                    </Transition>                        
                </Sticky>
                <div style={{ display: 'flex'}}>
                    <ReadSideBar />
                    <ContextSideBar />
                    <div className='working' style={{ width: '100%',height: '100vh'}}>
                        <ReadBookPanel useMobile={false}/>
                    </div>
                </div>
            </Fullscreen>
        </div>
        </Ref>
    );
};

export default ReadBookPage;
