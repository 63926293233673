import React from 'react';
import {useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Modal,Form,Input,Popup,Card,Segment,Breadcrumb} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import {format} from 'date-fns';
import Showable from '../../components/Showable';
import {
    addEvent,showDeleteEventModal,showCreateEventModal,hideCreateEventModal,
    openEvent,updateLocalEvent,closeEvent
} from '../../project/actions';

import "react-datepicker/dist/react-datepicker.css";

const gregorianToEpoch = (date) => {
    let val = date.getTime() / (1000 * 60 );
    return val;
}

const CreateEventModal = (props) => {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);
    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [epoch,setEpoch] = useState(new Date());

    return(
        <Modal open={projects.showCreateEventModal}>
        <Modal.Header style={{background:'#363636',color:'white'}}>
            New Event
        </Modal.Header>
        <Modal.Content style={{background:'#363636',color:'white'}}>
            <Form>
                <Form.Field>
                    <Input 
                        label='Name'
                        placeholder='Name' 
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Description</label>
                    <Form.TextArea
                        value={description}
                        onChange={(ev) => setDescription(ev.target.value)}
                        placeholder='Describe what happened on the event...'
                    />
                </Form.Field>

                <Form.Field>
                    <label style={{ color: 'white' }}>Date and Time</label>
                    <DatePicker 
                        selected={epoch} 
                        showTimeSelect 
                        onChange={(date) => setEpoch(date)}/>
                </Form.Field>

                <Button color='teal' onClick={ 
                    () => {
                        dispatch(addEvent(projects.openProject._id,{
                            event: {
                                name,
                                description,
                                epoch: gregorianToEpoch(epoch)
                            }}));
                        dispatch(hideCreateEventModal());
                }}>Create</Button>
                <Button onClick={() => {
                    dispatch(hideCreateEventModal());
                }}>Cancel</Button>
            </Form>
        </Modal.Content>
    </Modal>

    );
}

const EditEventModal = (props) => {
    const dispatch = useDispatch();
    const openProjectEvent = useSelector(state => state.projects.openProjectEvent);
    const showEditEventModal = useSelector(state => state.projects.showEditEventModal);

    return(
        <Modal open={showEditEventModal}>
        <Modal.Header style={{background:'#363636',color:'white'}}>
            Edit Event
        </Modal.Header>
        <Modal.Content style={{background:'#363636',color:'white'}}>
            <Form>
                <Form.Field>
                    <Input 
                        label='Name'
                        placeholder='Name' 
                        value={openProjectEvent.name}
                        onChange={(ev) => dispatch(updateLocalEvent('name',ev.target.value))}
                    />
                </Form.Field>
                <Form.Field>
                    <label style={{ color: 'white' }}>Date and Time</label>
                    <DatePicker 
                        selected={openProjectEvent.epoch * 1000 * 60} 
                        showTimeSelect 
                        onChange={(date) => dispatch(updateLocalEvent('epoch',gregorianToEpoch(date)))}/>
                </Form.Field>
                <Form.Field>
                    <label style={{color:'white'}}>Description</label>
                    <Form.TextArea
                        value={openProjectEvent.description}
                        onChange={(ev) => dispatch(updateLocalEvent('description',ev.target.value))}
                        placeholder='Describe what happened on the event...'
                    />
                </Form.Field>
                <Button onClick={() => {
                    dispatch(closeEvent());
                }}>Close</Button>
            </Form>
        </Modal.Content>
    </Modal>

    );
}

const TimelinePanel = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const [session,setSession] = useState(new Date());
    const [interval,setInterval] = useState(1);
    const [scaled,setScaled] = useState(false);

    let lastMark = 0; // This is in whichever units the timeline is currently in. Assume days
    
    if(openProject.events && openProject.events.length > 0) {
        // Initialize the last mark to the first epoch
        lastMark = openProject.events[0].epoch;
    }
    let endEpoch = 0;
    if(openProject.events && openProject.events.length > 0) {
        // Initialize the last mark to the first epoch
        endEpoch = openProject.events[openProject.events.length-1].epoch;
    }
    const totalDistance = endEpoch - lastMark;

    let flip = false;
    
    const eventList = openProject.events.map( event => {

        const epochDistance = scaled ? (event.epoch - lastMark) * 10 * (interval/totalDistance) : 150;
        lastMark = event.epoch;        
        
        //flip = !flip;
        let barColor = flip ? '#ccc' : '#0FF';
        return (
            <div key={event._id} style={{display:'inline-block', width:epochDistance+'px',userSelect:'none'}}>
                <div style={{height:'4px',backgroundColor:barColor}}/>
                <div style={{
                    backgroundColor:'#ccc',borderRadius:'100%',
                    width:'20px',height:'20px',
                    zindex: 2,
                    margin:'-10px',position:'relative',marginLeft:epochDistance+'px'}}>
                    <Popup trigger={
                    <div style={{
                        position:'absolute',top:'30px',
                        left:'50%',transform: 'translate(-50%)',
                        width:'100px',padding: '5px 10px',
                        borderRadius:'5px',textAlign:'center',
                        zIndex: 1,
                        backgroundColor:'white',cursor:'pointer'}}
                        onClick={() => console.log('foo')}
                        >
                    <div style={
                        {width:'10px',height:'10px',
                        backgroundColor:'inherit',display:'block',
                        position:'absolute',marginTop:'-5px',
                        left:'50%', zIndex: -1,
                        transform:'rotate(45deg) translate(-50%)'}}
                        />
                        <div style={{userSelect: 'none'}}>
                            {format(new Date().setTime(event.epoch * (1000 * 60 )),'MMM dd, yyyy')}
                        </div>
                        <div style={{userSelect: 'none'}}>
                            {format(new Date().setTime(event.epoch * (1000 * 60 )),'HH:mmaa')}
                        </div>
                        <div style={{userSelect: 'none'}}>
                        {event.name}
                        </div>
                    </div>
                    } position='bottom center' hoverable={true}>
                        <Card>
                            <Card.Content>{event.description}</Card.Content>
                            <Card.Content extra>
                                <Button.Group>
                                    <Button size='tiny' icon='edit' onClick={() => dispatch(openEvent(event._id))} />
                                    <Button size='tiny' icon='delete' onClick={() => dispatch(showDeleteEventModal(projects.openProject._id,event._id))}/>
                                </Button.Group>
                            </Card.Content>
                        </Card>
                    </Popup>
                </div>
            </div>
        );
    });

    const noItemsMessage = (openProject.events.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', width: '100%', margin: '2em'}}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Events</h2>
        </div>
    ) : null;

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column'            
        }}>
            <div>
            <CreateEventModal  key={session} />
            <EditEventModal />
            </div>
            <div style={{flexShrink: 0}}>
                <Segment inverted>
                    <Breadcrumb style={{color:'white'}} size='large'>
                        <Breadcrumb.Section>Project</Breadcrumb.Section>
                        <Breadcrumb.Divider style={{ color:'white'}}/>
                        <Breadcrumb.Section active>Timeline</Breadcrumb.Section>
                    </Breadcrumb>
                </Segment>

                <Showable showIf={!props.readOnly}>
                <Button color='teal' onClick={() => {
                    dispatch(showCreateEventModal());
                    setSession(new Date());
                }}>
                    <Icon name='calendar plus'/> Add Event
                </Button>
                </Showable>
                <Button color={scaled ? 'teal' : null} active={scaled} onClick={() => setScaled(!scaled)}>{scaled ? 'Disable Scaling':'Enable Scaling'}</Button>
                <Showable showIf={scaled}>
                    <input 
                        type="range" 
                        step="1"
                        value={interval} 
                        onChange={(ev) => setInterval(ev.target.value)}/>
                </Showable>
            </div>
            <div style={{flexGrow:1,overflowX:'auto'}}>                
                <div style={{display:'block',marginLeft:'50px',marginRight:'em',marginTop:'5em',whiteSpace:'nowrap'}}>
                    {noItemsMessage}
                    {eventList}
                </div>
            </div>
        </div>
    );
};

export default TimelinePanel;