import * as ActionType from './constants';

const initialState = {
    all:[],
    isFetchingPosts: false,
    isCreatingPost: false,
    isDeletingPost: false,
    showError: false,
    errorMessage: ''
};

export default (state = initialState, action) => {
    switch(action.type) {
        case ActionType.FETCH_POSTS_SEND:
            return {
                ...state,
                isFetchingPosts: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.FETCH_POSTS_SUCCESS:
            return {
                ...state,
                all: action.payload.data.posts,
                isFetchingPosts: false
            };
        case ActionType.FETCH_POSTS_ERROR:
            return {
                ...state,
                isFetchingPosts: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.CREATE_POST_SEND:
            return {
                ...state,
                isCreatingPost: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.CREATE_POST_SUCCESS:
            return {
                ...state,
                isCreatingPost: false
            };
        case ActionType.CREATE_POST_ERROR:
            return {
                ...state,
                isCreatingPost: false,
                showError: true,
                errorMessage: action.payload
            };      
        case ActionType.DELETE_POST_SEND:
            return {
                ...state,
                isDeletingPost: true,
                showError: false,
                errorMessage: ''
            };
        case ActionType.DELETE_POST_SUCCESS:
            return {
                ...state,
                isDeletingPost: false
            };
        case ActionType.DELETE_POST_ERROR:
            return {
                ...state,
                isDeletingPost: false,
                showError: true,
                errorMessage: action.payload
            };      
        
        default:
            return {...state};
    }
};