import React from 'react';
import {useSelector} from 'react-redux';
import {useSlate} from 'slate-react';
import {Button,Icon,Popup} from 'semantic-ui-react';
import {hasPremium} from '../../services/roles';
import {insertImage} from './richTextTools';

const ImageButton = (props) => {
    const editor = useSlate();
    const user = useSelector(state => state.user);
    const isPremium = hasPremium(user);
    return (
        <Popup trigger={
            <Button icon size='mini' compact={props.compact}
            disabled={!isPremium}
            style={{ margin:0, borderRadius:0,lineHeight:'1.15em'}}
            onClick={event => {
                event.preventDefault()
                let win = window.cloudinary.openUploadWidget({ 
                    cloud_name: 'cetacean-app', 
                    upload_preset: 'up_usr_pre',
                    multiple: false,
                    folder: 'user/'+user.userId,
                    tags: [user.userId],
                    cropping: true,
                    defaultSource: "local",
                    styles: {
                        palette: {
                            window: "#141414",
                            windowBorder: "#90A0B3",
                            tabIcon: "#6DFFFF",
                            menuIcons: "#5A616A",
                            textDark: "#000000",
                            textLight: "#FFFFFF",
                            link: "#6DFFFF",
                            action: "#FF620C",
                            inactiveTabIcon: "#888888",
                            error: "#F44235",
                            inProgress: "#0078FF",
                            complete: "#20B832",
                            sourceBg: "#272727"
                        },
                        fonts: {
                            default: {
                                active: true
                            }
                        }
                    }            
                    },
                    (error, result) => {
                        //console.log(result);
                        if(result.event === 'success') {
                            // Uploaded successfully. Time to update.
                            console.log(result.info.secure_url)
                            insertImage(editor,result.info.secure_url);
                        }
                    });

    
            }}><Icon name='image'/></Button>
        } content='Insert Image' />
          
    );
}

export default ImageButton;