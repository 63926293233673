import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Table,Button,Icon,Menu,Tab,Input,TextArea} from 'semantic-ui-react';
import Post from '../blog/Post';
import {createPost} from '../../post/actions';

const BlogPost = (props) => {
    const dispatch = useDispatch();
    const isCreatingPost = useSelector(state => state.posts.isCreatingPost);
    const [title,setTitle] = useState('');
    const [text,setText] = useState('');
    return (
        <div style={{ display: 'flex',flexDirection:'row', width: '100%'}}>
            <div style={{ width: '50%', padding: '2em'}}>
                <Input fluid label='Title' value={title} onChange={(ev) => setTitle(ev.target.value)}/>
                <p/>
                <TextArea style={{ height: '50vh', width: '100%', padding: '1em' }} placeholder='Post content here...' value={text} onChange={(ev) => setText(ev.target.value)}/>
                <Button color='teal' loading={isCreatingPost} onClick={() => {
                    dispatch(createPost({
                        post: {
                            title,
                            text
                        }
                    }));
                    setTitle('');
                    setText('');
                }}>Submit Post</Button>
            </div>
            <div style={{ width: '50%',padding: '2em'}}>
                <Post post={{title,text,createDate: new Date()}} />
            </div>
        </div>
    );
}

export default BlogPost;