import React,{useEffect,useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Popup,Card,Image,Transition,Loader} from 'semantic-ui-react';
import {getUrlPreview} from '../../../services/api';
import Showable from '../../../components/Showable';
import {
    showDeleteResearchModal,openResearchNotes,showResearchRenameModal
} from '../../../project/actions';

const getImageStyle = (url) => {
    return {
        backgroundImage: 'url('+url+')',
        backgroundSize: 'cover',
        height: '10em'
    };
}

const NotesCard = ({research,readOnly}) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = readOnly ? viewer.openProject : projects.openProject;
    const [loading,setLoading] = useState(false); 

    const deleteButton = (<Button icon onClick={() => dispatch(showDeleteResearchModal(openProject._id,research._id))}>
        <Icon name='delete'/></Button>);

    useEffect( () => {
        if(!research.previewChecked && research.url) {
            setLoading(true);
            getUrlPreview(research.url).then( response => {
                research.preview = response.data.preview;
                setLoading(false);
            });
            research.previewChecked = true;
        }
    },[research.preview]);

    const cardContent = research.preview ? (
        <Card.Content style={{color:'white'}}>
            <Card.Header style={{color:'white'}}>{research.preview.title}</Card.Header>
            <Card.Description style={{color:'white'}}>{research.description}</Card.Description>
            <Card.Meta style={{color:'#00b5ad'}} as='a' href={research.url} target='_blank'>{research.url}</Card.Meta>
        </Card.Content>
    ) : (
        <Card.Content style={{color:'white'}}>
            <Card.Meta style={{color:'#00b5ad'}} as='a' href={research.url} target='_blank'>{research.url}</Card.Meta>
        </Card.Content>
    );

    const cardMarquis = research.preview ? (
        <a href={research.url} target='_blank'>
        <div style={getImageStyle(research.preview.images[0])}>
        </div>
        </a>
    ) : (
        <div style={{textAlign:'center'}}>
            <Showable showIf={!loading}>
                <Icon name='linkify'color='teal' size='huge' style={{marginTop:'0.5em'}}/>
            </Showable>
            <Showable showIf={loading}>
                <Loader active inline />
            </Showable>
        </div>
    );

    return (
        <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={research._id}>
            <Card  style={{backgroundColor:'#363636'}}>
                {cardMarquis}
                {cardContent}
                <Card.Content extra>
                    <Showable showIf={!readOnly}>
                        <Button.Group>
                            <Popup trigger={deleteButton}>Delete</Popup>
                        </Button.Group>
                    </Showable>
                </Card.Content>
            </Card>
        </Transition>
    );
}

export default NotesCard;