import React,{useState,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Button, Modal,Input,Form} from 'semantic-ui-react';

import {renameProject,hideProjectRenameModal} from '../../../project/actions';

const RenameProjectModal = (props) => {
    const dispatch = useDispatch();
    const showRenameProjectModal = useSelector(state => state.projects.showRenameProjectModal);
    const renameProjectModalValue = useSelector(state => state.projects.renameProjectModalValue);
    const renameProjectModalProjectId = useSelector(state => state.projects.renameProjectModalProjectId);
    const [text,setText] = useState('');

    useEffect( () => {
        setText(renameProjectModalValue);
    },[renameProjectModalValue]);

    return (
        <Modal open={showRenameProjectModal}>
            <Modal.Header style={{backgroundColor:'#363636', color:'white'}}>Enter New Project Name</Modal.Header>
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
                <Form>
                    <Form.Field>
                        <Form.Input 
                            placeholder="New name here" 
                            value={text} 
                            onChange={(ev) => setText(ev.target.value)}/>
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
                <Button onClick={() => dispatch(hideProjectRenameModal())}>Cancel</Button>
                <Button color='teal' 
                disabled={text===''}
                    onClick={()=>{
                    dispatch(renameProject({projectId:renameProjectModalProjectId,name:text}));
                    dispatch(hideProjectRenameModal());
                }}>Save</Button>
                
            </Modal.Actions>
        </Modal>

    );
}

export default RenameProjectModal;