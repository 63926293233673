import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';
import {Container,Segment,Header,Button} from 'semantic-ui-react';
import { ConnectedRouter } from 'connected-react-router'
import ReactGA from 'react-ga';
import routes from './routes';
import * as storage from './services/storage';
import {sendReauthentication} from './user/actions';
import CONFIG from './config';
import './cetacean.css';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      showError: false
    };
  }

  componentDidMount() {
    if(CONFIG.enableGoogleAnalytics) {
      ReactGA.initialize('UA-164666253-1',{ debug: false});
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    if(storage.getToken()) {
        this.props.sendReauthentication();
    }
  }

  componentDidCatch(error,info) {
    console.log('ERROR CAUGHT',error,info);
    this.setState({...this.state,showError:true});
  }

  render() {
    if(this.state.showError) {
      return (
        <Container text style={{marginTop:'2em'}}>
          <Segment inverted textAlign='center'>
            <Header>Don't Panic!</Header>
            <div style={{textAlign:'left',marginBottom:'1em'}}>
              Something Very Bad(tm) has happened. The error has been logged and we will see about fixing it as soon as we can.
              We're sorry for the inconvenience. 
            </div>
            <Button color='teal' onClick={() => window.location = window.location}>Return to Dashboard</Button>
          </Segment>
        </Container>
      );
    }
    return (
              <ConnectedRouter history={this.props.history}>
                { routes }
              </ConnectedRouter>
    )
  }
}

App.propTypes = {
  history: PropTypes.object,
}

const modalStyle={
  backgroundColor: '#363636',
  color:'white'
};

export default connect( 
  state => ({user: state.user}),
  {sendReauthentication})(App);