import officegen from 'officegen';
import stream from 'readable-stream';
import { saveAs } from 'file-saver';
import superagent from 'superagent';

export async function exportToOpenXml(project) {
    let docx = officegen('docx');
    docx.on('error', err=> console.log(err));

    for(let i=0;i<project.chapters.length;i++) {
        let chapter = project.chapters[i];
        if(chapter && chapter.text) {
            let text = JSON.parse(chapter.text);
            if(text) {
                if(i !== 0) {
                    docx.putPageBreak();
                }
                for(let i=0;i< text.length; i++) {
                    let element = text[i];
                    if(element.type === 'heading-one') {
                        let pObj = docx.createP()
                        for(let m=0;m<element.children.length;m++) {
                            let textChild = element.children[m];
                            let options = getTextOptions(textChild);
                            options.bold = true;
                            options.font_size = 20;
                            pObj.addText(textChild.text,options);
                        }                    
                    }
                    else if(element.type === 'heading-two') {
                        let pObj = docx.createP()
                        for(let m=0;m<element.children.length;m++) {
                            let textChild = element.children[m];
                            let options = getTextOptions(textChild);
                            options.bold = true;
                            options.font_size = 18;
                            pObj.addText(textChild.text,options);
                        }                    
                    }
                    else if (element.type === 'numbered-list') {
                        element.children.forEach( child => {
                            if(child.type === 'list-item') {
                                let pObj = docx.createListOfNumbers();
                                child.children.forEach( item => {
                                    pObj.addText(item.text,getTextOptions(item));
                                });
                            }
                        });                
                    }
                    else if (element.type === 'bulleted-list') {
                        element.children.forEach( child => {
                            if(child.type === 'list-item') {
                                let pObj = docx.createListOfDots();
                                child.children.forEach( item => {
                                    pObj.addText(item.text,getTextOptions(item));
                                });
                            }
                        });                
                    }
                    else if(element.type === 'image') {
                        let pObj = docx.createP()
                        // Cram an image in there
                        let buffer = require('buffer');
                        let response = await superagent.get(element.url).responseType('blob');
                        let dataUrl = await readBlobAsAsync(response.body);
                        let data = dataUrl.split(',')[1];
                        let stuff = await pObj.addImage(buffer.Buffer.from(data,"base64"),{},'png');                     
                    }
                    else {
                        let pObj = docx.createP();
                        for(let m=0;m<element.children.length;m++) {
                            let textChild = element.children[m];
                            pObj.addText(textChild.text,getTextOptions(textChild));
                        }                
                    }
                }
            }
        }
            
    }

    // Now create a writable stream
    let streamBuffer = [];
    let out = new stream.Writable();
    out._write = (chunk,encoding,done) => {
        streamBuffer.push(chunk);
        done();
    }
    out._final = () => {
        let blob = new Blob(streamBuffer, 
            {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        let s = project.name;
        let filename = s.replace(/[^a-z0-9]/gi, '_').toLowerCase();
        saveAs(blob,filename+'.docx');

    }
    docx.generate(out);    
}


function getTextOptions(element) {
    let textProps = {};
    textProps.bold = element.bold;
    textProps.italic = element.itallic;
    textProps.underline = element.underline;

    if(element.fontFamily) {
        textProps.font_face = element.fontFamily;
    }
    if(element.fontSize) {
        textProps.font_size = element.fontSize.substring(0,element.fontSize.length-2);
    }

    return textProps;
}


function readBlobAsAsync(blob) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        }
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    })
}