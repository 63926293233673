import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Table,Button,Icon} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {fetchAnalytics,fetchProjectsReport} from '../../admin/actions';

const AnalyticsPage = (props) => {
    const dispatch = useDispatch();
    const analytics = useSelector(state => state.admin.analytics);
    const projectsReport = useSelector(state => state.admin.projectsReport);
    const admin = useSelector(state => state.user);

    useEffect( () => {
        dispatch(fetchAnalytics())
    },[dispatch]);
    
    const projectsReportList = projectsReport.map( (v,i) => <Table.Row key={i}><Table.Cell>{v}</Table.Cell></Table.Row>);
    return (
        <div>
        <Toolbar/>
        <div style={{margin:'1em'}}>
            <Button color='teal' onClick={() => dispatch(fetchAnalytics())}><Icon name='refresh'/></Button>
            <Table inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Metric</Table.HeaderCell>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Users</Table.Cell>
                        <Table.Cell>{analytics.numberOfUsers}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Unverified users</Table.Cell>
                        <Table.Cell>{analytics.numberOfUnverified}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Premium users</Table.Cell>
                        <Table.Cell>{analytics.numberOfPremium}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Projects</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjects}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Users with projects</Table.Cell>
                        <Table.Cell>{analytics.numberOfUsersWithProjects}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Projects with characters</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjectsWithCharacters}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects with locations</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjectsWithLocations}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects with events</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjectsWithEvents}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects with relationships</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjectsWithRelationships}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects with research</Table.Cell>
                        <Table.Cell>{analytics.numberOfProjectsWithResearch}</Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Projects updated in last day</Table.Cell>
                        <Table.Cell>{analytics.projectsUpdatedLastDay}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects updated in last week</Table.Cell>
                        <Table.Cell>{analytics.projectsUpdatedLastWeek}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>Projects updated in last month</Table.Cell>
                        <Table.Cell>{analytics.projectsUpdatedLastMonth}</Table.Cell>
                    </Table.Row>
                     
                </Table.Body>
            </Table>
            <p/>
            <Button color='teal' onClick={() => dispatch(fetchProjectsReport())}><Icon name='table'/> Projects Report</Button>
            <Table inverted>
                <Table.Body>
                {projectsReportList}
                </Table.Body>
            </Table>
        </div>
    </div>        
    );
};

export default AnalyticsPage;