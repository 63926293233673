import React from 'react';
import {connect} from 'react-redux';
import {sendAuthentication} from '../../user/actions';
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Segment, Dimmer, Loader, Message, Header } from 'semantic-ui-react';
import Showable from '../../components/Showable';
import * as storage from '../../services/storage';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = { email: '', password: ''}
    }

    sendLogin = () => {
        this.props.sendAuthentication({login: {email:this.state.email, password:this.state.password}});
    }
    
    render() {
        if(this.props.user.isAuthenticating || (!this.props.user.isAuthenticated && storage.getToken())) {
            return (
            <Dimmer active>
                <Loader />
            </Dimmer>            
            );
        }

        return (
            <Grid inverted textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                <Header inverted as='h1'>C e t a c e a n</Header>
                <Form inverted size='large' error={this.props.user.authenticationError}>
                    <Segment style={{backgroundColor:'#272727',color:'white'}}>
                    <Form.Input fluid 
                        icon='envelope'
                        iconPosition='left'
                        placeholder='E-mail address' 
                        onChange={ (event) => this.setState({...this.state,email: event.target.value})}
                        />
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        onChange={ (event) => this.setState({...this.state,password: event.target.value})}
                    />

                    <Message
                        error
                        header='Unable To Log In'
                        content={this.props.user.authenticationErrorMessage}
                    />
                    <Showable showIf={this.props.user.isUnverified}>
                        <Button color='orange' fluid size='large' as={Link} to='/reverify'>
                            Resend Email Verification
                        </Button>
                        <p/>
                    </Showable>
                    <Button color='teal' fluid size='large' onClick={this.sendLogin}>
                        Log In
                    </Button>
                    <Link style={{color:'#00b5ad'}} to="/register">Register</Link> &nbsp;|&nbsp;
                    <Link style={{color:'#00b5ad'}} to="/reset">Forgot Password?</Link>
                    </Segment>
                </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect( 
    state => ({user: state.user}),
    {sendAuthentication})(Login);
