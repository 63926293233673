import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Container,Segment} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {dismissWordCountError} from '../../project/actions';

const WordCountExceededModal = (props) => {
    const dispatch = useDispatch();
    const showWordCountExceededModal = useSelector(state => state.projects.showWordCountExceededModal);
    return (
        <Modal open={showWordCountExceededModal} className='ceta-modal'>
            <Modal.Header>Exceeded Free Tier Limit</Modal.Header>
            <Modal.Content>
                <Container text>
                    <Segment inverted>
                        You have exceeded the word count limit for free tier users. If you believe that Cetacean has
                        value and wish to remove this limit then sign up for Cetacean Premium.
                    </Segment>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => dispatch(dismissWordCountError())}>Dismiss</Button>
                <Button as={Link} to='/getpremium' color='orange' onClick={() => dispatch(dismissWordCountError())}>Get Premium</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default WordCountExceededModal;