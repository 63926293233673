import React,{useState} from 'react';
import {Button, Image,Dimmer,Icon,Modal,Input} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {hasPremium} from '../services/roles';

const UploadedImage = ({src,onDelete,onSuccess,dim,stock,forThought}) => {
    const user = useSelector(state => state.user);
    const [dimmed,setDimmed] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const [linkUrl,setLinkUrl] = useState('');
    let defaultImage = stock ? stock : '/img/default-persona.png';
    const isPremium = hasPremium(user);
    let styleDim = { height: dim ? dim : '100px', width: dim ? dim : '100px'};
    let cropOptions = {
        cropping: true,
        showSkipCropButton: false,
        croppingAspectRatio: 1,
        croppingCoordinatesMode: 'custom',

    };

    if(forThought) {
        // Override image settings
        styleDim = { width: '100%', maxWidth:'40em'};
        cropOptions = {
            cropping: true,
            showSkipCropButton: true,
            croppingCoordinatesMode: 'custom'
        };
        defaultImage = '/img/pickanimage.jpg';
    }
    const url = src ? src : defaultImage;

    return (
        <div>
            <Modal open={showModal} className='ceta-modal'>
                <Modal.Content>
                    <Input fluid label='Image URL' value={linkUrl} onChange={(ev) => setLinkUrl(ev.target.value)} />
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button color='teal' onClick={() => {
                        setShowModal(false);
                        onSuccess(linkUrl);
                    }}>Save</Button>
                </Modal.Actions>
            </Modal>

            <Dimmer.Dimmable as='div' dimmed={dimmed}
                style={styleDim}
                onMouseOver={() => setDimmed(true)}
                onMouseOut={() => setDimmed(false)}
            >
                <Image src={url} style={{...styleDim, marginBottom:'1em', objectFit: 'cover',borderRadius: '8px',border:'1px solid #ccc'}} />
                <Dimmer active={dimmed} onClickOutside={() => setDimmed(false)}>
                    <Button.Group>
                        <Button icon onClick={() => setShowModal(true)}><Icon name='linkify'/></Button>
                        <Button icon disabled={!isPremium} onClick={() => {
                            let win = window.cloudinary.openUploadWidget({ 
                                cloud_name: 'cetacean-app', 
                                upload_preset: 'up_usr_pre',
                                multiple: false,
                                folder: 'user/'+user.userId,
                                tags: [user.userId],
                                ...cropOptions,
                                defaultSource: "local",
                                styles: {
                                    palette: {
                                        window: "#141414",
                                        windowBorder: "#90A0B3",
                                        tabIcon: "#6DFFFF",
                                        menuIcons: "#5A616A",
                                        textDark: "#000000",
                                        textLight: "#FFFFFF",
                                        link: "#6DFFFF",
                                        action: "#FF620C",
                                        inactiveTabIcon: "#888888",
                                        error: "#F44235",
                                        inProgress: "#0078FF",
                                        complete: "#20B832",
                                        sourceBg: "#272727"
                                    },
                                    fonts: {
                                        default: {
                                            active: true
                                        }
                                    }
                                }            
                                },
                                (error, result) => {
                                    //console.log(result);
                                    if(result.event === 'success') {
                                        // Uploaded successfully. Time to update.
                                        onSuccess(result.info.secure_url);
                                    }
                                });

                        }}><Icon name='upload'/></Button>
                        <Button icon onClick={() => onDelete()}><Icon name='delete'/></Button>
                    </Button.Group>
                </Dimmer>
            </Dimmer.Dimmable>
        </div>
    );
}

export default UploadedImage;