import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Draggable from 'react-draggable';
import {Segment,Button,Icon,Image} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import DocumentDisplay from '../../editor/DocumentDisplay';
import {closeCharacterBreakout} from '../../../project/actions';

const CharacterBreakoutWindow = (props) => {
    const dispatch = useDispatch();
    const showCharacterBreakout = useSelector(state => state.projects.showCharacterBreakout);
    const openBreakoutCharacter = useSelector(state => state.projects.openBreakoutCharacter);

    const descElement = (openBreakoutCharacter.description) 
        ? JSON.parse(openBreakoutCharacter.description) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    const demeanorElement = (openBreakoutCharacter.demeanor) 
        ? JSON.parse(openBreakoutCharacter.demeanor) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];

    const valuesElement = (openBreakoutCharacter.values) 
        ? JSON.parse(openBreakoutCharacter.values) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const needsElement = (openBreakoutCharacter.needs) 
        ? JSON.parse(openBreakoutCharacter.needs) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    const fearsElement = (openBreakoutCharacter.fears) 
        ? JSON.parse(openBreakoutCharacter.fears) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
        
    const avatar = openBreakoutCharacter.image ? (<Image draggable={false} src={openBreakoutCharacter.image}/>) : null;
    return (
        <Showable showIf={showCharacterBreakout}>
            <Draggable bounds='parent'>
                <div style={{ position: 'absolute', zIndex: 2,top:0,left:0, width: '250px', height: '50vh', color:'white'}}>
                    <Segment inverted style={{ 
                            backgroundColor:'#141414',
                            height:'100%', 
                            width:'100%',
                            boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
                            padding:0}}>
                        <Button style={{ marginTop:'0.5em',marginLeft:'0.5em'}} compact icon size='mini' onClick={() => dispatch(closeCharacterBreakout())}><Icon name='close'/></Button>
                        <div style={{ overflowY:'auto',overflowX:'hidden', height:'45vh',padding:'0.5em'}}>
                            {avatar}
                            <div><strong>{openBreakoutCharacter.name}</strong></div>
                            <div>{openBreakoutCharacter.role}</div>
                            <div>{openBreakoutCharacter.archetype}</div>
                            <div>
                                <label>Description:</label>
                                <DocumentDisplay content={descElement} />
                            </div>
                            <div>
                                Likable: {openBreakoutCharacter.likable}<br/>
                                Proactive: {openBreakoutCharacter.proactive}<br/>
                                Competent: {openBreakoutCharacter.competent}<br/>
                            </div>
                            <div>
                                <label>Demeanor:</label>
                                <DocumentDisplay content={demeanorElement} />
                            </div>
                            <div>
                                <label>Values:</label>
                                <DocumentDisplay content={valuesElement} />
                            </div>
                            <div>
                                <label>Needs:</label>
                                <DocumentDisplay content={needsElement} />
                            </div>
                            <div>
                                <label>Fears:</label>
                                <DocumentDisplay content={fearsElement} />
                            </div>
                        </div>
                    </Segment>
                </div>
            </Draggable>
        </Showable>
    );
}

export default CharacterBreakoutWindow;