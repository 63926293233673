export const DISMISS_INBOX_ERROR = 'DISMISS_INBOX_ERROR';

export const dismissInboxError = () => ({
    type: DISMISS_INBOX_ERROR
});

export const FETCH_INBOX_SEND = 'FETCH_INBOX_SEND';
export const FETCH_INBOX_SUCCESS = 'FETCH_INBOX_SUCCESS';
export const FETCH_INBOX_ERROR = 'FETCH_INBOX_ERROR';

export const fetchInbox = payload => ({
    type: FETCH_INBOX_SEND,
    payload
});

export const fetchInboxSuccess = payload => {
    return {
    type: FETCH_INBOX_SUCCESS,
    payload
}};

export const fetchInboxError = payload => ({
    type: FETCH_INBOX_ERROR,
    payload
});

export const FETCH_MESSAGES_SEND = 'FETCH_MESSAGES_SEND';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

export const fetchMessages = payload => ({
    type: FETCH_MESSAGES_SEND,
    payload
});

export const fetchMessagesSuccess = payload => {
    return {
    type: FETCH_MESSAGES_SUCCESS,
    payload
}};

export const fetchMessagesError = payload => ({
    type: FETCH_MESSAGES_ERROR,
    payload
});

export const SEND_MESSAGE_SEND = 'SEND_MESSAGE_SEND';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

export const sendMessage = payload => ({
    type: SEND_MESSAGE_SEND,
    payload
});

export const sendMessageSuccess = payload => {
    return {
    type: SEND_MESSAGE_SUCCESS,
    payload
}};

export const sendMessageError = payload => ({
    type: SEND_MESSAGE_ERROR,
    payload
});

export const DELETE_MESSAGE_SEND = 'DELETE_MESSAGE_SEND';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_ERROR = 'DELETE_MESSAGE_ERROR';

export const deleteMessage = payload => ({
    type: DELETE_MESSAGE_SEND,
    payload
});

export const deleteMessageSuccess = payload => {
    return {
    type: DELETE_MESSAGE_SUCCESS,
    payload
}};

export const deleteMessageError = payload => ({
    type: DELETE_MESSAGE_ERROR,
    payload
});

export const OPEN_COMPOSE_MODAL = 'OPEN_COMPOSE_MODAL';
export const CLOSE_COMPOSE_MODAL = 'CLOSE_COMPOSE_MODAL';

export const openComposeModal = () => ({
    type: OPEN_COMPOSE_MODAL
});
export const closeComposeModal = () => ({
    type: CLOSE_COMPOSE_MODAL
});

export const OPEN_READ_MESSAGE_MODAL = 'OPEN_READ_MESSAGE_MODAL';
export const CLOSE_READ_MESSAGE_MODAL = 'CLOSE_READ_MESSAGE_MODAL';

export const openReadMessageModal = (message) => ({
    type: OPEN_READ_MESSAGE_MODAL,
    message
});
export const closeReadMessageModal = () => ({
    type: CLOSE_READ_MESSAGE_MODAL
});

export const MARK_READ_SEND = 'MARK_READ_SEND';
export const MARK_READ_SUCCESS = 'MARK_READ_SUCCESS';
export const MARK_READ_ERROR = 'MARK_READ_ERROR';

export const markRead = payload => ({
    type: MARK_READ_SEND,
    payload
});
export const markReadSuccess = payload => ({
    type: MARK_READ_SUCCESS,
    payload
});

export const markReadError = (payload) => ({
    type: MARK_READ_ERROR,
    payload
});

export const FETCH_UNREAD_SEND = 'FETCH_UNREAD_SEND';
export const FETCH_UNREAD_SUCCESS = 'FETCH_UNREAD_SUCCESS';
export const FETCH_UNREAD_ERROR = 'FETCH_UNREAD_ERROR';

export const fetchUnread = payload => ({
    type: FETCH_UNREAD_SEND,
    payload
});

export const fetchUnreadSuccess = payload => {
    return {
    type: FETCH_UNREAD_SUCCESS,
    payload
}};

export const fetchUnreadError = payload => ({
    type: FETCH_UNREAD_ERROR,
    payload
});

export const START_POLLING = 'START_POLLING';
export const STOP_POLLING = 'STOP_POLLING';

export const startPolling = () => ({
    type: START_POLLING
});

export const stopPolling = () => ({
    type: STOP_POLLING
});


export const BROADCAST_MESSAGE_SEND = 'BROADCAST_MESSAGE_SEND';
export const BROADCAST_MESSAGE_SUCCESS = 'BROADCAST_MESSAGE_SUCCESS';
export const BROADCAST_MESSAGE_ERROR = 'BROADCAST_MESSAGE_ERROR';

export const broadcastMessage = payload => ({
    type: BROADCAST_MESSAGE_SEND,
    payload
});

export const broadcastMessageSuccess = payload => {
    return {
    type: BROADCAST_MESSAGE_SUCCESS,
    payload
}};

export const broadcastMessageError = payload => ({
    type: BROADCAST_MESSAGE_ERROR,
    payload
});
