export const SUBSCRIBE_PREMIUM_SEND  = 'SUBSCRIBE_PREMIUM_SEND';
export const SUBSCRIBE_PREMIUM_SUCCESS  = 'SUBSCRIBE_PREMIUM_SUCCESS';
export const SUBSCRIBE_PREMIUM_ERROR  = 'SUBSCRIBE_PREMIUM_ERROR';
export const SUBSCRIBE_PREMIUM_REJECTED  = 'SUBSCRIBE_PREMIUM_REJECTED';

export const subscribePremium = (stripe,payload) => ({
    type: SUBSCRIBE_PREMIUM_SEND,
    payload,
    stripe
});

export const subscribePremiumSuccess = payload => {
    return {
    type: SUBSCRIBE_PREMIUM_SUCCESS,
    payload
}};

export const subscribePremiumError = payload => ({
    type: SUBSCRIBE_PREMIUM_ERROR,
    payload
});

export const subscribePremiumRejected = payload => ({
    type: SUBSCRIBE_PREMIUM_REJECTED,
    payload
});


export const CONFIRM_PAYMENT_SEND  = 'CONFIRM_PAYMENT_SEND';
export const CONFIRM_PAYMENT_SUCCESS  = 'CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR  = 'CONFIRM_PAYMENT_ERROR';

export const confirmPayment = (clientSecret) => ({
    type: CONFIRM_PAYMENT_SEND,
    clientSecret
});

export const confirmPaymentSuccess = payload => {
    return {
    type: CONFIRM_PAYMENT_SUCCESS,
    payload
}};

export const confirmPaymentError = payload => ({
    type: CONFIRM_PAYMENT_ERROR,
    payload
});


export const UNSUBSCRIBE_PREMIUM_SEND  = 'UNSUBSCRIBE_PREMIUM_SEND';
export const UNSUBSCRIBE_PREMIUM_SUCCESS  = 'UNSUBSCRIBE_PREMIUM_SUCCESS';
export const UNSUBSCRIBE_PREMIUM_ERROR  = 'UNSUBSCRIBE_PREMIUM_ERROR';

export const unsubscribePremium = (payload) => ({
    type: UNSUBSCRIBE_PREMIUM_SEND,
    payload
});

export const unsubscribePremiumSuccess = payload => {
    return {
    type: UNSUBSCRIBE_PREMIUM_SUCCESS,
    payload
}};

export const unsubscribePremiumError = payload => ({
    type: UNSUBSCRIBE_PREMIUM_ERROR,
    payload
});

