import React from 'react';
import { Button, Grid, Segment, Message, Header } from 'semantic-ui-react';
import {Link} from 'react-router-dom';

const OutagePage = (props) => {
    return (
        <Grid inverted textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
            <Header inverted as='h1'>C e t a c e a n</Header>
                <Segment style={{backgroundColor:'#272727',color:'white'}}>

                <Message>
                    <Message.Header>
                        Unable to connect to servers
                    </Message.Header>
                    Your browser is currently unable to connect with the Cetacean servers.
                    This may be due to a network connectivity issues on your end, or the
                    Cetacean servers may be undergoing maintainance.
                </Message>
                <Button color='teal' as={Link} to='/'>Try Again</Button>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default OutagePage;