import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom'
import { Button, Form, Grid, Segment, Message, Header, Popup } from 'semantic-ui-react';
import {sendRegistration,checkUsername} from '../../user/actions';
import CONFIG from '../../config';
import Showable from '../../components/Showable';
import { keyframes } from 'emotion';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = { 
            email: '', 
            password: '',
            password2: '',
            name: '', 
            code: props.match.params.inviteCode,
            agreement: false,
            invalidEmail: false,
        };
    }

    sendRegistration = () => {
        if(this.validateEmail(this.state.email)) {
            this.setState({...this.state,invalidEmail: false})
            this.props.sendRegistration({registration: {
                email: this.state.email, 
                password: this.state.password,
                code: this.state.code,
                name: this.state.name,
                marker: this.props.user.linkMarker
            }});
        }
        else {
            this.setState({...this.state,invalidEmail: true})
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        const usernameCheckButton = (
            <Button 
                icon={this.props.user.usernameStatus === 'available'?'checkmark':'search'}
                color={this.props.user.usernameStatus === 'available'?'teal':null}
                disabled={this.state.name===''}
                loading={this.props.user.isCheckingUsername}
                onClick={() => this.props.checkUsername(this.state.name)}
            />
        );
        const usernameCheckPopup = (
            <Popup trigger={usernameCheckButton}>
                Check username availability
            </Popup>
        );
        return (
            <Grid inverted textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                <Grid.Column style={{ maxWidth: 450 }}>
                <Header inverted as='h1'>C e t a c e a n</Header>
                <Form inverted size='large' error={this.props.user.registrationError}>
                    <Segment style={{backgroundColor:'#272727',color:'white'}}>
                    <Form.Input 
                        fluid 
                        icon='envelope' 
                        iconPosition='left' 
                        placeholder='E-mail address' 
                        onChange={ (event) => this.setState({...this.state,email: event.target.value})}
                        error={this.state.invalidEmail}
                    />
                    <Showable showIf={this.state.invalidEmail}>
                        <Message negative>
                            Please use a valid email address
                        </Message>
                    </Showable>
                    <Form.Input 
                        fluid
                        error={this.props.user.usernameStatus === 'unavailable'}
                        icon='user' 
                        iconPosition='left' 
                        placeholder='Username'
                        value={this.state.name}
                        maxLength='20'
                        onChange={ (event) => {
                            if( event.target.value==='' || event.target.value.match(/^[0-9a-zA-Z]+$/)) {
                                this.setState({...this.state,name: event.target.value});
                            }
                            }
                        }
                        action={usernameCheckPopup}
                    />

                    <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Password'
                    type='password'
                    onChange={ (event) => this.setState({...this.state,password: event.target.value})}
                    />
                    <Form.Input
                    fluid
                    icon='lock'
                    iconPosition='left'
                    placeholder='Re-Enter Password'
                    type='password'
                    onChange={ (event) => this.setState({...this.state,password2: event.target.value})}
                    error={(this.state.password !== this.state.password2)}
                    />

                    <Form.Input
                    fluid
                    icon='key'
                    iconPosition='left'
                    placeholder='Invite Code'
                    value={this.state.code}
                    onChange={ (event) => this.setState({...this.state,code: event.target.value.trim().toUpperCase()})}
                    />
                    <Form.Checkbox 
                        label={<label>I confirm that I am at least 13 years of age and that I have read and 
                            agree to the <a style={{color:'#00b5ad'}} href='/privacy.html' target='_blank'>Privacy Policy</a> and&nbsp;
                            <a style={{color:'#00b5ad'}} href='/tos.html' target='_blank'>Terms of Service</a>.</label>} 
                        onClick={() => this.setState({...this.state,agreement:!this.state.agreement})}
                    />
                    <Message
                        error
                        header='Unable To Register Account'
                        content={this.props.user.registrationErrorMessage}
                    />
                    <Message
                        hidden={!this.props.user.hasRegistered}
                    >
                        <Message.Header>Account Request Sent</Message.Header>
                        <p>Check your email to confirm your registration.</p>
                    </Message>

                    <Button color='teal' fluid size='large'
                        onClick={this.sendRegistration} 
                        disabled={this.props.user.hasRegistered || (this.state.password !== this.state.password2) ||
                            !this.state.agreement || !this.state.email || !this.state.password ||
                            !this.state.code
                        }>
                        Register
                    </Button>
                    <Link style={{color:'#00b5ad'}} to="/login">Log In</Link> &nbsp;|&nbsp;
                    <Link style={{color:'#00b5ad'}} to="/reset">Forgot Password?</Link>
                    </Segment>
                </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect( state => ({user: state.user}),
    {sendRegistration,checkUsername})(Register);