import React from 'react';
import {useSelector} from 'react-redux';
import {Responsive} from 'semantic-ui-react';
import RichTextEditor from '../editor/RichTextEditor';
import ChapterView from './ChapterView';

const BookManuscriptPanel = ({project}) => {
    const fullView = project.chapters.map( (chapter,index) => {
        const element = (chapter.text) 
        ? JSON.parse(chapter.text) 
        : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
        return (
            <div key={chapter._id}>
                <div style={{color:'white'}}>
                    <h2>Chapter {index+1}:&nbsp;{chapter.name}</h2>
                </div>
                <RichTextEditor 
                    key={chapter._id}
                    content={element}
                    footer={false}
                    centered={true}
                    readOnly={true}
                />
            </div>
        );    
    });
    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{flexShrink: 0}}>
            </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{marginTop:'1em',marginLeft:'1em'}}>
                <div style={{color:'white'}}
                    ><h1>{project.name}</h1>
                </div>
                {fullView}
                </div>
            </div>            
        </div>
    );

}

const ManuscriptPanel = (props) => {
    const openProject = useSelector(state => state.viewer.openProject);
    if(openProject.projectType === 'Book') {
        return (<BookManuscriptPanel project={openProject}/>);
    }
    const fullView = openProject.chapters.map( chapter => <ChapterView key={chapter._id} chapter={chapter}/>)
    
    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{flexShrink: 0}}>
            </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{marginTop:'1em',marginLeft:'1em'}}>
                <div style={{color:'white'}}
                    ><h1>{openProject.name}</h1>
                </div>
                {fullView}
                </div>
            </div>            
        </div>
    );
}

export default ManuscriptPanel;