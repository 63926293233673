import React,{useEffect,createRef} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Dimmer,Loader,Responsive,Icon,Sticky,Ref,Transition} from 'semantic-ui-react';
import {Prompt} from 'react-router-dom';
import Fullscreen from 'react-full-screen';

import MainSideBar from './MainSideBar';
import ContextSideBar from './ContextSideBar';
import ProjectPanel from './ProjectPanel';
import IntroModal from './IntroModal';
import ErrorModal from '../../components/ErrorModal';
import BookViewModal from './BookViewModal';
import MobileMainSideBar from './MobileMainSideBar';
import MobileContextSideBar from './MobileContextSideBar';
import WordCountExceededModal from './WordCountExceededModal';

import {toggleDistractionFree} from '../../user/actions';
import { showMainSideBar,dismissProjectError} from '../../project/actions';


const BookPage = (props) => {
    const dispatch = useDispatch();
    const showMain = useSelector(state => state.projects.showMainSideBar);
    const showContext = useSelector(state => state.projects.showContextSideBar);
    const projects = useSelector(state => state.projects);
    const isDistractionFree = useSelector(state => state.user.isDistractionFree);

    let contextRef = createRef();
    const contentOffset = 0 + (showMain ? 200 : 0) + (showContext ? 300 : 0);
    const contentWidth = 'calc(100vw - '+contentOffset+'px)';

    return (
        <Ref innerRef={contextRef}>
        <div className='ceta-book main'>
            <Dimmer active={projects.isUpdatingProject}>
                <Loader>Loading</Loader>
            </Dimmer>
            <Prompt
                when={projects.isProjectDirty}
                message='You have unsaved changes, are you sure you want to leave?'
            />                
            <ErrorModal 
                show={projects.showError} 
                message={projects.errorMessage}
                onConfirm={() => dispatch(dismissProjectError())}
            />
            <BookViewModal />
            <WordCountExceededModal />
            <Fullscreen
                enabled={isDistractionFree}
                onChange={isFull => dispatch(toggleDistractionFree(isFull))}
            >
                <Responsive minWidth={Responsive.onlyTablet.minWidth}>
                    <Sticky context={contextRef}>
                        <Transition visible={!showMain}>
                            <div style={{ position: 'absolute', top:'10px',left:'5px',zIndex:100}}>
                                <Icon onClick={() => dispatch(showMainSideBar())} name='bars' size='big'/>
                            </div>
                        </Transition>                        
                    </Sticky>
                    <div style={{ display: 'flex'}}>
                        <MainSideBar />
                        <ContextSideBar />
                        <IntroModal />
                        <div className='working' style={{ width: '100%',height: '100vh'}}>
                            <ProjectPanel useMobile={false}/>
                        </div>
                    </div>
                </Responsive>
                <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
                    <Sticky context={contextRef}>
                        <Transition visible={!showMain}>
                            <div style={{ position: 'absolute', top:'5px',left:'5px',zIndex:100}}>
                                <Icon onClick={() => dispatch(showMainSideBar())} name='bars' size='large'/>
                            </div>
                        </Transition>                        
                    </Sticky>

                    <div style={{ display: 'flex'}}>
                        <MobileMainSideBar />
                        <MobileContextSideBar />
                        <IntroModal />
                        <div className='working' style={{ width: '100%',height: '100vh'}}>
                            <ProjectPanel useMobile={true}/>
                        </div>
                    </div>
                </Responsive>

            </Fullscreen>
        </div>
        </Ref>
    );
};

export default BookPage;
