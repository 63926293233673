import React from 'react';
import {useSelector} from 'react-redux';
import SceneEditPanel from './SceneEditPanel';
import ChapterEditPanel from './ChapterEditPanel';
import MobileSceneEditPanel from './MobileSceneEditPanel';
import MobileChapterEditPanel from './MobileChapterEditPanel';
import CharacterEditPanel from './CharacterEditPanel';
import RelationshipEditPanel from './RelationshipEditPanel';
import LocationEditPanel from './LocationEditPanel';
import CharactersPanel from './CharactersPanel';
import LocationsPanel from './LocationsPanel';
import TimelinesPanel from './timeline/TimelinesPanel';
import ProgressPanel from './ProgressPanel';
import ChaptersPanel from './ChaptersPanel';
import ResearchPanel from './research/ResearchPanel';
import EditResearchNotesPanel from './research/EditResearchNotesPanel';

const ProjectPanel = (props) => {
    const projects = useSelector( state => state.projects);

    switch(projects.openProjectPanel) {
        case 'Scene':
            if(props.useMobile) {
                return (
                    <MobileSceneEditPanel key={projects.openProject._id}/>
                );    
            }
            return (
                <SceneEditPanel key={projects.openProject._id}/>
            );
        case 'Chapter':
            if(props.useMobile) {
                return (
                    <MobileChapterEditPanel key={projects.openProject._id}/>
                );    
            }
            return (
                <ChapterEditPanel key={projects.openProject._id}/>
            );
    
        case 'Character':
            return (<CharacterEditPanel key={projects.openProject._id}/>);
        case 'Relationship':
            return (<RelationshipEditPanel key={projects.openProject._id}/>);
        case 'LocationsPanel':
                return (<LocationsPanel key={projects.openProject._id}/>);
        case 'Location':
                return (<LocationEditPanel key={projects.openProject._id}/>);
        case 'CharactersPanel':
            return (<CharactersPanel key={projects.openProject._id}/>);
        case 'Timeline':
                return (<TimelinesPanel key={projects.openProject._id}/>);
        case 'ResearchPanel':
            return (<ResearchPanel key={projects.openProject._id}/>);
        case 'ResearchNotes':
            return (<EditResearchNotesPanel key={projects.openProject._id}/>);        
        case 'Progress':
            return (<ProgressPanel key={projects.openProject._id}/>);
        case 'ChaptersPanel':
            return (<ChaptersPanel key={projects.openProject._id}/>);
                        
        default:
            return (
                <div>
                    Project Panel Here
                </div>
            );            
    }
}

export default ProjectPanel;