import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Modal,Button,Form,Icon} from 'semantic-ui-react';
import {closeShareCheckpointModal,shareCheckpoint} from '../../../checkpoint/actions';
import {fetchContacts} from '../../../contact/actions';

const ShareWithUserModal = (props) => {
    const dispatch = useDispatch();
    const showShareModal = useSelector(state => state.checkpoints.showShareModal);
    const sharedCheckpointId = useSelector(state => state.checkpoints.sharedCheckpointId);
    const isSharingCheckpoint = useSelector(state => state.checkpoints.isSharingCheckpoint);
    const sharedCheckpointIsEditors = useSelector(state => state.checkpoints.sharedCheckpointIsEditors);
    const contacts = useSelector(state => state.contacts);
    const [selectedContact,setSelectedContact] = useState('');
    
    useEffect( () => {
        dispatch(fetchContacts());
    },[dispatch]);

    const options = contacts.mututal.map( contact => ({
        text: contact.name,
        value: contact._id
    }));

    return (
        <Modal open={showShareModal} className='ceta-modal'>
            <Modal.Header>
                Select a contact
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Select 
                        style={{background:'#363636',color:'white'}}
                        placeholder='Select a contact...' 
                        onChange={ (ev,data) => setSelectedContact(data.value)}
                        options={options} />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => dispatch(closeShareCheckpointModal())}>Cancel</Button>
                <Button color='teal' loading={isSharingCheckpoint}
                        onClick={() => {
                            let contact;
                                contacts.mututal
                                    .filter(c => c._id === selectedContact)
                                    .forEach(c => contact = c);
                            dispatch(shareCheckpoint(sharedCheckpointId,{
                                shareTerms: {
                                    projectId: sharedCheckpointId,
                                    lendeeId: contact._id,
                                    lendeeName: contact.name,
                                    isEditor: sharedCheckpointIsEditors
                                }},props.reloadLendees
                            ));
                        }}>Share</Button>
            </Modal.Actions>

        </Modal>
    );
}

export default ShareWithUserModal;