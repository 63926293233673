import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../services/api';

import {
    SUBSCRIBE_PREMIUM_SEND, subscribePremiumSuccess, subscribePremiumError,subscribePremiumRejected,
    CONFIRM_PAYMENT_SEND, confirmPaymentSuccess, confirmPaymentError, confirmPayment,
    UNSUBSCRIBE_PREMIUM_SEND, unsubscribePremiumSuccess, unsubscribePremiumError
} from './actions';

function* confirmSubPayment(action) {
    try {
        let response = yield call(() => api.confirmPayment());
        if(response.success) {
                yield put(confirmPaymentSuccess(response));
        }
        else {
            yield put(confirmPaymentError(response.message));
        }
    }
    catch(err) {
        yield put(confirmPaymentError(err.message));
    }

}

function* subscribeToPremium(action) {
    try {
        let response = yield call(() => api.subscribeToPremium(action.payload));
        if(response.success) {            
            if(response.data.subscription.status === 'active') {
                yield put(subscribePremiumSuccess(response));
            }
            else if (response.data.subscription.latest_invoice.payment_intent.status === 'requires_action') {
                // We need to prompt user to confirm info
                const stripe = action.stripe;
                const paymentIntent = response.data.subscription.latest_invoice.payment_intent;
                let confirmResponse = yield call( () => stripe.confirmCardPayment(paymentIntent.client_secret));
                if(confirmResponse.error) {
                    yield put(subscribePremiumRejected(confirmResponse.error));
                }
                else if (confirmResponse.paymentIntent && confirmResponse.paymentIntent.status === 'succeeded'){
                    // tell server to update subscription status
                    yield put(confirmPayment(paymentIntent.client_secret));
                }
            }
            else {
                // Invalid payment
                yield put(subscribePremiumError('Unknown error making payment'));
            }
        }
        else {
            yield put(subscribePremiumError(response.message));
        }
    }
    catch(err) {
        yield put(subscribePremiumError(err.message));
    }
}


function* unsubscribeToPremium(action) {
    try {
        let response = yield call(() => api.unsubscribeToPremium());
        if(response.success) {
                yield put(unsubscribePremiumSuccess(response));
        }
        else {
            yield put(unsubscribePremiumError(response.message));
        }
    }
    catch(err) {
        yield put(unsubscribePremiumError(err.message));
    }

}

export default function* projectSagas() {
    yield takeLatest(SUBSCRIBE_PREMIUM_SEND,subscribeToPremium);
    yield takeLatest(CONFIRM_PAYMENT_SEND, confirmSubPayment);
    yield takeLatest(UNSUBSCRIBE_PREMIUM_SEND,unsubscribeToPremium);
}