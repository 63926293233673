import React, {useMemo,useState, useCallback,useRef} from 'react';
import {createEditor} from 'slate';
import {Slate, Editable, withReact} from 'slate-react';
import {withHistory} from 'slate-history';
import isHotkey from 'is-hotkey';
import { 
  withRichText, Element, Leaf, Toolbar, MarkButton, BlockButton, CommentButton, MarkDropdown, 
  withDirty, withImages,handleDeco,handleHotkeys
} from './richTextTools';
import ImageButton from './ImageButton';

class TempScrollBox {
    constructor() {
      this.scrollBarWidth = 0;
  
      this.measureScrollbarWidth();
    }
  
    measureScrollbarWidth() {
      // Add temporary box to wrapper
      let scrollbox = document.createElement('div');
  
      // Make box scrollable
      scrollbox.style.overflow = 'scroll';
  
      // Append box to document
      document.body.appendChild(scrollbox);
  
      // Measure inner width of box
      this.scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
  
      // Remove box
      document.body.removeChild(scrollbox);
    }
  
    get width() {
      return this.scrollBarWidth;
    }
  }
  
export const MobileRichTextEditor = ({
        content,onUpdate,placeholder,focus,footer,
        centered,readOnly,ignoreSelectionUpdate,lastSaveDate,onSave}) => {
    const editor = useMemo( () => withDirty(withImages(withHistory(withReact(createEditor())))), []);
    const [value, setValue] = useState(content);
    const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
    const renderElement = useCallback( props => <Element {...props}/>,[]);
    const renderLeaf = useCallback( props => <Leaf {...props}/>,[]);
    const marginBottom = footer ? '10em' : '0em';
    const editorWidth = centered ? '90vh' : '100%';
    let marginLeft = centered ? 'calc((100% - '+editorWidth+') / 2)' : '0em';
    let marginRight = centered ? 'calc((100% - '+editorWidth+') / 2)' : '0em';

    const refContainer = useRef( (input) => {this.myElement = input;});
    if(refContainer.current && centered) {
        let offset =  refContainer.current.offsetWidth - refContainer.current.clientWidth;
        if(offset > 0) {
            marginLeft = 'calc('+(offset/2)+'px + (100% - '+editorWidth+') / 2)';
        }
    }
    
    // Mark !dirty if there's been a save
    if(lastSaveDate && lastSaveDate > lastUpdate) {
        editor.dirty = false;
    }

    const toolbar = !readOnly ? (
      <Toolbar>
          <div style={{ width: '100%', backgroundColor:'#363636'}}>
      <MarkButton format="bold" icon="bold" tooltip='Bold' hotkey='B' />
      <MarkButton format="italic" icon="italic" tooltip='Italic' hotkey='I' />
      <MarkButton format="underline" icon="underline" tooltip='Underline' hotkey='U' />
      <MarkButton format="strikethrough" icon="strikethrough" tooltip='Strikethrough' hotkey='K' />
      <BlockButton format="heading-one" text="H1" tooltip="Heading 1"/>
      <BlockButton format="heading-two" text="H2" tooltip="Heading 2"/>
      <BlockButton format="numbered-list" icon="list ol" tooltip="Numbered List"/>
      <BlockButton format="bulleted-list" icon="list ul" tooltip="Bullet List"/>
      <CommentButton icon="comment" tooltip='Comment' />
      <ImageButton/>
      <MarkDropdown name='Font' type='fontFamily' tooltip='Font' options={[
          {label:'Arial',value:'Arial'},
          {label:'Arial Black',value:'Arial Black'},
          {label:'Bookman',value:'Bookman'},
          {label:'Candara',value:'Candara'},
          {label:'Courier New',value:'Courier New'},
          {label:'Times New Roman',value:'Times New Roman'},
          {label:'Verdana',value:'Verdana'},
      ]} />
      <MarkDropdown name='Size' type='fontSize' tooltip='Font Size' options={[
          {label:'8',value:'8pt'},
          {label:'9',value:'9pt'},
          {label:'10',value:'10pt'},
          {label:'11',value:'11pt'},
          {label:'12',value:'12pt'},
          {label:'14',value:'14pt'},
          {label:'16',value:'16pt'},
          {label:'18',value:'18pt'},
      ]} />
      </div>
    </Toolbar>

    ) : null;
    return (
      <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
          <Slate editor={editor} value={value} onChange={value => {
                setValue(value);

                if(ignoreSelectionUpdate) {
                    if(editor.dirty) {
                        if(onUpdate) {
                            setLastUpdate(new Date().getTime());
                            onUpdate(value);
                        }        
                    }
                }
                else {
                    if(onUpdate) {
                        setLastUpdate(new Date().getTime());
                        onUpdate(value);
                    }
                }
          }}>

            <div>
              {toolbar}
            </div>
            <div style={{height: '99vh',overflowY:'auto',backgroundColor:'#363636'}} ref={refContainer}>
                    <div style={{
                      background: 'white',
                      marginLeft:0,
                      marginRight:0,
                      width: '100%',
                      marginBottom: 0,
                      backgroundColor: '#363636',
                      color: 'white',
                      paddingLeft: '2em',
                      paddingRight: '2em',
                      paddingTop: '2em',
                      paddingBottom: '10em'
                      }}>
                  <Editable 
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      decorate={ useCallback( ([node,path]) => handleDeco(node,path),[])}
                      placeholder={placeholder}
                      readOnly={readOnly}
                      spellCheck='true'
                      autoFocus={focus}
                      onKeyDown={ event => {
                          handleHotkeys(editor,event);

                          if(isHotkey('mod+z',event)) {
                              event.preventDefault();
                              editor.undo();
                          }
                          else if(isHotkey('mod+y',event)) {
                              event.preventDefault();
                              editor.redo();
                          }
                          else if(event.key === 'Tab') {
                              // Stop the tab from changing focus
                              event.preventDefault();
                              editor.insertText('\t');
                          }
                          if(isHotkey('mod+s',event)) {
                              event.preventDefault();
                              if(onSave) {
                                  onSave();
                              }
                          }
                      }}
                  />
                  </div>
            </div>
            </Slate>
        </div>
    );
}

export default MobileRichTextEditor;