import React,{useState,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Modal,Loader} from 'semantic-ui-react';
import Showable from '../../../components/Showable';
import UtilityBar from './UtilityBar';
import TagBar from './TagBar';
import NoteCard from './NoteCard';
import WebsiteCard from './WebsiteCard';
import PinterestBoardCard from './PinterestBoardCard';

const calculateNoteHeight = (research) => {
    const small = research.size === 'regular';
    const large = research.size !== 'regular';
    switch(research.researchType) {
        case 'Image':
        case 'Website': {
            if(small) {
                return '3em';
            }
            return '5em';
        }
        default:
            return small ? '10em' : '20em'
    }
}

const ResearchCard = ({research,readOnly}) => {
    const dispatch = useDispatch();
    const researchBackgroundColor = useSelector(state => state.projects.openProject.researchBackgroundColor);
    const researchTextColor = useSelector(state => state.projects.openProject.researchTextColor);
    const [show,setShow] = useState(false);
    const trigger = (<img src={research.url} style={{ objectFit: 'contain',width:'100%',height:'100%'}} onClick={() => setShow(true)}/>);
    const sizeClass = 'ceta-idea-shell '+(research.size ? research.size : 'regular');
    const noteHeight = calculateNoteHeight(research);
    return (
        <div className={sizeClass} style={{ 
            backgroundColor: (researchBackgroundColor ? researchBackgroundColor :'#ffff88'),
            color: (researchTextColor ? researchTextColor :'black'),
        }}>
            <UtilityBar research={research}/>
            <div style={{ height: '100%'}}>
                <Showable showIf={research.researchType === 'Image'}>
                    <Modal trigger={trigger}>
                        <Modal.Content>
                            <img src={research.url} style={{ objectFit: 'contain',width:'100%',height:'100%'}} />
                        </Modal.Content>
                    </Modal>
                </Showable>     
                <Showable showIf={research.researchType === 'Website'}>
                    <WebsiteCard research={research} />
                </Showable>
                <Showable showIf={research.researchType === 'Pinterest Board'}>
                    <PinterestBoardCard research={research} />
                </Showable>
                <NoteCard 
                    research={research} 
                    noteHeight={noteHeight} 
                    background={(researchBackgroundColor ? researchBackgroundColor :'#ffff88')}
                    text={(researchTextColor ? researchTextColor :'black')}
                    />
            </div>
            <TagBar research={research}/>
        </div>)
}

export default ResearchCard;