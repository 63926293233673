import React, {useState,useRef,useEffect} from 'react';
import ReactDOM from 'react-dom'
import {Editor, Transforms,Text,Range} from 'slate';
import {useSlate,useSelected,useFocused,ReactEditor} from 'slate-react';
import {useDispatch} from 'react-redux';
import {css,cx} from 'emotion';
import isHotkey from 'is-hotkey';
import imageExtensions from 'image-extensions'
import isUrl from 'is-url'
import {Button,Icon,Popup,Dropdown} from 'semantic-ui-react';
import reactModal from '@prezly/react-promise-modal';
import OS from '../../util/os';
import EditorCommentModal from './EditorCommentModal';
import EditorLinkModal from './EditorLinkModal';
import {toggleDistractionFree} from '../../user/actions';

const writeGood = require('write-good');


const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+k': 'strikethrough',
  }

export const getHotkey = (key) => {
  if('Win' === OS) {
    return 'Ctrl+'+key;
  }
  if('Mac' === OS) {
    return 'CMD+'+key;
  }
  return '';
};

export const handleHotkeys = (editor,event) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault()
      const mark = HOTKEYS[hotkey]
      toggleMark(editor, mark)
    }
  }                          
};

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  ))

  export const ToolbarButton = React.forwardRef(
    ({ className, active, reversed, ...props }, ref) => (
      <span
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
            cursor: pointer;
            background-color: #cacbcd;
            display: inline-block;
            padding: .6em 1.5em .6em;
            font-weight: 700;
            text-align: center;
            border-radius: .285rem;
            border: none;
          `
        )}
      />
    )
  )
  export const Toolbar = React.forwardRef(({ className, embedded,...props }, ref) => {
    const toolbarClass = embedded ?
      cx(
        className,
        css`
          width: 100%;
          height: 2em;
        `
      ) : 
      cx(
        className,
        css`
          width: 100%;
          text-align: center;
        `
      );
    return (
    <Menu
      {...props}
      ref={ref}
      className={toolbarClass}
    />
  )});

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  }

const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

export const MarkButton = ({ format,icon,tooltip,compact,hotkey }) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);
    const tooltipText = hotkey ? tooltip + ' ('+getHotkey(hotkey)+')' : tooltip;
    return (
        <Popup trigger={
          <Button
          icon
          size='mini'
          compact={compact}
          color={isActive ? 'teal':null}
          active={isActive}
          onMouseDown={event => {
              event.preventDefault()
              toggleMark(editor,format);
          }}
          style={{ margin:0, borderRadius:0,lineHeight:'1.15em'}}
          >
          <Icon name={icon}/>
          </Button>
        } content={tooltipText} />
    );
}

const insertComment = (editor,comment) => {
  if(editor.selection) {
    wrapComment(editor,comment);
  }
}

const wrapComment = (editor, comment) => {
  Editor.addMark(editor,'comment',comment);
}

const unwrapComment = (editor) => {
  Editor.removeMark(editor, 'comment');
}


export const CommentButton = ({ icon,tooltip,compact }) => {
  const editor = useSlate();
  const marks = Editor.marks(editor)
  const isActive = (marks && marks['comment']) ? true : false;
  const dispatch = useDispatch();
  
  return (
      <Popup trigger={
        <Button
        icon
        compact={compact}
        size='mini'
        color={isActive ? 'teal':null}
        active={isActive}
        onMouseDown={async event => {
            event.preventDefault();
            if(!isActive) {
              //const comment = window.prompt('Enter comment');
              const savedSelect = editor.selection;
              const comment = await reactModal(({ show, onSubmit, onDismiss }) => {
                dispatch(toggleDistractionFree(false));
                return (<EditorCommentModal show={show} onSubmit={onSubmit} onDismiss={onDismiss} />)
              });
              if(!comment) return;
              Transforms.select(editor,savedSelect);
              insertComment(editor,comment);
            }
            else {
              unwrapComment(editor);
            }
        }}
        style={{ margin:0, borderRadius:0,lineHeight:'1.15em'}}
        >
        <Icon name={icon}/>
        </Button>
      } content={tooltip} />
  );
}

const insertLink = (editor,link) => {
  if(editor.selection) {
    wrapLink(editor,link);
  }
}

const wrapLink = (editor, link) => {
  Editor.addMark(editor,'link',link);
}

const unwrapLink = (editor) => {
  Editor.removeMark(editor, 'link');
}


export const LinkButton = ({ icon,tooltip,compact }) => {
  const editor = useSlate();
  const marks = Editor.marks(editor)
  const isActive = (marks && marks['link']) ? true : false;
  const dispatch = useDispatch();
  
  return (
      <Popup trigger={
        <Button
        icon
        compact={compact}
        size='mini'
        color={isActive ? 'teal':null}
        active={isActive}
        onMouseDown={async event => {
            event.preventDefault();
            if(!isActive) {              
              const savedSelect = editor.selection;
              const link = await reactModal(({ show, onSubmit, onDismiss }) => {
                dispatch(toggleDistractionFree(false));
                return (<EditorLinkModal show={show} onSubmit={onSubmit} onDismiss={onDismiss} />)
              });
              if(!link) return;
              Transforms.select(editor,savedSelect);
              insertLink(editor,link);
            }
            else {
              unwrapLink(editor);
            }
        }}
        style={{ margin:0, borderRadius:0,lineHeight:'1.15em'}}
        >
        <Icon name={icon}/>
        </Button>
      } content={tooltip} />
  );
}

export const MarkDropdown = (props) => {
  const editor = useSlate();
  const optionList = props.options.map( option => (
    <Dropdown.Item key={option.value} text={option.label} value={option.value} onClick={ (ev,dat) => {
      ev.preventDefault();
      editor.addMark(props.type,dat.value);
    
    }}/>
  ));
  const marks = Editor.marks(editor);
  const dropdownLabel = (marks && marks[props.type]) ? marks[props.type] : props.name;

  return (
    <Dropdown text={dropdownLabel} compact={props.compact} as={Button} size='mini' onMouseDown={(ev) => ev.preventDefault()} style={{ paddingLeft:'5px',paddingRight:'5px',margin:0, borderRadius:0, lineHeight:'1.15em'}}>
      <Dropdown.Menu>
        {optionList}
      </Dropdown.Menu>
    </Dropdown>
  );
}

  
const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      mode: 'all',
      match: node => node.type === format
    })

    return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n => LIST_TYPES.includes(n.type),
    split: true,
  })

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  })

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}
  
export const BlockButton = ({ format, icon, text, compact,tooltip }) => {
    const editor = useSlate()
    const isActive = isBlockActive(editor, format);
    const showIcon = (icon) ? (<Icon name={icon} />) : null;
    return (
      <Popup trigger={      
        <Button
          icon
          compact={compact}
          size='mini'
          color={isActive ? 'teal' : null}
          active={isActive}
          onMouseDown={event => {
            event.preventDefault()
            toggleBlock(editor, format)
          }}
          style={{ margin:0, borderRadius:0, lineHeight:'1.15em'}}
        >
          {showIcon}
          {text}
        </Button>
      } content={tooltip}/>
    )
  }  
 
  const ImageElement = React.forwardRef(({ attributes, children, element }, ref) => {
    const selected = useSelected()
    const focused = useFocused()
    return (
      <div {...attributes}>
        <div contentEditable={false}>
          <img
            src={element.url}
            className={css`
              display: block;
              max-width: 100%;
              max-height: 20em;
              box-shadow: ${selected && focused ? '0 0 0 3px #00FFFF' : 'none'};
            `}
          />
        </div>
        {children}
      </div>
    )
  });

export const Element = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>
      case 'list-item':
        return <li {...attributes}>{children}</li>
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>
      case 'image':
        return <ImageElement {...props}/>
      default:
        return <p style={{marginBottom: 0}}{...attributes}>{children}</p>
    }
  }
  
  export const Leaf = ({ attributes, children, leaf, checkGrammar, lineSpacing }) => {
    const editor = useSlate();
    attributes.style = {...attributes.style, lineHeight: lineSpacing+'em'}
    if(leaf.fontFamily) {
        attributes.style = {...attributes.style,fontFamily:leaf.fontFamily};
    }
    if(leaf.fontSize) {
      let val = leaf.fontSize.split('pt')[0];
      val = parseInt(val,10) + 1;

        if( children.props.parent && 
            children.props.parent.type !== 'heading-one' && 
            children.props.parent.type !== 'heading-two') {
          attributes.style = {...attributes.style,fontSize:val+'pt'};
        }
    }

    if (leaf.bold) {
      children = <strong>{children}</strong>
    }
  
    if (leaf.code) {
      children = <code>{children}</code>
    }
  
    if (leaf.italic) {
      children = <em>{children}</em>
    }
  
    if (leaf.underline) {
      children = <u>{children}</u>
    }

    if (leaf.strikethrough) {
      children = <del>{children}</del>
    }

    if(leaf.link) {
      attributes.style = {...attributes.style, color:'#00cccc'};
      return <span {...attributes}><a style={{ cursor:'pointer'}} href={leaf.link} target='_blank' onClick={() => window.open(leaf.link,'_blank')}>{children}</a></span>;
    }

    if(leaf.comment) {
      attributes.style = {...attributes.style, backgroundColor:'#a11b1b',color:'black'};
      let span = <span {...attributes}>{children}</span>;
      return <Popup trigger={span} on='hover'>{leaf.comment}</Popup>
    }

    if(leaf.highlight && checkGrammar) {
      attributes.style = {...attributes.style, color:'#ff8800'};
      let span = <span {...attributes}>{children}</span>
      return <Popup trigger={span} on='hover'>{leaf.reason}</Popup>
    }
  
    return <span {...attributes}>{children}</span>
  }

export const withDirty = editor => {
  const {apply} = editor;
  editor.dirty = false;

  editor.apply = (op) => {
    if (op.type !== 'set_selection') {
      editor.dirty = true;
    }
    apply(op);
  }
  return editor;
}

export const withTabIndent = (editor,autoIndent) => {
  const {insertBreak} = editor;

  editor.insertBreak = () => {
    insertBreak();
    if(autoIndent) {
      editor.insertText('\t');
    }
  }
  return editor;
}

export const withRichText = editor => {
    const {insertData} = editor;

    // This is a work around for a bug in the current version.
    // Shouldn't be needed after next SlateJS release.
    editor.insertData = data => {
      // This is a bad hack to detect cut/paste from inside slate.
      const appData = data.getData('application/x-slate-fragment');
      if(appData) {
        return insertData(data);
      }

      const text = data.getData('text/plain')
      
      if(text) {
        const lines = text.split('\n');
        let split = false
  
        for (const line of lines) {
          Transforms.insertText(editor, line+'\n')
        }        
      }

      // The block comment below is the original fix suggested by the forums
      // https://github.com/ianstormtaylor/slate/pull/3472
      // Used the above fix in order to preserve carriage returns in the
      // the final text. So when you cut and paste it from the editor again
      // the linefeeds are included.
      /*
      if (text) {
        console.log(text);
        const lines = text.split(/\r\n|\r|\n/g);
        let split = false
  
        for (const line of lines) {
          if (split) {
            Transforms.splitNodes(editor, { always: true })
          }
  
          Transforms.insertText(editor, line)
          split = true
        }
      }
      */

        //return insertData(data);
    }
    
    return editor;
};

export const withImages = editor => {
  const { insertData, isVoid } = editor

  editor.isVoid = element => {
    return element.type === 'image' ? true : isVoid(element)
  }

  editor.insertData = data => {
    const text = data.getData('text/plain')
    const { files } = data

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader()
        const [mime] = file.type.split('/')

        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const url = reader.result
            insertImage(editor, url)
          })

          reader.readAsDataURL(file)
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertImage = (editor, url) => {
  const text = { text: '' }
  const image = { type: 'image', url, children: [text] }
  Transforms.insertNodes(editor, image)
}

const isImageUrl = url => {
  if (!url) return false
  if (!isUrl(url)) return false
  const ext = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(ext)
}

export const handleDeco = (node,path) => {
  let ranges = [];

  // Disabling grammar checking for now
  /*
  if (Text.isText(node)) {
    const { text } = node;
    const suggestions = writeGood(text, { weasel: false, adverb: false});
    suggestions.forEach( (part,i) => {
      if(part.reason) {
        ranges.push({
          anchor: { path, offset: part.index },
          focus: { path, offset: part.index + part.offset },
          highlight: true,
          reason: part.reason
        });
      }
    });
  }
  */ 
  //console.log(ranges);
  return ranges;
}

const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}
export const HoveringToolbar = () => {
  const ref = useRef()
  const editor = useSlate()
  let opacity = 0;
  let top = '-10000px'
  let left = '-10000px'
  useEffect(() => {
    const el = ref.current
    const { selection } = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }
    console.log('bork')
    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    
    el.style.opacity = 1
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left +
      window.pageXOffset -
      el.offsetWidth / 2 +
      rect.width / 2}px`

    /*
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${rect.left +
      window.pageXOffset -
      el.offsetWidth / 2 +
      rect.width / 2}px`*/
  })

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          position: absolute;
          z-index: 1000;
          top: -10000px;
          left: -10000px;
          margin-top: -6px;
          opacity: 0;
          transition: opacity 0.75s;
        `}
      >
      <MarkButton format="bold" icon="bold" tooltip='Bold' hotkey='B' />
      <MarkButton format="italic" icon="italic" tooltip='Italic' hotkey='I' />
      <MarkButton format="underline" icon="underline" tooltip='Underline' hotkey='U' />
      <MarkButton format="strikethrough" icon="strikethrough" tooltip='Strikethrough' hotkey='K' />
      <CommentButton icon="linkify" tooltip='Link' />
      <CommentButton icon="comment" tooltip='Comment' />
      </Menu>
    </Portal>
  )
}        
