import React,{useState,useRef,useCallback} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Input,Menu, List,Icon,Label,Popup,Button,Transition} from 'semantic-ui-react';
import { useDrag, useDrop } from 'react-dnd';
import TextareaAutosize from 'react-textarea-autosize';

import OutlineGuide from './OutlineGuide';
import DeleteModal from '../../../components/DeleteModal';
import ConfirmModal from '../../../components/ConfirmModal';
import Showable from '../../../components/Showable';
import SelectorPanel from './SelectorPanel';
import {addPlotPoint,addAct,movePlotPoint,updateLocalPlotPoint,showDeletePlotPointModal,
    hideDeletePlotPointModal,removePlotPoint,resetOutline, openProject} from '../../../project/actions';


const PlotPointCard =  ({ id, plotpoint, actId, actIndex, index, moveCard }) => {
    const dispatch = useDispatch();
    const characters = useSelector( state => state.projects.openProject.characters);
    const locations = useSelector( state => state.projects.openProject.locations);
    const [newTag,setNewTag] = useState('');
    const [showPopup,setShowPopup] = useState(false);
    const [editing,setEditing] = useState(false);

    const keyName = plotpoint.isKey ? plotpoint.keyName : null;
    const tagList = plotpoint.tags ? plotpoint.tags.map( tag => <Label key={tag} content={tag} tag color='teal' removeIcon='delete' onRemove={() => {
        let newTags = plotpoint.tags.filter(t => t !== tag);
        dispatch(updateLocalPlotPoint(plotpoint._id,'tags',newTags));
    }}/>) : [];


    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: 'plotpoint',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index;
        const dragAct = item.actIndex;
        const hoverIndex = index;
        const hoverAct = actIndex;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex && dragAct === hoverAct) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action
        moveCard(dragIndex, dragAct, hoverIndex, hoverAct);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
        item.actIndex = hoverAct;
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'plotpoint', id, index, actIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const synopsisDiv = editing ? (
        <div>
            <TextareaAutosize autoFocus className='ceta-outline-plotpoint-textarea'
                value={plotpoint.synopsis} 
                onChange={(ev) => dispatch(updateLocalPlotPoint(plotpoint._id,'synopsis',ev.target.value))}                
                onBlur={() => setEditing(false)}/>            
        </div>
        ) : (
        <div style={{ cursor: 'pointer' }}>
            {plotpoint.synopsis ? plotpoint.synopsis.split('\n').map( (s,i) => <span key={i}>{s}<br/></span>) : <span className='ceta-outline-placeholder'>Click here to edit...</span>}
        </div>);

    return (
        <List.Item key={plotpoint._id}>
            <List.Icon>
            <Popup 
                trigger={<Icon name={plotpoint.isKey ? 'key' : 'map marker'} 
                style={{ cursor: 'pointer'}}/>} 
                on='click' 
                open={showPopup} 
                onOpen={() => setShowPopup(true)} 
                onClose={() => setShowPopup(false)}>
                    <Input icon='tag' iconPosition='left' placeholder='Add tag...' value={newTag} 
                    onKeyPress={(ev) => {
                        if(ev.key === 'Enter') {
                            let newTags = [newTag];
                            newTags = newTags.concat(plotpoint.tags);
                            dispatch(updateLocalPlotPoint(plotpoint._id,'tags',newTags));
                            setNewTag('');
                        }
                    }} onChange={(ev) => {
                        setNewTag(ev.target.value)
                    }}/>
                    <Showable showIf={!plotpoint.isKey}>
                        <p/>
                        <Button onClick={(ev) => {
                        dispatch(showDeletePlotPointModal(actId,plotpoint._id));
                        setShowPopup(false);
                        }}>Delete <Icon name='delete'/></Button>
                    </Showable>
                </Popup>
            </List.Icon>
        <List.Content>
        <div ref={ref} key={id} >
            <div className='ceta-outline-plotpoint' style={{ opacity}}>
                <List.Header className='ceta-outline-plotpoint-header'>
                    {keyName}</List.Header>
                <div onClick={() => setEditing(true)} >
                    {synopsisDiv}
                </div>
                <hr style={{ color:'#ccc'}}/>                
                {tagList}
                <SelectorPanel id={plotpoint._id} field='characters' value={plotpoint.characters} options={characters}/>
                <SelectorPanel id={plotpoint._id} field='locations' value={plotpoint.locations} options={locations}/>
            </div>
        </div>
        </List.Content>
        </List.Item>
    );
}

const OutlinePanel = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;
    const [filter,setFilter] = useState('');
    const [showResetConfirm,setShowResetConfirm] = useState(false);

    const moveCard = useCallback(
        (dragIndex,dragAct,hoverIndex,hoverAct) => {
            dispatch(movePlotPoint(dragIndex,dragAct,hoverIndex,hoverAct));
        }
    );    

    const list = openProject.acts.map( (act,actIndex) => {

        const points = act.plotpoints.map( (pp, ppIndex) => {
            let plotpoint;
            for(let i=0;i<openProject.plotpoints.length;i++) {
                if( pp === openProject.plotpoints[i]._id) {
                    plotpoint = openProject.plotpoints[i];
                }
            }
            if(plotpoint) {
                return (
                        <PlotPointCard 
                            key={plotpoint._id} 
                            id={plotpoint._id} 
                            plotpoint={plotpoint}
                            index={ppIndex} 
                            actId={act._id}
                            actIndex={actIndex}
                            moveCard={moveCard}
                        />
                );
            }
        });

        return (
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500} key={act._id}>
                <div key={act._id} className='ceta-outline-act'>
                    <Menu color='teal' attached='top' inverted borderless>
                        <Menu.Item>Act {actIndex+1}</Menu.Item>
                        <Menu.Item position='right' onClick={() => dispatch(addPlotPoint(act._id))}>
                            <Popup trigger={<Icon name='add circle' style={{ cursor:'pointer'}}/>}>Add a plot point</Popup>
                        </Menu.Item>
                    </Menu>
                    <List verticalAlign='middle' style={{paddingLeft: '0.5em'}}>
                        {points}
                    </List>
                </div>
            </Transition>
        );
    });

    if(openProject.acts.length === 0) {
        // Show the template option
        return <OutlineGuide project={openProject}/>
    }
    
    return (
        <div className='ceta-idea-board'>
            <div className='idea-toolbar'>
                <Button color='teal' onClick={() => dispatch(addAct())}><Icon name='plus circle'/>Add Act</Button>
                <Button color='teal' onClick={() => setShowResetConfirm(true)}><Icon name='minus circle'/>Reset Outline</Button>
            </div>
            <div className='idea-cards'>
                <div className='idea-card-group'>
                    <ConfirmModal 
                        show={showResetConfirm} 
                        title='Reset Outline'
                        message='Are you sure you want to reset the outline? All work on it will be lost'
                        onCancel={() => setShowResetConfirm(false)} 
                        onConfirm={ () => {
                            dispatch(resetOutline());
                            setShowResetConfirm(false);
                        }}/>                    
                    <DeleteModal 
                        plotpointId={projects.deletePlotPointModalPlotPointId}
                        actId={projects.deletePlotPointModalActId}
                        show={projects.showDeletePlotPointModal}                     
                        onCancel={() => dispatch(hideDeletePlotPointModal())} 
                        onConfirm={(data) => {
                            dispatch(removePlotPoint(data.actId,data.plotpointId));
                            dispatch(hideDeletePlotPointModal());
                        }}
                    />

                    {list}
                </div>
            </div>
        </div>
    );
}

export default OutlinePanel;