import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Menu,Sidebar,Icon} from 'semantic-ui-react';
import {
    createChapter,createScene,openScene,updateProject,
    showDeleteSceneModal, showDeleteChapterModal,
    openTimeline,openCharactersPanel,openLocationsPanel
} from '../../project/actions';

const ProjectBar = (props) => {
    const dispatch = useDispatch();
    const [sidebarOpened,setSidebarOpened] = useState(false);
    const [chapterSidebarOpened,setChapterSidebarOpened] = useState(false);
    const [sceneSidebarOpened,setSceneSidebarOpened] = useState(false);
    const [sceneMenuOpened,setSceneMenuOpened] = useState(false);
    const [selectedChapterIndex,setSelectedChapterIndex] = useState(0);
    const [selectedSceneIndex,setSelectedSceneIndex] = useState(0);
    const [active,setActive] = useState('');
    const openProject = useSelector(state => state.projects.openProject);
    const openProjectChapter = useSelector(state => state.projects.openProjectChapter);
    const openProjectScene = useSelector(state => state.projects.openProjectScene);

    const chapterList = openProject.chapters.map( (chapter,index) => {
        const titleStyle = (openProjectChapter._id === chapter._id) ? {fontWeight:'bold'} : {};
        return (
        <Menu.Item key={chapter._id} style={titleStyle} onClick={() => {
            setSelectedChapterIndex(index);
            setSceneSidebarOpened(true);
            setChapterSidebarOpened(false);
        }}>
            {chapter.name}
        </Menu.Item>
        )
    });
    const sceneList = openProject.chapters[selectedChapterIndex].scenes.map((scene,index) => {
        const titleStyle = (openProjectScene._id === scene._id) ? {fontWeight:'bold'} : {};
        return (
            <Menu.Item key={scene._id} style={titleStyle} onClick={() => {
                setSelectedSceneIndex(index);
                setSceneSidebarOpened(false);
                setChapterSidebarOpened(false);
                setSceneMenuOpened(true);
            }}>
                {scene.name}
            </Menu.Item>    
        );
    });
    return (
        <div >
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSidebarOpened(false)}
                vertical
                visible={sidebarOpened}
            >
                <Menu.Item onClick={() => {
                    setSidebarOpened(false);
                    dispatch(updateProject({project: openProject}))}
                    }><Icon name='cloud upload'/> Save to cloud</Menu.Item>
                <Menu.Item
                    active={active === 'Chapters'}
                    onClick={() => {
                        setActive('Chapters');
                        setChapterSidebarOpened(true);
                        setSidebarOpened(false);
                        }}><Icon name='book'/>Chapters</Menu.Item>
                <Menu.Item 
                    active={active === 'Characters'}
                    onClick={() => {
                        setActive('Characters');
                        dispatch(openCharactersPanel(openProject._id));
                        setSidebarOpened(false);
                        }}><Icon name='users'/>Characters
                </Menu.Item>
                <Menu.Item
                    active={active === 'Locations'}
                    onClick={() => {
                        setActive('Locations');
                        dispatch(openLocationsPanel(openProject._id));
                        setSidebarOpened(false);
                        }}
                    ><Icon name='globe'/> Locations</Menu.Item>
                <Menu.Item
                    active={active === 'Timeline'}
                    onClick={() => {
                        setActive('Timeline');
                        dispatch(openTimeline(openProject._id));
                        setSidebarOpened(false);
                        }}
                ><Icon name='calendar'/> Timeline</Menu.Item>
            </Sidebar>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setChapterSidebarOpened(false)}
                vertical
                visible={chapterSidebarOpened}
            >
                <Menu.Item onClick={() => {
                    dispatch(createChapter(openProject._id))}
                    }><Icon name='plus'/>Add Chapter</Menu.Item>
                {chapterList}
            </Sidebar>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSceneSidebarOpened(false)}
                vertical
                visible={sceneSidebarOpened}
            >
                <Menu.Item onClick={() => {
                    dispatch(createScene(openProject._id,openProject.chapters[selectedChapterIndex]._id))}
                    }><Icon name='plus'/> Add Scene</Menu.Item>
                <Menu.Item onClick={() => {
                    setSelectedSceneIndex(0);
                    setSelectedChapterIndex(0);
                    setSceneSidebarOpened(false);
                    dispatch(showDeleteChapterModal(openProject._id,openProject.chapters[selectedChapterIndex]._id))}
                    }><Icon name='delete'/> Delete Chapter</Menu.Item>
                {sceneList}
            </Sidebar>
            <Sidebar
                as={Menu}
                animation='overlay'
                inverted
                onHide={() => setSceneMenuOpened(false)}
                vertical
                visible={sceneMenuOpened}
            >
                <Menu.Item onClick={() => 
                    dispatch(openScene(openProject.chapters[selectedChapterIndex]._id,
                    openProject.chapters[selectedChapterIndex].scenes[selectedSceneIndex]._id))}
                    ><Icon name='edit'/> Edit</Menu.Item>
                <Menu.Item onClick={() => dispatch(showDeleteSceneModal(openProject._id,
                    openProject.chapters[selectedChapterIndex]._id,
                    openProject.chapters[selectedChapterIndex].scenes[selectedSceneIndex]._id))}
                    ><Icon name='delete'/> Delete</Menu.Item>
            </Sidebar>

            <div style={{
                background:'black',
                color:'white',
                width: '100%',
                height: '3em',
                lineHeight: '3em',
                display: 'inline-block'
            }}>
              <Menu inverted pointing secondary size='large'>
                <Menu.Item onClick={() => setSidebarOpened(true)}>
                  <Icon name='cog' />
                </Menu.Item>
                <Menu.Item>{active}</Menu.Item>
              </Menu>
            </div>
            <div style={{ height: '80vh', overflowY:'auto'}}>
                {props.children}
            </div>            
        </div>
    );
}

export default ProjectBar;