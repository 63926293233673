import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Statistic,Button,Icon,Input,Modal,Menu,Header,Progress,Table, Form} from 'semantic-ui-react';
import {hideContextSideBar,setResearchPallet} from '../../../project/actions';

const BrainstormSettingsContext = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;

    const palleteOptions = [
        { key: '#ffff88', value: '#ffff88', text:'Sticky', textcolor: 'black',active: (openProject.researchBackgroundColor==='')},
        { key: '#bbb', value: '#bbb', text:'Grey', textcolor: 'black'},
        { key: 'white', value: 'white', text:'Notecard', textcolor: 'black'},
        { key: '#70E0E0', value: '#70E0E0', text:'Gulf', textcolor: 'black'},
        { key: '#363636', value: '#363636', text:'Slate', textcolor: 'white'},
    ];    
    return (
        <div>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item >
                            <Header color='teal'>Settings</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>            
            <div style={{ height:'calc(100vh - 50px)', overflowY: 'auto',overflowX: 'hidden'}}>
                <div style={{ marginTop:'1em'}}>
                    <Form.Field>
                        <label>Color Pallete</label>
                        <Form.Select options={palleteOptions} value={openProject.researchBackgroundColor} 
                            onChange={ (ev,dat) => {
                                let textcolor = dat.options.filter(opt => opt.value === dat.value).reduce( (a,c) => c.textcolor);
                                dispatch(setResearchPallet(dat.value,textcolor.textcolor));
                            }}/>
                    </Form.Field>
                </div>
            </div>
        </div>
    );
}

export default BrainstormSettingsContext;