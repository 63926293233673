import React, { useCallback,useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Grid,Popup,Breadcrumb,Responsive,Segment} from 'semantic-ui-react';
import Showable from '../../components/Showable';
import {
    addChapter,moveChapter,moveChapterUp,moveChapterDown,showChapterRenameModal,showDeleteChapterModal
} from '../../project/actions';
import {convertElementToPlainText} from '../../util/exportToPlainText';

const style = {
    border: '1 solid',
    boxShadow: '0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5',
    padding: '0.5rem 1rem',
    marginBottom: '1em',
    backgroundColor: '#141414',
    color:'white',
    cursor: 'move',
    borderRadius: '.28571429rem',
    minHeight: '2em'
  }

const MyCard = ({ id, chapter, index, moveCard }) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: 'card',
      hover(item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect()
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
        // Time to actually perform the action
        moveCard(dragIndex, hoverIndex)
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
    const [{ isDragging }, drag] = useDrag({
      item: { type: 'card', id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    let preview = convertElementToPlainText(chapter.text);
    if(preview.length > 250) {
        preview = preview.slice(0,250);
    }
    const moveUpButton = (<Button size='mini' icon='arrow left' onClick={() => dispatch(moveChapterUp(chapter._id))}/>);
    const deleteButton = (<Button size='mini' icon='delete' 
        onClick={() => dispatch(showDeleteChapterModal(openProject._id,chapter._id))}/>);
    const renameButton = (<Button size='mini' icon='eraser' 
        onClick={() => dispatch(showChapterRenameModal(chapter._id,chapter.name))}/>);
    const moveDownButton = (<Button size='mini' icon='arrow right' onClick={() => dispatch(moveChapterDown(chapter._id))}/>);

    return (
      <div ref={ref} style={{ ...style, opacity }}>
        <Grid container columns={2} stackable>
            <Grid.Row>
                <Grid.Column width={12}>
                    <span style={{ fontSize: 'larger'}}><strong>Chapter {index+1}</strong>: {chapter.name}</span>
                    <div style={{ color: '#ccc'}}>{preview}</div>
                </Grid.Column>
                <Grid.Column  width={4} >
                    <Button.Group size='mini'>
                        <Popup trigger={moveUpButton}>Move Up</Popup>
                        <Popup trigger={renameButton}>Edit Title</Popup>
                        <Popup trigger={deleteButton}>Delete Chapter</Popup>
                        <Popup trigger={moveDownButton}>Move Down</Popup>
                    </Button.Group>            
                </Grid.Column>
            </Grid.Row>
        </Grid>
      </div>
    )
  }

const ChaptersPanel = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);

    const noItemsMessage = (openProject.chapters.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', margin: '2em'}}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Chapters</h2>
        </div>
    ) : null;

    const moveCard = useCallback(
        (dragIndex,hoverIndex) => {
            dispatch(moveChapter(dragIndex,hoverIndex));
        }
    );

    const renderCard = (chapter,index) => {
        return (
            <MyCard 
                key={chapter._id} 
                index={index}
                id={chapter._id}
                chapter={chapter}
                moveCard={moveCard}
            />
        );
    }

    return (
        <div style={{
            height:'100%',display:'flex',flexDirection:'column',            
        }}>
            <div>
                
            </div>
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <div style={{flexShrink: 0}}>
                <div style={{color:'white'}}>
                    <Segment inverted>
                        <Breadcrumb style={{color:'white'}} size='large'>
                            <Breadcrumb.Section>Project</Breadcrumb.Section>
                            <Breadcrumb.Divider style={{ color:'white'}}/>
                            <Breadcrumb.Section active>Chapters</Breadcrumb.Section>
                        </Breadcrumb>
                    </Segment>
                </div>
            </div>
            </Responsive>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <div style={{margin:'1em'}}>

                    <Showable showIf={!props.readOnly}>
                    <Button color='teal' onClick={() => {
                        dispatch(addChapter(openProject._id));
                    }}>
                        <Icon name='book'/> New Chapter
                    </Button>
                    </Showable>
                    <p/>
                    {noItemsMessage}
                    {openProject.chapters.map( (chapter,index) => renderCard(chapter,index))}
                </div>
            </div>
        </div>

    );
}

export default ChaptersPanel;