import React, { useEffect,useState } from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {format} from 'date-fns';
import {Statistic,Button,Icon,Input,Modal,Menu,Header,Progress,Table, Form} from 'semantic-ui-react';
import {setLineSpacing,hideContextSideBar,toggleAutoIndent,setEditorPallet} from '../../../project/actions';
import {countWordsInProject} from '../../../util/wordCount';

const EditorSettingsContext = (props) => {
    const dispatch = useDispatch();
    const viewer = useSelector(state => state.viewer);
    const projects = useSelector(state => state.projects);
    const openProject = props.readOnly ? viewer.openProject : projects.openProject;

    const spacingOptions = [
        { key: '1', value:1, text:'Single'},
        { key: '1.5', value:1.5, text:'1.5'},
        { key: '2', value:2, text:'Double'},
    ];
    const palleteOptions = [
        { key: 'black', value: 'black', text:'Black', textcolor: 'white'},
        { key: '#1b1c1d', value: '#1b1c1d', text:'Slate', textcolor: 'white'},
        { key: '#eee', value: '#eee', text:'Fog', textcolor: 'black'},
        { key: '#ffff88', value: '#ffff88', text:'Legal', textcolor: 'black'},
        { key: '#1d202e', value: '#1d202e', text:'Navy', textcolor: 'white'},
        { key: '#363636', value: '#363636', text:'Grey', textcolor: 'white', active: (openProject.backgroundColor==='' || openProject.backgroundColor ==='#363636')},
        { key: '#000', value: '#000', text:'Terminal', textcolor: '#0d0'},        
        { key: 'white', value: 'white', text:'White', textcolor: 'black'},
    ];    
    return (
        <div>
            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item >
                            <Header color='teal'>Settings</Header>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>            
            <div style={{ height:'calc(100vh - 50px)', overflowY: 'auto',overflowX: 'hidden'}}>
                <div style={{ marginTop:'1em'}}>
                    <Button color={openProject.autoIndent?'teal':null} onClick={() => dispatch(toggleAutoIndent())}>Auto-Indent {openProject.autoIndent?'Enabled':'Disabled'}</Button>
                    <p/>
                    <Form.Field>
                        <label>Line Spacing</label>
                        <Form.Select options={spacingOptions} value={openProject.spacing} onChange={ (ev,dat) => dispatch(setLineSpacing(dat.value))}/>
                    </Form.Field>
                    <p/>
                    <Form.Field>
                        <label>Color Pallete</label>
                        <Form.Select options={palleteOptions} value={openProject.backgroundColor} 
                            onChange={ (ev,dat) => {
                                let textcolor = dat.options.filter(opt => opt.value === dat.value).reduce( (a,c) => c.textcolor);
                                dispatch(setEditorPallet(dat.value,textcolor.textcolor));
                            }}/>
                    </Form.Field>

                </div>
            </div>
        </div>
    );
}

export default EditorSettingsContext;