export const ROLES = {
    Basic: 'Basic',
    Premium: 'Premium',
    Admin: 'Admin',
    Demo: 'Demo',
    Beta: 'Beta'
};

export const hasRole = (user,role) => {
    if(user && user.roles && role) {
        return user.roles.indexOf(role) > -1;
    }
    return false;
}

export const hasPremium = (user) => {
    return (user && user.roles && 
        ( hasRole(user,ROLES.Premium) || hasRole(user,ROLES.Admin) || 
        hasRole(user,ROLES.Demo) || hasRole(user,ROLES.Beta)));
};

export const isAdmin = (user) => {
    return (user && user.roles && hasRole(user,ROLES.Admin) );
}