import React,{useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Container,Dimmer,Loader,Segment,Header} from 'semantic-ui-react';
import Avatar from '../../components/Avatar';
import Toolbar from '../header/Toolbar';
import ProfileModal from './ProfileModal';
import ErrorModal from '../../components/ErrorModal';

import {followUser,unfollowUser,fetchFollowers,dismissFeedError,openProfileModal} from '../../feed/actions';

const LikeButton = ({profile}) => {
    const dispatch = useDispatch();

    let followButton = profile.isFollowing?
        (<Button color='teal' onClick={() => dispatch(unfollowUser(profile.name))}>Unfollow</Button>):
        (<Button onClick={() => dispatch(followUser(profile.name))}>Follow</Button>);
    return  followButton;
}

const Following = (props) => {
    const dispatch = useDispatch();
    const feed = useSelector( state => state.feed);
    const followers = useSelector( state => state.feed.followers);

    useEffect( () => {
        dispatch(fetchFollowers());
    },[dispatch]);
    
    const list = followers.map( profile => 
    <div style={{ color:'white', width:'100%'}} key={profile.name}>
        <div style={{ display:'inline-block' }}><Avatar profile={profile} /></div>
        <div style={{ display:'inline-block' }}><span style={{ cursor:'pointer'}} onClick={() => dispatch(openProfileModal(profile.name))}><strong>{profile.name}</strong></span></div>
        <div  style={{ display:'inline-block',float:'right' }}><LikeButton profile={profile} /></div>
    </div>);
    return (
        <Toolbar>
            <Dimmer active={feed.isLoadingFollows}>
                <Loader>Loading</Loader>
            </Dimmer>
            <ProfileModal />
            <ErrorModal 
                show={feed.showError} 
                message={feed.errorMessage}
                onConfirm={() => dispatch(dismissFeedError())}
            />
            <Container text style={{paddingTop: '2em'}}>
                <Segment inverted>
                <Header>You Have {followers.length} Followers</Header>
                {list}
                </Segment>
            </Container>
        </Toolbar>
    );
};

export default Following;