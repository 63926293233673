import React,{useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Icon,Button,List,Image,Transition,Popup} from 'semantic-ui-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './patch.css';
import NewEventModal from './NewEventModal';
import EditEventModal from './EditEventModal';
import ClosePanelWidget from '../ClosePanelWidget';
import Showable from '../../../components/Showable';
import DeleteModal from '../../../components/DeleteModal';
import {
    showCreateEventModal,openEvent,showDeleteEventModal,removeEvent,hideDeleteEventModal
} from '../../../project/actions';

const filterEvents = (event,criteria) => {
    return true;
}

function pad(num, size) {
    var s = num+'';
    while (s.length < size) s = '0' + s;
    return s;
}    

const translateEpoch = (value) => {
    let s = value.split(',');
    return pad(s[2],2)+'/'+pad(s[3],2)+'/'+pad(s[0],2)+pad(s[1],2)+' '+pad(s[4],2)+':'+pad(s[5],2);
}

const translateBookmark = (event) => {
    return 'Act '+event.act+' Chapter '+event.chapter+' Scene '+event.scene;
}

const sortByChapter = (ev1,ev2) => {
    if(ev1.act > ev2.act) {
        return 1;
    }
    else if (ev1.act < ev2.act) {
        return -1;
    }
    else if (ev1.chapter > ev2.chapter) {
        return 1;
    }
    else if (ev1.chapter < ev2.chapter) {
        return -1;
    }
    else if (ev1.scene > ev2.scene) {
        return 1;
    }
    else if (ev1.scene < ev2.scene) {
        return -1;
    }
    return 0;
}

const sortByEpoch = (ev1,ev2) => {
    const ev1Epoch = ev1.epoch.split(',');
    const ev2Epoch = ev2.epoch.split(',');
    if(ev1Epoch[0] > ev2Epoch[0]) {
        return 1;
    }
    else if (ev1Epoch[0] < ev2Epoch[0]) {
        return -1;
    }
    else if(ev1Epoch[1] > ev2Epoch[1]) {
        return 1;
    }
    else if (ev1Epoch[1] < ev2Epoch[1]) {
        return -1;
    }
    else if(ev1Epoch[2] > ev2Epoch[2]) {
        return 1;
    }
    else if (ev1Epoch[2] < ev2Epoch[2]) {
        return -1;
    }
    else if(ev1Epoch[3] > ev2Epoch[3]) {
        return 1;
    }
    else if (ev1Epoch[3] < ev2Epoch[3]) {
        return -1;
    }
    else if(ev1Epoch[4] > ev2Epoch[4]) {
        return 1;
    }
    else if (ev1Epoch[4] < ev2Epoch[4]) {
        return -1;
    }
    else if(ev1Epoch[5] > ev2Epoch[5]) {
        return 1;
    }
    else if (ev1Epoch[1] < ev2Epoch[5]) {
        return -1;
    }
    return 0;
}

const PlotPointDisplay = ({plotpointId}) => {
    const openProject = useSelector(state => state.projects.openProject);
    let plotpoint;
    if(plotpointId) {
        openProject.plotpoints.forEach( pp => {
            if(pp._id == plotpointId) { 
                plotpoint = pp; 
            }
        });
    }
    return (
        <div style={{ fontSize: 'smaller',paddingTop:'0.5em'}}>
            {plotpoint?.synopsis}
        </div>
    );
}

const TimelinesPanel = (props) => {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);
    const openProject = useSelector(state => state.projects.openProject);
    const [storyMode,setStoryMode] = useState(false);

    const hourglass = (
        <div style={{ width:'100%',height:'100%',marginLeft:'0.555em',marginTop:'0.555em'}}>
            <Icon name='hourglass outline' size='large' style={{margin:0}}/>
        </div>
    );
    let sortedEvents = openProject.events.slice();
    sortedEvents.sort( storyMode? sortByChapter : sortByEpoch );
    const events = sortedEvents
        .filter(event => filterEvents(event,[]))
        .map( event => {
            let charList = [];
            if(event.characters) {
                charList = event.characters.map( charId => {
                    for(let i=0;i<openProject.characters.length;i++) {
                        if(openProject.characters[i]._id === charId) {
                            const avatarUrl = openProject.characters[i].image ? 
                                openProject.characters[i].image : '/img/ceta-persona-default.png';
                            return (
                            <List.Item key={openProject.characters[i]._id}>
                                <Image avatar src={avatarUrl} />
                                <List.Content>
                                <List.Header style={{ color: 'white'}}>
                                {openProject.characters[i].name}
                                </List.Header>
                                </List.Content>
                            </List.Item>
                            );
                        }
                    }
                });
            }

            const ordering = storyMode ? translateBookmark(event) : translateEpoch(event.epoch);
            const editButton = (<Button size='tiny' icon='edit' onClick={() => dispatch(openEvent(event._id))} />);
            const deleteButton = (<Button size='tiny' icon='delete' onClick={() => dispatch(showDeleteEventModal(openProject._id,event._id))}/>);
            
            return (
                <VerticalTimelineElement key={event._id}
                    className="vertical-timeline-element"
                    contentStyle={{ background: '#262626', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #262626' }}
                    date={ordering}
                    iconStyle={{ background: '#262626', color: '#fff' }}
                    icon={hourglass}
                >
                    <h3 className="vertical-timeline-element-title">{event.name}</h3>
                    <p>
                    {event.description}
                    </p>
                    <PlotPointDisplay plotpointId={event.plotpoint} />
                    <List>
                        {charList}
                    </List>
                    <div style={{float:'right',marginTop:'1em'}}>
                        <Button.Group>
                            <Popup trigger={editButton}>Edit</Popup>
                            <Popup trigger={deleteButton}>Delete</Popup>
                        </Button.Group>
                    </div>
                </VerticalTimelineElement>                
            );
        });

    const noItemsMessage = (openProject.events.length === 0) ? (
        <div style={{ display: 'flex',justifyContent: 'center', alignItems: 'center', margin: '2em'}}>
            <Transition transitionOnMount={true} unmountOnHide={true} visible={true} animation='scale' duration={500}>
            <h2 style={{ color: 'white' }}>You Have Not Added Any Events</h2>
            </Transition>
        </div>
    ) : null;
    
    return (
        <div className='ceta-idea-board'>
            <DeleteModal 
                eventId={projects.deleteEventModalEventId}
                projectId={projects.deleteEventModalProjectId}
                show={projects.showDeleteEventModal}                     
                onCancel={() => dispatch(hideDeleteEventModal())} 
                onConfirm={(data) => {
                    dispatch(removeEvent(data.projectId,data.eventId));
                    dispatch(hideDeleteEventModal());
                }}
            />

            <ClosePanelWidget />
            <div className='idea-toolbar'>
                <Button color='teal' onClick={() => dispatch(showCreateEventModal())}><Icon name='hourglass'/>New Event</Button>
            </div>            
            <div className='idea-cards'>
                <NewEventModal />
                <EditEventModal />
                {noItemsMessage}
                <Showable showIf={(openProject.events.length > 0)}>
                    <VerticalTimeline>
                        {events}
                    </VerticalTimeline>
                </Showable>

            </div>

        </div>
    );
}

export default TimelinesPanel;