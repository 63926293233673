import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Button,Popup} from 'semantic-ui-react';
import {
    updateProject
} from '../../../project/actions';
import {toggleDistractionFree} from '../../../user/actions';

const BasicSideBar = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const isProjectDirty = useSelector( state => state.projects.isProjectDirty);
    const isDistractionFree = useSelector( state => state.user.isDistractionFree);
    const uploadStyle = isProjectDirty ? {color:'teal'} : {};

    return (
        <div style={{
            height:'100%',
            display:'flex',
            flexDirection:'column'
        }}>
            <div style={{flexShrink: 0}}>
            <Popup trigger={
                    <Button {...uploadStyle} icon='cloud upload' 
                        disabled={!isProjectDirty}
                        onMouseDown={ (ev) => ev.preventDefault()}
                        onClick={(ev) => {
                            ev.preventDefault();
                            dispatch(updateProject({project: openProject}));                            
                        }}
                    />
                } content='Save project to cloud' />
            <Popup trigger={
                    <Button icon='expand' 
                        onMouseDown={ (ev) => ev.preventDefault()}
                        onClick={() => dispatch(toggleDistractionFree(!isDistractionFree))}
                    />
                } content='Distraction free mode' />
            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
            </div>
        </div>
    );
};

export default BasicSideBar;