import {
    DISMISS_CONTACT_ERROR,
    FETCH_CONTACTS_SEND,FETCH_CONTACTS_SUCCESS,FETCH_CONTACTS_ERROR,
    REQUEST_CONTACT_SEND,REQUEST_CONTACT_SUCCESS,REQUEST_CONTACT_ERROR,
    DELETE_CONTACT_SEND,DELETE_CONTACT_SUCCESS,DELETE_CONTACT_ERROR,
    APPROVE_CONTACT_SEND,APPROVE_CONTACT_SUCCESS,APPROVE_CONTACT_ERROR,
    OPEN_CHAT
} from './actions';
import { FETCH_UNREAD_SUCCESS } from '../inbox/actions';

const initialState = {
    mututal:[],
    pending:[],
    requested:[],
    pendingRequests: 0,
    showError: false,
    errorMessage: '',
    isFetchingContacts: false,
    fetchContactsError: false,
    fetchContactsErrorMessage: '',
    isRequestingContact: false,
    requestContactError: false,
    requestContactErrorMessage: '',
    isDeletingContact: false,
    deleteContactError: false,
    deleteContactErrorMessage: '',
    isApprovingContact: true,
    approveContactError: false,
    approveContactErrorMessage: '',
    openChatContact: '',
    openChatLog: []
};

export default (state = initialState, action) => {
switch(action.type) {
    case DISMISS_CONTACT_ERROR:
        return {
            ...state,
            showError: false,
            errorMessage: ''
        }
    case FETCH_CONTACTS_SEND:
        return {
            ...state,
            isFetchingContacts: true,
            fetchContactsError: false,
            fetchContactsErrorMessage: ''
        };
    case FETCH_CONTACTS_SUCCESS:
        let newPending = action.payload.data.contacts.filter( contact => contact.pending && !contact.mutual);
        let newRequested = action.payload.data.contacts.filter( contact => contact.requested && !contact.mutual);
        let newMutual = action.payload.data.contacts.filter( contact => contact.mutual);
        return {
            ...state,
            pending: newPending,
            requested: newRequested,
            mututal: newMutual,
            isFetchingContacts: false
        };
    case FETCH_CONTACTS_ERROR:
        return {
            ...state,
            isFetchingContacts: false,
            showError: true,
            errorMessage: action.payload
        };

    case REQUEST_CONTACT_SEND:
        return {
            ...state,
            isRequestingContact: true,
            requestContactError: false,
            requestContactErrorMessage: ''
        };
    case REQUEST_CONTACT_SUCCESS:
        return {
            ...state,
            isRequestingContact: false
        };
    case REQUEST_CONTACT_ERROR:
        return {
            ...state,
            isRequestingContact: false,
            showError: true,
            errorMessage: action.payload
        };  
    case DELETE_CONTACT_SEND:
        return {
            ...state,
            isDeletingContact: true,
            deleteContactError: false,
            deleteContactErrorMessage: ''
        };
    case DELETE_CONTACT_SUCCESS:
        return {
            ...state,
            isDeletingContact: false
        };
    case DELETE_CONTACT_ERROR:
        return {
            ...state,
            isDeletingContact: false,
            showError: true,
            errorMessage: action.payload
        };
    case APPROVE_CONTACT_SEND:
        return {
            ...state,
            isApprovingContact: true,
            approveContactError: false,
            approveContactErrorMessage: ''
        };
    case APPROVE_CONTACT_SUCCESS:
        return {
            ...state,
            isApprovingContact: false
        };
    case APPROVE_CONTACT_ERROR:
        return {
            ...state,
            isApprovingContact: false,
            showError: true,
            errorMessage: action.payload
        };  
    case OPEN_CHAT:
        return {
            ...state,
            openChatContact: action.contactId,
        }
    case FETCH_UNREAD_SUCCESS:
        return {
            ...state,
            pendingRequests: action.payload.data.contacts
        }
    default:
        return {...state};
}
};