import * as ActionTypes from './constants';
import {convertToPlainText} from '../util/exportToPlainText';

export const handleProjects = () => {
    return {

    [ActionTypes.DISMISS_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            showError: false,
            errorMessage: ''
        }
    },
   [ActionTypes.CREATE_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingProject: true,
            createProjectError: false,
            createProjectErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            isCreatingProject: false,
            showCreateProjectModal: false
        };
    },
    [ActionTypes.CREATE_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingProject: false,
            showError: true,
            errorMessage: action.payload
        };  
    },
    [ActionTypes.UPDATE_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isUpdatingProject: true,
            updateProjectError: false,
            updateProjectErrorMessage: ''
        };
    },
    [ActionTypes.UPDATE_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            isUpdatingProject: false,
            isOffline: false,
            isProjectDirty: false,
            lastSaveDate: new Date().getTime(),
            openProject: action.payload.data.project
        };
    },
    [ActionTypes.UPDATE_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isUpdatingProject: false,
            showError: true,
            errorMessage: action.payload
        };  
    },
    [ActionTypes.AUTO_SAVE_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isAutoSavingProject: true,
            showError: false,
            errorMessage: ''
        };
    },
    [ActionTypes.AUTO_SAVE_PROJECT_SUCCESS]: (state,action) => {
        let autoOpenProject = {...state.openProject};
        autoOpenProject.version = action.payload.data.version;
        return {
            ...state,
            isAutoSavingProject: false,
            isOffline: false,
            isProjectDirty: false,
            lastSaveDate: new Date().getTime(),
            openProject: autoOpenProject
        };
    },
    [ActionTypes.AUTO_SAVE_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isAutoSavingProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OFFLINE_MODE]: (state,action) => {
        return {
            ...state,
            isOffline: true
        }
    },
    [ActionTypes.SHOW_CREATE_PROJECT_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateProjectModal:true
        }
    },
    [ActionTypes.HIDE_CREATE_PROJECT_MODAL]: (state,action) => {
        return {
            ...state,
            showCreateProjectModal:false
        }
    },
    [ActionTypes.FETCH_PROJECTS_SEND]: (state,action) => {
        return {
            ...state,
            isFetchingProjects: true,
            fetchProjectsError: false,
            fetchProjectsErrorMessage: ''
        };
    },
    [ActionTypes.FETCH_PROJECTS_SUCCESS]: (state,action) => {
        return {
            ...state,
            all: action.payload.data.projects,
            isFetchingProjects: false
        };
    },
    [ActionTypes.FETCH_PROJECTS_ERROR]: (state,action) => {
        return {
            ...state,
            isFetchingProjects: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.READ_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isReadingProject: true,
        };
    },
    [ActionTypes.READ_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            openProject: action.payload.data.project,
            isReadingProject: false
        };
    },
    [ActionTypes.READ_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isReadingProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.DELETE_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isDeletingProject: true,
            deleteProjectError: false,
            deleteProjectErrorMessage: ''
        };
    },
    [ActionTypes.DELETE_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            isDeletingProject: false
        };
    },
    [ActionTypes.DELETE_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isDeletingProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_PROJECT]: (state,action) => {
        return {
            ...state,
            isOpeningProject:true,
            openProjectSharedEdit: action.sharedEdit
        };
    },
    [ActionTypes.OPEN_PROJECT_SUCCESS]: (state,action) => {
        let openChapter = {};
        let openScene = {};

        for(let i=0;i<action.payload.chapters.length;i++) {
            let chapter = action.payload.chapters[i];
            if(chapter.scenes.length > 0) {
                openChapter = chapter;
                openScene = chapter.scenes[0];
                break;
            }
        }

        let initialOpenPanel = 'Chapter';
        let initialContext = '';
        let initialShowContext = false;
        switch(action.payload.view) {
            case 'Brainstorm': {
                initialOpenPanel = 'Ideas';
                break;
            }
            case 'Outline': {
                initialOpenPanel = 'Outline';
                break;
            }
            default: {
                initialOpenPanel = 'Chapter';
                initialContext = 'Chapters';
                initialShowContext = true;
                break;
            }
        }
        
        return {
            ...state,
            isOpeningProject:false,
            isProjectDirty: action.isDirty,
            openProjectPanel: initialOpenPanel,
            showContextSideBar: initialShowContext,
            openContextPanel: initialContext,
            openProject: action.payload,
            openProjectChapter: openChapter,
            openProjectScene: openScene,
            openProjectCharacter: {},
            openProjectRelationship: {},
            showIntroModal: (action.payload.createDate === action.payload.updateDate)
        };
    },
    [ActionTypes.OPEN_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isOpeningProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.UPDATE_LOCAL_SCENE]: (state,action) => {
        let updatedProject = {...state.openProject};
        let updatedScene = {...state.openProjectScene};        
        for(let i=0;i<updatedProject.chapters.length;i++) {
            let chapter = updatedProject.chapters[i];
            if(chapter._id === state.openProjectChapter._id) {
                for(let j=0;j< chapter.scenes.length;j++) {
                    let scene = chapter.scenes[j];
                    if(scene._id === state.openProjectScene._id) {
                        scene.text = JSON.stringify(action.payload);
                        updatedScene.text = JSON.stringify(action.payload);
                    }
                }
            }
        }
        return {
            ...state,
            openProject: updatedProject,
            openProjectScene: updatedScene,
            isProjectDirty: true
        }
    },
    [ActionTypes.OPEN_PROGRESS_PANEL]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'Progress'
        };
    },
    [ActionTypes.SET_WORD_COUNT_GOAL]: (state,action) => {
        let newWordCountProject = {...state.openProject};
        newWordCountProject.wordGoal = action.value;
        return {
            ...state,
            openProject: newWordCountProject,
            isProjectDirty: true
        };
    },
    [ActionTypes.SHOW_EXPORT_MODAL]: (state,action) => {
        return {
            ...state,
            exportModalProjectId: action.project,
            showExportModal: true
        };
    },
    [ActionTypes.HIDE_EXPORT_MODAL]: (state,action) => {
        return {
            ...state,
            showExportModal: false
        };
    },
    [ActionTypes.FETCH_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isFetchingProject: true
        };
    },
    [ActionTypes.FETCH_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            openProject: action.payload.data.project,
            isFetchingProject: false
        };
    },
    [ActionTypes.FETCH_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isFetchingProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_PROJECT_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameProjectModal: true,
            renameProjectModalValue: action.initialValue,
            renameProjectModalProjectId: action.projectId
        };
    },
    [ActionTypes.HIDE_PROJECT_RENAME_MODAL]: (state,action) => {
        return {
            ...state,
            showRenameProjectModal: false
        };
    },
    [ActionTypes.RENAME_PROJECT_SEND]: (state,action) => {
        return {
            ...state,
            isRenamingProject: true
        };
    },
    [ActionTypes.RENAME_PROJECT_SUCCESS]: (state,action) => {
        return {
            ...state,
            isRenamingProject: false
        };
    },
    [ActionTypes.RENAME_PROJECT_ERROR]: (state,action) => {
        return {
            ...state,
            isRenamingProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_IMPORT_MODAL]: (state,action) => {
        return {
            ...state,
            showImportModal: true
        };
    },
    [ActionTypes.CLOSE_IMPORT_MODAL]: (state,action) => {
        return {
            ...state,
            showImportModal: false
        };
    },
    [ActionTypes.IMPORT_DOC_SEND]: (state,action) => {
        return {
            ...state,
            isImporting: true
        };
    },
    [ActionTypes.IMPORT_DOC_SUCCESS]: (state,action) => {
        return {
            ...state,
            isImporting: false,
            showImportModal: false
        };
    },
    [ActionTypes.IMPORT_DOC_ERROR]: (state,action) => {
        return {
            ...state,
            isImporting: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.SHOW_MAIN_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showMainSideBar: true
        };
    },
    [ActionTypes.HIDE_MAIN_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showMainSideBar: false,
            showContextSideBar: false
        };
    },
    [ActionTypes.HIDE_MOBILE_MAIN_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showMainSideBar: false
        };
    },
    [ActionTypes.SHOW_CONTEXT_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true
        };
    },
    [ActionTypes.HIDE_CONTEXT_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: false
        };
    },
    [ActionTypes.OPEN_DICTIONARY_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Dictionary'
        };
    },
    [ActionTypes.OPEN_PROGRESS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'Progress'
        };
    },
    [ActionTypes.SHOW_PROJECT_VIEW_MODAL]: (state,action) => {
        return {
            ...state,
            showProjectViewModal: true
        };
    },
    [ActionTypes.HIDE_PROJECT_VIEW_MODAL]: (state,action) => {
        return {
            ...state,
            showProjectViewModal: false
        };
    },
    [ActionTypes.UPDATE_LOCAL_PROJECT_VIEW]: (state,action) => {
        let viewOpenProject = {...state.openProject};
        viewOpenProject.view = action.view;
        let showContext = false;
        let openContext = '';
        if(action.view === 'Writing') {
            showContext = true;
            openContext = 'Chapters';
        }
        return {
            ...state,
            openProject: viewOpenProject,
            showProjectViewModal: false,
            showContextSideBar: showContext,
            openContextPanel: openContext,            
            showIntroModal: false
        };
    },
    [ActionTypes.OPEN_REVISION]: (state,action) => {
        return {
            ...state,
            openProjectPanel: 'Revision'
        };
    },
    [ActionTypes.READ_BOOK]: (state,action) => {
        return {
            ...state,
            isOpeningProject:true,
            openProjectPrevious: action.previousProjectId
        };
    },
    [ActionTypes.READ_BOOK_SUCCESS]: (state,action) => {
        let readChapter = {};

        for(let i=0;i<action.payload.chapters.length;i++) {
            let chapter = action.payload.chapters[i];
            if(chapter) {
                readChapter = chapter;
                break;
            }
        }
        
        return {
            ...state,
            isOpeningProject:false,
            openProjectPanel: 'Read',
            openProject: action.payload,
            openProjectChapter: readChapter,
        };
    },
    [ActionTypes.READ_BOOK_ERROR]: (state,action) => {
        return {
            ...state,
            isOpeningProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.OPEN_PROJECT_DIFF]: (state,action) => {
        return {
            ...state,
            isOpeningDiffs: true,
            openDiffError: false,
            openDiffErrorMessage: ''
        };
    },
    [ActionTypes.OPEN_PROJECT_DIFF_SUCCESS]: (state,action) => {
        let newText = convertToPlainText(action.payload.data.project);
        let oldText = convertToPlainText(state.openProject);
        return {
            ...state,
            openProjectPanel: 'Diff',
            oldDiffText: oldText,
            newDiffText: newText,                    
            isOpeningDiffs: false,
            showError: false,
            errorMessage: ''
        };
    },
    [ActionTypes.OPEN_PROJECT_DIFF_ERROR]: (state,action) => {
        return {
            ...state,
            isOpeningDiffs: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.HIDE_INTRO_MODAL]:  (state,action) => {
        return {
            ...state,
            showIntroModal: false
        };
    },
    [ActionTypes.EDIT_BOOK]: (state,action) => {
        return {
            ...state,
            isOpeningProject:true,
            openProjectPrevious: action.previousProjectId
        };
    },
    [ActionTypes.EDIT_BOOK_SUCCESS]: (state,action) => {
        let editChapter = {};

        for(let i=0;i<action.payload.chapters.length;i++) {
            let chapter = action.payload.chapters[i];
            if(chapter) {
                editChapter = chapter;
                break;
            }
        }
        
        return {
            ...state,
            isOpeningProject:false,
            openProjectPanel: 'Edit',
            openProject: action.payload,
            openProjectChapter: editChapter,
        };
    },
    [ActionTypes.EDIT_BOOK_ERROR]: (state,action) => {
        return {
            ...state,
            isOpeningProject: false,
            showError: true,
            errorMessage: action.payload
        };
    },
    [ActionTypes.EXCEEDED_WORDCOUNT_ERROR]: (state,action) => {
        return {
            ...state,
            showWordCountExceededModal: true,
            isUpdatingProject: false
        };
    },
    [ActionTypes.DISMISS_WORDCOUNT_ERROR]: (state,action) => {
        return {
            ...state,
            showWordCountExceededModal: false,
            isUpdatingProject: false
        };
    },
    [ActionTypes.TOGGLE_GRAMMAR_CHECK]: (state,action) => {
        return {
            ...state,
            checkGrammar: !state.checkGrammar
        };
    },
    [ActionTypes.SHOW_PROJECT_VIEW_DEFAULT]: (state,action) => {
        return {
            
        };
    },
    [ActionTypes.SET_LINE_SPACING]: (state,action) => {
        let newOpenProject = {...state.openProject, spacing: action.spacing};
        return {
            ...state,
            openProject: newOpenProject
        }
    },
    [ActionTypes.OPEN_EDITOR_SETTINGS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'EditorSettings'
        }
    },
    [ActionTypes.OPEN_BRAINSTORM_SETTINGS_SIDEBAR]: (state,action) => {
        return {
            ...state,
            showContextSideBar: true,
            openContextPanel: 'BrainstormSettings'
        }
    },
    [ActionTypes.TOGGLE_AUTO_INDENT]: (state,action) => {
        const newCount = state.keyCount + 1;
        let newOpenProject = {...state.openProject, autoIndent: !state.openProject.autoIndent};
        return {
            ...state,
            openProject: newOpenProject,
            keyCount: newCount
        }
    },
    [ActionTypes.SET_EDITOR_PALLETE]: (state,action) => {
        let newOpenProject = {...state.openProject, backgroundColor: action.backgroundColor, textColor: action.textColor};
        return {
            ...state,
            openProject: newOpenProject
        }
    },
    [ActionTypes.EXPORT_EPUB_SEND]: (state,action) => {
        return {
            ...state,
            showError: false,
            errorMessage: ''
        };
    },
    [ActionTypes.EXPORT_EPUB_SUCCESS]: (state,action) => {
        return {
            ...state
        };
    },
    [ActionTypes.EXPORT_EPUB_ERROR]: (state,action) => {
        return {
            ...state,
            showError: true,
            errorMessage: action.payload
        };  
    },
    [ActionTypes.EXPORT_PDF_SEND]: (state,action) => {
        return {
            ...state,
            showError: false,
            errorMessage: ''
        };
    },
    [ActionTypes.EXPORT_PDF_SUCCESS]: (state,action) => {
        return {
            ...state
        };
    },
    [ActionTypes.EXPORT_PDF_ERROR]: (state,action) => {
        return {
            ...state,
            showError: true,
            errorMessage: action.payload
        };  
    },
    [ActionTypes.CREATE_SEQUEL_SEND]: (state,action) => {
        return {
            ...state,
            isCreatingProject: true,
            createProjectError: false,
            createProjectErrorMessage: ''
        };
    },
    [ActionTypes.CREATE_SEQUEL_SUCCESS]: (state,action) => {
        return {
            ...state,
            isCreatingProject: false,
            showCreateProjectModal: false
        };
    },
    [ActionTypes.CREATE_SEQUEL_ERROR]: (state,action) => {
        return {
            ...state,
            isCreatingProject: false,
            showError: true,
            errorMessage: action.payload
        };  
    },
}
};
