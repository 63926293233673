export const DISMISS_PROJECT_ERROR = 'DISMISS_PROJECT_ERROR';
export const SHOW_CREATE_PROJECT_MODAL = 'SHOW_CREATE_PROJECT_MODAL';
export const HIDE_CREATE_PROJECT_MODAL = 'HIDE_CREATE_PROJECT_MODAL';
export const OPEN_PROJECT = 'OPEN_PROJECT';
export const OPEN_PROJECT_SUCCESS = 'OPEN_PROJECT_SUCCESS';
export const OPEN_PROJECT_ERROR = 'OPEN_PROJECT_ERROR';
export const CLOSE_PROJECT = 'CLOSE_PROJECT';
export const READ_PROJECT_SEND = 'READ_PROJECT_SEND';
export const READ_PROJECT_SUCCESS = 'READ_PROJECT_SUCCESS';
export const READ_PROJECT_ERROR = 'READ_PROJECT_ERROR';
export const CREATE_PROJECT_SEND = 'CREATE_PROJECT_SEND';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR';
export const FETCH_PROJECTS_SEND = 'FETCH_PROJECTS_SEND';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'FETCH_PROJECTS_ERROR';
export const DELETE_PROJECT_SEND = 'DELETE_PROJECT_SEND';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR';
export const UPDATE_PROJECT_SEND = 'UPDATE_PROJECT_SEND';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR';
export const AUTO_SAVE_PROJECT_SEND = 'AUTO_SAVE_PROJECT_SEND';
export const AUTO_SAVE_PROJECT_SUCCESS = 'AUTO_SAVE_PROJECT_SUCCESS';
export const AUTO_SAVE_PROJECT_ERROR = 'AUTO_SAVE_PROJECT_ERROR';
export const OFFLINE_MODE = 'OFFLINE_MODE';
export const UPDATE_LOCAL_SCENE = 'UPDATE_LOCAL_SCENE';
export const SHOW_CREATE_CHAPTER_MODAL = 'SHOW_CREATE_CHAPTER_MODAL';
export const HIDE_CREATE_CHAPTER_MODAL = 'HIDE_CREATE_CHAPTER_MODAL';
export const ADD_CHAPTER = 'ADD_CHAPTER';
export const CREATE_CHAPTER_SEND = 'CREATE_CHAPTER_SEND';
export const CREATE_CHAPTER_SUCCESS = 'CREATE_CHAPTER_SUCCESS';
export const CREATE_CHAPTER_ERROR = 'CREATE_CHAPTER_ERROR';
export const OPEN_CHAPTER = 'OPEN_CHAPTER';
export const UPDATE_LOCAL_CHAPTER = 'UPDATE_LOCAL_CHAPTER';
export const OPEN_CHAPTERS_PANEL = 'OPEN_CHAPTERS_PANEL';
export const ADD_SCENE = 'ADD_SCENE';
export const CREATE_SCENE_SEND = 'CREATE_SCENE_SEND';
export const CREATE_SCENE_SUCCESS = 'CREATE_SCENE_SUCCESS';
export const CREATE_SCENE_ERROR = 'CREATE_SCENE_ERROR';
export const OPEN_SCENE = 'OPEN_SCENE';
export const SHOW_SCENE_RENAME_MODAL = 'SHOW_SCENE_RENAME_MODAL';
export const HIDE_SCENE_RENAME_MODAL = 'HIDE_SCENE_RENAME_MODAL';
export const RENAME_LOCAL_SCENE = 'RENAME_LOCAL_SCENE';
export const SHOW_CHAPTER_RENAME_MODAL = 'SHOW_CHAPTER_RENAME_MODAL';
export const HIDE_CHAPTER_RENAME_MODAL = 'HIDE_CHAPTER_RENAME_MODAL';
export const RENAME_LOCAL_CHAPTER = 'RENAME_LOCAL_CHAPTER';
export const MOVE_SCENE_UP = 'MOVE_SCENE_UP';
export const MOVE_SCENE_DOWN = 'MOVE_SCENE_DOWN';
export const MOVE_CHAPTER_UP = 'MOVE_CHAPTER_UP';
export const MOVE_CHAPTER_DOWN = 'MOVE_CHAPTER_DOWN';
export const MOVE_CHAPTER = 'MOVE_CHAPTER';
export const SHOW_RECHAPTER_SCENE_MODAL = 'SHOW_RECHAPTER_SCENE_MODAL';
export const HIDE_RECHAPTER_SCENE_MODAL = 'HIDE_RECHAPTER_SCENE_MODAL';
export const RECHAPTER_LOCAL_SCENE = 'RECHAPTER_LOCAL_SCENE';
export const SHOW_DELETE_SCENE_MODAL = 'SHOW_DELETE_SCENE_MODAL';
export const HIDE_DELETE_SCENE_MODAL = 'HIDE_DELETE_SCENE_MODAL';
export const REMOVE_SCENE = 'REMOVE_SCENE';
export const DELETE_SCENE_SEND = 'DELETE_SCENE_SEND';
export const DELETE_SCENE_SUCCESS = 'DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_ERROR = 'DELETE_SCENE_ERROR';
export const SHOW_DELETE_CHAPTER_MODAL = 'SHOW_DELETE_CHAPTER_MODAL';
export const HIDE_DELETE_CHAPTER_MODAL = 'HIDE_DELETE_CHAPTER_MODAL';
export const REMOVE_CHAPTER = 'REMOVE_CHAPTER';
export const DELETE_CHAPTER_SEND = 'DELETE_CHAPTER_SEND';
export const DELETE_CHAPTER_SUCCESS = 'DELETE_CHAPTER_SUCCESS';
export const DELETE_CHAPTER_ERROR = 'DELETE_CHAPTER_ERROR';
export const ADD_CHARACTER = 'ADD_CHARACTER';
export const CREATE_CHARACTER_SEND = 'CREATE_CHARACTER_SEND';
export const CREATE_CHARACTER_SUCCESS = 'CREATE_CHARACTER_SUCCESS';
export const CREATE_CHARACTER_ERROR = 'CREATE_CHARACTER_ERROR';
export const SHOW_DELETE_RELATIONSHIP_MODAL = 'SHOW_DELETE_RELATIONSHIP_MODAL';
export const HIDE_DELETE_RELATIONSHIP_MODAL = 'HIDE_DELETE_RELATIONSHIP_MODAL';
export const REMOVE_RELATIONSHIP = 'REMOVE_RELATIONSHIP';
export const DELETE_RELATIONSHIP_SEND = 'DELETE_RELATIONSHIP_SEND';
export const DELETE_RELATIONSHIP_SUCCESS = 'DELETE_RELATIONSHIP_SUCCESS';
export const DELETE_RELATIONSHIP_ERROR = 'DELETE_RELATIONSHIP_ERROR';
export const OPEN_RELATIONSHIP = 'OPEN_RELATIONSHIP';
export const CLOSE_RELATIONSHIP = 'CLOSE_RELATIONSHIP';
export const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP';
export const SHOW_NEW_RELATIONSHIP_MODAL = 'SHOW_NEW_RELATIONSHIP_MODAL';
export const HIDE_NEW_RELATIONSHIP_MODAL = 'HIDE_NEW_RELATIONSHIP_MODAL';
export const CREATE_RELATIONSHIP_SEND = 'CREATE_RELATIONSHIP_SEND';
export const CREATE_RELATIONSHIP_SUCCESS = 'CREATE_RELATIONSHIP_SUCCESS';
export const CREATE_RELATIONSHIP_ERROR = 'CREATE_RELATIONSHIP_ERROR';
export const OPEN_CHARACTERS_PANEL = 'OPEN_CHARACTERS_PANEL';
export const OPEN_CHARACTER = 'OPEN_CHARACTER';
export const CLOSE_CHARACTER = 'CLOSE_CHARACTER';
export const UPDATE_LOCAL_CHARACTER = 'UPDATE_LOCAL_CHARACTER';
export const UPDATE_LOCAL_RELATIONSHIP = 'UPDATE_LOCAL_RELATIONSHIP';
export const SHOW_DELETE_CHARACTER_MODAL = 'SHOW_DELETE_CHARACTER_MODAL';
export const HIDE_DELETE_CHARACTER_MODAL = 'HIDE_DELETE_CHARACTER_MODAL';
export const REMOVE_CHARACTER = 'REMOVE_CHARACTER';
export const DELETE_CHARACTER_SEND = 'DELETE_CHARACTER_SEND';
export const DELETE_CHARACTER_SUCCESS = 'DELETE_CHARACTER_SUCCESS';
export const DELETE_CHARACTER_ERROR = 'DELETE_CHARACTER_ERROR';
export const ADD_LOCATION = 'ADD_LOCATION';
export const CREATE_LOCATION_SEND = 'CREATE_LOCATION_SEND';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';
export const SHOW_DELETE_LOCATION_MODAL = 'SHOW_DELETE_LOCATION_MODAL';
export const HIDE_DELETE_LOCATION_MODAL = 'HIDE_DELETE_LOCATION_MODAL';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';
export const DELETE_LOCATION_SEND = 'DELETE_LOCATION_SEND';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const OPEN_LOCATIONS_PANEL = 'OPEN_LOCATIONS_PANEL';
export const OPEN_LOCATION = 'OPEN_LOCATION';
export const CLOSE_LOCATION = 'CLOSE_LOCATION';
export const UPDATE_LOCAL_LOCATION = 'UPDATE_LOCAL_LOCATION';
export const SHOW_CREATE_EVENT_MODAL = 'SHOW_CREATE_EVENT_MODAL';
export const HIDE_CREATE_EVENT_MODAL = 'HIDE_CREATE_EVENT_MODAL';
export const ADD_EVENT = 'ADD_EVENT';
export const CREATE_EVENT_SEND = 'CREATE_EVENT_SEND';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_ERROR = 'CREATE_EVENT_ERROR';
export const SHOW_DELETE_EVENT_MODAL = 'SHOW_DELETE_EVENT_MODAL';
export const HIDE_DELETE_EVENT_MODAL = 'HIDE_DELETE_EVENT_MODAL';
export const REMOVE_EVENT = 'REMOVE_EVENT';
export const DELETE_EVENT_SEND = 'DELETE_EVENT_SEND';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_ERROR = 'DELETE_EVENT_ERROR';
export const OPEN_EVENT = 'OPEN_EVENT';
export const CLOSE_EVENT = 'CLOSE_EVENT';
export const UPDATE_LOCAL_EVENT = 'UPDATE_LOCAL_EVENT';
export const OPEN_TIMELINE = 'OPEN_TIMELINE';
export const MOVE_EVENT_EARLIER = 'MOVE_EVENT_EARLIER';
export const MOVE_EVENT_LATER = 'MOVE_EVENT_LATER';
export const OPEN_PROGRESS_PANEL = 'OPEN_PROGRESS_PANEL';
export const SET_WORD_COUNT_GOAL = 'SET_WORD_COUNT_GOAL';
export const SHOW_EXPORT_MODAL = 'SHOW_EXPORT_MODAL';
export const HIDE_EXPORT_MODAL = 'HIDE_EXPORT_MODAL';
export const FETCH_PROJECT_SEND = 'FETCH_PROJECT_SEND';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS';
export const FETCH_PROJECT_ERROR = 'FETCH_PROJECT_ERROR';
export const SHOW_PROJECT_RENAME_MODAL = 'SHOW_PROJECT_RENAME_MODAL';
export const HIDE_PROJECT_RENAME_MODAL = 'HIDE_PROJECT_RENAME_MODAL';
export const RENAME_PROJECT_SEND = 'RENAME_PROJECT_SEND';
export const RENAME_PROJECT_SUCCESS = 'RENAME_PROJECT_SUCESS';
export const RENAME_PROJECT_ERROR = 'RENAME_PROJECT_ERROR';
export const OPEN_RESEARCH_PANEL = 'OPEN_RESEARCH_PANEL';
export const ADD_RESEARCH = 'ADD_RESEARCH';
export const OPEN_RESEARCH_NOTES = 'OPEN_RESEARCH_NOTES';
export const UPDATE_LOCAL_RESEARCH_NOTES = 'UPDATE_LOCAL_RESEARCH_NOTES';
export const SHOW_DELETE_RESEARCH_MODAL = 'SHOW_DELETE_RESEARCH_MODAL';
export const HIDE_DELETE_RESEARCH_MODAL = 'HIDE_DELETE_RESEARCH_MODAL';
export const REMOVE_RESEARCH = 'REMOVE_RESEARCH';
export const SHOW_RESEARCH_RENAME_MODAL = 'SHOW_RESEARCH_RENAME_MODAL';
export const HIDE_RESEARCH_RENAME_MODAL = 'HIDE_RESEARCH_RENAME_MODAL';
export const RENAME_LOCAL_RESEARCH = 'RENAME_LOCAL_RESEARCH';
export const SHOW_NEW_RESEARCH_LINK_MODAL = 'SHOW_NEW_RESEARCH_LINK_MODAL';
export const HIDE_NEW_RESEARCH_LINK_MODAL = 'HIDE_NEW_RESEARCH_LINK_MODAL';
export const OPEN_CHARACTER_BREAKOUT = 'OPEN_CHARACTER_BREAKOUT';
export const CLOSE_CHARACTER_BREAKOUT = 'CLOSE_CHARACTER_BREAKOUT';
export const OPEN_RESEARCH_NOTES_BREAKOUT = 'OPEN_RESEARCH_NOTES_BREAKOUT';
export const CLOSE_RESEARCH_NOTES_BREAKOUT = 'CLOSE_RESEARCH_NOTES_BREAKOUT';
export const OPEN_LOCATION_BREAKOUT = 'OPEN_LOCATION_BREAKOUT';
export const CLOSE_LOCATION_BREAKOUT = 'CLOSE_LOCATION_BREAKOUT';
export const OPEN_IMPORT_MODAL = 'OPEN_IMPORT_MODAL';
export const CLOSE_IMPORT_MODAL = 'CLOSE_IMPORT_MODAL';
export const IMPORT_DOC_SEND = 'IMPORT_DOC_SEND';
export const IMPORT_DOC_SUCCESS = 'IMPORT_DOC_SUCCESS';
export const IMPORT_DOC_ERROR = 'IMPORT_DOC_ERROR';
export const SHOW_MAIN_SIDEBAR = 'SHOW_MAIN_SIDEBAR';
export const HIDE_MAIN_SIDEBAR = 'HIDE_MAIN_SIDEBAR';
export const HIDE_MOBILE_MAIN_SIDEBAR = 'HIDE_MOBILE_MAIN_SIDEBAR';
export const SHOW_CONTEXT_SIDEBAR = 'SHOW_CONTEXT_SIDEBAR';
export const HIDE_CONTEXT_SIDEBAR = 'HIDE_CONTEXT_SIDEBAR';
export const OPEN_CHAPTERS_SIDEBAR = 'OPEN_CHAPTERS_SIDEBAR';
export const OPEN_READ_CHAPTERS_SIDEBAR = 'OPEN_READ_CHAPTERS_SIDEBAR';
export const OPEN_EDIT_CHAPTERS_SIDEBAR = 'OPEN_EDIT_CHAPTERS_SIDEBAR';
export const OPEN_DICTIONARY_SIDEBAR = 'OPEN_DICTIONARY_SIDEBAR';
export const OPEN_PROGRESS_SIDEBAR = 'OPEN_PROGRESS_SIDEBAR';
export const SHOW_PROJECT_VIEW_MODAL = 'SHOW_PROJECT_VIEW_MODAL';
export const HIDE_PROJECT_VIEW_MODAL = 'HIDE_PROJECT_VIEW_MODAL';
export const UPDATE_LOCAL_PROJECT_VIEW = 'UPDATE_LOCAL_PROJECT_VIEW';
export const OPEN_IDEA_BOARD = 'OPEN_IDEA_BOARD';
export const OPEN_RESEARCH_NOTE_MODAL = 'OPEN_RESEARCH_NOTE_MODAL';
export const CLOSE_RESEARCH_NOTE_MODAL = 'CLOSE_RESEARCH_NOTE_MODAL';
export const UPDATE_LOCAL_RESEARCH = 'UPDATE_LOCAL_RESEARCH';
export const OPEN_CHARACTERS_SIDEBAR = 'OPEN_CHARACTERS_SIDEBAR';
export const OPEN_LOCATIONS_SIDEBAR = 'OPEN_LOCATIONS_SIDEBAR';
export const OPEN_OUTLINE = 'OPEN_OUTLINE';
export const ADD_PLOTPOINT = 'ADD_PLOTPOINT';
export const REMOVE_PLOTPOINT = 'REMOVE_PLOTPOINT';
export const APPLY_TEMPLATE = 'APPLY_TEMPLATE';
export const SHOW_EDIT_PLOTPOINT_MODAL = 'SHOW_EDIT_PLOTPOINT_MODAL';
export const HIDE_EDIT_PLOTPOINT_MODAL = 'HIDE_EDIT_PLOTPOINT_MODAL';
export const UPDATE_LOCAL_PLOTPOINT = 'UPDATE_LOCAL_PLOTPOINT';
export const MOVE_PLOTPOINT = 'MOVE_PLOTPOINT';
export const SHOW_DELETE_PLOTPOINT_MODAL = 'SHOW_DELETE_PLOTPOINT_MODAL';
export const HIDE_DELETE_PLOTPOINT_MODAL = 'HIDE_DELETE_PLOTPOINT_MODAL';
export const ADD_ACT = 'ADD_ACT';
export const RESET_OUTLINE = 'RESET_OUTLINE';
export const OPEN_RESEARCH_SIDEBAR = 'OPEN_RESEARCH_SIDEBAR';
export const OPEN_OUTLINE_SIDEBAR = 'OPEN_OUTLINE_SIDEBAR';
export const OPEN_REVISION = 'OPEN_REVISION';
export const READ_BOOK = 'READ_BOOK';
export const READ_BOOK_SUCCESS = 'READ_BOOK_SUCCESS';
export const READ_BOOK_ERROR = 'READ_BOOK_ERROR';
export const OPEN_PROJECT_DIFF = 'OPEN_PROJECT_DIFF';
export const OPEN_PROJECT_DIFF_SUCCESS = 'OPEN_PROJECT_DIFF_SUCCESS';
export const OPEN_PROJECT_DIFF_ERROR = 'OPEN_PROJECT_DIFF_ERROR';
export const HIDE_INTRO_MODAL = 'HIDE_INTRO_MODAL';
export const EDIT_BOOK = 'EDIT_BOOK';
export const EDIT_BOOK_SUCCESS = 'EDIT_BOOK_SUCCESS';
export const EDIT_BOOK_ERROR = 'EDIT_BOOK_ERROR';
export const EXCEEDED_WORDCOUNT_ERROR = 'EXCEEDED_WORDCOUNT_ERROR';
export const DISMISS_WORDCOUNT_ERROR = 'DISMISS_WORDCOUNT_ERROR';
export const TOGGLE_GRAMMAR_CHECK = 'TOGGLE_GRAMMAR_CHECK';
export const SHOW_PROJECT_VIEW_DEFAULT = 'SHOW_PROJECT_VIEW_DEFAULT';
export const SET_LINE_SPACING = 'SET_LINE_SPACING';
export const OPEN_EDITOR_SETTINGS_SIDEBAR = 'OPEN_EDITOR_SETTINGS_SIDEBAR';
export const OPEN_BRAINSTORM_SETTINGS_SIDEBAR = 'OPEN_BRAINSTORM_SETTINGS_SIDEBAR';
export const TOGGLE_AUTO_INDENT = 'TOGGLE_AUTO_INDENT';
export const SET_EDITOR_PALLETE = 'SET_EDITOR_PALLETE';
export const SET_RESEARCH_PALLETE = 'SET_RESEARCH_PALLETE';
export const MOVE_RESEARCH = 'MOVE_RESEARCH';
export const RESIZE_RESEARCH = 'RESIZE_RESEARCH';
export const EXPORT_EPUB_SEND = 'EXPORT_EPUB_SEND';
export const EXPORT_EPUB_SUCCESS = 'EXPORT_EPUB_SUCCESS';
export const EXPORT_EPUB_ERROR = 'EXPORT_EPUB_ERROR';
export const EXPORT_PDF_SEND = 'EXPORT_PDF_SEND';
export const EXPORT_PDF_SUCCESS = 'EXPORT_PDF_SUCCESS';
export const EXPORT_PDF_ERROR = 'EXPORT_PDF_ERROR';
export const CREATE_SEQUEL_SEND = 'CREATE_SEQUEL_SEND';
export const CREATE_SEQUEL_SUCCESS = 'CREATE_SEQUEL_SUCCESS';
export const CREATE_SEQUEL_ERROR = 'CREATE_SEQUEL_ERROR';