import React, { useCallback,useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd'
import {useDispatch,useSelector} from 'react-redux';
import {Icon,Button,Grid,Popup,Header,Image,Dropdown,Menu} from 'semantic-ui-react';
import DeleteModal from '../../../components/DeleteModal';
import Showable from '../../../components/Showable';
import {
    addCharacter,showDeleteCharacterModal,openCharacter,
    removeCharacter,hideDeleteCharacterModal,hideContextSideBar
} from '../../../project/actions';

const CharacterCard = ({character,index,readOnly}) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);

    const editButton = (<Button icon onClick={() => dispatch(openCharacter(character._id))}>
        <Icon name='edit'/></Button>);
    const deleteButton = (<Button icon onClick={() => dispatch(showDeleteCharacterModal(openProject._id,character._id))}>
        <Icon name='delete'/></Button>);
    const avatar = character.image ? character.image : '/img/ceta-persona-default.png';

    return (
        <div onClick={() => dispatch(openCharacter(character._id))} style={{ cursor:'pointer'}}>
            <div style={{ width: '100px',display:'inline-block'}}>
                <img src={avatar} onClick={() => dispatch(openCharacter(character._id))} style={{cursor: 'pointer',objectFit: 'contain',width:'100%',height:'100%'}}/>
            </div>
            <div style={{ width: '190px',display:'inline-block',verticalAlign: 'top', paddingLeft: '0.5em'}}>
                <div style={{ float: 'right'}}>
                    <Popup position='left center' trigger={<Icon name='delete' onClick={() => dispatch(showDeleteCharacterModal(openProject._id,character._id))}/>}>
                        Delete
                    </Popup>
                </div>
                <div style={{color:'white',paddingTop:'1em'}}><h3>{character.name}</h3></div>
                <div style={{color: '#cdcdcd'}}>{character.role}</div>
            </div>
        </div>
    );
}

const CharactersContext = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const projects = useSelector(state => state.projects);

    return (
        <div>
            <DeleteModal 
                characterId={projects.deleteCharacterModalCharacterId}
                projectId={projects.deleteCharacterModalProjectId}
                show={projects.showDeleteCharacterModal}                     
                onCancel={() => dispatch(hideDeleteCharacterModal())} 
                onConfirm={(data) => {
                    dispatch(removeCharacter(data.projectId,data.characterId));
                    dispatch(hideDeleteCharacterModal());
                }}
            />

            <div className='ceta-chapter-context'>
                <div style={{ width: '100%'}}>
                    <Menu inverted borderless>
                        <Menu.Item>
                            <Header color='teal'>Characters</Header>
                        </Menu.Item>
                        <Menu.Item>
                            <Icon color='teal' name='plus circle' size='large'
                                onClick={() => dispatch(addCharacter(openProject._id))}
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                        <Menu.Item position='right'>
                            <Icon name='delete' size='large'
                                onClick={() => dispatch(hideContextSideBar())}                        
                                style={{ float:'right',cursor:'pointer'}}/>
                        </Menu.Item>
                    </Menu>
                </div>
            </div>
            <div style={{ height:'calc(100vh - 70px)', overflowY: 'auto',overflowX: 'hidden'}}>
                {openProject.characters.map( (character,index) => <CharacterCard key={character._id} character={character} index={index}/>)}
            </div>
        </div>
    );
}

export default CharactersContext;