import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {utcToZonedTime,format} from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import {Table,Button,Icon,Dimmer,Loader,Form,Checkbox,Modal,Input,Label,Dropdown} from 'semantic-ui-react';
import Toolbar from '../header/Toolbar';
import {searchUsers,adminUpdateUser} from '../../admin/actions';
import {ROLES, hasRole} from '../../services/roles';

const formatRoles = (roles) => {
    let value = '';
    for(let i=0;i<roles.length;i++) {
        value = value + roles[i] + ' ';
    }
    return value;
}

const formatDate = (date,timeZone) => {
    if(!date) {
        return '';
    }
    const zonedDate = utcToZonedTime(date, timeZone);
    const pattern = 'MM/dd/yyyy h:mm:ss a';
    const formattedDate = format(zonedDate, pattern, { timeZone });        
    return formattedDate;
}

const getDateEpoch = (days) => {
    if(!days) {
        return '';
    }
    return addDays(new Date(),-days).getTime();
}

const UsersPage = (props) => {
    const dispatch = useDispatch();
    const timeZone = useSelector(state => state.user.timezone);
    const isSearchingUsers = useSelector(state => state.admin.isSearchingUsers);
    const searchUsersResults = useSelector(state => state.admin.searchUsersResults);
    const [searchEmail,setSearchEmail] = useState('');
    const [searchRole,setSearchRole] = useState('');
    const [searchLastLogin,setSearchLastLogin] = useState('');
    const [showModal,setShowModal] = useState(false);
    const [editedUser,setEditedUser] = useState({});

    const roleBoxes = [];
    for(let role in ROLES) {
        roleBoxes.push(
        <Form.Checkbox  key={role} label={<label style={{color:'white'}}>{role}</label>} checked={hasRole(editedUser,role)} onChange={() => {
            if(hasRole(editedUser,role)) {                
                let newRoles = editedUser.roles.slice();
                newRoles.splice(editedUser.roles.indexOf(role),1);
                setEditedUser({...editedUser,roles: newRoles});
            }
            else {
                let newRoles = editedUser.roles.slice();
                newRoles.push(role);
                setEditedUser({...editedUser,roles: newRoles});
            }
        }}/>
        )}
    
    const results = searchUsersResults.map( user => {
        const formattedCreated = formatDate(user.createDate,timeZone);
        const formattedLastLogin = formatDate(user.lastLogin,timeZone);

        return (
            <Table.Row key={user._id}>
                <Table.Cell>
                    {user.email}
                </Table.Cell>
                <Table.Cell>
                    {user.name}
                </Table.Cell>
                <Table.Cell>
                    {user.disabled?'DISABLED':'Enabled'}
                </Table.Cell>
                <Table.Cell>
                    {user.verified?'Verified':'Unverified'}
                </Table.Cell>
                <Table.Cell>
                    {formatRoles(user.roles)}
                </Table.Cell>
                <Table.Cell>
                    {user.referralSource}
                </Table.Cell>
                <Table.Cell>
                    {formattedCreated}
                </Table.Cell>
                <Table.Cell>
                    {formattedLastLogin}
                </Table.Cell>
                <Table.Cell>
                    <Button size='mini' compact={true} icon onClick={() => {
                        setEditedUser({...user});
                        setShowModal(true);
                    }}>
                        <Icon name='edit'/>
                    </Button>
                </Table.Cell>
            </Table.Row>

        )
    });

    const roleTypes = [
        { key: 'None', text: 'None', value: ''},
        { key: 'Basic', text: 'Basic', value: 'Basic'},
        { key: 'Beta', text: 'Beta', value: 'Beta'},
        { key: 'Demo', text: 'Demo', value: 'Demo'},
        { key: 'Premium', text: 'Premium', value: 'Premium'},
        { key: 'Admin', text: 'Admin', value: 'Admin'}
    ];
    
    const lastLoginOptions = [
        { key: '', text: '', value: ''},
        { key: 1, text: 'Last 24hrs', value: 1},
        { key: 7, text: 'Last 7 days', value: 7},
        { key: 30, text: 'Last 30 days', value: 30}
    ];
    return (
        <div>
        <Toolbar/>
        <div style={{margin:'1em'}}>
            <Dimmer active={isSearchingUsers}>
                <Loader>Loading</Loader>
            </Dimmer>
            <Modal open={showModal}>
                <Modal.Content style={{ backgroundColor: '#363636',color: 'white'}}>
                    <Form>
                        <Form.Field>
                            <Form.Checkbox label={<label style={{color:'white'}}>Disabled</label>}
                                checked={editedUser.disabled} 
                                onChange={() => setEditedUser({...editedUser, disabled: !editedUser.disabled})} 
                                />
                        </Form.Field>
                        <Form.Field>
                            <Input label='Email' placeholder='Email' value={editedUser.email} 
                                onChange={(ev) => setEditedUser({...editedUser, email: ev.target.value})} 
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input label='Username' placeholder='Username' value={editedUser.name} 
                                onChange={(ev) => setEditedUser({...editedUser, name: ev.target.value})} 
                            />
                        </Form.Field>
                        <Form.Field>
                            <Label size='large' >Roles</Label> {roleBoxes}
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions style={{ backgroundColor: '#363636',color: 'white'}}>
                <Button color='teal' 
                        disabled={(!editedUser.email || !editedUser.name || (editedUser.roles.length === 0))} 
                        onClick={() => {
                            dispatch(adminUpdateUser({user: editedUser}));
                            setShowModal(false);
                        }}>Update</Button>
                    <Button onClick={() => setShowModal(false)}>Cancel</Button>

                </Modal.Actions>
            </Modal>

                    <Input label='Email' placeholder='Email' value={searchEmail} onChange={(ev) => setSearchEmail(ev.target.value)} />
                    &nbsp;&nbsp;
                    <Dropdown options={roleTypes} placeholder='Select Role' selection
                        onChange={ (ev,data) => setSearchRole(data.value) }
                    />
                    &nbsp;&nbsp;
                    <Dropdown options={lastLoginOptions} placeholder='Last Login' selection
                        onChange={ (ev,data) => {
                            console.log(data.value)
                            setSearchLastLogin(data.value); 
                        }}
                    />
                    &nbsp;&nbsp;
                    <Button color='teal' onClick={() => dispatch(searchUsers(searchEmail,searchRole,getDateEpoch(searchLastLogin)))}>Search</Button>
            
            <p/>
            <div style={{ color: 'white'}}>
                Results: {searchUsersResults.length}
            </div>
            <Table inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Username</Table.HeaderCell>
                        <Table.HeaderCell>Disabled</Table.HeaderCell>
                        <Table.HeaderCell>Verified</Table.HeaderCell>
                        <Table.HeaderCell>Roles</Table.HeaderCell>
                        <Table.HeaderCell>Source</Table.HeaderCell>
                        <Table.HeaderCell>Created</Table.HeaderCell>
                        <Table.HeaderCell>Last Login</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {results}
                </Table.Body>
            </Table>
        </div>
    </div>        
    );
};

export default UsersPage;