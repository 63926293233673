import React from 'react';
import {Modal,Header,Button,Icon} from 'semantic-ui-react';

const ConfirmModal = (props) => {
    return (
        <Modal open={props.show}  size='small'>
            <Header style={{backgroundColor:'#363636', color:'white'}} icon='warning circle' content={props.title} />
            <Modal.Content style={{backgroundColor:'#363636', color:'white'}}>
            <p>
                {props.message}
            </p>
            </Modal.Content>
            <Modal.Actions style={{backgroundColor:'#363636', color:'white'}}>
            <Button onClick={props.onCancel}>
                <Icon name='remove' /> No
            </Button>
            <Button color='teal' onClick={() => props.onConfirm(props)}>
                <Icon name='checkmark' /> Yes
            </Button>
            </Modal.Actions>
        </Modal>        
    );
};

export default ConfirmModal;