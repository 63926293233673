import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import {openIdeaBoard,openOutline,openChapter,openChaptersPanel} from '../../project/actions';

const ClosePanelWidget = (props) => {
    const dispatch = useDispatch();
    const projectView = useSelector(state => state.projects.openProject.view);
    const projects = useSelector(state => state.projects);
    return (
        <div style={{ float:'right',marinTop:'0.5em', marginRight: '0.5em',cursor:'pointer'}} onClick={() => {
            if(projectView === 'Brainstorm') {
                dispatch(openIdeaBoard());
            }
            else if (projectView === 'Outline'){
                dispatch(openOutline());
            }
            else if (projectView === 'Writing'){
                if('Book' === projects.openProject.projectType) {
                    if(projects.openProject.chapters[0]) {
                        dispatch(openChapter(projects.openProject.chapters[0]._id));
                    }
                    else {                        
                        dispatch(openChaptersPanel(projects.openProject._id));
                    }
                }
            }
        }}><Icon name='delete' size='large'/></div>
    );
}

export default ClosePanelWidget;