import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import RichTextEditor from '../editor/RichTextEditor';
import {updateLocalScene} from '../../project/actions';

const SceneEditPanel = (props) =>  {
    const dispatch = useDispatch();
    const projects = useSelector(state => state.projects);

    const sceneElement = (projects.openProjectScene.text) 
    ? JSON.parse(projects.openProjectScene.text) 
    : [{ children:[{text: '',fontFamily:'Arial',fontSize:'11pt'}]}];
    return (
            <RichTextEditor 
                key={projects.openProjectScene._id}
                content={sceneElement}
                onUpdate={ (value) => dispatch(updateLocalScene(value))}
                focus={true}
                footer={true}
                centered={true}
                ignoreSelectionUpdate={true}
                lastSaveDate={projects.lastSaveDate}
            />
    );
}

export default SceneEditPanel;