import React,{useState} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {Menu,Button,Icon,Popup,Segment,Dropdown} from 'semantic-ui-react';
import {
    addChapter,updateProject,
    openTimeline,openCharactersPanel,openLocationsPanel,
    openProgressPanel,openChapter,openChaptersPanel,openResearchPanel,toggleGrammarCheck
} from '../../../project/actions';
import {toggleDistractionFree} from '../../../user/actions';
import {openDictionaryBreakout,openThesaurusBreakout} from '../../../dictionary/actions';
import {getHotkey} from '../../editor/richTextTools';

const BookSideBar = (props) => {
    const dispatch = useDispatch();
    const openProject = useSelector(state => state.projects.openProject);
    const isProjectDirty = useSelector( state => state.projects.isProjectDirty);
    const isDistractionFree = useSelector( state => state.user.isDistractionFree);
    const openProjectSharedEdit = useSelector( state => state.projects.openProjectSharedEdit);
    const [active,setActive] = useState('chapter0');
    const uploadStyle = isProjectDirty ? {color:'teal'} : {};

    const chapterItems = openProject.chapters.map( (chapter,index) => (
        <Menu.Item 
            key={chapter._id}
            active={active === 'chapter'+index} 
            onClick={() => {
                setActive('chapter'+index);
                dispatch(openChapter(chapter._id));
            }}
        ><Icon name='book'/> Chapter {index+1}</Menu.Item>
    ));

    const ownerOptions = openProjectSharedEdit ? null : [(
        <Menu.Item 
        key='progress'
        active={active === 'progress'}
        onClick={() => {
            setActive('progress');
            dispatch(openProgressPanel(props.project._id));
            }}>
        Progress
    </Menu.Item>
    ),(
        <Menu.Item 
        key='research'
        active={active === 'research'}
        onClick={() => {
            setActive('research');
            dispatch(openResearchPanel(props.project._id));
            }}>
        Research
    </Menu.Item>
    ),(
    <Menu.Item 
        key='characters'
        active={active === 'characters'}
        onClick={() => {
            setActive('characters');
            dispatch(openCharactersPanel(props.project._id));
            }}>
        Characters
    </Menu.Item>
    ),(
    <Menu.Item 
        key='locations'
        active={active === 'locations'}
        onClick={() => {
            setActive('locations');
            dispatch(openLocationsPanel(props.project._id));
            }}>
        Locations
    </Menu.Item>
    ),(
    <Menu.Item 
        key='history'
        active={active === 'history'}
        onClick={() => {
            setActive('history');
            dispatch(openTimeline(props.project._id));
            }}>
        Timeline
    </Menu.Item>
    )];

    const ownerChapterOptions = openProjectSharedEdit ? null : [(
        <Menu.Item key='addChapter'
        onClick={() => dispatch(addChapter(props.project._id))}
        ><Icon name='plus circle'/> New Chapter</Menu.Item>
        ),(
        <Menu.Item key='manageChapters'
            active={active === 'manageChapters'}
            onClick={() => {
                setActive('manageChapters');
                dispatch(openChaptersPanel(props.project._id));
            }}
            ><Icon name='setting'/>Manage Chapters</Menu.Item>

        )];
    const saveTooltip = 'Save Project ('+getHotkey('S')+')';
    return (
        <div style={{
            height:'100%',
            display:'flex',
            flexDirection:'column'
        }}>
            <div style={{flexShrink: 0}}>
                <Segment inverted>
                    <Dropdown icon='bars' button className='icon' pointing='top left'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => dispatch(openDictionaryBreakout())}><Icon name='keyboard'/>Dictionary</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(openThesaurusBreakout())}><Icon name='keyboard outline'/>Thesaurus</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(toggleDistractionFree(!isDistractionFree))}><Icon name='expand'/>Distraction Free</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

            <Popup trigger={
                    <Button {...uploadStyle} icon='cloud upload' 
                        disabled={!isProjectDirty}
                        onMouseDown={ (ev) => ev.preventDefault()}
                        onClick={(ev) => {
                            ev.preventDefault();
                            dispatch(updateProject({project: openProject}));
                        }}
                    />
                } content={saveTooltip} />
            </Segment>
            </div>
            <div style={{flexGrow:1,overflowY:'auto'}}>
                <Menu vertical inverted style={{width:'100%'}}>
                    {ownerOptions}
                    <Menu.Item>
                        Chapters
                        <Menu.Menu>
                            {ownerChapterOptions}
                            {chapterItems}
                        </Menu.Menu>
                    </Menu.Item>

                </Menu>
            </div>
        </div>
    );
};

export default BookSideBar;